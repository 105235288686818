import React, {Component} from 'react';
import {NotLogged} from 'global/styles/styles.js';
import {connect} from 'react-redux';
import {paymentProviders} from 'paymentActions.js';
import Loader from 'Loader.js';
import {translation} from 'helpers/utilsHelper.js';
import { SBPayments } from 'sb-payments-editec'
import _size from 'lodash/size';
import paymentPrepareTransaction from 'paymentPrepareTransaction.enum.js';
import {providerSettingsSelector} from 'paymentsSelector.js';
import S from 'StyledAccountDeposit.js';

class AccountDeposit extends Component {

    componentDidMount = async() => {
        const {dispatch} = this.props;
        const {paymentCurrency} = paymentPrepareTransaction.LIST_SETTINGS;
        const currencyCode = process.env.MAIN_CURRENCY_CODE;

        try {
            paymentCurrency ? await dispatch(paymentProviders(currencyCode)) : await dispatch(paymentProviders()); 
        } catch (error) {
            console.log('paymentProviders error:' + error);
        }
    };

    static getDerivedStateFromError = (error) => {
        return {hasError: true};
    };

    render() {
        const {isPending, hasError, paymentProviders, isLogged} = this.props;
        const {LIST_SETTINGS:{visalbleDepositsIfNotLogged}} = paymentPrepareTransaction;
        const paymentData = paymentProviders.deposits;
        const controller = app.getCurrentController();
        const brandId = app.config.brandId ? app.config.brandId : app.enumerator.Brands.BRANDSMAP[app.config.mainCountryCode.toLowerCase()] !== undefined ? app.enumerator.Brands.BRANDSMAP[app.config.mainCountryCode.toLowerCase()] : '1';
        const lang = `lang_${app.config.lang.toLowerCase()}_${app.config.mainCountryCode.toLowerCase()}_${brandId}_1`
        const { Payments,useSession } = SBPayments
        const session = useSession()
        if (hasError) {
            return <div>Error fallback component!</div>
        }
        
        if (isPending) {
            return <Loader/>
        }

        if (!isLogged && controller != 'AgentDeposit' && !visalbleDepositsIfNotLogged && !isPending) {
            return <NotLogged>{translation('payment_notLogged')}</NotLogged>
        }

        if (paymentData.length > 1) {
            return (
                <Payments lang={lang} session={session} currencyCode={app.config.currency} countryCode={app.config.mainCountryCode}/>
            );
        } else if (paymentData.length == 1) {
            return (
                <Payments lang={lang} session={session} currencyCode={app.config.currency} countryCode={app.config.mainCountryCode}/>
            )
        } else {
            return (
                <S.NoActiveProviders>
                    {translation('payments_noActiveProviders')}
                </S.NoActiveProviders>
            );
        }
    }
}

const mapStateToProps = (state, props) => {
    const {AccountDeposit:{isPending, error, hasError}, Auth:{isLogged, user}} = state;
    const paymentProviders = providerSettingsSelector(state, props);
    return {
        isPending,
        error,
        hasError,
        paymentProviders,
        user,
        isLogged
    }
};

export default connect(mapStateToProps)(AccountDeposit);
