import styled from 'styled-components';

const hostname = window.location.hostname;

let imageDirectory = (hostname) => {
  switch (hostname) {
    case '127.0.0.1':
    case 'localhost':
      imageDirectory = '/';
      return imageDirectory;
    case 'test.premierbetzone.co.tz':
    case 'premierbetzone.co.tz':
    case 'test.premierbetzone.co.ao':
    case 'premierbetzone.co.ao':
    case 'test.sbazone.com':
    case 'sbazone.com':
    case 'test.guineegameszone.com':
    case 'guineegameszone.com':
    case 'test.mercurybetzone.com':
    case 'mercurybetzone.com':
    case 'test.nairabetzone.com':
    case 'nairabetzone.com':
      imageDirectory = `${window.location.origin}/`;
      return imageDirectory;
    default:
      imageDirectory = `${window.location.origin}/${process.env.MAIN_COUNTRY_CODE.toLowerCase()}/`;
      return imageDirectory;
  }
};
imageDirectory(hostname);

const S = {};

S.StyledGamblingControlsHeading = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  height: 5.5rem;
  background-color: ${({ theme: { $gamblingControlsPrimaryColor } }) => $gamblingControlsPrimaryColor};
  font-size: 1.3em;
  font-weight: 700;
  color: #fae100;
`;

S.StyledGamblingControlsIcon = styled.div`
  width: 1.1em;
  height: 1.1em;
  background: url(${imageDirectory}images/svg/gambling-controls.svg) transparent no-repeat 0 0;
`;

S.StyledGamblingControlsLimits = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  font-weight: bold;
  background-color: #fff;
`;

S.StyledGamblingControlsLimitsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

S.StyledGamblingControlsLimitsStatus = styled.div`
  color: ${({ active }) => (active ? '#009a44' : '#e30613')};
`;

S.StyledGamblingControlsLimitsContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

S.StyledGamblingControlsLimitsContentLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 2rem;
`;

S.StyledGamblingControlsLimitsInput = styled.input`
  width: 8rem;
  height: 2.5rem;
  padding: 0 0.5rem;
  font-size: 16px;
  font-weight: bold;
`;

S.StyledGamblingControlsLimitsInfo = styled(S.StyledGamblingControlsLimitsContentLabel)`
  flex-direction: column;
  flex: 1 1 auto;
  align-items: start;
`;

S.StyledGamblingControlsLimitsInfoLabel = styled.span`
  font-weight: bold;
  color: ${({ color, theme: { $gamblingControlsPrimaryColor } }) => (color === 'green' ? $gamblingControlsPrimaryColor : '#696969')};
`;

S.StyledGamblingControlsLimitsInfoDate = styled.span`
  color: #999999;
`;

S.StyledGamblingControlsButton = styled.button`
  border: 0;
  border-radius: 20px;
  width: 6.5rem;
  height: 2.5rem;
  font-size: 14px;
  font-weight: bold;
  color: #fff;

  background-color: ${({ color, theme: { $gamblingControlsPrimaryColor } }) =>
    color === 'green'
      ? $gamblingControlsPrimaryColor
      : color === 'orange'
      ? '#ff8217'
      : color === 'red'
      ? '#e30613'
      : color === 'gray'
      ? '#696969'
      : '#009a44'};
`;

S.StyledGamblingControls = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  font-size: 20px;
  background-color: lightgray;
`;

S.StyledAccountActionModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 2rem;
  text-align: center;
  background-color: #fff;
`;

S.StyledAccountActionModalTitle = styled.div`
  margin-bottom: 2rem;
  font-weight: bold;
  color: ${({ action }) => (action === 'suspendAccount' ? '#ff8217' : action === 'closeAccount' ? '#e30613' : '#000')};
`;

S.StyledAccountActionModalContent = styled.div`
  font-weight: bold;
  margin-bottom: 1.5rem;
`;

S.StyledAccountActionModalButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

S.StyledAccountActionModalButton = styled.button`
  border: none;
  border-radius: 1.5rem;
  padding: 0.9rem 2rem;
  background-color: #000;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background-color: ${({ action }) => (action === 'suspendAccount' ? '#ff8217' : action === 'closeAccount' ? '#e30613' : '#000')};
`;

S.StyledAccountActionModalIcon = styled.div`
  width: 4rem;
  height: 4rem;
  margin-bottom: 0.5rem;
  background-image: url(${({ action }) =>
    action === 'suspendAccount' ? `${imageDirectory}images/svg/suspend_account_warning.svg` : action === 'closeAccount' ? `${imageDirectory}images/svg/close_account_warning.svg` : ''});
  background-position: 0 0;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
`;

S.StyledAccountActionModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
`;

export default S;
