export default {
    LIST_SETTINGS: {
        miniature: true,
        className: 'paymentList'
    },
    PROVIDER: {
      19: {
        steps: 2,
        callData: ['callBankList', 'callAccountTypes'],

        confirmUserData: [
          {transactionAmount: {label: 'payment_transactionAmount'}}        
        ],

        initialize: {
          providerId: '',
          transactionAmount: '',
          transactionCurrency: '',
        },

        stepOne: [
          { ImageComponent: 'bank.png' },

          { ImgAndTextComponent: { 
            imageComponent: 'slice1.png', 
            label: 'payments_fastTransfers' } 
          },
          { ImgAndTextComponent: {
            imageComponent: 'slice2.png', 
            label: 'payments_blik' } 
          },
          { ImgAndTextComponent: { 
            imageComponent: 'slice3.png', 
            label: 'payments_cards' } 
          },
          { ImgAndTextComponent: {
            imageComponent: 'slice4.png', 
            label: 'payments_wallets' } 
          },
          { ImgAndTextComponent: { 
            imageComponent: 'slice5.png', 
            label: 'payments_fastTransactions' } 
          },
          { ImgAndTextComponent: {
            imageComponent: 'slice6.png', 
            label: 'payments_nocommission' } 
          },

          { InputComponent: {
              label: 'payment_transactionAmount',
              name: 'transactionAmount',
              required: 'required'
            }
          },
          { ButtonComponent: {
              label: 'payment_deposit',
              action: 'submit',
              type: 'submitting'
            }
          }
        ],
        stepTwo: [
           { ImageComponent: 'bank.png' },
           { ConfirmDataComponent: {} },
           { GroupComponents: [
              { ButtonComponent: {
                label: 'common_back',
                onClick: 'backStep'
              }},
              { ButtonComponent: {
                label: 'common_accept',
                action: 'submit'
              }},
              { ButtonComponent: {
                label: 'common_cancel',
                onClick: 'resetProvider'
              }}
            ]
          }]
        },

        20: {
            steps: 2, 
            callData: ['callBankList', 'callAccountTypes', 'callCustomerBankDetail', 'callOffersCanceledOnWithdrawal'],

            confirmUserData: [
                {bankId: {label: 'payment_bankLists'}},
                {accountNumber: {label: 'payment_bankAccountNumber'}},
                {accountType: {label: 'payment_accountTypes'}},
                {transactionAmount: {label: 'payment_transactionAmount'}}        
            ],

            initialize: {
                providerId: '',
                transactionCurrency: '',
                bank: {
                    actionOnAccount: 1,
                    additionalInformationsJSON: {},
                    customerName: ''
                }
                
            },

            stepOne: [
                {ImageComponent: 'bank.png'},

                {SelectComponent: {
                    data: 'callBankList',
                    label: 'payment_bankLists',
                    name: 'bank->bankId',
                    value: 'bankId',
                    text: 'bankName',
                    first: 'payment_chooseSelected'
                }},

                {InputComponent: {
                    label: 'payment_bankAccountNumber',
                    name: 'bank->accountNumber',
                    required: 'required'
                }},

                {SelectComponent: {
                    data: 'callAccountTypes',
                    label: 'payment_accountTypes',
                    name: 'bank->accountType',
                    value: 'accountType',
                    text: 'accountTypeName',
                    first: 'payment_chooseSelected'
                }},

                {InputComponent: {
                    label: 'payment_transactionAmount',
                    name: 'transactionAmount',
                    required: 'required',
                    limitsProvider: true
                }},

                {ButtonComponent: {
                    label: 'payment_payout',
                    action: 'submit',
                    type: 'submitting'
                }}
            ],

            stepTwo: [
                {ImageComponent: 'bank.png'},
                {ConfirmOffersCanceledOnWithdrawalComponent: {
                  titleLabel: 'payment_offersCanceledOnWithdrawal',
                  label: 'payment_offersTitle',
                  value: 'payment_offersValue',
                  data: 'callOffersCanceledOnWithdrawal'
                }},
                {ConfirmDataComponent: {}},

                {InputComponent: {
                    label: 'payment_password',
                    name: 'security->securityToken',
                    required: 'required',
                    type: 'password'
                }},

                {GroupComponents: [

                    {ButtonComponent: {
                        label: 'common_back',
                        onClick: 'backStep'
                    }},

                    {ButtonComponent: {
                        label: 'common_accept',
                        action: 'submit'
                    }},

                    {ButtonComponent: {
                        label: 'common_cancel',
                        onClick: 'resetProvider'
                    }}

                ]}
            ]
        },

        103: {
            steps: 2,
            callData: 'callCreditCardList',

            minatureComponent: {
                img: 'paystack.png',
                shortDescription: 'payments_shortDescription_'
            },

            confirmUserData: [
                {cardType: {label: 'payment_selectedCard'}},
                {transactionAmount: {label: 'payment_transactionAmount'}}        
            ],

            initialize: {   
                providerId: '',
                transactionCurrency: '',
                callbackUrl: ''
            }, 

            stepOne: [
                {ImageComponent: 'paystack.png'},

                {SelectComponent: {
                    data: 'callCreditCardList',
                    label: 'payment_cardLists',
                    name: 'card->cardType',
                    value: 'cardType',
                    text: 'cardNumber',
                    first: 'payment_newCard',
                }},

                {InputComponent: {
                    label: 'payment_transactionAmount',
                    name: 'transactionAmount',
                    required: 'required'
                }},

                {ButtonComponent: {
                    label: 'payment_deposit',
                    action: 'submit',
                    type: 'submitting'
                }}
            ],

            stepTwo: [
                {ImageComponent: 'paystack.png'},

                {ConfirmDataComponent: {}},

                {GroupComponents: [

                    {ButtonComponent: {
                        label: 'common_back',
                        onClick: 'backStep'
                    }},

                    {ButtonComponent: {
                        label: 'common_accept',
                        action: 'submit'
                    }},

                    {ButtonComponent: {
                        label: 'common_cancel',
                        onClick: 'resetProvider'
                    }}

                ]}
            ]
        },

        104: {
            steps: 3, 
            isBettingShopTransaction: true,

            minatureComponent: {
                img: 'paystack.png',
                shortDescription: 'payments_shortDescription_'
            },

            confirmUserData: [
                {customerId: {label: 'payment_customerId'}},
                {loginName: {label: 'payment_loginName'}},
                {transactionAmount: {label: 'payment_transactionAmount'}}        
            ],

            initialize: {
                providerId: '',
                transactionCurrency: '',
                isBettingShopTransaction: ''
            },

            stepOne: [
                {ImageComponent: 'paystack.png'},

                {InputComponent: {
                    label: 'payment_customerId',
                    name: 'customerId',
                    required: 'required'
                }},                
                
                {InputComponent: {
                    label: 'payment_loginName',
                    name: 'customer->loginName',
                    required: 'required'
                }},

                {InputComponent: {
                    label: 'payment_transactionAmount',
                    name: 'transactionAmount',
                    required: 'required'
                }},

                {ButtonComponent: {
                    label: 'payment_deposit',
                    action: 'submit',
                    type: 'submitting'
                }}
            ],

            stepTwo: [
                {ImageComponent: 'paystack.png'},

                {ConfirmDataComponent: {}},

                {GroupComponents: [

                    {ButtonComponent: {
                        label: 'common_back',
                        onClick: 'backStep'
                    }},

                    {ButtonComponent: {
                        label: 'common_accept',
                        action: 'submit'
                    }},

                    {ButtonComponent: {
                        label: 'common_cancel',
                        onClick: 'resetProvider'
                    }}

                ]}
            ]

        },

        113: {
            steps: 2, 
            callData: 'callPaystackBankList',

            minatureComponent: {
                img: 'paystack.png',
                shortDescription: 'payments_shortDescription_'
            },

            confirmUserData: [
                {cbnCode: {label: 'payment_bankLists'}},
                {accountNumber: {label: 'payment_bankAccountNumber'}},
                {additionalData: {label: 'payment_additionalData'}},
                {transactionAmount: {label: 'payment_transactionAmount'}}        
            ],

            initialize: {
                providerId: '',
                transactionCurrency: '',
                customer: {
                    email: '',
                    firstName: '',
                    lastName: ''
                }
            },

            stepOne: [
                {ImageComponent: 'paystack.png'},

                {SelectComponent: {
                    data: 'callPaystackBankList',
                    label: 'payment_bankLists',
                    name: 'bank->cbnCode',
                    value: 'cbnCode',
                    text: 'bankName',
                    first: 'payment_chooseSelected',
                    required: 'required'
                }},

                {InputComponent: {
                    label: 'payment_bankAccountNumber',
                    name: 'bank->accountNumber',
                    required: 'required'
                }},

                {InputComponent: {
                    label: 'payment_additionalData',
                    name: 'additionalData',
                    type: 'date',
                    required: 'required',
                    className: 'without-floating-label'
                }},

                {InputComponent: {
                    label: 'payment_transactionAmount',
                    name: 'transactionAmount',
                    required: 'required'
                }},

                {ButtonComponent: {
                    label: 'payment_deposit',
                    action: 'submit',
                    type: 'submitting'
                }}
            ],

            stepTwo: [
                {ImageComponent: 'paystack.png'},

                {ConfirmDataComponent: {}},

                {GroupComponents: [

                    {ButtonComponent: {
                        label: 'common_back',
                        onClick: 'backStep'
                    }},

                    {ButtonComponent: {
                        label: 'common_accept',
                        action: 'submit'
                    }},

                    {ButtonComponent: {
                        label: 'common_cancel',
                        onClick: 'resetProvider'
                    }}

                ]}
            ],

            stepThree: [
              {ImageComponent: 'paystack.png'},

              {InputComponent: {
                label: 'payment_verificationCode',
                name: 'verificationCode',
                required: 'required'
              }},

              {GroupComponents: [

                  {ButtonComponent: {
                      label: 'common_back',
                      onClick: 'backStep'
                  }},

                  {ButtonComponent: {
                      label: 'common_accept',
                      action: 'submit'
                  }},

                  {ButtonComponent: {
                      label: 'common_cancel',
                      onClick: 'resetProvider'
                  }}

              ]}
            ]
        },

        234: {
        steps: 2,
        callData: 'callMobilePhoneNumbers',
        confirmUserData: [
          { phoneNumber: {label: 'payment_phoneNumber'} }, 
          { transactionAmount: {label: 'payment_transactionAmount'} },      
        ],
        initialize: {
          providerId: '',
          transactionAmount: '',
          transactionCurrency: '',
          phonePrefix: '',
          phoneNumber: '',
        },

        stepOne: [
          { ImageComponent: 'mtn.png' },           
          { GroupComponents: [
            { InputComponent: {
                name: 'mobilePrefix',
                disabled: 'disabled',
              }
            },
            { InputComponent: {
                label: 'payment_phoneNumber',
                name: 'phoneNumber',
                type: 'text',
                required: 'required',
              }
            }
          ]},
          { InputComponent: {
              label: 'payment_transactionAmount',
              name: 'transactionAmount',
              required: 'required',
              type: 'text',
            }
          },
          { ButtonComponent: {
              label: 'payment_deposit',
              action: 'submit',
              type: 'submitting'
            }
          }
        ],
        stepTwo: [
           { ImageComponent: 'mtn.png' },
           { ConfirmDataComponent: {} },
           { GroupComponents: [
              { ButtonComponent: {
                label: 'common_back',
                onClick: 'backStep'
              }},
              { ButtonComponent: {
                label: 'common_accept',
                action: 'submit'
              }},
              { ButtonComponent: {
                label: 'common_cancel',
                onClick: 'resetProvider'
              }}
            ]
          }]
        },

        235: {
          steps: 2,
          callData: 'callMobilePhoneNumbers',
          confirmUserData: [
            { phoneNumber: {label: 'payment_phoneNumber'} }, 
            { transactionAmount: {label: 'payment_transactionAmount'} },      
          ],
          initialize: {
            providerId: '',
            transactionAmount: '',
            transactionCurrency: '',
            phonePrefix: '',
            phoneNumber: '',
          },
  
          stepOne: [
            { ImageComponent: 'mtn.png' },           
            { GroupComponents: [
              { InputComponent: {
                  name: 'mobilePrefix',
                  disabled: 'disabled'
                }
              },
              { InputComponent: {
                  label: 'payment_phoneNumber',
                  name: 'phoneNumber',
                  type: 'text',
                  required: 'required',
                }
              }
            ]},
            { InputComponent: {
                label: 'payment_transactionAmount',
                name: 'transactionAmount',
                required: 'required',
                type: 'text',
              }
            },
            { ButtonComponent: {
                label: 'payment_payout',
                action: 'submit',
                type: 'submitting'
              }
            }
          ],
          stepTwo: [
             { ImageComponent: 'mtn.png' },
             { ConfirmDataComponent: {} },
             { GroupComponents: [
                { ButtonComponent: {
                  label: 'common_back',
                  onClick: 'backStep'
                }},
                { ButtonComponent: {
                  label: 'common_accept',
                  action: 'submit'
                }},
                { ButtonComponent: {
                  label: 'common_cancel',
                  onClick: 'resetProvider'
                }}
              ]
            }]
          },

          238: {
            steps: 2, 
            insideIframe: true,

            minatureComponent: {
                img: 'paystack.png',
                shortDescription: 'payments_shortDescription_'
            },

            confirmUserData: [
                {transactionAmount: {label: 'payment_transactionAmount'}}        
            ],

            initialize: {
                providerId: '',
                transactionCurrency: '',
                callbackUrl: ''
            },

            stepOne: [
                {ImageComponent: 'paystack.png'},

                {InputComponent: {
                    label: 'payment_transactionAmount',
                    name: 'transactionAmount',
                    required: 'required'
                }},

                {ButtonComponent: {
                    label: 'payment_deposit',
                    action: 'submit',
                    type: 'submitting'
                }}
            ],
            stepTwo: [
              { ImageComponent: 'paystack.png' },
              { ConfirmDataComponent: {} },
              { GroupComponents: [
                 { ButtonComponent: {
                   label: 'common_back',
                   onClick: 'backStep'
                 }},
                 { ButtonComponent: {
                   label: 'common_accept',
                   action: 'submit'
                 }},
                 { ButtonComponent: {
                   label: 'common_cancel',
                   onClick: 'resetProvider'
                 }}
               ]
             }]
          },

          255: {
            steps: 2, 
            isBettingShopTransaction: true,
            renderAccount: true,
            autoInitialize: true,

            minatureComponent: {
                img: 'monnify.png',
                shortDescription: 'payments_shortDescription_'
            },

            confirmUserData: [
                {bankName: {label: 'payment_bankName'}},  
                {accountNumber: {label: 'payment_bankAccountNumber'}},
                {accountName: {label: 'payment_bankAccountName'}},
                {cbnCode: {label: 'payment_cbnCode'}}
            ],

            initialize: {
                providerId: '',
                transactionCurrency: '',
            },

            stepOne: [
                {ImageComponent: 'monnify.png'},

                {ButtonComponent: {
                    label: 'payment_reservation',
                    action: 'submit',
                    type: 'submitting'
                }}
            ],
            stepTwo: [
              { ImageComponent: 'monnify.png' },
              { ConfirmDataComponent: {} },

              { ImgAndTextComponent: { 
                imageComponent: 'exclamation-mark.svg', 
                label: 'payments_monnifyTransfer' } 
              }
            ]
          },

          257: {
            steps: 2, 
            insideIframe: true,

            minatureComponent: {
                img: 'monnify.png',
                shortDescription: 'payments_shortDescription_'
            },

            confirmUserData: [
                {transactionAmount: {label: 'payment_transactionAmount'}}        
            ],

            initialize: {
                providerId: '',
                transactionCurrency: '',
                callbackUrl: ''
            },

            stepOne: [
                {ImageComponent: 'monnify.png'},

                {InputComponent: {
                    label: 'payment_transactionAmount',
                    name: 'transactionAmount',
                    required: 'required',
                    limitsProvider: true
                }},

                {ButtonComponent: {
                    label: 'payment_deposit',
                    action: 'submit',
                    type: 'submitting'
                }}
            ],
            stepTwo: [
              { ImageComponent: 'monnify.png' },
              { ConfirmDataComponent: {} },
              { GroupComponents: [
                 { ButtonComponent: {
                   label: 'common_back',
                   onClick: 'backStep'
                 }},
                 { ButtonComponent: {
                   label: 'common_accept',
                   action: 'submit'
                 }},
                 { ButtonComponent: {
                   label: 'common_cancel',
                   onClick: 'resetProvider'
                 }}
               ]
             }]
          },

          269: {
            steps: 2, 
            insideIframe: true,

            minatureComponent: {
                img: 'gladePay.png',
                shortDescription: 'payments_shortDescription_'
            },

            confirmUserData: [
                {transactionAmount: {label: 'payment_transactionAmount'}}        
            ],

            initialize: {
                providerId: '',
                transactionCurrency: '',
                callbackUrl: ''
            },

            stepOne: [
                {ImageComponent: 'gladePay.png'},

                {InputComponent: {
                    label: 'payment_transactionAmount',
                    name: 'transactionAmount',
                    required: 'required'
                }},

                {ButtonComponent: {
                    label: 'payment_deposit',
                    action: 'submit',
                    type: 'submitting'
                }}
            ],
            stepTwo: [
              { ImageComponent: 'gladePay.png' },
              { ConfirmDataComponent: {} },
              { GroupComponents: [
                 { ButtonComponent: {
                   label: 'common_back',
                   onClick: 'backStep'
                 }},
                 { ButtonComponent: {
                   label: 'common_accept',
                   action: 'submit'
                 }},
                 { ButtonComponent: {
                   label: 'common_cancel',
                   onClick: 'resetProvider'
                 }}
               ]
             }]
          },
          275: {
            steps: 2, 
            insideIframe: true,
            submitHiddenForm: true,
            minatureComponent: {
                img: 'gtbank.png',
                shortDescription: 'payments_shortDescription_'
            },

            confirmUserData: [
                {transactionAmount: {label: 'payment_transactionAmount'}}        
            ],

            initialize: {
                providerId: '',
                transactionCurrency: '',
                callbackUrl: '',
                customer: {
                  firstName: '',
                  lastName: ''
              }
            },

            stepOne: [
                {ImageComponent: 'gtbank.png'},

                {InputComponent: {
                    label: 'payment_transactionAmount',
                    name: 'transactionAmount',
                    required: 'required'
                }},

                {ButtonComponent: {
                    label: 'payment_deposit',
                    action: 'submit',
                    type: 'submitting'
                }}
            ],
            stepTwo: [
              // { ImageComponent: 'gtbank.png' },
              { ConfirmDataComponent: {} },
              { GroupComponents: [
                 { ButtonComponent: {
                   label: 'common_back',
                   onClick: 'backStep'
                 }},
                 { ButtonComponent: {
                   label: 'common_accept',
                   action: 'submit'
                 }},
                 { ButtonComponent: {
                   label: 'common_cancel',
                   onClick: 'resetProvider'
                 }}
               ]
             }]
          }

    }
};