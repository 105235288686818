import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';
import {EXCLUDE_ACCOUNT_UPDATE_CUSTOMER_LIMITS, RESET_TO_INITIAL_STATE} from 'responsibleGameActions.js';

let initialState = {
    suspension: {
        period: 1,
        error: null,
        isPending: false
    },
    exclusion: {
        period: 90,
        error: null,
        isPending: false
    }
};

const accountResponsibleGameSelfExclusionInitialState = {...initialState};

export default typeToReducer({
    [EXCLUDE_ACCOUNT_UPDATE_CUSTOMER_LIMITS]: {
        PENDING: (state, {payload: {type}}) => {
            return update(state, {[type]: {isPending: {$set: true}}})
        },
        FAILURE: (state, {payload: {error, type}}) => {
            error = String(error);
            return update(state, {[type]: {isPending: {$set: false}, error: {$set: error}}});
        },
        SUCCESS: (state, {payload: {type}}) => {
            return update(state, {[type]: {isPending: {$set: false}, error: {$set: null}}});
        }
    },
    [RESET_TO_INITIAL_STATE]: (state) => {
        return accountResponsibleGameSelfExclusionInitialState;
    }
}, initialState);
