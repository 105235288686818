import {formatDate} from 'helpers/datesHelper.js';
const encode = encodeURIComponent;
const today = new Date();

export default (requests, axios) => {
    return {
        getTransactionsByFilter: ({activeTab = 'all', endDate = today, startDate = today, limit = 10, currentPage = 0, activeFilter = 'all'}={}) => {
            const dateFrom = formatDate(startDate, 'yyyy-MM-dd');
            const dateTo = formatDate(endDate, 'yyyy-MM-dd');
            return requests.get(`/transaction/transactions/${dateFrom}/${dateTo}/${limit}/${currentPage}/${activeTab}${(activeFilter !='all' ? `?eventsSlipType=${activeFilter}` : '')}`);
        },
        getCashouts: ({endDate = today, startDate = today}={}) => {
            const dateFrom = formatDate(startDate, 'yyyy-MM-dd');
            const dateTo = formatDate(endDate, 'yyyy-MM-dd');
            return requests.get(`/cashout/bets/${dateFrom}/${dateTo}/`);
        },
        getMultiCashouts: (betSlipsIds = []) => {
            return requests.get(`/cashout/multi/maxAmount/${betSlipsIds.join(',')}`);
        },
        cashoutMaximumAmount: (slipId) => {
            return requests.get(`/cashout/max/${slipId}`);
        },
        cashoutPartialAmount: (slipId, data) => {
            return requests.post(`/cashout/partial/${slipId}/`, data);
        },
        cashoutFullAmount: (slipId, data) => {
            return requests.post(`/cashout/full/${slipId}/`, data);
        },
        cashoutPrepareLiveCountDown: (slipId, data) => {
            return requests.post(`/cashout/prepare-live-countdown/${slipId}`, data);
        }
    }
};
