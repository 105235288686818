import React from 'react';
import { RotateSpinLoader  } from 'react-css-loaders';
import S from 'StyledLoader.js';

const Loader = (props) => {
    const {size, color, className} = props;
    return (
        <S.LoaderWrapper className={`loader ${className}`}>
            <S.RotateSpinLoader size={size} color={color}></S.RotateSpinLoader>
        </S.LoaderWrapper>
    );
};

export default React.memo(Loader);