// action creators
import {translation} from 'utilsHelper.js';

export const LOAD_CMS_PAGE = 'LOAD_CMS_PAGE';

// action creators
const cmsPage = (page, errorPage = null, isPlaces = false, alternativeContent = null, countryCode = null, languageCode = null, brand = null, isLogged = false) => {
    return async(dispatch, getState, {CmsApi}) => {

        const {CmsPages: {isPending}} = getState();
        if (!isPending) {
            dispatch(loadCmsPagePending());
        }

        let rest;
        if(isLogged && app.config.segmentationInCMS) {
            rest = CmsApi.getCmsPage(errorPage||page, countryCode, languageCode, brand, true, true);
        } else if(!isLogged && app.config.segmentationInCMS) {
            rest = CmsApi.getCmsPage(errorPage||page, countryCode, languageCode, brand, true, false);
        } else {
            rest = CmsApi.getCmsPage(errorPage||page, countryCode, languageCode, brand);
        }

        try {
            const {code, data = {}} = await rest;
            if(code != 200) {
                if (code == 404) {
                    const errorPageRedirect = process.env.ERROR_PAGE_REDIRECT && JSON.parse(process.env.ERROR_PAGE_REDIRECT);
                    if(alternativeContent) {
                        return dispatch(cmsPage(page, alternativeContent, isPlaces));
                    } else if(errorPageRedirect && !isPlaces){
                        window.location.href= '/error-pages/404';
                    } else if (!isPlaces) {
                        return dispatch(cmsPage(page, 'error404'));
                    }
                } else {
                    throw {message: translation(`cmsPage_error${code}`), code};
                }
            }

            const cmsPageData = {name: page, data, code};
            dispatch(loadCmsPageSuccess(cmsPageData));

            return cmsPageData;
        } catch ({message}) {
            dispatch(loadCmsPageFailure(message));
            throw message;
        }
    }
};

const createMarkup = (html) => {
    return {__html: html};
}

const loadCmsPageFailure = (error) => {
    return {
        type: `${LOAD_CMS_PAGE}_FAILURE`,
        payload: {error}
    }
};

const loadCmsPageSuccess = (cmsData) => {
    return {
        type: `${LOAD_CMS_PAGE}_SUCCESS`,
        payload: {cmsData}
    }
};

const loadCmsPagePending = () => {
    return {
        type: `${LOAD_CMS_PAGE}_PENDING`
    }
};

export {cmsPage, createMarkup};