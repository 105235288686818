const encode = encodeURIComponent;
import _get from 'lodash/get';
import _toLower from 'lodash/toLower';

export default (requests, axios) => {
    return {
        getFavouriteLeagues: () => {
            const config = {};
            return requests.get('/favourites/getFavouriteLeagues', config);
        },
        
        updateFavouriteLeagues: (leagueId, status) => {
            const config = {};
            if (CLIENT == 'europebet') {
                const lang = Cookies.get('Language');
                config['headers'] = {..._get(config, 'headers', {}), 'Request-Language': (lang || 'en')};
            }
            return requests.post(`/favourites/updateFavouriteLeagues/${leagueId}/${status}`, {}, config);
        },

        getFavouritesByType: (type) => {
            return requests.get(`/favourites?type=${encode(_toLower(type))}`);
        },

        updateFavouritesByType: (id, status, type) => {
            const params = {
                type,
                data: id,
                remove: !status
            };
            return requests.post(`/favourites`, params);
        }
    }
};
