import monitoringError from 'monitoringError.enum.js';
import {device, browser} from 'utilsHelper.js';

const proccessFrontError = (error, component) => {
  
  return (dispatch, getState, {ErrorApi}) => {

    if(!process.env.NOTIFY_FRONT_ERRORS || !error || !error.name ){
      return;
    }

    let {requestCount, validTo} = getCurrentRequestData('rapc');
    if(requestCount > parseInt(process.env.NOTIFY_FRONT_ERRORS_REQUEST_LIMIT_COUNT) && new Date().getTime() < validTo){
      return;
    }

    const {Auth:{user}} = getState();
    const errorType = monitoringError.getErrorByName(error.name);
    const payload = {
      'errorCode': errorType.id,
      'errorDescription': error.message,
      'customerId': user?.userId,
      'componentName': component,
      'url': location.href,
      'version': getVersion()
    };
    
    getAdditionalParams(payload);
    ErrorApi.reportError(payload);
    setCurrentRequestData(payload);
  }
    
}

const getVersion = () => {
  const script = $('#appJSVersion')?.[0].src;
  if(script?.split('?rel=').length > 1){
    return script.split('?rel=')[1];
  }
}

const getAdditionalParams = (payload) => {

  payload['channel'] = "desktop";
  payload['browser'] = browser();
  payload['device'] = device();
}

const getCurrentRequestData = (key) => {
  const data = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : '';
  if(data){
    const {requestCount, validTo} = data;
    return {requestCount, validTo};
  } else{
    return {
      requestCount: 0,
      validTo: new Date().getTime() + (parseInt(process.env.NOTIFY_FRONT_ERRORS_REQUEST_TIME_LIMIT)*60*1000)};
  }
}

const setCurrentRequestData = (requestCount, validTo) => {

  if(new Date().getTime() > validTo){
    validTo = new Date().getTime() + (parseInt(process.env.NOTIFY_FRONT_ERRORS_REQUEST_TIME_LIMIT)*60*1000);
    requestCount = 0;
  }
  
  requestCount++;
  const config = {
    requestCount,
    validTo: validTo
  };

  localStorage.setItem('rapc', JSON.stringify(config));
}

export {proccessFrontError}