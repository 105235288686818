
import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { SBPayments } from 'sb-payments-editec'
import _size from 'lodash/size';
import {paymentProviders} from 'paymentActions.js';
import Loader from 'Loader.js';
import {translation} from 'helpers/utilsHelper.js';
import paymentPrepareTransaction from 'paymentPrepareTransaction.enum.js';
import S from 'StyledAccountWithdraw.js';
import {NotLogged} from 'global/styles/styles.js';
import {providerSettingsSelector} from 'paymentsSelector.js';

export const AccountWithdraw = () => {
    const { Withdraw, useSession } = SBPayments
    const dispatch = useDispatch()
    const { 
        AccountWithdraw: {
            isPending, 
            error, 
            hasError
        },
        Auth:{
            isLogged, 
            user
        },
        providers: {payouts}
        } = useSelector((state) => ({
            ...state,
            providers: providerSettingsSelector(state)
        }));
    const session = useSession()

    const {paymentCurrency} = paymentPrepareTransaction.LIST_SETTINGS;
    const currencyCode = process.env.MAIN_CURRENCY_CODE;
    const {LIST_SETTINGS:{visalbleDepositsIfNotLogged}} = paymentPrepareTransaction;
    const brandId = app.config.brandId ? app.config.brandId : app.enumerator.Brands.BRANDSMAP[app.config.mainCountryCode.toLowerCase()] !== undefined ? app.enumerator.Brands.BRANDSMAP[app.config.mainCountryCode.toLowerCase()] : '1';
    const lang = `lang_${app.config.lang.toLowerCase()}_${app.config.mainCountryCode.toLowerCase()}_${brandId}_1`

    const fetchProviders = async() => {
        try {
            paymentCurrency ? await dispatch(paymentProviders(currencyCode)) : await dispatch(paymentProviders()); 
        } catch (error) {
            console.log('paymentProviders error:' + error);
        }
    }

    useEffect(() => {
        fetchProviders();
    }, [])

    return (
        hasError ? <div>Error fallback component!</div>
        
        : isPending ? <Loader/>

        : !isLogged && !visalbleDepositsIfNotLogged ? <NotLogged>{translation('payment_notLogged')}</NotLogged>
        
        : payouts?.length > 0 ?
            <Withdraw lang={lang} session={session} currencyCode={app.config.currency} countryCode={app.config.mainCountryCode}/>
        : (
            <S.NoActiveProviders>
                {translation('payments_noActiveProviders')}
            </S.NoActiveProviders>
        )
    )
}
