import styled from 'styled-components';
import {Link} from 'global/styles/styles.js';
const S = {};

const hostname = window.location.hostname

let imageDirectory = (hostname) => {

    switch (hostname) {
        case 'localhost':
            imageDirectory = '/';
            return imageDirectory;
            break;
        case 'test.premierbetzone.co.tz':
        case 'premierbetzone.co.tz':
        case 'test.premierbetzone.co.ao':
        case 'premierbetzone.co.ao':
        case 'test.sbazone.com':
        case 'sbazone.com':
        case 'test.guineegameszone.com':
        case 'guineegameszone.com':
        case 'test.mercurybetzone.com':
        case 'mercurybetzone.com':
        case 'test.nairabetzone.com':
        case 'nairabetzone.com':
            imageDirectory = `${window.location.origin}/`
            return imageDirectory;
            break;
        default:
            imageDirectory = `${window.location.origin}/${process.env.MAIN_COUNTRY_CODE.toLowerCase()}/`
            return imageDirectory;
    }
};

imageDirectory(hostname)

S.MenuLink = styled(Link)`
    display:flex;
    margin:0 50px 0 auto;
    color: #F2BB2B;
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: bold;
    font-family: 'Calibri';
    font-style: normal;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
        text-decoration: none;
        cursor:pointer;
    }
`;

S.TopMenu = styled.div`
    position: fixed;
    top:0;
    min-height: 1px;
    padding:0.2rem 0;
    float: left;
    width: 100%;
    background-color: ${({theme:{$topMenuTopBarBgColor}})=> $topMenuTopBarBgColor};
    box-sizing: border-box;
    z-index: 201;
`;

S.MainRow = styled.div`
    display: flex;
    height: 2.67rem;
    justify-content: flex-end;
    position: relative;
    background-color: ${({theme:{$topMenuTopBarBgColor}})=> $topMenuTopBarBgColor};
    z-index: 2;
    width: 100%;
    padding: 0 2rem 0 .70834rem;
`;

S.MenuRow = styled.div`
    display: flex;
    align-items: center;
    height: 4.9rem;
    width: 100%;
    padding: 0 2rem 0 0;
`;

S.Link = styled.a`
    display: flex;
    min-width:23rem;
    align-items: center;
    padding-left: 1.2rem;
    position: relative;
    &:after {
        content: '';
        right: -2rem;
        position: absolute;
        width: 0;
        height: 0;
    }
`;

S.LanguageChoice = styled.div`
    margin-left: 1rem;
`;

S.LanguageField = styled.div`
    display: flex;
    background-color: ${({ theme: { $topMenuLanguageFieldColor } }) => $topMenuLanguageFieldColor};
    width: 15.2rem;
    border-bottom: 1px solid ${({ theme: { $topMenuSubMenuBackgroundColor } }) => $topMenuSubMenuBackgroundColor};
    font-size: 10px;
    align-items: center;
    height: 2.8rem;
    border-radius:1.3125rem;
    padding-left:0.625rem;
    cursor: pointer;
    &.active {
        font-size: 12px;
    }
    &.hidden {
        display: none;
    }
`;
S.IconZoneGold = styled.div`
    width:19px;
    height : 19px;
    margin-left:11px;
    background-image:url(${imageDirectory}images/zonegold-icon.svg) ;
`
S.LanguageFlag = styled.div`
    width: 24px;
    height: 16px;
    margin-left: 0.25rem;
    background-size: cover;
    &.en {
        background-image: url(${imageDirectory}images/svg/en.svg);
    }
    &.fr {
        background-image: url(${imageDirectory}images/svg/fr.svg);
    }
    &.pt {
        background-image: url(${imageDirectory}images/svg/pt.svg);
    }
    &.sw {
        background-image: url(${imageDirectory}images/svg/sw.svg);
    }
`;

S.LanguageText = styled.div`
    color: #000;
    padding: 0.5rem;
    flex: 1;
    font-weight: bold
`;

S.LanguageChevron = styled.div`
    width: 1rem;
    margin-right: 0.75rem;
    transition: 0.3s;
    &.active {
        transform: rotate(180deg);
    }
`;

S.MenuContent = styled.div`
    display: flex;
    align-items: center;
    height: 100%;

`;

S.Logo = styled.img`
    width: 100%;
    height: auto;
`;

export default S;