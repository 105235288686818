import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';

import {
    FETCH_POPULAR_CATEGORIES,
} from 'categoriesActions.js';

let initialState = {
    isPending: true,
    error: null,
    popularCategories: []
};

export default typeToReducer({
    [FETCH_POPULAR_CATEGORIES]: {
        PENDING: (state, action) => {
            return {...state}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {popularCategories}}) => {
            return {...state, isPending: false, popularCategories};
        }
    }
}, initialState);
