import {getCustomerBettingStatus, startLoadCustomerDataInterval, stopLoadCustomerDataInterval, getCustomerSegmentStatus} from 'customerActions.js';
import {startSessionTimeInterval, stopSessionTimeInterval} from 'accountActions.js';
import {integrationBetgeniusCreateScript,createAndInitSmarticoData} from 'integrationActions.js';
import {translation, device, browser, generateId} from 'utilsHelper.js';
import {formatDate} from 'datesHelper.js';
import {removeSessionCookie, setAutologinCookies, removeAutologinCookies, getSessionCookie} from 'cookiesHelper.js';
import {proccessFrontError} from 'errorActions.js';
import {refreshPrematchOddsByOutcomeId} from 'betSlipActions.js';
import store from 'store';
import _get from 'lodash/get';
import _omit from 'lodash/omit';

// actions name
export const USER_FROM_COOKIE = 'USER_FROM_COOKIE';
export const USER_SIGNIN = 'USER_SIGNIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const CLEAR_BETSLIP_FREEBET_AND_PROMOTION = 'CLEAR_BETSLIP_FREEBET_AND_PROMOTION';
export const USER_LOAD_DATA = 'USER_LOAD_DATA';
export const UPDATE_USER_BALANCE = 'UPDATE_USER_BALANCE';
export const UPDATE_BALANCE = 'UPDATE_BALANCE';
export const BRANDS_DATA = 'BRANDS_DATA';

// actions creator
const registerUser = (user) => {
    return async(dispatch, getState, {AuthApi}) => {

        // set channel to desktop
        user['channel'] = "desktop";
        // check browser
        user['browser'] = browser();
        // check device
        user['device'] = device();

        try {
            const {code, data, description} = await AuthApi.registerUser(user);

            if (code == 10101) {
                throw {message: translation(`login_api_error_${code}`)};
            } else if (code != 200) {
                throw {message: translation(`login_api_error_${code}`)};
            } else {
                return data;
            }
        } catch ({message}) {
            throw message;
        }
    };
};

const verificationCode = (mobile)=>{
    return async(dispatch, getState, {AuthApi}) =>{
        try {
            const {code, data} = await AuthApi.verificationCode(mobile);
            if (code == 200) {
                return data;
            }
            throw translation(`verificationCode_error`);
        } catch (error) {
            throw error;
        }
    }
};

const preregisterUser = (user) => {
    return async(dispatch, getState, {AuthApi}) => {
        try {
            const {code, data} = await AuthApi.preregisterUser(user);
            if (code == 200) {
                return data;
            }
        } catch (error) {
        }
    }
};

const logInUser = (user) => {

    // set channel to desktop
    user['channel'] = "desktop";
    // check browser
    user['browser'] = browser();
    // check device
    user['device'] = device();

    return async(dispatch, getState, {AuthApi, CustomerApi}) => {

        dispatch(signUpUserPending());

        try {

            const hasAutologin = _get(user, ['autologin']);
            if(hasAutologin){
                user = _omit(user, ['autologin']);
                user['uniqueHash'] = generateId(16);
            }
            const {code, data, description} = await AuthApi.logInUser(user);

            if (code == 10101) {
                throw {message: translation(`login_api_error_${code}`)};
            } else if(code == 485){
                let exclusionDate = new Date(description.replace("CEST", "").replace("CET", ""));
                exclusionDate = formatDate(exclusionDate, 'yyyy-MM-dd HH:mm');
                throw {message: translation(`login_api_error_${code}`, [exclusionDate])};

            } else if(code == 403){
              dispatch({
                type: 'TOGGLE_BY_KEY',
                payload: {key: 'TOGGLE_FORBIDDEN_ACCESS_MODAL', isOpen: true}
              });
              throw {message: translation(`access_forbidden`)};
            } else if (code != 200) {
                throw {message: translation(`login_api_error_${code}`)};

            } else {

                return logInUserSuccess(data, dispatch, getState, CustomerApi);
            }
        } catch (error) {
            dispatch(signUpUserFailure(error.message));
            dispatch(proccessFrontError(error, "AuthActions.logInUser()"));
            throw error.message;
        }
    }
};

const logInUserSuccess = async (data, dispatch, getState, CustomerApi) => {

    const {uniqueHash, passwordHash, accountHash} = data;
    if (uniqueHash && passwordHash && accountHash) {
        const cookieParams = {uniqueHash, passwordHash, accountHash};
        let expirationTime = process.env.AUTO_LOGIN_COOKIES_EXPIRATION_TIME && JSON.parse(process.env.AUTO_LOGIN_COOKIES_EXPIRATION_TIME);
        setAutologinCookies(cookieParams, expirationTime);
    }

    const checkCustomerRegulations = process.env.CHECK_CUSTOMER_REGULATIONS;
    if (checkCustomerRegulations) {
        await dispatch(getCustomerBettingStatus());
    }

    if(CLIENT == 'fuksiarz') {
        const { data: verificationStatusData } = await CustomerApi.getVeryficationStatus();
        let daysFromRegistration = (new Date() - new Date(verificationStatusData.account.registeredOn)) / (1000 * 60 * 60 * 24);
        data['daysFromRegistration'] = daysFromRegistration;
    }

    dispatch(signUpUserSuccess(data));
    dispatch(startLoadCustomerDataInterval());

    if (app.config.fofEnabled?.fofPerCustomerEnabled) {
        localStorage.setItem('_fof_parameters', JSON.stringify(data.fofData));
        const {BetSlip:{activeTab, betSlips}} = getState();
        const outcomesOnTab = _get(betSlips, [activeTab, 'outcomes']);
        let freshoutcomeOddsData = [];
        outcomesOnTab.forEach(outcome => {
            if(outcome.eventType == 1){
                const dataForFOF = {eventId: outcome.eventId,
                    category3Id: outcome.sportId,
                    category2Id: outcome.countryId,
                    category1Id: outcome.categoryId,
                    eventType: outcome.eventType,
                    gameType: outcome.gameType,
                    outcome: {outcomeId: outcome.outcomeId, outcomeOdds: outcome.outcomeOdds}
                }
                const freshoutcomeOdds = app.service.FlexibleOdds.convertSingleOddsForFof(dataForFOF);
                freshoutcomeOddsData.push({outcomeId: outcome.outcomeId, outcomeOdds: freshoutcomeOdds, outcomeOddsWithoutFof: outcome.outcomeOdds});
            }
        })
        dispatch(refreshPrematchOddsByOutcomeId(freshoutcomeOddsData));
        
        if(!CLIENT == 'fuksiarz' && !CLIENT == 'etoto'){
            app.router.reloadView(); 
        }
    }

    if(CLIENT == 'fuksiarz' || CLIENT == 'etoto' || CLIENT == 'europebet'){
        await dispatch(getCustomerSegmentStatus())
        app.router.reloadView();
    }

    if(CLIENT == 'fuksiarz'){
        app.service.Customer.validateCustomerConsents(); // for regulations modal (spike)
    }

    if(CLIENT == 'fuksiarz' || CLIENT == 'etoto'){
        dispatch(startSessionTimeInterval());
    }
    if (process.env.BETGENIUS_TAGS_INTEGRATION_SCRIPT == 'true'){
        integrationBetgeniusCreateScript('loggedin', {AccountID:data.userId});
    }

    if(app.config.smarticoIsEnabled){
        createAndInitSmarticoData(data)
    }


    return data;

};


const logOutUser = (clickedByUser = false) => async (dispatch, getState, {AuthApi}) => {

    try{
        const sessionCookie = getSessionCookie();
        AuthApi.logoutUser(sessionCookie);
    }catch(error){
        console.log('logOutUser: ', error);
        dispatch(proccessFrontError(error, "AuthActions.logOutUser()"));
    }

    dispatch({type: CLEAR_BETSLIP_FREEBET_AND_PROMOTION});
    dispatch({type: USER_LOGOUT});
    dispatch(stopLoadCustomerDataInterval());

    clickedByUser && removeAutologinCookies();

    if(CLIENT == 'fuksiarz' || CLIENT == 'etoto') {
        dispatch(stopSessionTimeInterval());
    }

    removeSessionCookie();

    if (app.config.fofEnabled?.fofPerCustomerEnabled) {
        localStorage.removeItem('_fof_parameters');
        localStorage.removeItem('_event_cache_time_');
        app.service.EventsApi.refreshTime = {};
        const {BetSlip:{activeTab, betSlips}} = getState();
        const outcomesOnTab = _get(betSlips, [activeTab, 'outcomes']);
        let freshoutcomeOddsData = [];
        outcomesOnTab.forEach(outcome => {
            if(outcome.eventType == 1){
                freshoutcomeOddsData.push({outcomeId: outcome.outcomeId, outcomeOdds: outcome.outcomeOddsWithoutFof != null ? outcome.outcomeOddsWithoutFof : outcome.outcomeOdds, outcomeOddsWithoutFof: outcome.outcomeOddsWithoutFof != null ? outcome.outcomeOddsWithoutFof : outcome.outcomeOdds});
            }
        })
        dispatch(refreshPrematchOddsByOutcomeId(freshoutcomeOddsData));
                    
        if(!CLIENT == 'fuksiarz' && !CLIENT == 'etoto'){
            app.router.reloadView(); 
        }
    }

    if(CLIENT == 'fuksiarz' || CLIENT == 'etoto' || CLIENT == 'europebet' ) {
        await dispatch(getCustomerSegmentStatus());
        app.router.reloadView();
    }

    if (CLIENT == 'editec') {
        setTimeout(()=>app.router.reloadView(), 500);
    }

    if(app.config.smarticoIsEnabled){
        createAndInitSmarticoData(null)
    }
};

const forgotPassword = (email) => {
    return async(dispatch, getState, {AuthApi}) => {

        try {
            const {code, data, description} = await AuthApi.forgotPassword(email);

            if (code == 423 && description?.includes('customer-not-found')) {
                throw {message: translation(`resetPassword_userNotFound`)};
            } else if (code != 200) {
                throw {message: translation(`resetPassword_error_${code}`)};
            } else {
                if (data == true) {
                    return data;
                } else {
                    throw {message: translation('resetPassword_error')}
                }
            }
        } catch (error) {
            dispatch(proccessFrontError(error, "AuthActions.forgotPassword()"));
            throw error.message;
        }
    }
};


const loadUserFromCookie = () => async(dispatch, getState, {AuthApi}) => {

    dispatch(meFromCookiePending());

    try {
        const [user] = await AuthApi.meFromCookie();
        dispatch(meFromCookieSuccess(user));
        return user;
    } catch (error) {
        dispatch(meFromCookieFailure(error));
        dispatch(proccessFrontError(error, "AuthActions.loadUserFromCookie()"));
        throw error;
    }
};


const changePassword = (passwordData) => {
    return async(dispatch, getState, {AuthApi}) => {
        try {
            const {code, data, description} = await AuthApi.changePassword(passwordData);
            if (code == 200) {
                return data;
            } else if (code == 464) {
                throw {message: translation(`login_api_error_${description}`)};
            } else if (code == 465) {
                throw {message: translation(`api_error_465`)};
            } else {
                throw {message: description};
            }
        } catch (error) {
            dispatch(proccessFrontError(error, "AuthActions.changePassword()"));
            throw error.message;
        }
    }
};


const resetPassword = (passwordData) => {
    return async(dispatch, getState, {AuthApi}) => {
        try {
            const {code, data, description} = await AuthApi.resetPassword(passwordData);
            if (code == 200) {
                return data;
            } else if (code == 464) {
                throw {message: translation(`login_api_error_${description}`)};
            } else {
                throw {message: translation(`account_passwordSetNewForm_resetError_${code}`)};
            }
        } catch (error) {
            dispatch(proccessFrontError(error, "AuthActions.resetPassword()"));
            throw error.message;
        }
    }
};


const isValueUnique = (fieldName, fieldValue) => {
    return async(dispatch, getState, {AuthApi}) => {
        try {
            let fName = (fieldName == 'loginName') ? 'login' : fieldName;
            let fValue = fieldValue;
            const { data } = await AuthApi.isValueUnique(fName, fValue);
            if (!data) {
                throw `${fName}_isUsed`;
            }

            return data;
        } catch (error) {
            dispatch(proccessFrontError(error, "AuthActions.isValueUnique()"));
            throw error;
        }
    }
};

const smsCodeVerify = (fieldName, fieldValue, mobileNumber) => {
    return async (dispatch, getState, {AuthApi}) => {
        try {
            let fName = fieldName;
            let fValue = fieldValue;
            const {code, data} = await AuthApi.validateSMSCode(fName, fValue, mobileNumber, false);

            if (!data){
                throw `${fName}_wrongSMSCode`;
            }

            return data;
        } catch (error) {
            dispatch(proccessFrontError(error, "AuthActions.smsCodeVerify()"));
            throw error;
        }
    }
};

const smsCodeVerifyWithAttemps = (fieldName, fieldValue, mobileNumber) => {
    return async (dispatch, getState, {AuthApi}) => {
        try {
            let fName = fieldName;
            let fValue = fieldValue;
            const {code, data} = await AuthApi.validateSMSCode(fName, fValue, mobileNumber, true);

            if (code == 440) {
                throw {message: `${fName}_wrongAttemptsLimitExceeded`, code};
            } else if(!data){
                throw `${fName}_wrongSMSCode`;
            }

            return data;
        } catch (error) {
            dispatch(proccessFrontError(error, "AuthActions.smsCodeVerify()"));
            throw error;
        }
    }
};

const loginCustomerByCookie = (requestProperties)=>{
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            const {code, data} = await CustomerApi.getCustomerData(requestProperties);
            if(code != 200){
                throw {message:`customer data error`};
            }
            
            return logInUserSuccess(data, dispatch, getState, CustomerApi);

        } catch ({message}) {
            console.log(message);
        }
    }
};


const meFromCookieFailure = (error) => {
    return {
        type: `${USER_FROM_COOKIE}_FAILURE`,
        payload: {error}
    }
};

const meFromCookieSuccess = (user) => {
    return {
        type: `${USER_FROM_COOKIE}_SUCCESS`,
        payload: {user}
    }
};

const meFromCookiePending = () => {
    return {
        type: `${USER_FROM_COOKIE}_PENDING`
    }
};

const signUpUserPending = () => {
    return {
        type: `${USER_SIGNIN}_PENDING`
    }
};

const signUpUserSuccess = (user) => {
    return {
        type: `${USER_SIGNIN}_SUCCESS`,
        payload: {user}
    }
};

const signUpUserFailure = (error) => {
    return {
        type: `${USER_SIGNIN}_FAILURE`,
        payload: {error}
    }
};

const updateUserBalance = (data) => {
    return {
        type: UPDATE_USER_BALANCE,
        payload: {data}
    }
};

const isLogged = () => {
    const {getState} = store;
    const {Auth:{isLogged}} = getState();
    return isLogged;
};

const registerUserByMyId = (user) => {
    return async(dispatch, getState, {AuthApi}) => {
        user['channel'] = "desktop";
        user['browser'] = browser();
        user['device'] = device();

        try {
            const {code, data} = await AuthApi.registerUserByMyId(user);
            if (code != 200) {
              throw {message: translation(`login_api_error_${code}`)};
            } else {
                return data;
            }
        } catch ({message}) {
            throw message;
        }
    };
};

const fetchBrands = () => {
    return async(dispatch, getState, {AuthApi}) => {
  
        dispatch(fetchBrandsPending());
  
        try {
            const {data} = await AuthApi.getBrandData();
            if(!data){
                throw 'empty brands array!';
            }
  
            dispatch(fetchBrandsSuccess(data));
            return data;
  
        } catch (error) {
            dispatch(fetchBrandsFailure(error));
            throw error;
        }
    }
  };
  
  const fetchBrandsFailure = (error) => {
    return {
        type: `${BRANDS_DATA}_FAILURE`,
        payload: {error}
    }
  };
  
  const fetchBrandsSuccess = (brands) => {

    const brandData = brands.find(x=>x.brandId == process.env.BRAND_ID);

    return {
        type: `${BRANDS_DATA}_SUCCESS`,
        payload: {brandData}
    }
  };
  
  const fetchBrandsPending = () => {
    return {
        type: `${BRANDS_DATA}_PENDING`
    }
  };

export {
    registerUser,
    logInUser,
    logOutUser,
    loadUserFromCookie,
    forgotPassword,
    isLogged,
    isValueUnique,
    changePassword,
    updateUserBalance,
    smsCodeVerify,
    smsCodeVerifyWithAttemps,
    resetPassword,
    preregisterUser,
    verificationCode,
    registerUserByMyId,
    loginCustomerByCookie,
    fetchBrands
};
