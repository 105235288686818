import React, { useEffect } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import classNames from 'classnames';
import { translation } from 'helpers/utilsHelper.js';
import { formatDate, compareAsc, substractDays, substractMonths } from 'helpers/datesHelper.js';
import { changeDateByType, changeActiveTab, fetchTransactionsByFilter, setCurrentPage } from 'accountWalletActions.js';
import Datepicker from 'Datepicker.js';
import Pagination from 'Pagination.js';
import Loader from 'Loader.js';
import WalletTransactionsItem from 'WalletTransactionsItem.js';
import CalendarIcon from 'calendar.svg';
import S from 'StyledAccountWallet.js';

const AccountWallet = () => {
  const dispatch = useDispatch();
  const { isPending, startDate, endDate, activeTab, currentPage, nextData, prevData, data, limit } = useSelector(({ AccountWallet }) => AccountWallet);

  const today = new Date();

  useEffect(() => {
    handleChangeDate('start', substractDays(today, 1));
  }, []);

  const handleChangeDate = async (type, date) => {
    const newDate = new Date(date);

    batch(async () => {
      dispatch(changeDateByType(`${type}Date`, newDate));
      await dispatch(fetchTransactionsByFilter({ 
        startDate: type === 'start' ? newDate : startDate, 
        endDate: type === 'end' ? newDate : endDate 
      }));
    });
  };

  const onChangeFilter = (activeTab) => {

    batch(() => {
      dispatch(changeActiveTab(activeTab));
      dispatch(fetchTransactionsByFilter({ activeTab }));
    });
  };

  const setStartDate = (type, time) => {
    const getStartDate = type == 'day' ? substractDays : substractMonths;
    const startDate = getStartDate(today, time);
    const endDate = today;

    batch(() => {
      dispatch(fetchTransactionsByFilter({ startDate, endDate }));
      dispatch(changeDateByType('startDate', startDate));
      dispatch(changeDateByType('endDate', endDate));
    });
  };

  const compareDates = (dateLeft, dateRight) => {
    dateLeft = new Date(formatDate(dateLeft, 'yyyy-MM-dd'));
    dateRight = new Date(formatDate(dateRight, 'yyyy-MM-dd'));
    const result = compareAsc(dateLeft, dateRight);
    if (!result) {
      return true;
    }
    return false;
  };

  const goPage = async (nextPage) => {
    try {
      batch(async () => {
        await dispatch(fetchTransactionsByFilter({ nextPage }));
        dispatch(setCurrentPage(nextPage));
      });
    } catch (error) {
      console.log('goPage:' + error);
    }
  };

  return (
    <S.AccountWallet className="account-wallet">
      <S.WalletFilters className="wallet-filters">
        <S.WalletFiltersDates className="wallet-filters-dates">
          <S.DateWrapper className="date-wrapper">
            <S.DateLabel>{translation('common_dateFrom')}</S.DateLabel>
            <S.DateInputWrapper>
              <Datepicker
                selected={startDate}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd.MM.yyyy"
                cb={(date) => handleChangeDate('start', date)}
              />
              <S.DateIcon as="div" dangerouslySetInnerHTML={{ __html: CalendarIcon }} />
            </S.DateInputWrapper>
          </S.DateWrapper>

          <S.DateWrapper className="date-wrapper">
            <S.DateLabel>{translation('common_dateTo')}</S.DateLabel>
            <S.DateInputWrapper>
              <Datepicker
                selected={endDate}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd.MM.yyyy"
                cb={(date) => handleChangeDate('end', date)}
              />
              <S.DateIcon as="div" dangerouslySetInnerHTML={{ __html: CalendarIcon }} />
            </S.DateInputWrapper>
          </S.DateWrapper>
        </S.WalletFiltersDates>
        <S.FilterDayBtnsWrapper>
          <S.FilterDayBtn
            className="--first"
            isActive={compareDates(startDate, substractDays(today, 1)) && compareDates(endDate, today)}
            onClick={() => setStartDate('day', 1)}
          >
            {translation('transactionHistory_range_day')}
          </S.FilterDayBtn>
          <S.FilterDayBtn isActive={compareDates(startDate, substractDays(today, 30)) && compareDates(endDate, today)} onClick={() => setStartDate('day', 30)}>
            {translation('transactionHistory_range_30days')}
          </S.FilterDayBtn>
          <S.FilterDayBtn
            className="--last"
            isActive={compareDates(startDate, substractMonths(today, 3)) && compareDates(endDate, today)}
            onClick={() => setStartDate('month', 3)}
          >
            {translation('transactionHistory_range_3months')}
          </S.FilterDayBtn>
        </S.FilterDayBtnsWrapper>
        <S.WalletFiltersBtns className="wallet-btns-wrapper">
          <S.FilterBtnsLabel>
            {translation('transactionHistory_filter')}
            <S.FilterBtnsLabelAfter />
          </S.FilterBtnsLabel>
          <S.FilterBtn onClick={() => onChangeFilter('all')} className={classNames({ active: activeTab == 'all' })}>
            {translation('transactionHistory_filter_all')}
          </S.FilterBtn>
          <S.FilterBtn onClick={() => onChangeFilter('deposit')} className={classNames({ active: activeTab == 'deposit' })}>
            {translation('transactionHistory_filter_deposits')}
          </S.FilterBtn>
          <S.FilterBtn onClick={() => onChangeFilter('payout')} className={classNames({ active: activeTab == 'payout' })}>
            {translation('transactionHistory_filter_withdrawals')}
          </S.FilterBtn>
        </S.WalletFiltersBtns>
      </S.WalletFilters>

      <S.WalletTransactions className="wallet-transaction-list">
        {isPending ? (
          <Loader />
        ) : (
          <>
            <S.WalletTransactionsList className="transaction-list">
              <S.FakeTableHead>
                <div className="wallet-date">{translation('transactionHistory_date')}</div>
                <div className="wallet-type">{translation('transactionHistory_type')}</div>
                <div className="wallet-amount">{translation('transactionHistory_amount')}</div>
                <div className="wallet-status">{translation('transactionHistory_status')}</div>
              </S.FakeTableHead>

              {data?.length > 0 ? (
                data.map(({ transactionId, regDate, ...transaction }) => (
                  <WalletTransactionsItem key={transactionId} transactionId={transactionId} regDate={new Date(regDate)} {...transaction} />
                ))
              ) : (
                <S.NoResult className="no-result">{translation('transactionHistory_notFound')}</S.NoResult>
              )}
            </S.WalletTransactionsList>

            {data?.length > 0 ? <Pagination useNewPagination cb={goPage} {...{ currentPage, limit, data, nextData, prevData }} /> : null}
          </>
        )}
      </S.WalletTransactions>
    </S.AccountWallet>
  );
};
export default AccountWallet;
