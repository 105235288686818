import styled from 'styled-components';
import StyledDatepicker from 'StyledDatepicker.js';
import {List, Button} from 'global/styles/styles.js';
const S = {};

S.AccountFreebet = styled.div`
    width: 100%;
    padding: .8333rem 2.5rem 0;
    padding-left: 30.7692px;
    padding-right: 30.7692px;
`;
S.FreebetFilters = styled.div`
    display: flex;
    justify-content: space-between;
`;
S.FreebetFiltersDates = styled.div`
    display: flex;
    & > div{
         margin-right: 10px;
         &:last-of-type{
             margin-right: 0;
         }
    }
`;
S.DateWrapper = styled.div`
    width: 237px;
    height: 46px;
    display: flex;
    position: relative;
    border-top: 2px solid #eee;
    border-bottom: 2px solid #eee;
    border-right: 2px solid #eee;
    & .react-datepicker-wrapper,
    & .react-datepicker__input-container{
       display: flex;
    }
    & .react-datepicker-wrapper{
       width: calc(100% - 4.5rem);
    }
    & .react-datepicker__input-container{
       width: 100%;
    }
    
    ${StyledDatepicker.Datepicker}{
        flex: 1;
        padding-left: 10px;
        border: none;
        font-size: 17px;
        font-weight: 700;
        width: 100%;
    }
`;
S.DateLabel = styled.div`
    display: flex;
    background-color: #eee;
    font-weight: 700;
    color: #676767;
    width: 4.5rem;
    justify-content: center;
    align-items: center;
`;
S.DateIcon = styled.i`
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 33px;
    font-size: 1.67rem;
    justify-content: center;
    align-items: center;
    display: flex !important;
    pointer-events: none;
`;

S.Table = styled.table`
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
`;

S.FreebetList = styled.div``;
S.FreebetActiveList= styled.div`
    margin-top: 20px;
`;
S.FreebetHistoryList= styled(S.FreebetActiveList)``;
S.FreebetListHeader= styled.div`
    background-color: #a3a3a3;
    color: #fff;
    padding: 1rem;
    font-weight: 500;
    border-bottom: .1rem solid #fff;
    font-size: 1.5rem;
    font-size: 1.5rem;
`;

S.FreebetFakeTable = styled.div``;

S.Th = styled.div`
    background-color: #eaeaea;
    color: #a3a3a3;
    text-transform: capitalize;
    padding: .5rem;
    border-bottom: .1rem solid #fff;
    border-right: .1rem solid #fff;
    font-size: 15px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    
    & > span{
        overflow: hidden;
    text-overflow: ellipsis;
    }
    
    &.freebet_id{
        width: 10%;
    }
    &.freebet_ammount{
      width: 15%;
    }
    &.freebet_name{
        width: 55%;
    }
    &.freebet_expirationDate{
       width: 20%;
    }
`;

S.Tr = styled.div`
    display: flex;
    width: 100%;
`;
S.Td = styled(S.Th)`
    font-size: 1rem;
    font-weight: 600;
    color: #000;
    
    &.freebet-id{
        width: 10%;
    }
    &.freebet-ammount{
      width: 15%;
    }
    &.freebet-name{
        width: 55%;
    }
    &.freebet-expirationDate{
       width: 20%;
    }
`;

S.NoResult = styled.div`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

S.Thead = styled.div`
    height: 40px;
    display: flex;
    
    ${S.Tr}{
       & > ${S.Th}{}
    }
`;
S.Tbody = styled.div`
    ${S.Tr}{
       & > ${S.Td}{}
    }
`;

export default S;