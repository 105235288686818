const encode = encodeURIComponent;

export default (requests, axios) => {
    return {

        getPaymentProviders: (currencyCode) => {
            if (currencyCode) {
                return requests.get(`/payment/providers/active/${currencyCode}`);
            } else {
                return requests.get('/payment/providers/active');
            }
        },

        getCustomerCreditCardList: (providerId) => {
            return requests.get(`/payment/customerCreditCardList/${providerId}`);
        },

        getPaystackBankList: (providerId) => {
            return requests.get(`/payment/payStack/bankList/${providerId}`);
        },

        getCustomerBankDetail: () => {
            return requests.get(`/payment/customerBankDetail`);
        },

        getCustomerBanksDetailList: () => {
            return requests.get(`/payment/customerBanksDetail`);
        },

        getBankList: () => {
            return requests.get(`/payment/banks`);
        },

        getAccountTypes: () => {
            return requests.get(`/payment/bank/accountTypes`);
        },

        getCustomerMobileAccount: () => {
            return requests.get(`/payment/customerMobileAccount`);
        },

        sendPaymentData: (data, step) => {
            return requests.post(`/payment/payment/${step}`, data);
        },

        getWonAmountToPayout: (data) => {
            return requests.post(`/payment/user/limit/wonAmountToPayout` , data);
        },

        customerCreditCardUpdate: (card) => {
            return requests.post(`payment/creditCard/update`, card);
        },  

        getWinningsBalance: () => {
            return requests.get(`customer/winningsBalance`);
        }, 
    }
};
