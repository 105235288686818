import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';
import _findIndex from 'lodash/findIndex';
import {START_INTERVAL, STOP_INTERVAL} from 'intervalActions.js';

let initialState = {
    intervals: []
};

export default typeToReducer({
    [START_INTERVAL]: (state, {payload: {key, interval}}) => {
        const index = _findIndex(state.intervals, {key});
        let newState = state;
        if (index != -1) {
            newState = update(newState, {intervals: {$splice: [[index, 1]]}});
        }

        const newInterval = [].concat({key, interval});
        const updatedState = update(newState, {intervals: {$push: newInterval}});
        return {...updatedState};
    },
    [STOP_INTERVAL]: (state, {payload: {key}}) => {
        const index = _findIndex(state.intervals, {key});
        const updatedState = update(state, {intervals: {$splice: [[index, 1]]}});
        return {...updatedState};
    }

}, initialState);


