import React from 'react';
import Modal from 'Modal.js';
import {CSSTransition} from 'react-transition-group';
import styled from 'styled-components';

const duration = 300;
const ModalWithOpacityStyles = styled(Modal)`
  &.modal-opacity-enter {
    opacity: 0.01;
  }
  &.modal-opacity-enter-active {
   transition: opacity ${duration}ms;
   opacity: 1;
  }
  &.modal-opacity-exit {
    opacity: 1;
  }
  &.modal-opacity-exit-active {
   opacity: 0.01;
  transition: opacity ${duration}ms ease-in;
  }
 
`;

const ModalOpacity = ({
  isOpen, 
  toggleOpen, 
  children, 
  title = '',
  titleColor="",
  showHeader = true, 
  showCloseIcon = true, 
  showHeaderIcon = true, 
  showHeaderTitle = true, 
  headerPadding = null,
  padding=null, 
  paddingBody=null,
  flexDirectionBody=null,
  minHeightBody = null,
  minWidthBody = null,
  justifyContentBody = null,
  alignItemsBody=null,
  backgroundBody=null,
  backgroundWrapper=null,
  backgroundCurtain=null,
  positionByMargin=null,
  closeIconColor=null,
  width=null, 
  height=null, 
  maxHeight=null, 
  margin=null, 
  borderRadius=null, 
  preventCloseOnOverlay = false,
  backgroundHeader=null,
  withCms=false,
  closeIconImg=null
}) => {
    return (
        <>
        <CSSTransition in={isOpen}
                       className="modal-opacity"
                       classNames="modal-opacity"
                       unmountOnExit
                       timeout={duration}>

            <ModalWithOpacityStyles
                showHeader={showHeader}
                withCms={withCms}
                titleColor={titleColor}
                showHeaderIcon={showHeaderIcon}
                showCloseIcon={showCloseIcon}
                closeIconColor={closeIconColor}
                showHeaderTitle={showHeaderTitle}
                headerPadding={headerPadding}
                title={title}
                open={isOpen}
                onClose={toggleOpen}
                flexDirectionBody={flexDirectionBody}
                minHeightBody={minHeightBody}
                minWidthBody={minWidthBody}
                justifyContentBody={justifyContentBody}
                positionByMargin={positionByMargin}
                alignItemsBody={alignItemsBody}
                backgroundBody={backgroundBody}
                padding={padding}
                paddingBody={paddingBody}
                backgroundWrapper={backgroundWrapper}
                backgroundCurtain={backgroundCurtain}
                width={width}
                height={height}
                maxHeight={maxHeight}
                margin={margin}
                borderRadius={borderRadius}
                preventCloseOnOverlay={preventCloseOnOverlay}
                backgroundHeader={backgroundHeader}
                closeIconImg={closeIconImg}
            >
                {children}
            </ModalWithOpacityStyles>

        </CSSTransition>
        </>
    );
};

export default ModalOpacity;
