import _each from 'lodash/each';
import {translation} from 'utilsHelper.js';
import {CHANGE_SLIP_STAKE, clearBetslip, addOutcome} from 'betSlipActions.js';

export const CHANGE_GET_LUCKY_STAKE = "CHANGE_GET_LUCKY_STAKE";
export const CHANGE_GET_LUCKY_TOWIN = "CHANGE_GET_LUCKY_TOWIN";
export const CHANGE_SELECTED_DAYS = "CHANGE_SELECTED_DAYS";
export const CHANGE_RANGE = "CHANGE_RANGE";
export const GET_LUCKY = "GET_LUCKY";

const changeGetLuckyStake = stake => {
    return {
        type: CHANGE_GET_LUCKY_STAKE,
        payload: {stake}
    };
};
const changeGetLuckyToWin = toWin => {
    return {
        type: CHANGE_GET_LUCKY_TOWIN,
        payload: {toWin}
    };
};
const changeSelectedDays = (checkboxName, value) => {
    return {
        type: CHANGE_SELECTED_DAYS,
        payload: {checkboxName, value}
    };
};
const changeRange = valueObject => {
    return {
        type: CHANGE_RANGE,
        payload: {valueObject}
    };
};
const getLuckyPending = () => {
    return {
        type: `${GET_LUCKY}_PENDING`,
        payload: {
            isPending: true
        }
    };
};
const getLuckySuccess = () => {
    return {
        type: `${GET_LUCKY}_SUCCESS`,
    };
};
const getLuckyFailure = error => {
    return {
        type: `${GET_LUCKY}_FAILURE`,
        payload: {
            error
        }
    };
};
const getLucky = () => {
    return async(dispatch, getState, {BettingApi}) => {
        dispatch(getLuckyPending());
        const {GetLucky: getLuckyStore} = getState();
        const {
            range: {
                value: {min: fromOdds, max: toOdds}
            },
            stake,
            toWin: amount,
            selectedDays: {today, tomorrow, dayAfterTomorrow: dayAfter}
        } = getLuckyStore;
        try {
            const {data, code} = await BettingApi.getLucky(
                fromOdds,
                toOdds,
                stake,
                amount,
                today,
                tomorrow,
                dayAfter
            );
            if (code !== 200) {
                throw {message: translation(`getLucky_error${code}`)};
            } else {
                dispatch(getLuckySuccess());
                dispatch(addGetLuckyToBetSlip(data))
            }
        } catch ({message}) {
            dispatch(getLuckyFailure(message));
        }
    };
};


const addGetLuckyToBetSlip = (data) => {
    return (dispatch, getState) => {
        const {GetLucky: {stake}} = getState();

        dispatch(clearBetslip());
        dispatch({type: CHANGE_SLIP_STAKE, payload: {stake}});
        _each(data, (outcome) => {
            outcome['outcomeOdds'] = outcome['odds'];
            outcome['eventType'] = outcome['live'] ? 2 : 1;
            dispatch(addOutcome(outcome, true));
        });

    }
};

export {
    changeGetLuckyStake,
    changeGetLuckyToWin,
    changeSelectedDays,
    changeRange,
    getLucky
};
