import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import S from 'StyledTopMenu.js';
import UnloggedPanel from 'UnloggedPanel.js';
import LoggedPanel from 'LoggedPanel.js';
import { changeActiveItem, changeSubmenuActiveItem} from 'topMenuActions.js';
import {logInUser, logOutUser, forgotPassword, resetPassword, registerUser, fetchBrands} from 'authActions.js';
import {toggleBalance} from 'betSlipActions.js';
import {toggleAccountModal} from 'accountActions.js';
import {translation, redirect} from 'utilsHelper.js';
import {getLanguageCookie} from 'helpers/cookiesHelper.js'
import LogoIcon from 'editec_logo.svg';
import ChevronIcon from 'chevron_down_dark.svg';
import CmsPage from 'CmsPage.js';

class TopMenu extends Component {

    static getDerivedStateFromError = (error) => {
        return {hasError: true};
    };

    state = {hasError: false};
    disabledRedirectCountryCodes = ['UG', 'NG', 'GN', 'TZ', 'SL'];

    componentDidMount() {
        this.subscribeToSpikeOnRouteChange();
        this.closeLanguageChoiceOnRouteChange();
        this.getBrands(); 
    };

    getBrands = async() => {
        const {fetchBrands} = this.props;
        await fetchBrands(); //zmiany zostały wycofane w związku z wycofaniem FOB'a z wersji 2406, aby FOB działał ponownie należy usunąć await fetchBrands() oraz odkomentować poniższy kod
        // const brandsData = await fetchBrands();
        // this.redirectToTermianlIfWrongBrand(brandsData);
    };

    // redirectToTermianlIfWrongBrand = async(brandsData) => {
    //     const countryCode = Cookies.get('countryCode');
    //     const currentBrand = Object.values(brandsData).find(brand => brand.countryCode === countryCode);
    //     if(!currentBrand?.customData?.isFOBAvaible && !window.location.origin.includes("localhost")){
    //         const pathname = window.location.pathname;
    //         pathname.replace("/terminal", '')
    //          redirect(`terminal${pathname}`);
    //     }
    // }

    loginOnSubmit = async(postData) => {
        const {logInUser} = this.props;
        try {
            const user = await logInUser(postData);
            return user;
        } catch (error) {
            throw error;
        }
    };

    registerOnSubmit = async(postData) => {
        const {registerUser} = this.props;
        try {
            const newUser = await registerUser(postData);
            return newUser;
        } catch (error) {
            throw error;
        }
    };

    forgotPasswordOnSubmit = async(postData) => {
        const {forgotPassword} = this.props;
        try {
            const user = await forgotPassword(postData);
            return user;
        } catch (error) {
            throw error;
        }
    };

    resetPasswordOnSubmit = async(postData) => {
        const {resetPassword} = this.props;
        try {
            const result = await resetPassword(postData);
            return result;
        } catch (error) {
            throw error;
        }
    };

    subscribeToSpikeOnRouteChange = () => {
        this.changeActiveItemBasedOnPath();
        app.router.onRouteChange('REACT_LISTEN_SPIKE_ROUTE_CHANGE_TOP_MENU', () => {
            this.changeActiveItemBasedOnPath();
        });
    };

    changeActiveItemBasedOnPath = () => {
        const currentPath = window.location.pathname;

        if (currentPath) {
            switch (true) {
                case /\/live.*?/.test(currentPath):
                    this.props.changeActiveItem('LIVES');
                    break;
                default:
                    this.props.changeActiveItem('HOME');
                    break;
            }
        }
    };

    changeLanguage = (language) => {
        app.service.Language.changeLanguage(language, true);
    };

    renderLanguageChoice = () => {
        const activeLangTranslated = translation(app.enumerator.Language[getLanguageCookie().toUpperCase()].fullName);
        return (
            <S.LanguageChoice className="languageChoice">
                <S.LanguageField className='active' key={`lang-active`} 
                                    onClick={this.toggleLanguageChoice.bind(this)}>
                    <S.LanguageFlag className={getLanguageCookie()}></S.LanguageFlag>
                    <S.LanguageText>{activeLangTranslated}</S.LanguageText>
                    <S.LanguageChevron className={this.state.isLanguageChoiceOpen ? 'active' : ''} dangerouslySetInnerHTML={{__html: ChevronIcon}}></S.LanguageChevron>
                </S.LanguageField>
                {this.renderLanguageChoiceButtons()}
            </S.LanguageChoice>
        );
    };

    renderLanguageChoiceButtons = () => {
        const langEnum = app.enumerator.Language;
        const langs = app.config.availableLanguages;

        // defines if whole dropdown is visible
        const isVisible = this.state.isLanguageChoiceOpen ? '' : 'hidden'; 

        if (langs.length > 1) {
            const languageFields = [];
            for (let i in langs) {

                // hides active item (so it isn't displayed twice when dropdown opens)
                const isVisibleItem = (getLanguageCookie().toUpperCase() == langs[i]) ? 'hidden' : ''; 

                const languageField = (
                    <S.LanguageField className={`${isVisibleItem} ${isVisible}`} key={`lang${langs[i]}`} onClick={this.changeLanguage.bind(this, langs[i])}>
                        <S.LanguageFlag className={langs[i].toLowerCase()}></S.LanguageFlag>
                        <S.LanguageText>{translation(langEnum[langs[i]].fullName)}</S.LanguageText>
                    </S.LanguageField>
                );
                languageFields.push(languageField);
            }
            return languageFields;
        } 
    };

    toggleLanguageChoice = () => {
        const isLanguageChoiceOpen = this.state.isLanguageChoiceOpen ? false : true;
        this.setState({
            isLanguageChoiceOpen: isLanguageChoiceOpen,
        });
    };

    closeLanguageChoiceOnRouteChange = () => {
        app.router.onRouteChange('closeLanguageChoice', () => {
            this.setState({
                isLanguageChoiceOpen: false,
            });
        });
    };

    render() {

        const {isLogged, logOutUser, user, balanceId, toggleBalance, toggleAccountModal, isOpen, ...rest} = this.props;
        const {hasError} = this.state;
  
        const terminalPartUrl =  app.config.isTerminal ? '' : '';
        const redirect = process.env.NODE_ENV == 'development' ? '/' : '';
         (this.disabledRedirectCountryCodes.includes(process?.env?.MAIN_COUNTRY_CODE ?? app.config.mainCountryCode)
            ? terminalPartUrl + '/'
            : terminalPartUrl + `/${process?.env?.MAIN_COUNTRY_CODE?.toLowerCase() ?? app.config.mainCountryCode.toLowerCase()}`)
        const topMenuComponentName = app.config.isTerminal ? 'top-menu-desktop' : 'top-menu-desktop-page';
        if (hasError) {
            return <div>Error fallback component!</div>
        }

        return (
            <S.TopMenu>
                <S.MenuRow className="menu-list">

                    <S.Link href={redirect}>
                        <S.Logo as="div" dangerouslySetInnerHTML={{__html: LogoIcon}} />
                    </S.Link>

                    <S.MenuContent className="subMenu-list">
                    <CmsPage 
                        name={topMenuComponentName} 
                        isPlaces={true} 
                        countryCode={process.env.MAIN_COUNTRY_CODE} 
                        languageCode={getLanguageCookie() || process.env.DEFAULT_LANGUAGE} 
                        brand={process.env.BRAND_ID} />
                </S.MenuContent>

                     <S.MainRow className="main-row">
                     { app.config.isTerminal!=true &&
                        <>
                          {isLogged ?
                        (<LoggedPanel user={user} logOut={logOutUser}
                                      toggleBalance={toggleBalance} balanceId={balanceId} isAccountOpen={isOpen}
                                      toggleUserAccountModal={toggleAccountModal}></LoggedPanel>) :
                        (<UnloggedPanel loginOnSubmit={this.loginOnSubmit} registerOnSubmit={this.registerOnSubmit}
                                        forgotPasswordOnSubmit={this.forgotPasswordOnSubmit}
                                        resetPasswordOnSubmit={this.resetPasswordOnSubmit}/>)
                        }
                        </>
                    }
                    {app.config.availableLanguages.length > 1 && this.renderLanguageChoice()}
                    </S.MainRow>
                </S.MenuRow>
                


            </S.TopMenu>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {TopMenu:{activeItem}, Auth:{isLogged, user}, BetSlip:{balanceId}, Account:{isOpen}} = state;
    return {
        activeItem,
        isLogged,
        user,
        balanceId,
        isOpen
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeActiveItem: bindActionCreators(changeActiveItem, dispatch),
        changeSubmenuActiveItem: bindActionCreators(changeSubmenuActiveItem, dispatch),
        logOutUser: bindActionCreators(logOutUser, dispatch),
        logInUser: bindActionCreators(logInUser, dispatch),
        registerUser: bindActionCreators(registerUser, dispatch),
        forgotPassword: bindActionCreators(forgotPassword, dispatch),
        resetPassword: bindActionCreators(resetPassword, dispatch),
        toggleBalance: bindActionCreators(toggleBalance, dispatch),
        toggleAccountModal: bindActionCreators(toggleAccountModal, dispatch),
        fetchBrands: bindActionCreators(fetchBrands, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);

