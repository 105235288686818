import styled from 'styled-components';

const S = {};

S.RegisterWizardForm = styled.div`
    width: 100%;
`;

S.RegisterSuccess = styled.div`
    width: 100%;
    padding:1.5625rem 4.3125rem;
    text-align: center;
`;

S.RegisterSuccessLabel = styled.span`
    font-size: 2rem;
`;

S.SubmitBtn = styled.div`
    border-radius: 1.75rem;
    background-color: ${({theme:{$registerFormSubmitBtnBgColor}}) => $registerFormSubmitBtnBgColor};
    font-size: 1rem;
    font-weight: 600;
    padding: 0.8333rem;
    width: 100%;
    margin-bottom: 1.7rem;
    margin-top: 1.8rem;
    color: ${({theme:{$registerFormSubmitBtnColor}}) => $registerFormSubmitBtnColor};
    min-width: 100%;
    cursor: pointer;
    text-transform:uppercase ;
    box-shadow: 3px 3px 6px #0000004D;
`;


export default S;
