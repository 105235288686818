import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import {formatDate } from 'datesHelper.js'
import S from 'StyledRegisterWizardForm.js';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _size from 'lodash/size';
import RegisterWizardFormStep1 from 'RegisterWizardFormStep1.js';
import RegisterWizardFormStep2 from 'RegisterWizardFormStep2.js';
import {SubmissionError, destroy} from 'redux-form';
import { verificationCode } from 'authActions.js';
import { connect } from 'react-redux';
import { translation } from 'helpers/utilsHelper.js';

const RegisterWizardForm = (props) => {

    const {toggleRegisterModal, registerOnSubmit, destroyWizardFormOnModalClose, verificationCode, brandData} = props;
    const [step, setNextStep]  = useState(1);

    useEffect(() => {
        return destroyWizardFormOnModalClose;
    }, []);

    const goToNextPage = () => {
        setNextStep((prevStep) => prevStep + 1);
    };

    const registerFirstStep = async(values) => {
        try {
            const mobileNumber = values.mobilePrefix + values.mobile;
            await verificationCode({"mobile": mobileNumber});

            goToNextPage();
        } catch (error) {
            throw new SubmissionError({_error: error});
        }
    };

    const registerSecondStep = async(values) => {
        try {

            let pickedValues = [
                'firstName',
                'lastName',
                'dateOfBirth',
                'password',
                'mobile',
                'verificationCode'
            ];

            let registerData = _pick(values, pickedValues);

            registerData.countryCode = process.env.MAIN_COUNTRY_CODE.toUpperCase();
            registerData.languageCode = app.config.lang.toUpperCase();
            registerData.mobile = brandData.mobilePrefix + registerData.mobile;
            registerData.dateOfBirth = formatDate(registerData.dateOfBirth, 'yyyy-MM-dd');

            if (values.email){
                registerData.email = values.email;
            }

            await registerOnSubmit(registerData);

            goToNextPage();

        } catch (error) {
            throw new SubmissionError({_error: error});
        }
    };

    return (
        <S.RegisterWizardForm>
            {step == 1 && (<RegisterWizardFormStep1 onSubmit={registerFirstStep}/>)}
            {step == 2 && (<RegisterWizardFormStep2 onSubmit={registerSecondStep}/>)}
            {step == 3 && ( 
                <S.RegisterSuccess>
                    <S.RegisterSuccessLabel> { translation('registerModal_registerSuccess') } </S.RegisterSuccessLabel>
                    <S.SubmitBtn onClick={toggleRegisterModal}> { translation('registerModal_registerSuccessButton')} </S.SubmitBtn>
                </S.RegisterSuccess>
            )}
        </S.RegisterWizardForm>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        verificationCode: bindActionCreators(verificationCode, dispatch),
        destroyWizardFormOnModalClose: () => dispatch(destroy('register')),
        dispatch
    }
};

const mapStateToProps = (state) => {
    const {Auth: {brandData}} = state;
    return {
        brandData
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterWizardForm);


