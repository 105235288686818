import axios from 'axios';
import CategoriesApiWrapper from 'CategoriesApi.js';
import FlagsApiWrapper from 'FlagsApi.js';
import AuthApiWrapper from 'AuthApi.js';
import CustomerApiWrapper from 'CustomerApi.js';
import PaymentApiWrapper from 'PaymentApi.js';
import IntegrationApiWrapper from 'IntegrationApi.js';
import FreebetApiWrapper from 'FreebetApi.js';
import AccountWalletApiWrapper from 'AccountWalletApi.js';
import AccountCashbackApiWrapper from 'AccountCashbackApi.js';
import AccountFreebetApiWrapper from 'AccountFreebetApi.js';
import AccountBonusApiWrapper from 'AccountBonusApi.js';
import AccountBetHistoryApiWrapper from 'AccountBetHistoryApi.js';
import AccountVirtualBetHistoryApiWrapper from 'AccountVirtualBetHistoryApi.js';
import CmsApiWrapper from 'CmsApi.js';
import OffersApiWrapper from 'OffersApi.js';
import LiveApiWrapper from 'LiveApi.js';
import VirtualApiWrapper from 'VirtualApi.js';
import BettingApiWrapper from 'BettingApi.js';
import FavouritesApiWrapper from 'FavouritesApi.js';
import EventsApiWrapper from 'EventsApi.js';
import SearchApiWrapper from 'SearchApi.js';
import TransactionApiWrapper from 'TransactionApi.js';
import OtherApiWrapper from 'OtherApi.js';
import ErrorApiWrapper from 'ErrorApi.js';
import MarketApiWrapper from 'MarketApi.js';
import store from 'store';
import { logOutUser } from 'authActions.js';
import { setSessionCookie, getLanguageCookie } from 'helpers/cookiesHelper.js';

const platform = location.hostname.split('.')[0];
const API_URL = (platform == "rc" && CLIENT == 'etoto')
    ? process.env.API_URL.replace('api', `${platform}-api`)
    : (platform === 'rc' && CLIENT === 'editec')
    ? location.origin + '/rest'
    : process.env.API_URL;
const LIVE_API_URL = (platform == "rc" && CLIENT == 'etoto') ? process.env.API_LIVE_BETTING_URL.replace('api', `${platform}-api`) : process.env.API_LIVE_BETTING_URL;
const VIRTUAL_API_URL = process.env.API_VIRTUAL_URL;
const EXTERNAL_BET_HISTORY_URL = process.env.EXTERNAL_BET_HISTORY_URL;

const responseBody = res => res.data;

const requests = function () {
    return {
        delete: (url, config) =>
            this.delete(`${url}`, config).then(responseBody),
        get: (url, config) =>
            this.get(`${url}`, config).then(responseBody),
        post: (url, body, config) =>
            this.post(`${url}`, body, config).then(responseBody),
        put: (url, body, config) =>
            this.put(`${url}`, body, config).then(responseBody)
    }
};

const baseInstance = axios.create({ baseURL: API_URL });
const liveInstance = axios.create({ baseURL: LIVE_API_URL });
const virtualInstance = axios.create({ baseURL: VIRTUAL_API_URL });
const customerInstance = axios.create({ baseURL: API_URL, withCredentials: true });
const externalInstance = axios.create({ baseURL: EXTERNAL_BET_HISTORY_URL });

customerInstance.interceptors.response.use(function (response) {
    const { headers, data: { code } } = response;

    if ('x-odds-session' in headers) {
        setSessionCookie(headers['x-odds-session']);
    }

    if (code == 456 || code == 476 || code == 421) {
        const { dispatch, getState } = store;
        const { Auth: { isLogged } } = getState();
        if (isLogged) {
            console.log('logout action in response interceptor!');
            dispatch(logOutUser());
        }
    }

    return response;
}, function (error) {
    return Promise.reject(error);
});

customerInstance.interceptors.request.use(function (config) {
    const sessionCookie = Cookies.get('X-ODDS-SESSION');

    const headers = {
        'Request-Language': (getLanguageCookie() || app.config.mainCountryCode),
    };

    if (sessionCookie) {
        headers['X-ODDS-SESSION'] = sessionCookie;
    }

    if (process.env.BRAND_ID) {
        headers['X-Brand-Data'] = process.env.BRAND_ID;
    }

    config['headers'] = { ...config['headers'], ...headers };
    return config;
}, function (error) {
    return Promise.reject(error);
});

externalInstance.interceptors.request.use(function (config) {
    const sessionCookie = Cookies.get('X-ODDS-SESSION');

    const headers = {
        'Request-Language': (getLanguageCookie() || app.config.mainCountryCode),
    };

    if (sessionCookie) {
        headers['X-ODDS-SESSION'] = sessionCookie;
    }

    if (process.env.BRAND_ID) {
        headers['X-Brand-Data'] = process.env.BRAND_ID;
    }

    config['headers'] = { ...config['headers'], ...headers };
    return config;
}, function (error) {
    return Promise.reject(error);
});

const CategoriesApi = CategoriesApiWrapper(requests.call(baseInstance));
const FlagsApi = FlagsApiWrapper(requests.call(baseInstance));
const AuthApi = AuthApiWrapper(requests.call(customerInstance));
const PaymentApi = PaymentApiWrapper(requests.call(customerInstance));
const IntegrationApi = IntegrationApiWrapper(requests.call(customerInstance));
const CustomerApi = CustomerApiWrapper(requests.call(customerInstance));
const FreebetApi = FreebetApiWrapper(requests.call(customerInstance));
const AccountWalletApi = AccountWalletApiWrapper(requests.call(customerInstance));
const AccountCashbackApi = AccountCashbackApiWrapper(requests.call(customerInstance));
const AccountFreebetApi = AccountFreebetApiWrapper(requests.call(customerInstance));
const AccountBonusApi = AccountBonusApiWrapper(requests.call(customerInstance));
const AccountBetHistoryApi = AccountBetHistoryApiWrapper(requests.call(customerInstance));
const AccountVirtualBetHistoryApi = AccountVirtualBetHistoryApiWrapper(requests.call(externalInstance));
const LiveApi = LiveApiWrapper(requests.call(liveInstance));
const VirtualApi = VirtualApiWrapper(requests.call(virtualInstance));
const BettingApi = BettingApiWrapper(requests.call(customerInstance));
const FavouritesApi = FavouritesApiWrapper(requests.call(customerInstance));
const EventsApi = EventsApiWrapper(requests.call(baseInstance));
const SearchApi = SearchApiWrapper(requests.call(baseInstance));
const TransactionApi = TransactionApiWrapper(requests.call(customerInstance));
const CmsApi = CmsApiWrapper(requests.call(customerInstance));
const OffersApi = OffersApiWrapper(requests.call(customerInstance));
const OtherApi = OtherApiWrapper(requests.call(baseInstance));
const ErrorApi = ErrorApiWrapper(requests.call(baseInstance));
const MarketApi = MarketApiWrapper(requests.call(baseInstance));

export {
    VirtualApi,
    CategoriesApi,
    FlagsApi,
    AuthApi,
    PaymentApi,
    IntegrationApi,
    CustomerApi,
    FreebetApi,
    AccountWalletApi,
    AccountCashbackApi,
    AccountFreebetApi,
    AccountBonusApi,
    AccountBetHistoryApi,
    LiveApi,
    BettingApi,
    FavouritesApi,
    EventsApi,
    SearchApi,
    CmsApi,
    OffersApi,
    TransactionApi,
    AccountVirtualBetHistoryApi,
    OtherApi,
    ErrorApi,
    MarketApi
};