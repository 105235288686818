import _size from 'lodash/size';
import limits from 'limitTypes.enum.js';
import {translation, redirect} from 'utilsHelper.js';
import {logOutUser} from 'authActions.js';
import {loadCustomerLimits} from 'customerActions.js';
export const CHANGE_DATE_BY_TYPE = 'CHANGE_RESPONSIBLE_GAME_DATE_BY_TYPE';
export const EXCLUDE_ACCOUNT_UPDATE_CUSTOMER_LIMITS = 'EXCLUDE_ACCOUNT_UPDATE_CUSTOMER_LIMITS';
export const RESET_TO_INITIAL_STATE = 'EXCLUDE_ACCOUNT_RESET_TO_INITIAL_STATE';

const changeDateByType = (dateType, date) => {
    return {
        type: CHANGE_DATE_BY_TYPE,
        payload: {
            dateType,
            date
        }
    }
};

const excludeAccount = (type, disablePeriod) => {
    return async (dispatch, getState, {CustomerApi}) => {

        dispatch(excludeAccountPending(type));

        try {
            let disableAccountModel = {
                limitType: limits.BETTING_EXCLUSION_TIME_LIMIT,
                limitAmount: disablePeriod,
            };
            disableAccountModel = [].concat(disableAccountModel);
            const {code, data} = await CustomerApi.updateCustomerLimits(disableAccountModel);
            if (code == 200 && _size(data)) {
                const [excludeLimit] = data;
                const {exceptionCode, limitAmount} = excludeLimit;

                dispatch(resetToInitialState());

                if(CLIENT == 'fuksiarz'){
                    if(limitAmount>=1){
                        dispatch(logOutUser());
                        const redirectHomePageUrl = process.env.REDIRECT_HOME_PAGE_URL;
                        if(redirectHomePageUrl&&_size(redirectHomePageUrl)){
                            redirect(String(redirectHomePageUrl));
                        }
                        return;
                    }
                }
                await dispatch(loadCustomerLimits());
                dispatch(excludeAccountSuccess(type));

                return data;
            } else {
                const errorMsg = translation(`excludeAccount_error_${code}`);
                throw errorMsg;
            }
        } catch (error) {
            dispatch(excludeAccountFailure(type, error));
            throw error;
        }
    }
};

const excludeAccountPending = (type) => {
    return {
        type: `${EXCLUDE_ACCOUNT_UPDATE_CUSTOMER_LIMITS}_PENDING`,
        payload: {type}
    }
};

const excludeAccountFailure = (type, error) => {
    return {
        type: `${EXCLUDE_ACCOUNT_UPDATE_CUSTOMER_LIMITS}_FAILURE`,
        payload: {type, error}
    }
};

const excludeAccountSuccess = (type, data) => {
    return {
        type: `${EXCLUDE_ACCOUNT_UPDATE_CUSTOMER_LIMITS}_SUCCESS`,
        payload: {type , data}
    }
};

const resetToInitialState = ()=>{
    return {
        type: RESET_TO_INITIAL_STATE
    }
}


export {
    changeDateByType,
    excludeAccount,
    resetToInitialState
}