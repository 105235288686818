import {createSelector} from 'reselect';

const normalizePaymentProviders = (paymentProviders) => {

    let out = {
        deposits: [],
        payouts: []
    };

    const sortedProviders = sortProviders(paymentProviders);

    for (const keys in sortedProviders) {

        if (sortedProviders.hasOwnProperty(keys)) {

            const provider = sortedProviders[keys];
            const {settings, isDeposit} = provider;
            const {href} = window.location;

            if (typeof settings === 'undefined' || typeof settings.visible === 'undefined' || settings.visible) {

                if (href.includes('agentDeposit') && settings && 'adminDeposit' in settings && settings.adminDeposit) {
                    isDeposit && out.deposits.push(provider);
                } 
                if (!href.includes('agentDeposit')  && settings && settings.adminDeposit == undefined) {
                    isDeposit ? out.deposits.push(provider) : out.payouts.push(provider);
                }
                
            }
        }
    }  
    return out;
};

const sortProviders = (providers) => {
    let sorted = _.chain(providers)
      .sortBy(function (provider) {
          if (provider.settings) {
            return provider.settings.order;
          } 
      })
        
    return sorted._wrapped;
};

const paymentsSelector = state => state.AccountDeposit.paymentProviders;

const providerSettingsSelector = createSelector(
    paymentsSelector,
    items => normalizePaymentProviders(items)
)

export {providerSettingsSelector}