import styled from 'styled-components';
import { RotateSpinLoader  } from 'react-css-loaders';

const S = {};
const defaultColor = '#123abc';
S.LoaderWrapper = styled.div`
`;
S.RotateSpinLoader = styled(RotateSpinLoader)`
    && {
        font-size: ${({size}) => size ? size : '3px'};
        border-color: ${({color}) => color ? color+'33' : defaultColor+'33'};
        border-left-color: ${({color}) => color ? color : defaultColor};
        margin: ${({size}) => size ? size : '3px'} auto;
    }
`

export default S;