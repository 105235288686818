import styled from 'styled-components';

const S = {};

S.AccountWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    flex:1;
    width: 100%;
    overflow: auto;
    border-radius:6px;
`;
S.AccountSidebar = styled.div`
    min-width:30%;
    flex-basis: 30%;
    background: ${({theme:{$accountBgColor}})=> $accountBgColor};
    
`;
S.AccountBody = styled.div`
    display: flex;
    width:70%;
    justify-content: center;
    background: #fff;
    border-bottom: 1px solid #a3a3a3;

    > * {
        overflow-x: hidden;
        ::-webkit-scrollbar {
            width: 0.3rem;
          }
    }
`;
S.AccountTop = styled.div`
    height: 4rem;
    display: flex;
    position: relative;
`;
S.AccountBottom = styled.div`
    flex: 1;
    padding: 0;
    display: flex;
    min-height: calc(100% - 5.83rem);
`;
S.AccountTopLeftSide = styled.div`
    position: relative;
    overflow: hidden;
    min-height: 1px;
    padding-right: 0;
    padding-left: 1.8333rem;
    flex-basis: 30%;
    background-color: ${({theme:{$accountBgColor}})=> $accountBgColor};
    color: ${({theme:{$accountPrimaryColor}})=> $accountPrimaryColor};
    display: flex;
    align-items: center;
`;

S.AccountTopRightSide = styled.div`
    position: relative;
    display: flex;
    justify-content:center;
    align-items: center;
    min-height: 1px;
    width: 83.3333333333%;
    flex: 1;
    background-color: ${({theme:{$accountPrimaryColor}})=> $accountPrimaryColor};
`;
S.ActiveTabTitle=styled.div`
    display:flex;
    justify-content:center;
    align-items: center;
    font-size: 1.33rem;
    font-weight: 700;
    color: #000;
`

S.UserFreebets = styled.div`
    background-color: #fff;
    margin-right: 3.33rem;
    border-radius: 5px;
    padding: 0 1.0833333333rem;
    width: 25%;
    height: 3.333rem;
`;

S.FreebetLabel = styled.div`
    font-weight: 700;
    color: #23282e;
    font-size: 1rem;
    color: #676767;
`;
S.FreebetsCount = styled.div`
    font-size: 1.2rem;
    font-weight: 600;
    color: #23282e;
    line-height: 1;
`;

S.UserMainBalance = styled.div`
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    margin-right: 3.33rem;
    border-radius: 5px;
    padding: 0 1.0833333333rem;
    width: 25%;
    height: 3.333rem;
`;

S.UserMainBalanceLabel = styled.div`
    width: 100%;
    font-weight: 700;
    color: #23282e;
    font-size: 1rem;
    color: #676767;
`;

S.UserMainBalanceValue = styled.div`
    font-size: 1.2rem;
    font-weight: 600;
    color: #23282e;
    align-self: baseline;
     line-height: 1;
`;

S.UserMainBalanceCurrency = styled.div`
    display: inline-block;
    font-weight: 700;
    color: #23282e;
    font-size: 1rem;
    text-transform: uppercase;
     align-self: baseline;
     margin-left: 2px;
     line-height: 1;
       font-size: 1.2rem;
`;

S.UserBonusBalance = styled.div`
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    margin-right: 3.33rem;
    border-radius: 5px;
    padding: 0 1.0833333333rem;
    width: 25%;
    height: 3.333rem;
    padding-right: 0;
`;

S.UserBonusBalanceLabel =  styled.div`
    width: 100%;
    font-weight: 700;
    color: #23282e;
    font-size: 1rem;
    color: #676767;
`;

S.UserIcon = styled.i`
    width:1.4167rem;
    height:1.4167rem;
    margin-right:0.4167rem;
    & > svg {
        & > path{
            fill: ${({theme:{$accountPrimaryColor}})=> $accountPrimaryColor} !important;
        }
    }
`;
S.UserName = styled.div`
    position:relative;
    font-size: 1.17rem;
    color: ${({theme:{$accountPrimaryColor}})=> $accountPrimaryColor};
    font-weight: 600;
    &:before{
        position:absolute;
        content:"";
        left: 110%;
        top:calc(50% - 1px);
        height:1px;
        width:1000%;
        background-color:${({theme:{$accountPrimaryColor}})=> $accountPrimaryColor};;
    }
`;
S.UserId = styled.div`
    font-size: 1.17rem;
    font-weight: 700;
`;
S.UserInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

S.BalanceSelect = styled.div`
    min-width: 100%;
    cursor: pointer;
    align-items: stretch;
    top: -3px;
    left: -10px;
    
    & .react-select__menu{
        margin-top: 0;
        
        & .react-select__menu-list{
             padding: 0;
        
            & > div{
                cursor: pointer;
            }
        }
    }
    
    & .react-select__control{
        box-shadow: none;
        min-height: 0;
        border: none;
        
        & .react-select__dropdown-indicator{
            padding: 3px;
        }
    
        & .react-select__value-container{
        
            & .react-select__single-value{
                 font-size: 1.2rem;
                 font-weight: 700;
                 font-family: Roboto,sans-serif;
            }
        }
    
    }
`;

S.Image = styled.img`
    display: flex;
    align-items: center;
    width: 2.08rem;
    margin-right: 0.75rem;
    
    & path {
        fill: #000;
    }
`;


export default S;