import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {translation, formatMoney} from 'helpers/utilsHelper.js';
import {formatDate} from 'helpers/datesHelper.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _get from 'lodash/get';
import Loader from 'Loader.js';
import Pagination from 'Pagination.js';
import ProgressCircularBar from 'ProgressCircularBar.js';
import S from 'StyledAccountHistoryBonus.js';
import ReactTooltip from 'react-tooltip';
import {
    fetchBonusListByFilter,
    setCurrentPage,
    fetchBonusListPending,
    fetchBonusListFulfilled,
    fetchBonusListFailure
} from 'accountBonusActions.js';

class AccountHistoricalBonus extends Component {

    componentDidMount = async() => {
        const {fetchBonusListPending, fetchBonusListByFilter, fetchBonusListFailure, fetchBonusListFulfilled, startDate, endDate} = this.props;

        fetchBonusListPending();

        try {
            const data = await fetchBonusListByFilter({status: 'HISTORY', startDate, endDate});
            fetchBonusListFulfilled(data);
        } catch (error) {
            console.log('AccountHistoricalBonus:', error);
            fetchBonusListFailure(error)
        }
    };

    goPage = async(status, currentPage) => {
        const {fetchBonusListByFilter, startDate, endDate, limit, setCurrentPage} = this.props;
        const params = {
            endDate,
            startDate,
            limit,
            currentPage,
            status
        };

        try {
            await fetchBonusListByFilter(params);
            setCurrentPage(currentPage, status);
        } catch (error) {
            console.log('goPage:' + error);
        }
    };


    render() {

        const {isPending, error, bonus, limit, currencyCode} = this.props;
        const historyBonus = _get(bonus, 'history');
        const historyBonusData = _get(historyBonus, 'data');

        return (
            <S.AccountHistoryBonus className="account-history-bonus">

                {isPending ?
                    <Loader/>
                    : (
                        <>

                        <S.BonusList>
                            <S.BonusListHeader>{translation('account_bonus_tableHistorical')}</S.BonusListHeader>
                            <S.Table>
                                <S.Thead>
                                    <S.Tr>
                                        <S.Th>{translation('account_bonus_name')}</S.Th>
                                        <S.Th>{translation('account_bonus_activatedDate')}</S.Th>
                                        <S.Th>{translation('account_bonus_completedDate')}</S.Th>
                                        <S.Th>{translation('account_bonus_amount')}</S.Th>
                                        <S.Th>{translation('account_bonus_totalToWager')}</S.Th>
                                        <S.Th>{translation('account_bonus_leftToWager')}</S.Th>
                                        <S.Th>{translation('account_bonus_wageredPercent')}</S.Th>
                                        <S.Th>
                                            <span className="ion ion-help-circled"></span>
                                        </S.Th>
                                    </S.Tr>
                                </S.Thead>
                                <S.Tbody>

                                    {_size(historyBonusData) ?
                                        (
                                            <>

                                            {_map(historyBonusData, ({offerName, activationTime, statusUpdatedOn, maxBonusAmount, totalToWager, leftToWager, wageredPercent, wagerBonusId, descriptionKey}) => {
                                                return (
                                                    <S.Tr key={wagerBonusId}>
                                                        <S.Td className="td">
                                                            <span>{offerName}</span>
                                                        </S.Td>
                                                        <S.Td>
                                                            <span>{formatDate(activationTime, 'yyyy-MM-dd HH:mm')}</span>
                                                        </S.Td>
                                                        <S.Td>
                                                            <span>{formatDate(statusUpdatedOn, 'yyyy-MM-dd HH:mm')}</span>
                                                        </S.Td>
                                                        <S.Td>
                                                            <span
                                                                className="ammount">{formatMoney(maxBonusAmount)}</span>&nbsp;
                                                            <span className="currency">{currencyCode}</span>
                                                        </S.Td>
                                                        <S.Td>
                                                            <span
                                                                className="ammount">{formatMoney(totalToWager)}</span>&nbsp;
                                                            <span className="currency">{currencyCode}</span>
                                                        </S.Td>
                                                        <S.Td>
                                                            <span
                                                                className="ammount">{formatMoney(leftToWager)}</span>&nbsp;
                                                            <span className="currency">{currencyCode}</span>
                                                        </S.Td>
                                                        <S.Td className="wageredPercent">
                                                            <S.ProgressCircularWrapper>
                                                                <ProgressCircularBar percentage={wageredPercent}/>
                                                            </S.ProgressCircularWrapper>
                                                        </S.Td>
                                                        <S.Td>
                                                            <a data-tip data-for={`bonus_desc_${wagerBonusId}`}
                                                               className="ion ion-help-circled"></a>
                                                            <ReactTooltip id={`bonus_desc_${wagerBonusId}`}
                                                                          type="light">
                                                                <span
                                                                    dangerouslySetInnerHTML={{__html: translation(descriptionKey)}}></span>
                                                            </ReactTooltip>
                                                        </S.Td>
                                                    </S.Tr>
                                                )
                                            })}

                                            {_size(historyBonusData) && (
                                                <S.Tr>
                                                    <S.Td colSpan="8" className="pagination">
                                                        <Pagination currentPage={_get(historyBonus, 'currentPage')}
                                                                    limit={limit} data={historyBonusData}
                                                                    cb={this.goPage.bind(this, 'history')}/>
                                                    </S.Td>
                                                </S.Tr>
                                            )}

                                            </>

                                        )
                                        :
                                        <S.NoResult>
                                            <S.Td colSpan="8">{translation('account_bonus_notNound')}</S.Td>
                                        </S.NoResult>
                                    }
                                </S.Tbody>
                            </S.Table>
                        </S.BonusList>

                        </>
                    )
                }

            </S.AccountHistoryBonus>
        );
    }
}

const mapStateToProps = ({AccountBonus:{isPending, error, startDate, endDate, activeTab, limit, bonus}, Auth:{user:{currencyCode}}}, props) => {
    return {
        error,
        isPending,
        startDate,
        endDate,
        activeTab,
        limit,
        bonus,
        currencyCode
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBonusListByFilter: bindActionCreators(fetchBonusListByFilter, dispatch),
        setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
        fetchBonusListPending: bindActionCreators(fetchBonusListPending, dispatch),
        fetchBonusListFulfilled: bindActionCreators(fetchBonusListFulfilled, dispatch),
        fetchBonusListFailure: bindActionCreators(fetchBonusListFailure, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountHistoricalBonus);
