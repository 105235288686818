export const CHANGE_DATE_BY_TYPE = 'CHANGE_SESSIONS_DATE_BY_TYPE';
export const FETCH_SESSIONS_LIST = 'FETCH_SESSIONS_LIST';
export const SET_CURRENT_PAGE = 'SET_SESSIONS_CURRENT_PAGE';
export const RESET_TO_INITIAL_STATE = 'RESET_TO_INITIAL_STATE';

const setCurrentPage = (currentPage)=>{
    return {
        type: SET_CURRENT_PAGE,
        payload: {currentPage}
    }
};

const changeDateByType = (dateType, date) => {
    return {
        type: CHANGE_DATE_BY_TYPE,
        payload: {
            dateType,
            date
        }
    }
};

const fetchSessionsByFilter = (config) => {
    return async(dispatch, getState, {CustomerApi}) => {
        dispatch(fetchSessionsListPending());

        try{
            const {code, data} = await CustomerApi.getSessionHistory(config);
            dispatch(fetchSessionsListSuccess(data));
            return data;
        }catch(error){
            dispatch(fetchSessionsListFailure(error));
            throw error;
        }
    }
};

const fetchSessionsListSuccess = (data) => {
    return {
        type: `${FETCH_SESSIONS_LIST}_SUCCESS`,
        payload: {data}
    }
};

const fetchSessionsListPending = () => {
    return {
        type: `${FETCH_SESSIONS_LIST}_PENDING`
    }
};

const fetchSessionsListFailure = (error) => {
    return {
        type: `${FETCH_SESSIONS_LIST}_FAILURE`,
        payload: {error}
    }
};

const resetToInitialState = ()=>{
    return {
        type: RESET_TO_INITIAL_STATE
    }
}

export {changeDateByType, fetchSessionsByFilter, setCurrentPage, resetToInitialState}