import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadCustomerLimits, updateCustomerLimits, closeCustomerAccount } from 'customerActions.js';
import { translation } from 'helpers/utilsHelper';
import S from 'StyledGamblingControls.js';

const DAILY_DEPOSIT_LIMIT_ID = 7;

const GamblingControls = () => {
  const dispatch = useDispatch();

  // Customer's current daily deposit limit
  const [currentDepositLimit, setCurrentDepositLimit] = useState(null);

  const [isLimitInputActive, setIsLimitInputActive] = useState(false);
  const [limitAmount, setLimitAmount] = useState('');

  const [isCloseAccountModalOpen, setIsCloseAccountModalOpen] = useState(false);

  // Fetch customer's current daily deposit limit on initial render
  useEffect(() => {
    const fetchCurrentLimit = async () => {
      const [currentLimit] = await dispatch(loadCustomerLimits());
      return currentLimit;
    };

    fetchCurrentLimit()
      .then((currentLimit) => setCurrentDepositLimit(currentLimit))
      .catch((error) => console.error(error));
  }, []);

  // Parse limit amount's input value, allowing only numbers
  const onInputChange = (event) => {
    const newValue = event.target.value.replace(/[^0-9]/g, '');
    setLimitAmount(newValue);
  };

  // Submit entered amount value as daily deposit limit,
  // then display new deposit limit
  const submitNewLimit = async () => {
    try {
      const [newLimit] = await dispatch(
        updateCustomerLimits([
          {
            limitType: DAILY_DEPOSIT_LIMIT_ID,
            amount: limitAmount,
          },
        ])
      );
      setIsLimitInputActive(false);
      setLimitAmount('');
      setCurrentDepositLimit(newLimit);
    } catch (error) {
      throw error;
    }
  };

  // Remove currently set daily deposit limit
  const removeCurrentLimit = async () => {
    try {
      await dispatch(
        updateCustomerLimits([
          {
            limitType: DAILY_DEPOSIT_LIMIT_ID,
          },
        ])
      );
      setCurrentDepositLimit(null);
    } catch (error) {
      throw error;
    }
  };

  // Close customer's account
  const handleCloseAccount = async () => {
    await dispatch(closeCustomerAccount());
  };

  // Parse daily deposit limit modification date
  const getModificationDate = (date) =>
    new Date(date).toLocaleString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });

  // Render conditionally customer's daily deposit limit,
  // or limit amount input depending on state
  const renderDepositLimitInfo = () => {
    if (isLimitInputActive)
      return (
        <S.StyledGamblingControlsLimitsContentLabel>
          <S.StyledGamblingControlsLimitsInput type="text" value={limitAmount} onChange={(event) => onInputChange(event)} />
        </S.StyledGamblingControlsLimitsContentLabel>
      );

    if (currentDepositLimit)
      return (
        <S.StyledGamblingControlsLimitsInfo>
          <S.StyledGamblingControlsLimitsInfoLabel color="green" data-translation="gamblingControls_dailyDepositLimitValue">
            {currentDepositLimit?.amount ? translation('gamblingControls_dailyDepositLimitValue', [currentDepositLimit.amount]) : ''}
          </S.StyledGamblingControlsLimitsInfoLabel>
          <S.StyledGamblingControlsLimitsInfoDate data-translation="gamblingControls_dailyDepositLimitDate">
            {currentDepositLimit?.modificationDate
              ? translation('gamblingControls_dailyDepositLimitDate', [getModificationDate(currentDepositLimit.modificationDate)])
              : ''}
          </S.StyledGamblingControlsLimitsInfoDate>
        </S.StyledGamblingControlsLimitsInfo>
      );

    return (
      <S.StyledGamblingControlsLimitsContentLabel>
        <S.StyledGamblingControlsLimitsInfoLabel data-translation="gamblingControls_noLimitSet">
          {translation('gamblingControls_noLimitSet')}
        </S.StyledGamblingControlsLimitsInfoLabel>
      </S.StyledGamblingControlsLimitsContentLabel>
    );
  };

  // Render conditionally daily deposit limit action buttons,
  // depending on current state
  const renderButtons = () => {
    if (!currentDepositLimit && !isLimitInputActive)
      return (
        <S.StyledGamblingControlsButton color="green" onClick={() => setIsLimitInputActive(true)}>
          {translation('gamblingControls_setLimit')}
        </S.StyledGamblingControlsButton>
      );

    if (currentDepositLimit)
      return (
        <S.StyledGamblingControlsButton color="red" onClick={removeCurrentLimit}>
          {translation('gamblingControls_remove')}
        </S.StyledGamblingControlsButton>
      );

    if (isLimitInputActive) {
      if (limitAmount)
        return (
          <S.StyledGamblingControlsButton color="green" onClick={submitNewLimit}>
            {translation('gamblingControls_ok')}
          </S.StyledGamblingControlsButton>
        );
      else
        return (
          <S.StyledGamblingControlsButton color="gray" onClick={() => setIsLimitInputActive(false)}>
            {translation('gamblingControls_cancel')}
          </S.StyledGamblingControlsButton>
        );
    }

    return null;
  };

  return (
    <S.StyledGamblingControls>
      {isCloseAccountModalOpen ? (
        <S.StyledAccountActionModal>
          <S.StyledAccountActionModalBody>
            <S.StyledAccountActionModalIcon action="closeAccount" />
            <S.StyledAccountActionModalTitle action="closeAccount" data-translation="closeAccountModal_bodyTitle">
              {translation('closeAccountModal_bodyTitle')}
            </S.StyledAccountActionModalTitle>
            <S.StyledAccountActionModalContent data-translation="closeAccountModal_bodyContent">
              {translation('closeAccountModal_bodyContent')}
            </S.StyledAccountActionModalContent>
            <S.StyledAccountActionModalButtonContainer>
              <S.StyledAccountActionModalButton onClick={() => setIsCloseAccountModalOpen(false)} data-translation="closeAccountModal_cancel">
                {translation('closeAccountModal_cancel')}
              </S.StyledAccountActionModalButton>
              <S.StyledAccountActionModalButton action="closeAccount" onClick={handleCloseAccount} data-translation="closeAccountModal_accept">
                {translation('closeAccountModal_accept')}
              </S.StyledAccountActionModalButton>
            </S.StyledAccountActionModalButtonContainer>
          </S.StyledAccountActionModalBody>
        </S.StyledAccountActionModal>
      ) : null}

      <S.StyledGamblingControlsHeading>
        <S.StyledGamblingControlsIcon />
        <span data-translation="gamblingControls">{translation('gamblingControls')}</span>
      </S.StyledGamblingControlsHeading>

      {/* Daily Deposit Limit */}
      <S.StyledGamblingControlsLimits>
        <S.StyledGamblingControlsLimitsHeader>
          <span data-translation="gamblingControls_dailyDepositLimit">{translation('gamblingControls_dailyDepositLimit')}</span>
          <S.StyledGamblingControlsLimitsStatus
            active={!!currentDepositLimit}
            data-translation={!!currentDepositLimit ? 'gamblingControls_active' : 'gamblingControls_off'}
          >
            {currentDepositLimit ? translation('gamblingControls_active') : translation('gamblingControls_off')}
          </S.StyledGamblingControlsLimitsStatus>
        </S.StyledGamblingControlsLimitsHeader>

        <S.StyledGamblingControlsLimitsContent>
          {renderDepositLimitInfo()}
          {renderButtons()}
        </S.StyledGamblingControlsLimitsContent>
      </S.StyledGamblingControlsLimits>

      {/* Account Suspension / Deletion */}
      <S.StyledGamblingControlsLimits>
        <S.StyledGamblingControlsLimitsHeader>
          <span data-translation="gamblingControls_accountStatus">{translation('gamblingControls_accountStatus')}</span>
          <S.StyledGamblingControlsLimitsStatus active data-translation="gamblingControls_active">
            {translation('gamblingControls_active')}
          </S.StyledGamblingControlsLimitsStatus>
        </S.StyledGamblingControlsLimitsHeader>

        <S.StyledGamblingControlsLimitsContent>
          <S.StyledGamblingControlsLimitsContentLabel>
            <S.StyledGamblingControlsLimitsInfoLabel data-translation="gamblingControls_suspendAccount">
              {translation('gamblingControls_suspendAccount')}
            </S.StyledGamblingControlsLimitsInfoLabel>
          </S.StyledGamblingControlsLimitsContentLabel>

          <S.StyledGamblingControlsButton color="orange" data-translation="gamblingControls_suspend">
            {translation('gamblingControls_suspend')}
          </S.StyledGamblingControlsButton>
        </S.StyledGamblingControlsLimitsContent>

        <S.StyledGamblingControlsLimitsContent>
          <S.StyledGamblingControlsLimitsContentLabel>
            <S.StyledGamblingControlsLimitsInfoLabel data-translation="gamblingControls_closeAccount">
              {translation('gamblingControls_closeAccount')}
            </S.StyledGamblingControlsLimitsInfoLabel>
          </S.StyledGamblingControlsLimitsContentLabel>

          <S.StyledGamblingControlsButton color="red" onClick={() => setIsCloseAccountModalOpen(true)} data-translation="gamblingControls_close">
            {translation('gamblingControls_close')}
          </S.StyledGamblingControlsButton>
        </S.StyledGamblingControlsLimitsContent>
      </S.StyledGamblingControlsLimits>
    </S.StyledGamblingControls>
  );
};

export default GamblingControls;
