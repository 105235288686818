import React, {PureComponent} from 'react';
import _head from 'lodash/head';
import _get from 'lodash/get';
import S from 'StyledDropdownMenu.js';
import classNames from 'classnames';
import {findCategoryItemByIdLevel} from 'helpers/utilsHelper';
import { CSSTransition } from 'react-transition-group';
import {translation} from 'utilsHelper.js';

class DropdownMenu extends PureComponent {

    static propTypes = {};

    constructor(props) {
        super(props);

        const menuItems = [];
        props.config.forEach((item) => {
            if (item.children) {
                const id = item.categoryId;
                const isOpen = false;
                menuItems.push({id, isOpen});
            }
        });
        this.state = {menuItems};
    }

    toggleDropdown = (e, id) => {
        e.preventDefault();

        const indexOfItem = this.findIndexOfItem(id);
        const item = _head(this.state.menuItems.slice(indexOfItem, indexOfItem + 1));

        this.updateCategoryItem(indexOfItem, item);
    }

    updateCategoryItem = (indexOfItem, item) => {
        this.setState((prevState) => {

            const itemsBeforeIndex = prevState.menuItems.slice(0, indexOfItem);
            const itemsAfterIndex = prevState.menuItems.slice(indexOfItem + 1);
            const nextState = {
                ...prevState,
                menuItems: [...itemsBeforeIndex, {...item, isOpen: !item.isOpen}, ...itemsAfterIndex]
            };
            return nextState;
        })
    }

    addCategoryItem = (id, isOpen = false) => {
        this.setState((prevState) => {
            return {...prevState, menuItems: [...prevState.menuItems, {id, isOpen}]}
        });
    }

    findIndexOfItem = (searchId) => {
        return this.state.menuItems.findIndex(({id}) => id == searchId);
    }

    findItemByCategoryId = (searchId) => {
        return this.state.menuItems.find(({id}) => id == searchId);
    }

    getMenuItem = (menuItem) => {
        const {isPopular,allCategories} = this.props
        const title = menuItem.categoryName;
        const categoryId = menuItem.categoryId;
        const sportId = menuItem.sportId;
        const parentCategory = menuItem.parentCategory;
        const level = menuItem.level;
        const eventsCount = menuItem.eventsCount;
        const lvl1OfCategory = isPopular ? allCategories.find(item => item.categoryId === categoryId) : null
        if (menuItem.children && menuItem.children.length > 0) {
            const {isOpen} = this.findItemByCategoryId(categoryId);
            const itemClass = classNames({'is-active': isOpen, 'has-submenu': true, 'dropdown-menu-item': true});
            return (
                <S.DropdownMenuItem key={categoryId} className={itemClass} data-level={level} data-parent-id={parentCategory}>
                    <S.DropdownMenuItemInner className={`dropdown-menu-item-inner sport-icon sport-${categoryId}`} onClick={(e) => {
                        this.toggleDropdown(e, categoryId);
                    }}>
                        {/* {level == 1 && <S.Icon className={`sport-icon sport-${sportId}`}></S.Icon>} */}

                        {/* <S.Marker className="event-marker"></S.Marker> */}

                        <S.Title className="title">{title}</S.Title>

                        {level == 1 && <S.EventsCount className="events-count">+{eventsCount}</S.EventsCount>}

                        {level == 2 && <S.Arrow className="arrow"></S.Arrow>}

                    </S.DropdownMenuItemInner>
                    <CSSTransition in={isOpen} mountOnEnter unmountOnExit timeout={500} classNames="slide-transition">
                        {isPopular&&lvl1OfCategory ? 
                        <>
                            <S.DropdownPopularTitle> {translation('popular_sport_title')} {title}</S.DropdownPopularTitle>
                            <DropdownMenu {...this.props} config={menuItem.children} submenu={true}/>
                            <S.DropdownPopularTitle>{translation('common_sports_title')} {title}</S.DropdownPopularTitle>
                            <DropdownMenu {...this.props} config={lvl1OfCategory.children} submenu={true}/>   
                        </>
                        :
                            <DropdownMenu {...this.props} config={menuItem.children} submenu={true}/>
                        }
                    </CSSTransition>
                </S.DropdownMenuItem>
            );
        } else {

            const sportName = _get(findCategoryItemByIdLevel(categoryId, 1, this.props.orgCategories), 'categoryName');
            const isChecked = this.isChecked(categoryId);
            const chboxClass = classNames({'is-active': isChecked, 'fake-chbox': true});
            const titleClass = classNames({'is-active': isChecked, 'title': true});
            const params = [sportName, sportId, categoryId];
            return (
                <S.DropdownMenuItem key={categoryId} className="dropdown-menu-item" data-level={level} data-parent-id={parentCategory}>
                    <S.DropdownMenuItemInner className="dropdown-menu-item-inner">
                        <S.Marker></S.Marker>

                        <S.FakeChbox className={chboxClass} onClick={(e) => {
                            this.handleCheckbox(e, ...params);
                        }}></S.FakeChbox>

                        <S.Title className={titleClass} onClick={(e)=>{
                            this.props.deselectLeagueById(this.props.selectedLeagues);
                            this.handleCheckbox(e, ...params);
                            this.redirectToEventList(...params);
                        }}>{title}</S.Title>

                    </S.DropdownMenuItemInner>
                </S.DropdownMenuItem>
            );
        }
    };

    isChecked = (leagueId) => {
        const {selectedLeagues} = this.props;
        return (selectedLeagues.indexOf(leagueId) != -1);
    };

    redirectToEventList = (sportName, sportId, leagueId) => {
        const params = {
            selectedSports: sportName,
            filterSportId: sportId,
            categories: leagueId
        };
        app.router.redirect('/sports/events/:selectedSports/:categories/:filterSportId', params);
    };

    handleCheckbox = (e, sportName, sportId, leagueId) => {
        e.preventDefault();

        let {selectedLeagues, ...rest} = this.props;
        let tempLeagues = [];
        const {prevSortId, changeSportId, selectLeagueById, deselectLeagueById} = rest;
        const target = e.target;

        const div = target.closest('div');
        if (div) {
            if (!div.classList.contains('is-active')) {
                if (sportId != prevSortId) {
                    changeSportId(sportId);
                    selectedLeagues = [];
                }
                if (selectedLeagues.indexOf(leagueId) == -1) {
                    selectLeagueById(leagueId);
                    tempLeagues = [...selectedLeagues, leagueId];
                }
            } else {
                deselectLeagueById(leagueId);
                tempLeagues = selectedLeagues.filter(id => id != leagueId);
            }

            if (!tempLeagues.length) {
                app.router.redirect('/sports/events');
            } else {
                const leaguesIds = tempLeagues.join(',');
                this.redirectToEventList(sportName, sportId, leaguesIds);
            }
        }
    };

    render() {

        const {config} = this.props;
        const options = [];
        config.map((item) => {
            options.push(this.getMenuItem(item));
        });

        if (this.props.submenu && this.props.submenu === true) {
        return  <S.DropdownSubmenuList className="dropdown-submenu">{options}</S.DropdownSubmenuList>
        }

        return (
        <S.DropdownMenuList className="dropdown-menu">{options}</S.DropdownMenuList>
        );
    }
}

export default DropdownMenu;
