import typeToReducer from "type-to-reducer";
import update from "immutability-helper";
import { GET_QUICK_SEARCH_RESULTS, CLEAR_QUICK_SEARCH_RESULTS } from "quickSearchActions.js";

let initialState = {
    events: [],
    isPending: false,
    error: null
};

export default typeToReducer(
    {
        [GET_QUICK_SEARCH_RESULTS]: {
            PENDING: state => {
                return { ...state, error: null, isPending: true };
            },
            SUCCESS: (state, { payload: { data } }) => {
                const newState = update(state, { events: { $set: data }, isPending: { $set: false } });
                return { ...newState };
            },
            FAILURE: (state, { payload: { error } }) => {
                return { ...state, isPending: false, error };
            }
        },
        [CLEAR_QUICK_SEARCH_RESULTS]: (state) => {
            return { ...state, events: [], error: null }
        }
    },
    initialState
);
