// actions name
export const CHANGE_ACTIVE_ITEM = 'CHANGE_ACTIVE_ITEM';
export const CHANGE_SUBMENU_ACTIVE_ITEM = 'CHANGE_SUBMENU_ACTIVE_ITEM';
export const CHANGE_SUBMENU_DROPDOWN_ITEM = 'CHANGE_SUBMENU_DROPDOWN_ITEM';
export const LOAD_PROMO_CODE_SETTING = 'LOAD_PROMO_CODE_SETTING';

const changeActiveItem = (activeItem) => {
    return {
        type: CHANGE_ACTIVE_ITEM,
        payload: {activeItem}
    }
};

const changeSubmenuActiveItem  = (submenuItem) => {
    return {
        type: CHANGE_SUBMENU_ACTIVE_ITEM,
        payload: {submenuItem}
    }
};

const changeSubmenuDropdownItem  = (submenuDropdownItem) => {
    return {
        type: CHANGE_SUBMENU_DROPDOWN_ITEM,
        payload: {submenuDropdownItem}
    }
};

const fetchPromoCodeSetting = () => {
    return async(dispatch, getState, {AccountBonusApi}) => {

        try {
            const {code, data} = await AccountBonusApi.getPromoCodeSetting();
            dispatch(loadPromoCodeSuccess(data));

            return data;
        } catch (error) {
            throw error;
        }
    }
};

const loadPromoCodeSuccess = (promoCodeSetting) => {
    return {
        type: `${LOAD_PROMO_CODE_SETTING}_SUCCESS`,
        payload:  {promoCodeSetting}
    }
};

export {changeActiveItem, changeSubmenuActiveItem, changeSubmenuDropdownItem, fetchPromoCodeSetting, loadPromoCodeSuccess};
