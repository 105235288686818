import React, {Component} from 'react';
import _size from 'lodash/size';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import DropdownMenu from 'DropdownMenu.js';
import {fetchCategories, selectLeagueById, deselectLeagueById, changeSportId} from 'categoriesActions.js';
import {dropdownMenuSelector} from 'categoriesSelector.js';
import S from 'StyledLeftMenu.js';

class LeftMenu extends Component {

    static propTypes = {};

    static getDerivedStateFromError = (error) => {
        return {hasError: true};
    };

    state = {hasError: false};

    componentDidMount = () => {
        try {
           const { fetchCategories, categories } = this.props;
           if(!_size(categories)){
               fetchCategories();
           }
        } catch (error) {
            console.log('fetchCategories error:', error);
        }
    }

    render() {
        const {isPending, categories, ...rest} = this.props;
        const {hasError} = this.state;
        if (hasError) {
            return <div>Error fallback component!</div>
        }
        if (isPending) {
            return <div>Loading ...</div>
        }
        return (
            <S.LeftMenu className="left-menu">
                {categories.length && <DropdownMenu config={categories} {...rest} />}
            </S.LeftMenu>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {Categories:{isPending, error, selectedLeagues, prevSortId, categories:orgCategories}} = state;
    const categories = dropdownMenuSelector(state, props);

    return {
        selectedLeagues,
        categories,
        isPending,
        error,
        prevSortId,
        orgCategories
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        selectLeagueById: bindActionCreators(selectLeagueById, dispatch),
        deselectLeagueById: bindActionCreators(deselectLeagueById, dispatch),
        changeSportId: bindActionCreators(changeSportId, dispatch),
        fetchCategories: bindActionCreators(fetchCategories, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu);
