import styled from 'styled-components';

const Button = styled.button.attrs(({type}) => ({
    type: type || 'button'
}))`
  border: none;
  cursor: pointer;
`;

const Form = styled.form.attrs(({method}) => ({
    method: method || 'post'
}))`
`;

const Input = styled.input.attrs(({type}) => ({
    type: type || 'text'
}))`
`;

const Link = styled.a`
    text-decoration: none;
    outline: none;
`;

const List = styled.ul`
  padding: 0;
  list-style: none;
  & > li{}
`;

const NotLogged = styled.div`
    font-size: 1.5rem;
    color: #fff;
    display: flex;
    justify-content: center;
    margin: 2rem 1rem;
    width: 100%;
`;

export {Button, Form, Input, List, Link, NotLogged};