
export default (requests, axios) => {
    return {
        getOffersCanceledOnWithdrawal: () => {
            const sessionCookie = Cookies.get('X-ODDS-SESSION');

            const config = {};
            if (sessionCookie) {
                config['headers'] = {'X-ODDS-SESSION': sessionCookie};
            }
            return requests.get(`public/offers/cancel/withdrawal`, config);
        },
        getCustomerOfferBalancesWithDetails: () => {
            const sessionCookie = Cookies.get('X-ODDS-SESSION');
            const config = {};

            if (sessionCookie) {
                config['headers'] = {'X-ODDS-SESSION': sessionCookie};
            }

            return requests.get('customer/account/customerOfferBalance/details', config);
        },
        withdrawCustomerOfferBalance: ({offerDefinitionId}={}) => {
            const sessionCookie = Cookies.get('X-ODDS-SESSION');
            const config = {};

            if (sessionCookie) {
                config['headers'] = {'X-ODDS-SESSION': sessionCookie};
            }

            return requests.get(`customer/account/customerOfferBalance/withdraw/${offerDefinitionId}`, config);
        },
        getCustomerBetSlipPromotions: () => {
            const sessionCookie = Cookies.get('X-ODDS-SESSION');
            const config = {};

            if (sessionCookie) {
                config['headers'] = {'X-ODDS-SESSION': sessionCookie};
            }

            return requests.get('public/offers/betslip/promotions', config);
        },

    }
};
