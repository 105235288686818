// import ChevronIcon from 'chevron_down_dark.svg';
import TransactionHistory from 'transaction_history.svg'
import BetHistoryIcon from 'box_history.svg'
import PaymentIcon from 'exchange_alt.svg'
import ProfileIcon from 'user_edit.svg'
export default {
    PAYMENTS:{
        href:"/payments",
        translationKey:"account_userPanel_payments",
        id:"accountPayments",
        file:PaymentIcon
    },

    WALLET: {
        href: '/wallet',
        translationKey: 'account_userPanel_wallet',
        id: 'accountWallet',
        file: TransactionHistory
    },
    SLIP_HISTORY: {
        href: '/bet-history',
        translationKey: 'account_userPanel_betHistory',
        id: 'accountBetHistory',
        file:BetHistoryIcon
    },
    EDIT_PROFILE: {
        href: '/edit-profile',
        translationKey: 'account_userPanel_editProfile',
        file:ProfileIcon
    },
    GAMBLING_CONTROLS: {
        href: '/gambling-controls',
        translationKey: 'account_userPanel_gamblingControls'
    }
};