import styled from 'styled-components';
import {Form, Input, Button} from 'global/styles/styles.js';
const S = {};

S.ChangePasswordForm = styled(Form)`
width: 100%;
padding:1.5625rem;
`;

S.Row = styled.div`
    width:100%;
    margin-bottom: 1rem;
`;

S.Label = styled.label`
    margin:0 0 1.0833rem 0.8333rem;
    pointer-events: none;
    transition: .2s;
    color: #111111!important;
    font-size: 1.1rem;
    font-weight: 700;
`;

S.InputWrapper = styled.div`
    position: relative;
`;

S.Input = styled(Input)`
    box-shadow: none;
    border: .08rem solid #e1e1e1;
    color: #000;
    border-radius:1.75rem;
    font-size: 1.33rem;
    padding: 1.6rem 1.75rem;
    background-color: #f6f6f6;
    display: block;
    width: 100%;
    height: 34px;
    &:valid{
        & + ${S.Label}{
        margin-top: -1.42rem;
        font-size: 1.08rem;
        }
    }
`;

S.SubmitBtn = styled(Button)`
    border-radius:1.75rem;
    background-color: #00a651;
    font-size: 16px;
    font-weight: 600;
    padding: 0;
    width: 187px;
    height: 43px;
    margin-bottom: 2.18rem;
    margin-top: .69rem;
    color: #fff;
    min-width: 20%;
    cursor: pointer;
    &:hover{
        background-color: #51a517;
        transition: .2s all linear;
    }
`;

S.CloseBtn = styled(Button)`
    border-radius:1.75rem;
    background-color: #000;
    font-size: 16px;
    font-weight: 600;
    padding: 0;
    width: 187px;
    height: 43px;
    margin-bottom: 2.18rem;
    margin-top: .69rem;
    color: #fff;
    min-width: 20%;
    cursor: pointer;
    &:hover{
        background-color: #3c3c3c;
        transition: .2s all linear;
    }
`;

S.IconShowPassword = styled.div`
    background-repeat: no-repeat;
    vertical-align: middle;
    display: block;
    position: absolute;
    right: 0rem;
    width: 1.5rem;
    height: 1.5rem;
    margin-right:30px;
    margin-top:10px;
`;

S.Wrapper = styled.div`
display:flex;
`;

S.BtnWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

S.Error = styled.div`
    color: red;
    margin: 5px 0;
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
`;


S.SuccessModal = styled.div`
    margin-top: -44px;
`;

S.SuccessModalTitle = styled.h4`
    color: #2d9ef5;
    text-transform: uppercase;
    font-size: 1.83rem;
    padding: 0;
    font-weight: 800;
    font-style: italic;
    float: none!important;
    text-align: center;
    margin-bottom: 3.42rem;
`;

S.SuccessModalMessage = styled.p`
    text-align: center;
    color: #000;
    margin: 0;
    font-size: 1.1538461538rem;
    letter-spacing: .2px;
    font-weight: 400;
`;

export default S;