import {logOutUser} from 'authActions.js';
import _get from 'lodash/get';

const formMiddleware = ({dispatch, getState}) => (next) => (action) => {
    const {type, meta} = action;
    if (type == '@@redux-form/DESTROY') {
        const {form:[formName]} = meta;
        if (formName == 'responsibleGamingForm') {
            const {Customer:{customerRegulations}} = getState();
            if (customerRegulations && !_get(customerRegulations, ['bettingAllowed'])) {
                 dispatch(logOutUser());
            }
        }
    }
    return next(action);
};

export default formMiddleware;