import {combineReducers} from 'redux';
import categoriesReducer from 'categoriesReducer.js';
import authReducer from 'authReducer.js';
import topMenuReducer from 'topMenuReducer.js';
import betSlipReducer from 'betSlipReducer.js';
import customerReducer from 'customerReducer.js';
import accountReducer from 'accountReducer.js';
import accountWalletReducer from 'accountWalletReducer.js';
import accountCashbackReducer from 'accountCashbackReducer.js';
import accountFreebetReducer from 'accountFreebetReducer.js';
import accountBonusReducer from 'accountBonusReducer.js';
import accountBetHistoryReducer from 'accountBetHistoryReducer.js';
import paymentProvidersReducer from 'paymentProvidersReducer.js';
import livesReducer from 'livesReducer.js';
import intervalsReducer from 'intervalsReducer.js';
import providerContentReducer from 'providerContentReducer.js';
import themeReducer from 'themeReducer.js';
import toggleReducer from 'toggleReducer.js';
import getLuckyReducer from 'getLuckyReducer.js';
import quickSearchReducer from 'quickSearchReducer.js';
import cmsReducer from 'cmsReducer.js';
import offersReducer from 'offersReducer.js';
import betSlipCheckReducer from 'betSlipCheckReducer.js';
import IntegrationGamesListReducer from 'IntegrationGamesListReducer.js';
import IntegrationGameIframeReducer from 'IntegrationGameIframeReducer.js';
import popularCategoriesReducer from "popularCategoriesReducer.js";
import accountSessionsHistoryReducer from 'accountSessionsHistoryReducer.js';
import accountResponsibleGameSelfExclusionReducer from 'accountResponsibleGameSelfExclusionReducer.js';
import favouritesReducer from 'favouritesReducer.js';
import customerDataByMyIdReducer from 'customerDataByMyIdReducer.js';
import {reducer as formReducer} from 'redux-form';

export default combineReducers({
    form: formReducer,
    Categories: categoriesReducer,
    Auth: authReducer,
    TopMenu: topMenuReducer,
    BetSlip: betSlipReducer,
    Customer: customerReducer,
    Account: accountReducer,
    AccountWallet: accountWalletReducer,
    AccountCashback: accountCashbackReducer,
    AccountFreebet: accountFreebetReducer,
    AccountBonus: accountBonusReducer,
    AccountBetHistory: accountBetHistoryReducer,
    AccountDeposit: paymentProvidersReducer,
    DepositLoginPopup: paymentProvidersReducer,
    Lives: livesReducer,
    Intervals: intervalsReducer,
    AccountWithdraw: paymentProvidersReducer,
    ProviderContent: providerContentReducer,
    AccountResponsibleGameSelfExclusion: accountResponsibleGameSelfExclusionReducer,
    Theme: themeReducer,
    Toggle: toggleReducer,
    GetLucky: getLuckyReducer,
    CmsPages: cmsReducer,
    QuickSearch: quickSearchReducer,
    Offers: offersReducer,
    IntegrationGamesList: IntegrationGamesListReducer,
    IntegrationGameIframe: IntegrationGameIframeReducer,
    BetSlipCheck: betSlipCheckReducer,
    PopularCategories: popularCategoriesReducer,
    AccountSessionHistory: accountSessionsHistoryReducer,
    Favourites: favouritesReducer,
    CustomerDataByMyId: customerDataByMyIdReducer
});

