import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import S from 'StyledAccountCurrentBonus.js';
import {translation, formatMoney} from 'helpers/utilsHelper.js';
import {formatDate} from 'helpers/datesHelper.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _get from 'lodash/get';
import Loader from 'Loader.js';
import Pagination from 'Pagination.js';
import ProgressCircularBar from 'ProgressCircularBar.js';
import ReactTooltip from 'react-tooltip';
import ModalOpacity from 'ModalOpacity.js';
import {
    fetchBonusListByFilter,
    setCurrentPage,
    manageBonus,
    fetchBonusListPending,
    fetchBonusListFulfilled,
    fetchBonusListFailure
} from 'accountBonusActions.js';


class AccountCurrentBonus extends Component {

    state = {
        selectedOfferId: null,
        showConfirmationModal: false,
        showInfoModal: false,
        modalTitle: '',
        modalDescription: '',
        infoModalTitle: '',
        infoModalDescription: '',
        status: ''
    };

    setSelectedOfferId = (selectedOfferId) => {
        this.setState((prevState) => {
            return {...prevState, selectedOfferId}
        });
    };

    setStatus = (status) => {
        this.setState((prevState) => {
            return {...prevState, status}
        });
    };

    setInfoModalTextElements = (infoModalTitle, infoModalDescription) => {
        this.setState((prevState) => {
            return {...prevState, infoModalTitle, infoModalDescription};
        });
    };

    setConfirmModalTextElements = (action) => {
        let modalTitle = '';
        let modalDescription = '';
        switch (action) {
            case 'activate':
                modalTitle = translation('account_bonus_activationTitle');
                modalDescription = translation('account_bonus_confirmActivate');
                break;
            case 'cancel':
                modalTitle = translation('account_bonus_cancellationTitle');
                modalDescription = translation('account_bonus_confirmCancel');
                break;
            case 'reject':
                modalTitle = translation('account_bonus_cancellationTitle');
                modalDescription = translation('account_bonus_confirmReject');
                break;
        }

        this.setState((prevState) => {
            return {...prevState, modalTitle, modalDescription};
        });
    };

    toggleModal = (type) => {
        this.setState((prevState) => {
            return {...prevState, [type]: !prevState[type]}
        });
    };

    renderBonusActionConfirmation = (offerId, status) => {
        this.setStatus(status);
        this.setConfirmModalTextElements(status);
        this.setSelectedOfferId(offerId);
        this.toggleModal('showConfirmationModal');
    };

    componentDidMount = async() => {
        const {fetchBonusListPending, fetchBonusListByFilter, fetchBonusListFailure, startDate, endDate, fetchBonusListFulfilled} = this.props;

        fetchBonusListPending();

        try {
            const activeBonusPromise = fetchBonusListByFilter({status: 'ACTIVE', endDate, startDate});
            const queueBonusPromise = fetchBonusListByFilter({status: 'QUEUE',  endDate, startDate});
            const result = await Promise.all([activeBonusPromise, queueBonusPromise]);
            fetchBonusListFulfilled(result);
        } catch (error) {
            console.log('AccountCurrentBonus:', error);
            fetchBonusListFailure(error)
        }
    };

    goPage = async(status, currentPage) => {
        const {fetchBonusListByFilter, startDate, endDate, limit, setCurrentPage} = this.props;
        currentPage = currentPage * limit;
        const params = {
            endDate,
            startDate,
            limit,
            currentPage,
            status
        };

        try {
            await fetchBonusListByFilter(params);
            setCurrentPage(currentPage, status);
        } catch (error) {
            console.log('goPage:' + error);
        }
    };

    bonusActionAccept = async(offerId, action) => {
        const {manageBonus} = this.props;

        try {
            const result = await manageBonus(offerId, action);
            this.toggleModal('showConfirmationModal');
            this.toggleModal('showInfoModal');

            this.setInfoModalTextElements('success', '');
        } catch (error) {
            this.toggleModal('showConfirmationModal');
            this.toggleModal('showInfoModal');

            this.setInfoModalTextElements('error', translation(error));
        }
    };

    render() {
        const {isPending, error, bonus, limit, currencyCode} = this.props;
        const {showInfoModal, showConfirmationModal, modalTitle, modalDescription, infoModalTitle, infoModalDescription, status, selectedOfferId} = this.state;

        const activeBonus = _get(bonus, 'active');
        const queueBonus = _get(bonus, 'queue');
        const activeBonusData = _get(activeBonus, 'data');
        const queueBonusData = _get(queueBonus, 'data');

        return (
            <S.AccountCurrentBonus className="account-current-bonus">

                {isPending ?
                    <Loader/>
                    : (
                        <>

                        <ModalOpacity isOpen={showConfirmationModal} showHeaderIcon={false} showHeaderTitle={false}
                                      toggleOpen={this.toggleModal.bind(this, 'showConfirmationModal')}>
                            <S.ActivateModal>
                                <S.Title>{modalTitle}</S.Title>
                                <S.Description>{modalDescription}</S.Description>
                                <S.BtnsWrapper>
                                    <S.ButtonCancel
                                        onClick={this.toggleModal.bind(this, 'showConfirmationModal')}>{translation('common_cancel')}</S.ButtonCancel>
                                    <S.ButtonAccept
                                        onClick={this.bonusActionAccept.bind(this, selectedOfferId, status)}>{translation('common_accept')}</S.ButtonAccept>
                                </S.BtnsWrapper>
                            </S.ActivateModal>
                        </ModalOpacity>

                        <ModalOpacity isOpen={showInfoModal} showHeaderIcon={false} showHeaderTitle={false}
                                      toggleOpen={this.toggleModal.bind(this, 'showInfoModal')}>

                            <S.ModalInfo>
                                <S.InfoTitle>{infoModalTitle}</S.InfoTitle>
                                {infoModalDescription && <S.InfoDescription>{infoModalDescription}</S.InfoDescription>}
                            </S.ModalInfo>

                        </ModalOpacity>

                        <S.BonusList>
                            <S.BonusListHeader>{translation('account_bonus_tableActive')}</S.BonusListHeader>
                            <S.Table>
                                <S.Thead>
                                    <S.Tr>
                                        <S.Th>{translation('account_bonus_name')}</S.Th>
                                        <S.Th>{translation('account_bonus_validFromDate')}</S.Th>
                                        <S.Th>{translation('account_bonus_validToDate')}</S.Th>
                                        <S.Th>{translation('account_bonus_amount')}</S.Th>
                                        <S.Th>{translation('account_bonus_totalToWager')}</S.Th>
                                        <S.Th>{translation('account_bonus_leftToWager')}</S.Th>
                                        <S.Th>{translation('account_bonus_wageredPercent')}</S.Th>
                                        <S.Th>
                                            <span className="ion ion-help-circled"></span>
                                        </S.Th>
                                        <S.Th>{translation('account_bonus_action')}</S.Th>
                                    </S.Tr>
                                </S.Thead>
                                <S.Tbody>

                                    {_size(activeBonusData) ?
                                        (
                                            <>

                                            {_map(activeBonusData, ({offerName, activationTime, validTo, maxBonusAmount, totalToWager, leftToWager, wageredPercent, wagerBonusId, descriptionKey}) => {
                                                return (
                                                    <S.Tr key={offerName}>
                                                        <S.Td className="td">
                                                            <span>{offerName}</span>
                                                        </S.Td>
                                                        <S.Td>
                                                            <span>{formatDate(activationTime, 'yyyy-MM-dd HH:mm')}</span>
                                                        </S.Td>
                                                        <S.Td>
                                                            <span>{formatDate(validTo, 'yyyy-MM-dd HH:mm')}</span>
                                                        </S.Td>
                                                        <S.Td>
                                                            <span
                                                                className="ammount">{formatMoney(maxBonusAmount)}</span>&nbsp;
                                                            <span className="currency">{currencyCode}</span>
                                                        </S.Td>
                                                        <S.Td>
                                                            <span
                                                                className="ammount">{formatMoney(totalToWager)}</span>&nbsp;
                                                            <span className="currency">{currencyCode}</span>
                                                        </S.Td>
                                                        <S.Td>
                                                            <span
                                                                className="ammount">{formatMoney(leftToWager)}</span>&nbsp;
                                                            <span className="currency">{currencyCode}</span>
                                                        </S.Td>
                                                        <S.Td className="wageredPercent">
                                                            <S.ProgressCircularWrapper>
                                                                <ProgressCircularBar percentage={wageredPercent}/>
                                                            </S.ProgressCircularWrapper>
                                                        </S.Td>
                                                        <S.Td>
                                                            <a data-tip data-for={`bonus_desc_${wagerBonusId}`}
                                                               className="ion ion-help-circled"></a>
                                                            <ReactTooltip id={`bonus_desc_${wagerBonusId}`}
                                                                          type="light">
                                                                <span
                                                                    dangerouslySetInnerHTML={{__html: translation(descriptionKey)}}></span>
                                                            </ReactTooltip>
                                                        </S.Td>
                                                        <S.Td className="bonus-actions">
                                                            <S.Button className="reject"
                                                                      onClick={this.renderBonusActionConfirmation.bind(this, wagerBonusId, 'cancel')}>{translation('common_cancel')}</S.Button>
                                                        </S.Td>
                                                    </S.Tr>
                                                )
                                            })}

                                            {_size(activeBonusData) && (
                                                <S.Tr>
                                                    <S.Td colSpan="9" className="pagination">
                                                        <Pagination currentPage={_get(activeBonus, 'currentPage')}
                                                                    limit={limit} data={activeBonusData}
                                                                    cb={this.goPage.bind(this, 'active')}/>
                                                    </S.Td>
                                                </S.Tr>
                                            )}

                                            </>

                                        )
                                        :
                                        <S.NoResult>
                                            <S.Td colSpan="9">{translation('account_bonus_notFound')}</S.Td>
                                        </S.NoResult>
                                    }
                                </S.Tbody>
                            </S.Table>
                        </S.BonusList>


                        <S.BonusList>
                            <S.BonusListHeader>{translation('account_bonus_tableAwaitingActivation')}</S.BonusListHeader>
                            <S.Table>
                                <S.Thead>
                                    <S.Tr>
                                        <S.Th>{translation('account_bonus_name')}</S.Th>
                                        <S.Th>{translation('account_bonus_amount')}</S.Th>
                                        <S.Th>{translation('account_bonus_totalToWager')}</S.Th>
                                        <S.Th>{translation('account_bonus_expiresOn')}</S.Th>
                                        <S.Th>
                                            <span className="ion ion-help-circled"></span>
                                        </S.Th>
                                        <S.Th>{translation('account_bonus_action')}</S.Th>
                                    </S.Tr>
                                </S.Thead>
                                <S.Tbody>

                                    {_size(queueBonusData) ?
                                        (
                                            <>

                                            {_map(queueBonusData, ({offerName, validTo, bonusValue, totalToWager, offerQueueId, descriptionKey}) => {
                                                return (
                                                    <S.Tr key={offerQueueId}>
                                                        <S.Td className="td">
                                                            <span>{offerName}</span>
                                                        </S.Td>
                                                        <S.Td>
                                                            <span
                                                                className="ammount">{formatMoney(bonusValue)}</span>&nbsp;
                                                            <span className="currency">{currencyCode}</span>
                                                        </S.Td>
                                                        <S.Td>
                                                            <span
                                                                className="ammount">{formatMoney(totalToWager)}</span>&nbsp;
                                                            <span className="currency">{currencyCode}</span>
                                                        </S.Td>
                                                        <S.Td>
                                                            <span>{formatDate(validTo, 'yyyy-MM-dd HH:mm')}</span>
                                                        </S.Td>
                                                        <S.Td>
                                                            <a data-tip data-for={`bonus_desc_${offerQueueId}`}
                                                               className="ion ion-help-circled"></a>
                                                            <ReactTooltip id={`bonus_desc_${offerQueueId}`}
                                                                          type="light">
                                                                <span
                                                                    dangerouslySetInnerHTML={{__html: translation(descriptionKey)}}></span>
                                                            </ReactTooltip>
                                                        </S.Td>
                                                        <S.Td className="bonus-actions">
                                                            <S.Button className="reject"
                                                                      onClick={this.renderBonusActionConfirmation.bind(this, offerQueueId, 'reject')}>{translation('account_bonus_delete')}</S.Button>
                                                            <S.Button className="accept"
                                                                      onClick={this.renderBonusActionConfirmation.bind(this, offerQueueId, 'activate')}>{translation('account_bonus_use')}</S.Button>
                                                        </S.Td>
                                                    </S.Tr>
                                                )
                                            })}

                                            {_size(queueBonusData) && (
                                                <S.Tr>
                                                    <S.Td colSpan="6" className="pagination">
                                                        <Pagination
                                                            currentPage={_get(queueBonus, 'currentPage')} limit={limit}
                                                            data={queueBonusData} cb={this.goPage.bind(this, 'queue')}/>
                                                    </S.Td>
                                                </S.Tr>)}

                                            </>

                                        )
                                        :
                                        <S.NoResult>
                                            <S.Td colSpan="6">{translation('account_bonus_notFound')}</S.Td>
                                        </S.NoResult>
                                    }
                                </S.Tbody>
                            </S.Table>
                        </S.BonusList>

                        </>
                    )
                }

            </S.AccountCurrentBonus>
        );
    }
}

const mapStateToProps = ({AccountBonus:{isPending, error, startDate, endDate, activeTab, limit, bonus}, Auth:{user:{currencyCode}}}, props) => {
    return {
        error,
        isPending,
        startDate,
        endDate,
        activeTab,
        limit,
        bonus,
        currencyCode
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBonusListByFilter: bindActionCreators(fetchBonusListByFilter, dispatch),
        setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
        fetchBonusListPending: bindActionCreators(fetchBonusListPending, dispatch),
        fetchBonusListFulfilled: bindActionCreators(fetchBonusListFulfilled, dispatch),
        fetchBonusListFailure: bindActionCreators(fetchBonusListFailure, dispatch),
        manageBonus: bindActionCreators(manageBonus, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountCurrentBonus);
