import { getPromoCookie } from 'cookiesHelper.js';
import RegisterByMyIdAttributes from 'registerByMyIdAttributes.enum.js';
import { translation } from 'helpers/utilsHelper.js';

export const NEW_CUSTOMER_DATA_BY_MY_ID = 'NEW_CUSTOMER_DATA_BY_MY_ID';

const fetchNewCustomerDataByMyId = (id) => {
    return async(dispatch, getState, {AuthApi}) => {
        dispatch(fetchNewCustomerDataByMyIdPending());
        try {
            const response = await AuthApi.fetchNewCustomerDataByMyId(id);
            const {code, description, data} = response;
            if (code == 200){
                let promo = getPromoCookie() || translation('registration_autocompletePromoCode');
                data.attributes.push({attribute: RegisterByMyIdAttributes.PROMO_CODE, value: promo}) ;
                dispatch(fetchNewCustomerDataByMyIdSuccess(data));
                return data;
            } else {
                dispatch(fetchNewCustomerDataByMyIdFailure(response));
                return response;
            }
        } catch (ex) {
            dispatch(fetchNewCustomerDataByMyIdFailure(ex));
            throw ex;
        }
    }
};

const fetchNewCustomerDataByMyIdPending = () => {
    return {
        type: `${NEW_CUSTOMER_DATA_BY_MY_ID}_PENDING`
    }
};

const fetchNewCustomerDataByMyIdSuccess = (customerDataByMyId) => {
    return {
        type: `${NEW_CUSTOMER_DATA_BY_MY_ID}_SUCCESS`,
        payload: {customerDataByMyId}
    }
};

const fetchNewCustomerDataByMyIdFailure = (error) => {
    return {
        type: `${NEW_CUSTOMER_DATA_BY_MY_ID}_FAILURE`,
        payload: {error}
    }
};

export {
    fetchNewCustomerDataByMyId,
    fetchNewCustomerDataByMyIdPending,
    fetchNewCustomerDataByMyIdSuccess,
    fetchNewCustomerDataByMyIdFailure
}