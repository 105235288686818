import {formatDate} from 'helpers/datesHelper.js';
const encode = encodeURIComponent;
const today = new Date();

// Getting tommorrow's date, 
// since today's date won't return most recent cashbacks
const tomorrow = new Date().setDate(today.getDate()+1);

export default (requests, axios) => {
    return {
        getCashbackByFilter: ({status='PAID', endDate = tomorrow, startDate = today, limit = 10, currentPage = 0}={}) => {
            const dateFrom = formatDate(startDate, 'yyyy-MM-dd');
            const dateTo = formatDate(endDate, 'yyyy-MM-dd');
            const sessionCookie = Cookies.get('X-ODDS-SESSION');
            const config = {};
            if (sessionCookie) {
                config['headers'] = {'X-ODDS-SESSION': sessionCookie};
            }
            return requests.get(`/public/offers/cashback/bet/${dateFrom}/${dateTo}/${limit}/${currentPage}/?STATUS=${encode(status)}`, config);
        }
    }
};
