import styled from 'styled-components';
import {List, Button} from 'global/styles/styles.js';
import {rem} from 'polished';
const S = {};
const FontFamilyMulish = ({theme: {$fontFamilyMulish}}) => `${$fontFamilyMulish}`;

const baseFont = `12px`;
S.LoggedPanel = styled.div`
    display: flex;
`;

S.LogoutBtn = styled(Button)`
    background-color: ${({theme:{$topMenuLoggedOutBtnBgColor}})=> $topMenuLoggedOutBtnBgColor};
    min-width: 6rem;
    height: 2.5rem;
    padding: .5rem 1rem;
    border: 0;
    border-radius: .5rem;
    display: block;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    font-size: 1.2rem;
    font-family: Roboto,sans-serif;
    line-height: 1.2rem;
    padding-left: 1rem;
    font-weight: 700;
    color: ${({theme:{$topMenuUnloggedBtnColor}})=> $topMenuUnloggedBtnColor};
    flex-shrink: 0;
    margin-left: 5px;
    text-transform: lowercase;
    
    &:first-letter{
        text-transform: uppercase;
    }
`;

S.UserAccout = styled.div`
    position:relative;
    margin-right:1rem;
    &:hover{
        .user-wrapper{
            display:block;
        }
    }
    .container{
        position:relative;
        z-index: 20;
        min-width: 6rem;
        height: 2.5rem;
        padding: .5rem 1rem;
        border: 0;
        border-radius: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${({theme:{$topMenuLoggedUserLoginBgColor}})=> $topMenuLoggedUserLoginBgColor};
        font-weight: 700;
        flex-shrink: 0;
        cursor: pointer;
    }
`;
S.UserChevron = styled.div`
    width: 1rem;
    margin:0 .5rem 0 .75rem;
    transition: 0.3s;
    color:${({theme:{$topMenuLoggedUserLoginColor}})=> $topMenuLoggedUserLoginColor};
    &.active {
        transform: rotate(180deg);
    }
    svg{
        path{
            fill:${({theme:{$topMenuLoggedUserLoginColor}})=> $topMenuLoggedUserLoginColor} !important;
        }
    }
`;

S.UserLogin = styled.div`
    color: ${({theme:{$topMenuLoggedUserLoginColor}})=> $topMenuLoggedUserLoginColor};
    font-size: 1.2rem;
    font-size: 1.2rem;
    font-family: Roboto,sans-serif;
    line-height: 1.2rem;
    padding-left: 1rem;
    cursor: pointer;
    flex-shrink: 0;
`;

S.UserIcon = styled.i`
    box-sizing: border-box;
    width: 1.6rem;
    height: 1.6rem;
    display: inline-block;
    line-height: 1.6rem;
    flex-shrink: 0;
    & > svg {
        & > path{
            fill: ${({theme:{$topMenuLoggedUserLoginColor}})=> $topMenuLoggedUserLoginColor} !important;
        }
    }
`;

S.BalanceSelect = styled.div`
    min-width: 275px;
    cursor: pointer;
    align-items: stretch;
    
    & .react-select__menu{
        margin-top: 0;
        
        & .react-select__menu-list{
             padding: 0;
        
            & > div{
                cursor: pointer;
            }
        }
    }
    
    & .react-select__control{
        box-shadow: none;
        min-height: 0;
        padding:.3rem;
        border-radius: 21px;
        background-color:transparent;
        line-height:1.2rem;
        border:3px solid #fff;
        & .react-select__indicators{
            display:none;
        }
        
        & .react-select__dropdown-indicator{
            padding: 3px;
        }
    
        & .react-select__value-container{
        
            & .react-select__single-value{
                 color:#fff;
                 font-size: 1.2rem;
                 font-weight: 700;
                 font-family: Roboto,sans-serif;
            }
        }
    
    }
`;
S.QuickMenuWrapper = styled.div` 
  position: absolute;
  top: 100%;
  right: 0;
  display: none;
  width: 100%;
  padding-top:10%;
  margin-top:-10%;
  background-color:${({theme:{$topMenuLoggedUserQuickMenuWrapper}})=> $topMenuLoggedUserQuickMenuWrapper};
  box-shadow: 3px 3px 6px -1px rgba(66, 68, 90, 1);
  border-radius:4px;
`;
S.QuickMenu = styled.ul`
    z-index: 1;
    position: relative;
    overflow: hidden;
    padding: 0;
    list-style: none;
`;

S.QuickMenuItem = styled.li`
  display: flex;
  font-size: 1.2rem;
  border-bottom: 1px solid #FAEB5F;
  background-color: transparent;
  padding: 8px;
  &:last-of-type{
    border-bottom: none;
  }
`;
S.QuickMenuLink = styled.div`
  color: #001A5B;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  &:link,
  &:visited,
  &:hover,
  &:active{
    text-decoration: none;
  }
`;

export default S;