import React from 'react';
import S from 'StyledRegisterWizardFormStep2.js';
import {Field, reduxForm} from 'redux-form';
import {translation} from 'utilsHelper.js';
import classNames from 'classnames';
import _find from 'lodash/find';
import _map from 'lodash/map';
import Loader from 'Loader.js';
import {connect} from 'react-redux';

// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    const errors = validation(values, validators.REGISTER_STEP2);
    return errors;
};

const renderField = ({
    input,
    type,
    meta: {touched, error, warning, valid, asyncValidating, submitFailed}
}) => {
    const inputClass = classNames({
        'async-validating': asyncValidating,
        'has-error': ((touched || submitFailed) && error),
        'is-valid': ((touched || submitFailed) && valid)
    });
    return (
        <S.Row>
            <S.InputWrapper>
                <S.Input {...input} type={type} required="required" className={inputClass}/>
                {(touched || submitFailed) && ((error && <S.Error>{error[0]}</S.Error>) || (warning &&
                <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

let RegisterWizardFormStep2 = ({handleSubmit, submitting, pristine, error}) => {

    return (
        <S.RegisterForm method="post" onSubmit={handleSubmit}>

            {submitting && (<S.Cover><Loader color="#F05A22"/></S.Cover>)}

            <div>
                <S.Label> {translation('registerModal_verificationCode')} <span>*</span></S.Label>
                { translation('registerModal_infoVerificationCode') != 'registerModal_infoVerificationCode' && ( 
                    <S.InfoLabel>{translation('registerModal_infoVerificationCode')}</S.InfoLabel>
                )}
                <Field
                    name="verificationCode"
                    type="text"
                    required={true}
                    component={renderField}
                />
            </div>
			
            {error && <S.SubmissionError className="submission-error">{error}</S.SubmissionError>}

            <S.SubmitBtn type="submit" disabled={pristine || submitting}>{translation('account_registerForm_signup')}</S.SubmitBtn>
            
        </S.RegisterForm>
    );
};

const mapDispatchToProps = {
};


export default  connect(null, mapDispatchToProps)(reduxForm({
    form: 'register',
    validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(RegisterWizardFormStep2));
