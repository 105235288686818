import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';

const intervalMiddleware = ({dispatch, getState}) => (next) => (action) => {

    const {type, payload} = action;
    if (type == 'START_INTERVAL') {
        const {key, timeout, actionCreator} = payload;

        dispatch(actionCreator());

        const interval = window.setInterval(()=>dispatch(actionCreator()), timeout);
        action.payload['key'] = key;
        action.payload['interval'] = interval;

    } else if (type == 'STOP_INTERVAL') {

        const {key} = payload;
        const {Intervals:{intervals}} = getState();
        const index = _findIndex(intervals, {key});
        if (index != -1) {
            const id = _get(intervals, [index, 'interval']);
            window.clearInterval(id);
        }
        action.payload['key'] = key;
    }

    return next(action);
};


export default intervalMiddleware;