import styled from 'styled-components';
import StyledDatepicker from 'StyledDatepicker.js';
import {Form, Input, Button} from 'global/styles/styles.js';
const S = {};

S.AccountEditProfileForm = styled(Form)`
 width: 100%;
 display: flex;
`;

S.SaveIcon = styled.i`
    width: .92rem;
    height: .92rem;
    background: transparent url(/images/svg/save-icon.svg) no-repeat 0 0;
    background-size: .92rem .92rem;
    position: absolute;
    right: 9px;
    top: 33px;
    margin: auto 0;
`;

S.Row = styled.div``;

S.LastLoginInfo= styled.div`
    display:flex;
    margin:1rem 0;
    span{
        min-width: 30%;
        margin-right: .83rem;
        text-align: left;
        color: #adadad;
        font-size: 1.5rem;
        font-weight: 700;
        display: flex;
        &:last-of-type{
            color:#000;
        }
    }
`
S.Label = styled.label`
    min-width: 30%;
    margin-right: .83rem;
    text-align: left;
    color: #adadad;
    font-size: 1.5rem;
    font-weight: 700;
    display: flex;
`;

S.Input = styled(Input)`
    box-shadow: none;
    color: #000;
    display: block;
    width: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    border: .08rem solid #707070;
    padding-right: 1.67rem;
    background-color: #FFF;
    height: 2.5rem;
    border-radius: 15px;
    padding-left: 1rem;
    max-width: 50%;
    &[disabled]{
        background-color: transparent;
        border: none;
        cursor: not-allowed;
    }
    &.has-error{
        border: .08rem solid red;
        border-radius: .42rem .42rem 0 0;
    }
`;

S.SubmitBtn = styled(Button)`
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 700;
    background-color: #3c3c3c;
    color: #fff;
    cursor: pointer;
    width: 17rem;
    height: 4.7916rem;
    text-align: center;
    border-radius: 5px;
    &[disabled]{
        background-color: #eaeaea;
        color: #000;
    }
`;

S.Error = styled.div`
    color: red;
    float: left;
    width: 100%;
    background-color: #fba59f;
    margin-top: 0;
    border-radius: 0 0 2px 2px;
    padding: 2px .85rem 2px .85rem;
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
`;

S.SuccessModal = styled.div`
     width: 100%;
`;

S.SuccessModalTitle = styled.h4`
    color: #2d9ef5;
    text-transform: uppercase;
    font-size: 1.83rem;
    padding: 0;
    font-weight: 800;
    font-style: italic;
    float: none!important;
    text-align: center;
    margin-bottom: 3.42rem;
`;

S.SuccessModalMessage = styled.p`
    text-align: center;
    color: #000;
    margin: 0;
    font-size: 2rem;
    letter-spacing: .2px;
    font-weight: 400;
`;
S.AccountHeader = styled.div`
display: flex;
justify-content: space-between;
color:#111111;
font-weight:bold;
background-color: #fff;
font-size:  1.2rem;
padding: 0.4rem  2.5rem;
align-items: center;
`
S.HeaderText = styled.span`
`

S.EditButton = styled(Button)`
    background-color: #3661C3;
    width: 9rem;
    height: 2.8rem;
    line-height: 2.8rem;
    border-radius: 19px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

S.SaveButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
`

S.CancelButton = styled(S.EditButton)`
    background-color: #000000;
    margin-right: 1rem;
`

S.SaveButton = S.EditButton;


S.EditIcon = styled.i`
    background: transparent url(/images/svg/edit-profile-icon.svg) no-repeat 0 0;
    width: 1.5rem;
    height: 1.5rem;
    background-size: 1.5rem 1.5rem;
    background-position: 0px -1px;
    margin-right: 0.5rem;
`

S.ButtonText = styled.span`
    color: white;
`

S.AccountEditStatus = styled.div`
    width: 100%;
`

S.AccountEditStatusMessage = styled.div`
    width: 100%;
    text-align: left;
    padding: 1rem;
`

S.AccountEditSuccess = styled(S.AccountEditStatusMessage)`
    background-color: #FAE100;
    color: #000;
`

S.AccountEditFailure = styled(S.AccountEditStatusMessage)`
    background-color: #CA2717;
    color: #fff;
`

S.InputWrapper = styled.div`
    position: relative;
    display:flex;
    align-items: center;
    margin-top:1rem;
    margin-bottom: 1.5rem;
    font-weight: bold;

    & select{
        display: block;
    }
    
    ${StyledDatepicker.Datepicker}{
        flex: 1;
        border: none;
        font-size: 17px;
        font-weight: 700;
        width: 100%;
        background: transparent;
}
`;

S.ChangePasswordBtn = styled(Button)`
    font-size: 1rem;
    line-height: 1rem;
    background-color: #3c3c3c;
    color: #fff;
    cursor: pointer;
    width: 17rem;
    height: 4.7916rem;
    text-align: center;
    border-radius: 5px;
`;

export default S;