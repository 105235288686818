import typeToReducer from 'type-to-reducer';
import {
    CHANGE_DATE_BY_TYPE,
    CHANGE_ACTIVE_TAB,
    FETCH_BONUS_LIST,
    FETCH_CREDIT_BONUS_LIST,
    SET_CURRENT_PAGE,
    FETCH_REGISTRATION_OFFERS,
} from 'accountBonusActions.js';

let initialState = {
    isPending: true,
    error: null,
    startDate: new Date(),
    endDate: new Date(),
    activeTab: 'current', 
    status: 'active',
    bonus: {
        active: {
            currentPage: 0,
            data: []

        },
        history: {
            currentPage: 0,
            data: []
        },
        queue: {
            currentPage: 0,
            data: []
        }
    },
    limit: 5,
    registrationOffers: [],
};

export default typeToReducer({
    [CHANGE_DATE_BY_TYPE]: (state, {payload: {dateType, date}}) => {
        return {...state, [dateType]: date}
    },
    [CHANGE_ACTIVE_TAB]: (state, {payload: {activeTab}}) => {
        return {...state, activeTab}
    },
    [SET_CURRENT_PAGE]: (state, {payload: {currentPage, status}}) => {
        return {...state, bonus: {...state.bonus, [status]: {...state.bonus[status], currentPage}}}
    },
    [FETCH_BONUS_LIST]: {
        PENDING: (state, action) => {
            return {...state, isPending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {data, status}}) => {
            return {...state, bonus: {...state.bonus, [status]: {...state.bonus[status], data}}};
        },
        FULFILLED: (state, action) => {
            return {...state, isPending: false}
        }
    },
    [FETCH_CREDIT_BONUS_LIST]: {
        PENDING: (state, action) => {
            return {...state, isPending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {data, status}}) => {
            return {...state, bonus: {...state.bonus, [status]: {...state.bonus[status], data}}};
        },
        FULFILLED: (state, action) => {
            return {...state, isPending: false}
        }
    },
    [FETCH_REGISTRATION_OFFERS]: {
        PENDING: (state) => {
            return {...state, isPending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {data}}) => {
            return {...state, registrationOffers: data};
        },
    },
}, initialState);
