import typeToReducer from "type-to-reducer";
import {LOAD_CMS_PAGE} from "cmsActions.js";

let initialState = {
    cmsData: [],
    error: null,
    isPending: false
};

export default typeToReducer({
    [LOAD_CMS_PAGE]: {
        PENDING: (state, action) => {
            return {...state, isPending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {cmsData}}) => {
            let cmsDataTemp = [...state.cmsData];
            const existedCMSElement = cmsDataTemp.find(cmsPage => cmsPage.name == cmsData.name);

            if(!existedCMSElement) {
                cmsDataTemp.push(cmsData);
            } else {
                // jeśli element istnieje ale miał np title error404 bo nie było uprawnień to musimy go usunąć z tablicy i dodać nowy content
                if(existedCMSElement.data.title != cmsData.data.title) {
                    cmsDataTemp = cmsDataTemp.filter(cmsPage => cmsPage.name != existedCMSElement.name);
                    cmsDataTemp.push(cmsData);
                }
            }
            return {...state, isPending: false, cmsData: cmsDataTemp};
        }
    },
}, initialState);

