import styled from 'styled-components';
const S = {};

S.ModalBackground = styled.div`
  background: rgba(0, 0, 0, 0.65);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

S.ModalWrapper = styled.div`
    z-index: 99;
    overflow: auto;
    margin: 5rem auto 0 auto;
    background-color:#fff;
    width: ${({width})=> (width!=null) ? width : '51rem' };
    position: relative;
    border-radius: .42rem;
    overflow: visible;
    padding: ${({padding})=> (padding!=null) ? padding : '40px 93px' }};
`

S.ModalHeader = styled.div`
  position:relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({headerPadding})=> (headerPadding!=null) ? headerPadding : '0.9375rem 0 1.0625rem 0'};
  background-color: ${({backgroundHeader})=> (backgroundHeader!=null) ? backgroundHeader : '#fff'};
  border-top-right-radius: 0.42rem;
  border-top-left-radius: 0.42rem;
`;
S.CmsBanner=styled.div`
position:absolute;
right:100%;
height:100%;
width:300px;
`

S.ModalTitle = styled.h2`
    margin: 0;
    color: ${({titleColor})=> (titleColor!=null) ? titleColor : '#000'};
    font-size: 1.5rem;
    padding: 0 0 0 10px;
    font-weight: 800;
    float: none!important;
    text-align: center;
`;


S.UserIconContainer = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    svg{
      path{
        fill:${({titleColor})=> (titleColor!=null) ? `${titleColor} !important`: '#3B3B3B'};
      }
    }
`;

S.CloseIconContainer = styled.div`
    position: absolute;
    right: 1.625rem;
    top: 50%;
    padding: 0;
    width: 1.4375rem;
    height: 1.4375rem;
    transform:translateY(-50%) ;
    line-height: 1;
    bottom: 0;
    background: transparent url(${({closeIconImg})=> (closeIconImg!=null) ? closeIconImg : '/images/svg/header_close_black.svg'}) no-repeat 50% 50%;
    background-size: 1.47rem 1.47rem;
    cursor: pointer;
`;

S.ModalBody = styled.div`
  display: flex;
  background-color:${({backgroundBody})=> (backgroundBody!=null) ? backgroundBody : '#fff'}; 
  border-radius:0.42rem;
`;

export default S;
