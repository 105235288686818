const encode = encodeURIComponent;

export default (requests, axios) => {
    return {
        liveEventsMajorUrl: () => {
            return requests.get('/running/games/major');
        },
        liveEventsMainUrl: () => {
            return requests.get('/running/games/main');
        },
        liveEventsMultiUrl: (eventId) => {
            return requests.get(`/running/multi/${eventId}`);
        },
        refreshOddsByOutcomeId: (outcomeId) => {
            return requests.get(`/running/refresh-odds/${outcomeId}`);
        },
        liveCalendarUpcomings: () => {
            const config = {};
            if (CLIENT == 'europebet') {
                const lang = Cookies.get('Language');
                config['headers'] = {
                    'Request-Language': lang || 'en'
                };
            }
            return requests.get(`/upcoming`, config);
        }
    }
};
