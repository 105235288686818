import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';
import {CHANGE_ACTIVE_ITEM, CHANGE_SUBMENU_ACTIVE_ITEM, CHANGE_SUBMENU_DROPDOWN_ITEM, LOAD_PROMO_CODE_SETTING} from 'topMenuActions.js';

let initialState = {
    activeItem: 'HOME',
    submenuItem: null,
    submenuDropdownItem: null,
    isPromoCodeVisible: true
};

export default typeToReducer({
    [CHANGE_ACTIVE_ITEM]: (state, {payload: {activeItem}}) => {
        return {...state, activeItem};
    },
    [CHANGE_SUBMENU_ACTIVE_ITEM]: (state, {payload: {submenuItem}}) => {
        return {...state, submenuItem};
    },
    [CHANGE_SUBMENU_DROPDOWN_ITEM]: (state, {payload: {submenuDropdownItem}}) => {
        return {...state, submenuDropdownItem};
    },
    [LOAD_PROMO_CODE_SETTING]:{
        SUCCESS: (state, {payload: {promoCodeSetting:{showCode}}}) => {
            return update(state, {
                isPromoCodeVisible: {$set: showCode}
            })
        }
    },
}, initialState);


