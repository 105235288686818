import React, {useMemo, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import S from 'StyledAccountResponsibleGameSelfExclusion.js';
import {translation} from 'utilsHelper.js';
import {excludeAccount} from 'responsibleGameActions.js';
import _get from 'lodash/get';
import _find from 'lodash/find';
import limits from 'limitTypes.enum.js';
import {formatDate} from 'datesHelper';
import Box from "react-styled-box";
import BellIcon from 'bell-icon.svg';
import dialogPolyfill from 'dialog-polyfill';

const Portal = ({children})=>{
    return ReactDOM.createPortal(children, document.body);
}

const AccountResponsibleGameSelfExclusion = ({excludeAccount, exclusion, suspension, customerLimits}) => {

    const suspensionError = _get(suspension, ['error']);
    const exclusionError = _get(exclusion, ['error']);
    const suspensionPending = _get(suspension, ['isPending']);
    const exclusionPending = _get(exclusion, ['isPending']);

    const dialogContainer = useRef(null);
    const [exclusionModalOpen, setExclusionModalOpen] = useState(false);

    const closeConfirmationModal = (action) => {
        const dialogElement = dialogContainer.current;
        if (dialogElement && typeof dialogElement.close === 'function') dialogElement.close(action);
    };

    const openConfirmationModal = () => {

        const promise = new Promise((resolve, reject) => {

            const dialogElement = dialogContainer.current;
            dialogPolyfill.registerDialog(dialogElement);

            if (dialogElement && typeof dialogElement.showModal === 'function') {
                const onModalClose = () => {
                    dialogElement.returnValue == 'approve'? resolve(): reject();
                    dialogElement.removeEventListener('close', onModalClose);
                };
                dialogElement.addEventListener('close', onModalClose);
                dialogElement.showModal();
            } else {
                reject();
            }
        });
        return promise;
    };

    const handleExclusionSubmit = async (event) => {
        event.preventDefault();
        setExclusionModalOpen(true);
        const disablePeriod = _get(exclusion, ['period']);
        try {
            await openConfirmationModal();
            excludeAccount('exclusion', disablePeriod);
        } catch (error) {}
    };

    const handleSuspensionSubmit = async (event) => {
        event.preventDefault();
        setExclusionModalOpen(false);
        const disablePeriod = _get(suspension, ['period']);
        try {
            await openConfirmationModal()
            excludeAccount('suspension', disablePeriod);
        } catch (error) {}
    };

    const userExcludedValidToDate = useMemo(() => {
        const limitType = limits.BETTING_EXCLUSION_TIME_LIMIT;
        const bettingExclusionLimitExist = _find(customerLimits, {limitType});
        if (bettingExclusionLimitExist) {
            const {limitAmount, limitValidTo} = bettingExclusionLimitExist;
            const todayUnix = +(new Date());
            if (limitValidTo && todayUnix < limitValidTo) {
                return limitAmount > 1 ? formatDate(limitValidTo, 'yyyy-MM-dd HH:mm') : false;
            }
        }
    }, [customerLimits]);

    const userSuspendedValidToDate = useMemo(() => {
        const limitType = limits.BETTING_EXCLUSION_TIME_LIMIT;
        const bettingExclusionLimitExist = _find(customerLimits, {limitType});
        if (bettingExclusionLimitExist) {
            const {limitAmount, limitValidTo} = bettingExclusionLimitExist;
            const todayUnix = +(new Date());
            if (limitValidTo && todayUnix < limitValidTo) {
                return limitAmount == 1 ? formatDate(limitValidTo, 'yyyy-MM-dd HH:mm') : false;
            }
        }
    }, [customerLimits]);

    return (
        <S.SelfExclusion>

            <Portal>
                <S.Dialog ref={dialogContainer}>
                    <Box flexDirection="column">

                        <Box justifyContent="center">
                            <S.BellIcon dangerouslySetInnerHTML={{__html: BellIcon}}></S.BellIcon>
                        </Box>

                        <Box margin="20px 0 0" alignSelf="center">
                            {exclusionModalOpen && 
                                <S.DialogText dangerouslySetInnerHTML={{__html: translation('account_responsibleGaming_exclusionConfirmMessage')}}/>
                            }
                            {!exclusionModalOpen &&
                                 <S.DialogText dangerouslySetInnerHTML={{__html: translation('account_responsibleGaming_suspensionConfirmMessage')}}/>
                            }
                        </Box>

                        <Box justifyContent="space-between" margin="20px 0 0">
                            <S.DialogBtnOk onClick={closeConfirmationModal.bind(null, 'approve')}>ok</S.DialogBtnOk>
                            <S.DialogBtnCancel onClick={closeConfirmationModal.bind(null, 'cancel')}>Cancel</S.DialogBtnCancel>
                        </Box>

                    </Box>

                </S.Dialog>
            </Portal>


            <S.SelfExclusionBoxArea>

                <S.SelfExclusionBoxWrapper>
                    <S.SelfExclusionBox onSubmit={handleSuspensionSubmit}>
                        <S.Title>{translation('account_responsibleGame_selfExclusion_suspensionTitle')}</S.Title>
                        <S.Description>{translation('account_responsibleGame_selfExclusion_suspensionDescription')}</S.Description>
                        <S.RowWrapper>
                            <S.Button type="submit"
                                      disabled={suspensionPending||userSuspendedValidToDate}>
                                {translation("account_responsibleGame_selfExclusion_suspensionConfirm")}
                            </S.Button>
                        </S.RowWrapper>
                        {userSuspendedValidToDate && <S.SelfExclusionValidTo>{translation('account_responsibleGaming_dateInfo', [userSuspendedValidToDate])}</S.SelfExclusionValidTo>}
                        {suspensionError && <S.Error>{suspensionError}</S.Error>}
                    </S.SelfExclusionBox>
                </S.SelfExclusionBoxWrapper>

                <S.SelfExclusionBoxWrapper>
                    <S.SelfExclusionBox onSubmit={handleExclusionSubmit}>
                        <S.Title>{translation('account_responsibleGame_selfExclusion_selfExclusionTitle')}</S.Title>
                        <S.Description>{translation('account_responsibleGame_selfExclusion_selfExclusionDescription')}</S.Description>
                        <S.RowWrapper>
                            <S.Button type="submit"
                                      disabled={exclusionPending||userExcludedValidToDate}>
                                {translation("account_responsibleGame_selfExclusion_selfExclusionConfirm")}
                            </S.Button>
                        </S.RowWrapper>
                        {userExcludedValidToDate && <S.SelfExclusionValidTo>{translation('account_responsibleGaming_dateInfo', [userExcludedValidToDate])}</S.SelfExclusionValidTo>}
                        {exclusionError && <S.Error>{exclusionError}</S.Error>}
                    </S.SelfExclusionBox>
                </S.SelfExclusionBoxWrapper>

            </S.SelfExclusionBoxArea>
        </S.SelfExclusion>
    );
}

const mapStateToProps = ({AccountResponsibleGameSelfExclusion: {suspension, exclusion}, Customer: {customerLimits}}) => {
    return {
        customerLimits,
        suspension,
        exclusion
    }
};

const mapDispatchToProps = {excludeAccount};

export default connect(mapStateToProps, mapDispatchToProps)(AccountResponsibleGameSelfExclusion);
