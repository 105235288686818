import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';

const themeCookie = document.cookie.match('(^|;) ?themeClass=([^;]*)(;|$)');
const themeCookieValue = (themeCookie ? themeCookie[2] : '');

let initialState = {
    currentTheme: (themeCookieValue === 'theme-light' ? 'light' : ''),
};

export default typeToReducer({
    ['CHANGE_THEME']: (state, {payload:{currentTheme}}) => {
        return update(state, {currentTheme: {$set: currentTheme}});
    }
}, initialState);
