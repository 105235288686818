import styled from 'styled-components';
const S = {}

S.AccountPaymentsContainer= styled.div`
display: flex;
flex-direction: column;
width: 100%;
padding: 0.67rem 1.08rem 1.67rem;
background-color:#DEDEDE;
`
S.InfoIcon = styled.div`
display:flex;
justify-content:center;
align-items:center;
width: 2rem;
height: 2rem;
margin-left: 0.83rem;
border-radius: 50%;
border: 0.17rem solid #101010;
`

S.Balance=styled.div`
display:flex;
justify-content:center;
align-items:center;
border: 0.17rem solid #101010;
border-radius: 1.83rem;
padding: 0.75rem;
background:transparent;
font-size: 1.17rem;
font-weight: 700;
`
S.PaymentsContainer = styled.div`
flex: 1;
display:flex;
flex-direction: column;
margin-top: 0.67rem;
`

S.PaymentSwitcher = styled.div`
display:flex;
align-items: center;
width:100%;
`
S.PaymentSwitch = styled.div`
flex: 1;
display: flex;
justify-content: center;
align-items: center;
border-radius: 0.5rem 0 0 0;
padding: 0.75rem;
background-color: #C6C6C6;
color: #9E9E9E;
text-transform: uppercase;
font-size: 1.17rem;
font-weight: 700;

&:hover{
    cursor: pointer;
}
&.active{
    color: #fff;
    background-color: #111111;
}
&:last-of-type{
    border-radius: 0 0.5rem 0 0;
}
`

S.Payment = styled.div`
 flex: 1;
 display: flex;
 flex-direction: column;
 background-color: #F7F7F7;
 padding: 1.33rem;
`
S.PaymentTitle = styled.div`
    font-size: 1.33rem;
    font-weight: bold;
    margin-bottom: 1.4rem;
`

export default S;