import {formatDate} from 'helpers/datesHelper.js';
const encode = encodeURIComponent;
const today = new Date();

export default (requests, axios) => {
    return {
        getBonusListByFilter: ({status = 'ACTIVE', endDate = today, startDate = today, limit = 5, currentPage = 0}={}) => {
            const dateFrom = formatDate(startDate, 'yyyy-MM-dd');
            const dateTo = formatDate(endDate, 'yyyy-MM-dd');
            const sessionCookie = Cookies.get('X-ODDS-SESSION');
            const config = {};
            const searchPath = [].concat(status).map((status) => {
                return `${encode(status.toUpperCase())}`;
            }).join(',');

            if (sessionCookie) {
                config['headers'] = {'X-ODDS-SESSION': sessionCookie};
            }
            return requests.get(`/public/offers/bonus/${dateFrom}/${dateTo}/${limit}/${currentPage}/?status=${searchPath}`, config);
        },

        getCreditBonusListByFilter: ({status = 'ACTIVE', endDate = today, startDate = today, limit = 5, currentPage = 0}={}) => {
            const dateFrom = formatDate(startDate, 'yyyy-MM-dd');
            const dateTo = formatDate(endDate, 'yyyy-MM-dd');
            const sessionCookie = Cookies.get('X-ODDS-SESSION');
            const config = {};
            const searchPath = [].concat(status).map((status) => {
                return `status=${encode(status.toUpperCase())}`;
            }).join('&');

            if (sessionCookie) {
                config['headers'] = {'X-ODDS-SESSION': sessionCookie};
            }
            return requests.get(`/public/offers/creditbonus/${dateFrom}/${dateTo}/${limit}/${currentPage}/?${searchPath}`, config);
        },

        manageBonus: (status, offerId) => {
            const sessionCookie = Cookies.get('X-ODDS-SESSION');
            const config = {};
            if (sessionCookie) {
                config['headers'] = {'X-ODDS-SESSION': sessionCookie};
            }
            return requests.post(`/public/offers/bonus/${offerId}/${status}/`, {}, config);
        },

        manageCreditBonus: (status, offerId) => {
            const sessionCookie = Cookies.get('X-ODDS-SESSION');
            const config = {};
            if (sessionCookie) {
                config['headers'] = {'X-ODDS-SESSION': sessionCookie};
            }
            return requests.post(`/public/offers/creditbonus/${status}/${offerId}/`, {}, config);
        },

        getRegistrationOffers: () => {
            return requests.get('/public/offers/register/offer-codes');
        },

        getPromoCodeSetting: () => {
            return requests.get('/config/promotions');
        },

    }
};
