import { getSegmentationCookie } from 'helpers/cookiesHelper.js';

export default (requests, axios) => {
    return {
        getCmsPage: (url, countryCode, languageCode, brand, enableSegmentation = false, isLogged = false) => {
            if (!countryCode && !languageCode && !brand) {
                if(enableSegmentation) {
                    if(isLogged) {
                        return requests.get(`cms/pages/${url}/content?segments=${getSegmentationCookie() ?? ""}`);
                    } else {
                        return requests.get(`cms/pages/${url}/content?segments=-1`);
                    }
                } else {
                    return requests.get(`cms/pages/${url}/content`);
                }
            } else {
                return requests.get(`cms/pages/${url}/content?wrapComponents=true&languageCode=${languageCode}&countryCode=${countryCode}&brand=${brand}`);
            }
        }
    }
};
