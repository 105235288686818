import {applyMiddleware, createStore, compose} from 'redux';
import thunk from 'redux-thunk';
import reducers from 'reducers.js';
import * as clientApi from 'api.js';
import authMiddleware from 'authMiddleware.js';
import betSlipMiddleware from 'betSlipMiddleware.js';
import intervalMiddleware from 'intervalMiddleware.js';
import formMiddleware from 'formMiddleware.js';
import {persistStore, persistReducer, createTransform} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {betSlipInitialState} from 'betSlipReducer.js';
import _some from 'lodash/some'

const middlewares = [thunk.withExtraArgument(clientApi), intervalMiddleware, betSlipMiddleware, formMiddleware, authMiddleware];
if (process.env.NODE_ENV === 'development') {
    const {createLogger} = require('redux-logger');
    const logger = createLogger({
        diff: false,
        collapsed: true,
        predicate: (getState, action) => {
            if(action.type.includes('@@redux-form') || ['UPDATE_SESSION_TIME','UPDATE_CURRENT_TIME'].indexOf(action.type) != -1){
                return false;
            }
            return true;
        }
    });
    middlewares.push(logger);
}

const expireTransform = createTransform(
    (inboundState, key) => {
        const expiresIn24H = new Date();
        expiresIn24H.setHours(expiresIn24H.getHours() + 24);
        return {...inboundState, persistExpiresAt: expiresIn24H};
    },
    (outboundState, key) => {
        const {persistExpiresAt, betSlips} = outboundState;
        const placed = _some(betSlips, ['placed', true])
        const nowUnix = new Date().getTime();
        const expireDateUnix = new Date(persistExpiresAt).getTime();

        if (placed || nowUnix > expireDateUnix) {
            console.log('clear bestSlip cache storage');
            return {...betSlipInitialState}
        }
        return {...outboundState};
    });


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['BetSlip', 'TopMenu'],
    transforms: [expireTransform]
};

const clientName = CLIENT;
const reduxDevToolsStoreName = `${(clientName).toUpperCase()}_MAIN_INSTANCE`;
let store = createStore(
    persistReducer(persistConfig, reducers),
    {},
    compose(
        applyMiddleware(...middlewares),
        (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__) ? window.__REDUX_DEVTOOLS_EXTENSION__({trace: true, name: reduxDevToolsStoreName}) : f => f
    )
);

let persistor = persistStore(store);

export {store as default, persistor}


