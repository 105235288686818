// action creators

export const LOAD_ACTIVE_PROVIDERS = 'LOAD_ACTIVE_PROVIDERS'; 
export const LOAD_CUSTOMER_CARD_LIST = 'LOAD_CUSTOMER_CARD_LIST'; 
export const LOAD_PAYSTACK_BANK_LIST = 'LOAD_PAYSTACK_BANK_LIST'; 
export const LOAD_BANK_LIST = 'LOAD_BANK_LIST'; 
export const LOAD_ACCOUNT_TYPES = 'LOAD_ACCOUNT_TYPES'; 
export const LOAD_CUSTOMER_BANK_DETAIL = 'LOAD_CUSTOMER_BANK_DETAIL';
export const LOAD_CUSTOMER_MOBILE_ACCOUNT = 'LOAD_CUSTOMER_MOBILE_ACCOUNT';
export const RESET_PAYMENT_PROVIDER = 'RESET_PAYMENT_PROVIDER';
export const LOAD_WON_AMOUNT_TO_PAYOUT = 'LOAD_WON_AMOUNT_TO_PAYOUT'; 
export const LOAD_WINNINGS_BALANCE = 'LOAD_WINNINGS_BALANCE'; 
export const CLEAR_PAYMENT_DATA = 'CLEAR_PAYMENT_DATA'; 

const paymentProviders = (currencyCode) => {
    return async(dispatch, getState, {PaymentApi}) => {

        dispatch(loadActiveProvidersPending());

        try {
            const {data, code} = await PaymentApi.getPaymentProviders(currencyCode);
            if (code == 200) {
                dispatch(loadActiveProvidersSuccess(data));
                return data
            }

            throw {message: `error_${code}`};

        } catch (error) {
            console.log({error})
            dispatch(loadActiveProvidersFailure(error));
            throw error;
        }
    }
};

const sendPaymentData = (paymentData, step) => {
    return async(dispatch, getState, {PaymentApi}) => {

        try {
            const {code, data} = await PaymentApi.sendPaymentData(paymentData, step);
            if (code == 200) {
                return data
            }

            throw {message: `error_${code}`};
        } catch (error) {
            throw error;
        }
    }
};

const creditCardList = (providerId) => {
    return async(dispatch, getState, {PaymentApi}) => {

        dispatch(loadCustomerCreditCardListPending());

        try {
            const {code, data} = await PaymentApi.getCustomerCreditCardList(providerId);
            if (code == 200) {
                dispatch(loadCustomerCreditCardListSuccess(data));
                return data
            }
            
            throw {message: `error_${code}`};

        } catch (error) {
            console.log({error})
            dispatch(loadCustomerCreditCardListFailure(error));
            throw error;
        }
    }
};

const customerCreditCardUpdate = (card) => {
    return async(dispatch, getState, {PaymentApi}) => {

        try {
            const {code, data} = await PaymentApi.customerCreditCardUpdate(card);
            if (code == 200) {
                return data
            }

            throw {message: `error_${code}`};
        } catch (error) {
            throw error;
        }
    }
};

const customerBankDetail = () => {
    return async(dispatch, getState, {PaymentApi}) => {

        dispatch(loadCustomerBankDetailPending());

        try {
            const {code, data} = await PaymentApi.getCustomerBankDetail();
            if (code == 200) {
                dispatch(loadCustomerBankDetailSuccess(data));
                return data
            }
            
            throw {message: `error_${code}`};

        } catch (error) {
            console.log({error})
            dispatch(loadCustomerBankDetailFailure(error));
            throw error;
        }
    }
}

const paystackBankList = (providerId) => {
    return async(dispatch, getState, {PaymentApi}) => {

        dispatch(loadPaystackBankListPending());

        try {
            const {code, data} = await PaymentApi.getPaystackBankList(providerId);
            if (code == 200) {
                dispatch(loadPaystackBankListSuccess(data));
                return data;
            }

            throw {message: `error_${code}`};

        } catch (error) {
            console.log({error})
            dispatch(loadPaystackBankListFailure(error));
            throw error;
        }
    }
}

const bankList = () => {
    return async(dispatch, getState, {PaymentApi}) => {

        dispatch(loadBankListPending());

        try {
            const {code, data} = await PaymentApi.getBankList();
            if (code == 200) {
                dispatch(loadBankListSuccess(data));
                return data;
            }

            throw {message: `error_${code}`};


        } catch (error) {
            console.log({error})
            dispatch(loadBankListFailure(error));
            throw error;
        }
    }
}

const accountTypes = () => {
    return async(dispatch, getState, {PaymentApi}) => {

        dispatch(loadAccountTypesPending());

        try {
            const {code, data} = await PaymentApi.getAccountTypes();
            if (code == 200) {
                dispatch(loadAccountTypesSuccess(data));
                return data;
            }

            throw {message: `error_${code}`};

        } catch (error) {
            console.log({error})
            dispatch(loadAccountTypesFailure(error));
            throw error;
        }
    }
}

const customerMobileAccount = () => {
    return async(dispatch, getState, {PaymentApi}) => {

        dispatch(loadCustomerMobileAccountPending());

        try {
            const {code, data} = await PaymentApi.getCustomerMobileAccount();
            if (code == 200) {
                dispatch(loadCustomerMobileAccountSuccess(data));
                return data;
            }

            throw {message: `error_${code}`};

        } catch (error) {
            console.log({error})
            dispatch(loadCustomerMobileAccountFailure(error));
            throw error;
        }
    }
}

const wonAmountToPayout = (paymentData) => {
    return async(dispatch, getState, {PaymentApi}) => {

        dispatch(loadWonAmountToPayoutPending());

        try {
            const {code, data} = await PaymentApi.getWonAmountToPayout(paymentData);
            if (code == 200) {
                dispatch(loadWonAmountToPayoutSuccess(data));
                return data
            }

            throw {message: `error_${code}`};
        } catch (error) {
            dispatch(loadWonAmountToPayoutFailure(error));
            throw error;
        }
    }
};

const winningsBalance = () => {
    return async(dispatch, getState, {PaymentApi}) => {

        dispatch(winningsBalancePending());

        try {
            const {code, data} = await PaymentApi.getWinningsBalance();
            if (code == 200) {
                dispatch(winningsBalanceSuccess(data));
                return data
            }

            throw {message: `error_${code}`};
        } catch (error) {
            dispatch(winningsBalanceFailure(error));
            throw error;
        }
    }
};

const loadActiveProvidersFailure = (error) => {
    return {
        type: `${LOAD_ACTIVE_PROVIDERS}_FAILURE`,
        payload: {error}
    }
};

const loadActiveProvidersSuccess = (paymentProviders) => {
    return {
        type: `${LOAD_ACTIVE_PROVIDERS}_SUCCESS`,
        payload: {paymentProviders}
    }
};

const loadActiveProvidersPending = () => {
    return {
        type: `${LOAD_ACTIVE_PROVIDERS}_PENDING`
    }
};

const loadCustomerCreditCardListFailure = (error) => {
    return {
        type: `${LOAD_CUSTOMER_CARD_LIST}_FAILURE`,
        payload: {error}
    }
};

const loadCustomerCreditCardListSuccess = (callCreditCardLis) => {
    return {
        type: `${LOAD_CUSTOMER_CARD_LIST}_SUCCESS`,
        payload: {callCreditCardLis}
    }
};

const  loadCustomerCreditCardListPending = () => {
    return {
        type: `${LOAD_CUSTOMER_CARD_LIST}_PENDING`
    }
};

const loadPaystackBankListFailure = (error) => {
    return {
        type: `${LOAD_PAYSTACK_BANK_LIST}_FAILURE`,
        payload: {error}
    }
};

const loadPaystackBankListSuccess = (callPaystackBankList) => {
    return {
        type: `${LOAD_PAYSTACK_BANK_LIST}_SUCCESS`,
        payload: {callPaystackBankList}
    }
};

const  loadPaystackBankListPending = () => {
    return {
        type: `${LOAD_PAYSTACK_BANK_LIST}_PENDING`
    }
};

const loadBankListFailure = (error) => {
    return {
        type: `${LOAD_BANK_LIST}_FAILURE`,
        payload: {error}
    }
};

const loadBankListSuccess = (callBankList) => {
    return {
        type: `${LOAD_BANK_LIST}_SUCCESS`,
        payload: {callBankList}
    }
};

const  loadBankListPending = () => {
    return {
        type: `${LOAD_BANK_LIST}_PENDING`
    }
};

const loadAccountTypesFailure = (error) => {
    return {
        type: `${LOAD_ACCOUNT_TYPES}_FAILURE`,
        payload: {error}
    }
};

const loadAccountTypesSuccess = (callAccountTypes) => {
    return {
        type: `${LOAD_ACCOUNT_TYPES}_SUCCESS`,
        payload: {callAccountTypes}
    }
};

const  loadAccountTypesPending = () => {
    return {
        type: `${LOAD_ACCOUNT_TYPES}_PENDING`
    }
};

const loadCustomerMobileAccountFailure = (error) => {
    return {
        type: `${LOAD_CUSTOMER_MOBILE_ACCOUNT}_FAILURE`,
        payload: {error}
    }
};

const loadCustomerMobileAccountSuccess = (callMobilePhoneNumbers) => {
    return {
        type: `${LOAD_CUSTOMER_MOBILE_ACCOUNT}_SUCCESS`,
        payload: {callMobilePhoneNumbers}
    }
};

const loadCustomerMobileAccountPending = () => {
    return {
        type: `${LOAD_CUSTOMER_MOBILE_ACCOUNT}_PENDING`
    }
};

const loadCustomerBankDetailFailure = (error) => {
    return {
        type: `${LOAD_CUSTOMER_BANK_DETAIL}_FAILURE`,
        payload: {error}
    }
};

const loadCustomerBankDetailSuccess = (callCustomerBankDetail) => {
    return {
        type: `${LOAD_CUSTOMER_BANK_DETAIL}_SUCCESS`,
        payload: {callCustomerBankDetail}
    }
};

const loadWonAmountToPayoutFailure = (error) => {
    return {
        type: `${LOAD_WON_AMOUNT_TO_PAYOUT}_FAILURE`,
        payload: {error}
    }
};

const loadWonAmountToPayoutSuccess = (callWonAmountToPayout) => {
    return {
        type: `${LOAD_WON_AMOUNT_TO_PAYOUT}_SUCCESS`,
        payload: {callWonAmountToPayout}
    }
};

const  loadWonAmountToPayoutPending = () => {
    return {
        type: `${LOAD_WON_AMOUNT_TO_PAYOUT}_PENDING`
    }
};

const  loadCustomerBankDetailPending = () => {
    return {
        type: `${LOAD_CUSTOMER_BANK_DETAIL}_PENDING`
    }
};

const winningsBalanceFailure = (error) => {
    return {
        type: `${LOAD_WINNINGS_BALANCE}_FAILURE`,
        payload: {error}
    }
};

const winningsBalanceSuccess = (callWonAmountToPayout) => {
    return {
        type: `${LOAD_WINNINGS_BALANCE}_SUCCESS`,
        payload: {callWonAmountToPayout}
    }
};

const  winningsBalancePending = () => {
    return {
        type: `${LOAD_WINNINGS_BALANCE}_PENDING`
    }
};


export {paymentProviders, 
    sendPaymentData, 
    creditCardList, 
    paystackBankList, 
    bankList, 
    accountTypes, 
    customerMobileAccount, 
    wonAmountToPayout,
    customerBankDetail,
    customerCreditCardUpdate,
    winningsBalance};