const encode = encodeURIComponent;

export default (requests, axios) => {
    return {
        getBetSlipTransactions: (betSlipId) => {
            return requests.get(`transaction/public/${betSlipId}`);
        },
        getBetSlipTransaction: (betSlipId) => {
            return requests.get(`transaction/transactions/${betSlipId}`);
        }
    };
};
