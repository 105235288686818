import _size from 'lodash/size';
import _find from "lodash/find";
import {translation} from 'utilsHelper.js';
import {loadCustomerData} from 'customerActions.js';
import {startInterval, stopInterval} from 'intervalActions.js';
import {calculateWinning} from 'betSlipActions.js';

export const LOAD_OFFERS_CANCELED_ON_WITHDRWAL = 'LOAD_OFFERS_CANCELED_ON_WITHDRWAL';
export const LOAD_OFFERS_BALANCES = 'LOAD_OFFERS_BALANCES';
export const WITHDRAW_OFFER_BALANCE = 'WITHDRAW_OFFER_BALANCE';
export const SET_CUSTOMER_BETSLIP_PROMOTIONS = 'SET_CUSTOMER_BETSLIP_PROMOTIONS';
export const TOGGLE_PROMOTION = 'TOGGLE_PROMOTION';

// action creators
const offersCanceledOnWithdrawal = () => {
    
    return async(dispatch, getState, {OffersApi}) => {

        dispatch(loadOffersCanceledOnWithdrawalPending());
        try {
            const {code, data} = await OffersApi.getOffersCanceledOnWithdrawal();
            if(code!=200){
                throw {message: translation(`offers_error${code}`)};
            }
            dispatch(loadOffersCanceledOnWithdrawalSuccess(data));

            return data;
        } catch ({message}) {
            dispatch(loadOffersCanceledOnWithdrawalFailure(message));
            throw message;
        }
    }
};

const loadOffersCanceledOnWithdrawalFailure = (error) => {
    return {
        type: `${LOAD_OFFERS_CANCELED_ON_WITHDRWAL}_FAILURE`,
        payload: {error}
    }
};

const loadOffersCanceledOnWithdrawalSuccess = (callOffersCanceledOnWithdrawal) => {
    return {
        type: `${LOAD_OFFERS_CANCELED_ON_WITHDRWAL}_SUCCESS`,
        payload: {callOffersCanceledOnWithdrawal}
    }
};

const loadOffersCanceledOnWithdrawalPending = () => {
    return {
        type: `${LOAD_OFFERS_CANCELED_ON_WITHDRWAL}_PENDING`
    }
};

const loadCustomerOffersBalances = () => {
    return async(dispatch, getState, {OffersApi}) => {
        dispatch(loadOffersBalancesPending());

        try{

            const {code, data} = await OffersApi.getCustomerOfferBalancesWithDetails();

            if(code!=200){
                throw {message: translation(`offers_error${code}`)};
            }
            dispatch(loadOffersBalancesSuccess(data));
            return data;
        }catch({message}){
            dispatch(loadOffersBalancesFailure(message));
        }
    }
};


const startIntervalLoadCustomerOffers = () => {
    return (dispatch, getState) => {
        const config = {
            key: 'FETCH_CUSTOMER_OFFERS',
            actionCreator: loadCustomerOffersBalances,
            timeout: 10000
        };
        dispatch(startInterval(config));
    }
};

const stopIntervalLoadCustomerOffers = () => {
    return (dispatch, getState) => {
        const key = 'FETCH_CUSTOMER_OFFERS';
        const {Intervals:{intervals}} = getState();
        const intervalExist = _find(intervals, {key});
        if (_size(intervalExist)) {
            dispatch(stopInterval(key));
        }
    }
};

const loadOffersBalancesSuccess = (offersBalances) => {
    return {
        type: `${LOAD_OFFERS_BALANCES}_SUCCESS`,
        payload: {offersBalances}
    }
};

const loadOffersBalancesPending = (data) => {
    return {
        type: `${LOAD_OFFERS_BALANCES}_PENDING`,
        payload: {data}
    }
};

const loadOffersBalancesFailure = (data) => {
    return {
        type: `${LOAD_OFFERS_BALANCES}_FAILURE`,
        payload: {data}
    }
};

const withdrawCustomerOfferBalance = (config) => {
    return async(dispatch, getState, {OffersApi}) => {
        dispatch(withdrawCustomerOfferBalancePending());

        try{
            const {code, data} = await OffersApi.withdrawCustomerOfferBalance(config);
            if (code != 200 || (Array.isArray(data) && data.length == 0)) {
                throw {message: translation(`offers_error${code}`)};
            } else {
                dispatch(withdrawCustomerOfferBalanceSuccess(data));
                dispatch(loadCustomerOffersBalances());
                dispatch(loadCustomerData());
            }
            return data;
        }catch(error){
            dispatch(withdrawCustomerOfferBalanceFailure(error));
            throw error;
        }
    }
};

const withdrawCustomerOfferBalanceSuccess = (data) => {
    return {
        type: `${WITHDRAW_OFFER_BALANCE}_SUCCESS`,
        payload: {data}
    }
};

const withdrawCustomerOfferBalancePending = (data) => {
    return {
        type: `${WITHDRAW_OFFER_BALANCE}_PENDING`,
        payload: {data}
    }
};

const withdrawCustomerOfferBalanceFailure = (error) => {
    return {
        type: `${WITHDRAW_OFFER_BALANCE}_FAILURE`,
        payload: {error}
    }
};

const getCustomerBetSlipPromotions = () => {
    return async(dispatch, getState, {OffersApi}) => {

        try{
            const {code, data} = await OffersApi.getCustomerBetSlipPromotions();
            if(code!=200){
                throw {message: translation(`betSlipPromotions_error${code}`)};
            }
            dispatch(setCustomerBetSlipPromotions(data));
            return data;
        }catch({message}){
            throw message;
        }
    }
};

const setCustomerBetSlipPromotions = (promotions) => {
    return {
        type: SET_CUSTOMER_BETSLIP_PROMOTIONS,
        payload: {promotions}
    }
};

const togglePromotion = (selectedPromotion) => {
    return (dispatch) => {
        dispatch({type: TOGGLE_PROMOTION, payload: {selectedPromotion}});
        dispatch(calculateWinning());
    }
};

export {
  offersCanceledOnWithdrawal,
  loadCustomerOffersBalances,
  withdrawCustomerOfferBalance,
  startIntervalLoadCustomerOffers,
  stopIntervalLoadCustomerOffers,
  getCustomerBetSlipPromotions,
  togglePromotion
};