import typeToReducer from 'type-to-reducer';
import {CHANGE_DATE_BY_TYPE, CHANGE_ACTIVE_TAB, FETCH_CASHBACK_LIST, SET_CURRENT_PAGE, CHANGE_ACTIVE_FILTER_BTN} from 'accountCashbackActions.js';

let initialState = {
    isPending: true,
    error: null,
    startDate: new Date(),
    endDate: new Date().setDate(new Date().getDate()+1),
    status: 'paid',
    data: [],
    currentPage: 0,
    limit: 10,
    filterBtnSelected: '',
};

export default typeToReducer({
    [CHANGE_DATE_BY_TYPE]: (state, {payload: {dateType, date}}) => {
        return {...state, [dateType]: date}
    },
    [CHANGE_ACTIVE_TAB]: (state, {payload: {status}}) => {
        return {...state, status}
    },
    [SET_CURRENT_PAGE]: (state, {payload: {currentPage}}) => {
        return {...state, currentPage}
    },
    [CHANGE_ACTIVE_FILTER_BTN]: (state, {payload: {filterBtnSelected}}) => {
        return {...state, filterBtnSelected}
    },
    [FETCH_CASHBACK_LIST]: {
        PENDING: (state, action) => {
            return {...state, isPending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {data}}) => {
            return {...state,  isPending: false, data};
        }
    }
}, initialState);
