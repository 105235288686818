import React from 'react';
import S from 'StyledRegisterWizardFormStep1.js';
import { Field, reduxForm } from 'redux-form';
import {translation} from 'helpers/utilsHelper.js';
import {isValueUnique} from 'authActions.js';
import classNames from 'classnames';
import _pick from 'lodash/pick';
import Datepicker from 'Datepicker.js';
import { subMonths } from 'date-fns/esm';

// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';
import { connect } from 'react-redux';

const validate = values => {
    const errors = validation(values, validators.REGISTER_STEP1);
    return errors;
};

const asyncValidate = async(values, dispatch, props, field) => {
    
    if (field) {
        const fieldName = (field == 'mobile' ? 'MOBILE_NUMBER' : field); 
        const fieldValue = (field == 'mobile' ? values['mobilePrefix'] + values[field] : values[field]);

        try {
            const result = await dispatch(isValueUnique(fieldName, fieldValue));
            return result;
        } catch (err) {
            throw {[field]: [translation(err)]};
        }
    }
};

const renderDatepicker = ({
    input,
    touch,
    meta: { touched, error, warning, valid, initial }
}) => {

    const inputClass = classNames({
        'has-error': error,
        'is-valid': (initial && valid)
    });

    const val = input.value || initial;
    return (
        <S.RowDatePicker>
            <S.DatePickerWrapper>
                    <Datepicker
                        selected={val}
                        selectsStart
                        startDate={val}
                        endDate={val}
                        dateFormat="dd-MM-yyyy"
                        cb={(value) => {
                            input.onChange(value);
                            input.onBlur();
                        }}
                        onBlur={(date) => touch(date)}
                        minDate={subMonths(new Date(), 1836)}
                        maxDate={new Date()}
                        className={inputClass}
                    />
            </S.DatePickerWrapper>
            {touched && ((error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
        </S.RowDatePicker>
    )
};
const renderField = ({
    input,
    type,
    hidden,
    required,
    meta: {touched, error, warning, valid, asyncValidating}
}) => {
    const inputClass = classNames({
        'async-validating': asyncValidating,
        'has-error': (touched && error),
        'is-valid': (touched && valid)
    });
    const rowClass = classNames({
        'hidden': hidden
    });
    return (
        <S.Row className={rowClass}>
            <S.InputWrapper>
                <S.Input {...input} type={type} required={required ? "required" : ""} className={inputClass} autoComplete="new-password" />
                {touched && ((error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

const CheckboxWithLabelAndError = ({
    input,
    label,
    type,
    required,
    meta: {touched, error, warning}
}) => {
    return (
        <S.Row>
            <S.InputWrapper className="with-checkbox">
                <S.InputWrapperRow>
                    <S.InputWrapperCol>
                        <S.Input {...input} value={input.value} checked={input.value} className="input-checkbox"
                                onChange={event => input.onChange(event.target.checked)} type={type} required={required}/>
                        <S.FakeChbox>
                            {input.value && <S.CheckMark>&#10004;</S.CheckMark>}
                        </S.FakeChbox>
                    </S.InputWrapperCol>
                    <span dangerouslySetInnerHTML={{__html: translation(label)}}></span>
                </S.InputWrapperRow>
                {touched && ((error && <S.Error className="with-checkbox">{translation(error[0])}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    );

};

let RegisterWizardFormStep1 = ({handleSubmit, pristine, submitting, error}) => {

    return (

        <S.RegisterForm method="post" autocomplete="off" onSubmit={handleSubmit}>

            <div>
            <S.Label> {translation('registerModal_firstName')} <span>*</span></S.Label>
            { translation('registerModal_infoFirstName') != 'registerModal_infoFirstName' && ( 
                <S.InfoLabel>{translation('registerModal_infoFirstName')}</S.InfoLabel>
             )}
            <Field
                name="firstName"
                type="text"
                required={true}
                component={renderField}
            />
            </div>
            <div>
            <S.Label> {translation('registerModal_lastName')} <span>*</span></S.Label>
            { translation('registerModal_infoLastName') != 'registerModal_infoLastName' && (
                <S.InfoLabel>{translation('registerModal_infoLastName')}</S.InfoLabel>
             )} 
            <Field
                name="lastName"
                type="text"
                required={true}
                component={renderField}
            />
            </div>
            <div>
            <S.Label> {translation('registerModal_mobile')} <span>*</span></S.Label>
            { translation('registerModal_infoMobile') != 'registerModal_infoMobile' && (
                <S.InfoLabel>{translation('registerModal_infoMobile')}</S.InfoLabel> 
            )} 
            <Field
                name="mobile"
                type="text"
                required={true}
                component={renderField}
            />
            </div>
            <div>
            <S.Label> {translation('registerModal_email')} </S.Label>
            { translation('registerModal_infoEmail') != 'registerModal_infoEmail' && (
                <S.InfoLabel>{translation('registerModal_infoEmail')}</S.InfoLabel>
            )}
            <Field
                name="email"
                type="text"
                required={false}
                component={renderField}
            />
            </div>
            <S.Label>{translation('registerModal_dateOfBirth')} <span>*</span></S.Label>
            { translation('registerModal_infoDateOfBirth') != 'registerModal_infoDateOfBirth' && (
                <S.InfoLabel>{translation('registerModal_infoDateOfBirth')}</S.InfoLabel>
            )} 
            <Field
                name="dateOfBirth"
                type="text"
                required={true}
                component={renderDatepicker}
            />
            <div>
            <S.Label>{translation('registerModal_password')} <span>*</span></S.Label>
            { translation('registerModal_infoPassword') != 'registerModal_infoPassword' && (
                <S.InfoLabel>{translation('registerModal_infoPassword')}</S.InfoLabel>
            )}
            <Field
                name="password"
                type="password"
                required={true}
                component={renderField}
            />
            </div>
            <div>
            <S.Label>{translation('registerModal_confirmPassword')} <span>*</span></S.Label>
            { translation('registerModal_infoConfirmPassword') != 'registerModal_infoConfirmPassword' && (
                <S.InfoLabel>{translation('registerModal_infoConfirmPassword')}</S.InfoLabel>
            )}
            <Field
                name="confirmPassword"
                type="password"
                required={true}
                component={renderField}
            />
            </div>
            <Field
                name="acceptRules"
                type="checkbox"
                component={CheckboxWithLabelAndError}
                label={translation('account_registerForm_chbox2')}
                required={true}
            />

            <Field
                name="mobilePrefix"
                type="text"
                hidden={true}
                required={true}
                component={renderField}
            />

            {error && <S.SubmissionError className="submission-error">{error}</S.SubmissionError>}

            <S.SubmitBtn type="submit" disabled={pristine || submitting}>{translation('account_registerForm_next')}</S.SubmitBtn>

        </S.RegisterForm>
    );
};

let RegisterWizardForm1 = reduxForm({
    form: 'register',
    validate,
    asyncValidate,
    asyncBlurFields: ['mobile'],
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(RegisterWizardFormStep1);

const mapStateToProps = (state) => {
    const {Auth: {brandData}} = state;
    return {
        brandData,
        initialValues: {
            mobilePrefix: brandData?.mobilePrefix ?? ''
        }
    }
}

RegisterWizardForm1 = connect( mapStateToProps)(RegisterWizardForm1);

export default RegisterWizardForm1 