import typeToReducer from "type-to-reducer";
import update from "immutability-helper";
import {
    CHANGE_GET_LUCKY_STAKE,
    CHANGE_GET_LUCKY_TOWIN,
    CHANGE_SELECTED_DAYS,
    CHANGE_RANGE,
    GET_LUCKY
} from "getLuckyActions.js";

let initialState = {
    toWin: 1000,
    stake: 2,
    selectedDays: {
        today: true,
        tomorrow: true,
        dayAfterTomorrow: true
    },
    range: {
        min: 1,
        max: 10,
        step: 0.1,
        value: {
            min: 2,
            max: 8
        }
    },
    isPending: false,
    error: null
};

export default typeToReducer(
    {
        [CHANGE_GET_LUCKY_STAKE]: (state, { payload: { stake } }) => {
            let newState = update(state, { stake: { $set: stake } });
            return { ...newState };
        },
        [CHANGE_GET_LUCKY_TOWIN]: (state, { payload: { toWin } }) => {
            let newState = update(state, { toWin: { $set: toWin } });
            return { ...newState };
        },
        [CHANGE_SELECTED_DAYS]: (
            state,
            { payload: { checkboxName, value } }
        ) => {
            let newState = update(state, {
                selectedDays: { [checkboxName]: { $set: value } }
            });
            return { ...newState };
        },
        [CHANGE_RANGE]: (state, { payload: { valueObject } }) => {
            let newState = update(state, {
                range: { value: { $set: valueObject } }
            });
            return { ...newState };
        },
        [GET_LUCKY]: {
            PENDING: (state, { payload: { isPending }}) => {
                return {...state, error: null, isPending};
            },
            SUCCESS: (state) => {
                return {...state, isPending: false};
            },
            FAILURE: (state, { payload: { error } }) => {
                return {...state, isPending: false, error};
            }
        }
    },
    initialState
);
