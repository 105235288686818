import typeToReducer from "type-to-reducer";
import update from 'immutability-helper';

import {
    LOAD_VIRTUAL_GAME,
    SET_VIRTUAL_PROVIDER
} from "integrationActions.js";

let initialState = {
    gameUrl: null,
    error: null,
    isPending: true,
    iFrameHeight: 2000,
    provider: null,
    singleGame: false,
    mustBeLogged: true,
    providerCreateIframe: false
};


export default typeToReducer({
    [SET_VIRTUAL_PROVIDER]: (state, {payload: {providerName, singleGame, mustBeLogged, providerCreateIframe}}) => {
        const newState  = update(
            state, 
            {provider: {$set: providerName},
            singleGame: {$set: singleGame},
            mustBeLogged: {$set: mustBeLogged},
            providerCreateIframe: {$set: providerCreateIframe}});
        return {...newState};
    },
    [LOAD_VIRTUAL_GAME]: {
        PENDING: (state, action) => {
            return {...state, isPending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {gameUrl}}) => {
            return {...state, isPending: false, gameUrl};
        }

    },
}, initialState);

