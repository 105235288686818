import styled from 'styled-components';
import StyledLoader from 'StyledLoader.js';
import {List} from 'global/styles/styles.js';
const S = {};

S.LeftMenu = styled.div`
    float: left;
    width: 100%;
`;
S.DropdownMenuWrapper = styled.div`
    position: relative;
    float: left;
    width: 100%;
`;

S.Cover = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
        justify-content: center;
    align-items: center;
    display: flex;
    
    & ${StyledLoader.LoaderWrapper}{
    
    }
`;
export default S;