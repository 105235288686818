import styled from 'styled-components';
import {Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwRegular = ({theme:{$fwRegular}}) => `${$fwRegular}`;
const FwSemibold = ({theme:{$fwSemibold}}) => `${$fwSemibold}`;

const baseFont = `12px`;
const S = {};

S.SelfExclusion = styled.div`
    font-family: ${FontFamily};
    width: 100%;
    padding: 0;
    font-weight: ${FwRegular};
`;

S.SelfExclusionBoxArea = styled.div`
    display: flex;
    flex-direction: column;
`;

S.SelfExclusionBoxWrapper = styled.div`
  &:nth-of-type(1){
    background-color: #fff;
  }
`;

S.SelfExclusionBox = styled.form`
    width: ${rem('600px', baseFont)};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${rem('27px', baseFont)} 0;
    margin: 0 auto;
`;

S.Title = styled.span`
    font-size: 1.67rem;
    font-weight:  ${FwSemibold};
    margin-bottom:  ${rem('20px', baseFont)};
`;

S.RowWrapper = styled.div`
  margin-top:  ${rem('30px', baseFont)};
`;

S.Button = styled(Button)`
   font-family: ${FontFamily};
    height: ${rem('37px', baseFont)};
    background-color: #D32F2F;
    font-size: ${rem('14px', baseFont)};
    text-transform: uppercase;
    font-weight: ${FwSemibold};
    color: #fff;
    border-radius: 4px;
    padding: 0 ${rem('10px', baseFont)};
    text-align: center;
    ${({disabled})=>{
        if(disabled){
            return `
                   background-color: grey;
                `;
        }
    }}
`;

S.Error = styled.span`
    color: #D32F2F;
    font-size: ${rem('13px', baseFont)};
    font-weight: ${FwSemibold};
    margin: ${rem('10px', baseFont)} 0 0;
`;

S.SelfExclusionValidTo = styled(S.Error)``;

S.Description = styled.div`
  text-align: center;
`;

S.Dialog = styled.dialog`
   width:  ${rem('335px', baseFont)};
   padding: ${rem('16px', baseFont)};
   box-shadow: 0 19px 38px #00000042;
   border: none;
   
   & + .backdrop{
     background-color: transparent;
   }
   
   &::backdrop {
    background-color: transparent;
   }
  
   @supports (-moz-appearance:meterbar) {
    top: 50%;
    transform: translate(0, -50%);
    position: fixed;
   }

`;

S.DialogBtnOk = styled(Button)`
 width:  ${rem('142px', baseFont)};
 min-height:  ${rem('42px', baseFont)};
 text-transform: uppercase;
 color: #fff;
 background-color: #C62828;
 border-radius: 3px;
 font-weight: ${FwSemibold};
`;

S.DialogBtnCancel = styled(S.DialogBtnOk)`
 color: #C62828;
 background-color: #fff;
 border: 1px solid #C62828;
`;

S.DialogText =styled.div`
  font-size: ${rem('13px', baseFont)};
  color: #000;
`;

S.BellIcon = styled.div`
  width:  ${rem('32px', baseFont)};
`;


export default S;