import styled from 'styled-components';
import {List} from 'global/styles/styles.js';
import {rem} from 'polished';
const baseFont = `12px`;
const S = {};

S.FreebetCount= styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${({theme:{$accountBgColor}})=> $accountBgColor};
    color: #fff;
    text-align: center;
    font-size: 1.1666666667rem!important;
    line-height: 2rem;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 1.6666rem;
`;

S.AccountMenu = styled(List)`
    background: #eaeaea;
    width: 100%;
`;
S.AccountMenuItem = styled.li`
    position:relative;
    display:flex;
    align-items: center;
`;
S.Image = styled.img`
    position:absolute;
    top:50%;
    left:5%;
    transform:translateY(-50%) ;
    height: ${rem('25px', baseFont)};
    width: ${rem('25px', baseFont)};
    margin: auto;
    display: block;
    background-color: ${({theme:{$accountBgColor}})=> $accountBgColor};
`;
S.AccountMenuLink = styled.a`
    color: #fff;
    display: flex;
    flex:1;
    align-items: center;
    background-color: ${({theme:{$accountBgColor}})=> $accountBgColor}; 
    margin: 0;
    padding: 1.4583rem 1.8333rem;
    padding-left:15%;
    border-bottom: ${({theme:{$accountPrimaryColor}})=> ` 1px solid ${$accountPrimaryColor}`}; 
    font-size:1.4167rem;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.active,
    &:hover{
        color: ${({theme:{$accountPrimaryColor}})=> $accountPrimaryColor}; 
        border-right: none;
        text-decoration: none;
        & > ${S.Image}{
            svg path{
                fill:${({theme:{$accountPrimaryColor}})=>$accountPrimaryColor}
            }
        }
    }
`;

export default S;