import _isNumber from 'lodash/isNumber';
const encode = encodeURIComponent;

export default (requests, axios) => {
    return {
        getResultsForPhrase: pattern => {
            const config = {
                areas: ["LIVE_EVENT", "PREMATCH_EVENT"],
                languageCode: Cookies.get("Language"),
                mergeLanguages: true,
                modes: ["PREFIX", "INFIX"],
                pattern
            };
            if(_isNumber(pattern)){
                config['modes'] = ['PARTICIPANT_ID'];
            }
            return requests.post("/search/events/quick-search", config);
        },

        saveChoiceHint: (area, itemId) => {
            var data = {
                area: area,
                itemId: itemId,
                languageCode: Cookies.get("Language")
            }
            return requests.post("/quick-search/public/choice", data);
        }
    };
};
