import React, {forwardRef} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import S from 'StyledDatepicker.js';

const Datepicker = ({cb, ...rest}, _ref) => {

    const handleChange = (date) => {
        cb(date);
    };

    return (
        <S.Datepicker as={DatePicker} {...rest} onChange={handleChange}  ref={_ref}></S.Datepicker>
    );
};

export default forwardRef(Datepicker);
