import styled from 'styled-components';

const S = {};

S.WalletTransactionsItem = styled.div`
display: flex;
gap: 0.08rem;
border-bottom: .08rem solid #898989;

&.open{
    border: none;
}

&>div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
	overflow: hidden;
    padding: 0.83rem 0;
	font-size: 1rem;
	text-overflow: ellipsis;
	font-weight: 500;
	white-space: nowrap;
	color: #000;

	&.wallet-status {
        position: relative;
		&.red{
		    color: #CA2717;
		}
		&.green{
		    color: #019A44;
		}
        &.blue{
            color: #0047B9;
        } 
	}
	&.wallet-type {
        justify-content: start;
	}
}
`;

S.WalletTransactionsItemDetails = styled.div`
    overflow: hidden;
    max-height: 0;
    background-color: #A5A5A5;
    font-size: 1rem;
    color: #fff;
    transition-property: max-height;
    transition-duration: 0.4s;

    ${({isOpen}) => isOpen == true && 'max-height: 10.83rem;'};

    & > div {
        padding: 0.91rem 2.25rem 1.17rem; 
    }
`

S.OpenDetailsIcon = styled.img`
    position: absolute;
    right: 0.9rem;
    height: 0.9rem;
    width: 0.9rem;
    transform: rotate(-90deg);
    transition-property: transform;
    transition-duration: 0.3s;

    ${({isOpen}) => isOpen == true && 'transform: rotate(0);'};

    path {
        fill: #A5A5A5;
    }
`

S.TypeIcon = styled.img`
    margin: 0 0.375rem 0 20%;
    width: 1.08rem;
    height: 1.08rem;
`

export default S;