import styled from 'styled-components';
import StyledResponsibleGamingForm from 'StyledResponsibleGamingForm.js';
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const baseFont = `12px`;

const S = {};

S.AccountResponsibleGame = styled.div`
  width: 100%;
  font-family: ${FontFamily};
  ${StyledResponsibleGamingForm.ResponsibleGamingForm}{
    width: ${rem('600px', baseFont)};
    margin: ${rem('50px', baseFont)} auto 0;
    border-bottom: 1px solid #D9D9D9;
  }
  
  ${StyledResponsibleGamingForm.Body}{
      padding: 0;
  }
  
    ${StyledResponsibleGamingForm.ButtonsWrapper}{
    padding-left: 0;
    padding-right: 0;
    }

`;

export default S;