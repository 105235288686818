import React, {useState, useEffect} from 'react';
import Box from 'react-styled-box';
import ModalOpacity from 'ModalOpacity.js';
import useToggle from 'UseToggle.js';
import S from 'StyledAccountEditProfileForm.js';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {translation} from 'helpers/utilsHelper.js';
import {fetchSessionsByFilter} from 'accountSessionsHistoryActions.js'
import {isValueUnique} from 'customerActions.js';
import classNames from 'classnames';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _map from 'lodash/map';
import ChangePasswordForm from 'ChangePasswordForm.js';
import Datepicker from 'Datepicker.js';
import { subMonths } from 'date-fns/esm';
import {formatDate} from 'helpers/datesHelper.js'

// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';
import { connect } from 'react-redux';


const validate = values => {
    const errors = validation(values, validators.EDIT_ACCOUNT);
    return errors;
};

const asyncValidate = async(values, dispatch, props, field) => {
    if (field) {
        const fieldName = field;
        const fieldValue = values[field];

        try {
            const result = await dispatch(isValueUnique(fieldName, fieldValue));
            return result;
        } catch (err) {
            throw {[fieldName]: [translation(err)]};
        }
    }
};

const renderField = ({
    input,
    label,
    type,
    meta: {touched, error, warning, valid, asyncValidating},
    disabled
}) => {

    const inputClass = classNames({
        'async-validating': asyncValidating,
        'has-error': (touched && error),
        'is-valid': (touched && valid)
    });

    return (
        <S.Row>
            <S.InputWrapper>
                <S.Label>{label}</S.Label>
                <S.Input {...input} type={type} required="required" className={inputClass} disabled={disabled}/>
                {!valid && <S.SaveIcon/>}
                {touched && ((error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

const renderDatepicker = ({
  input,
  label,
  meta: {touched, error, warning, valid, initial}
})=>{

  const val = input.value || initial;

  return (
    <S.Row>
      <S.InputWrapper>
        <S.Label>{label}</S.Label>
        <Datepicker
            selected={val}
            selectsStart
            startDate={val}
            endDate={val}
            dateFormat="dd-MM-yyyy"
            cb={(value)=>{
              input.onChange(value)
            }}
            minDate={subMonths(new Date(), 1836)}
            maxDate={new Date()}

        />
        <S.SaveIcon/>
        {(error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>)}
      </S.InputWrapper>
    </S.Row>
  )
};


const AccountEditProfileForm = ({
    userData,
    initialize,
    initialValues,
    userId,
    changePasswordOnSubmit,
    updateUserDataOnSubmit,
    handleSubmit,
    pristine,
    submitting,
    dispatch,
    refreshData,
    data,
    isPending,
    error
}) => {


    const [showChangePasswordModal, toggleChangePasswordModal] = useToggle(false);
    const [editMode, toggleEditMode] = useToggle(false);
    const [editResultSuccess, setEditResultSuccess] = useState(null);
    
    useEffect(()=>{
        // fetch last Session info for "Security" panel
        const fetchLastSessionInfo = async()=>{
            try{
                dispatch(fetchSessionsByFilter())
            }catch(error){
                throw new Error(error)
            }
        }
        fetchLastSessionInfo()
    },[])

    const onFormSubmit = async(values) => {
        try {
            const postData = _pick(values, ['email']);
            const response = await updateUserDataOnSubmit(postData);
            if (response.code == 200 && response.data == true){
                setEditResultSuccess(true);
                await refreshData();
            } else {
                setEditResultSuccess(false);
            }
            toggleEditMode();
        } catch ({message}) {
            throw new SubmissionError({_error: message});
            toggleEditMode();
        }
    };

    return (
        <S.AccountEditProfileForm className="account-edit-form" method="post" onSubmit={handleSubmit(onFormSubmit)}>

            <ModalOpacity isOpen={showChangePasswordModal}
                          toggleOpen={toggleChangePasswordModal}
                          showHeaderIcon={false}
                          titleColor="#fff"
                          backgroundBody="#DEDEDE"
                          backgroundHeader="#019A44"
                          closeIconColor={'#fff'}
                          title={translation('account_editProfileForm_changePassword_formHeader')}
                          padding="0rem" 
                          width="35rem"
                          headerPadding="0.9375rem"
                          closeIconImg="/images/svg/header_close_white.svg">

                <ChangePasswordForm changePasswordOnSubmit={changePasswordOnSubmit} userId={userId} toggleChangePasswordModal={toggleChangePasswordModal}/>

            </ModalOpacity>

            <Box width="100%">
                <Box flexDirection="column" width="100%" padding="0 40px 0 0">
                    <Box flexDirection="column" flexGrow={1} margin="0 0 20px 0">
                        
                        <S.AccountHeader>
                            <S.HeaderText>{translation('EditProfileHeader_login')}</S.HeaderText>
                            {!editMode && (
                                <S.EditButton onClick={toggleEditMode}>
                                    <S.EditIcon></S.EditIcon>
                                    <S.ButtonText>{translation('account_editProfileForm_editButton')}</S.ButtonText>
                                </S.EditButton>
                            )}
                            {editMode && (
                                <S.SaveButtonContainer>
                                    <S.CancelButton onClick={()=> {toggleEditMode(); setEditResultSuccess(null); }}>
                                        <S.ButtonText>{translation('account_editProfileForm_cancelButton')}</S.ButtonText>
                                    </S.CancelButton>
                                    <S.SaveButton type="submit" disabled={pristine || submitting}>
                                        <S.ButtonText>{translation('account_editProfileForm_saveButton')}</S.ButtonText>
                                    </S.SaveButton>
                                </S.SaveButtonContainer>
                            )}
                        </S.AccountHeader>
                        {editResultSuccess && 
                            <S.AccountEditSuccess>
                                {translation('account_editProfileForm_saveSuccess')}
                            </S.AccountEditSuccess>
                        }
                        {editResultSuccess == false &&
                            <S.AccountEditFailure>
                                {translation('account_editProfileForm_saveFailure')}
                            </S.AccountEditFailure>
                        }
                        <Field
                            name="firstName"
                            type="text"
                            component={renderField}
                            disabled={true}
                            label={translation('account_editProfileForm_firstName')}
                        />
                        <Field
                            name="lastName"
                            type="text"
                            disabled={true}
                            component={renderField}
                            label={translation('account_editProfileForm_lastName')}
                        />

                        <Field
                            name="email"
                            type="text"
                            disabled={!editMode}
                            component={renderField}
                            label={translation('account_editProfileForm_email')}
                        />
                        <Field
                            name="mobileNumber"
                            type="text"
                            disabled={true}
                            component={renderField}
                            label={translation('account_editProfileForm_telNumber')}
                        />

                        <Field
                            name="country"
                            type="text"
                            disabled={true}
                            component={renderField}
                            label={translation('account_editProfileForm_country')}
                        />
                        
                        { typeof initialValues.dateOfBirth == "string" ?
                            <Field
                                name="dateOfBirth"
                                type="text"
                                disabled={true}
                                component={renderField}
                                label={translation('account_editProfileForm_dateOfBirth')}
                            />
                            :
                            <Field
                                name="dateOfBirth"
                                type="text"
                                disabled={true}
                                component={renderDatepicker}
                                label={translation('account_editProfileForm_dateOfBirth')}
                            />
                        }
                        <S.AccountHeader>{translation('EditProfileHeader_accountDetails')}</S.AccountHeader>
                        <Field
                            name="loginName"
                            type="text"
                            disabled={true}
                            component={renderField}
                            label={translation('account_editProfileForm_loginName')}
                        />
                        <S.AccountHeader>{translation('EditProfileHeader_security')}</S.AccountHeader>
                         <S.LastLoginInfo>
                            <span>
                                {translation('account_editProfileForm_lastLogin')}
                            </span>
                            <span>
                                {data && !isPending && formatDate(data[0].loginDate,'dd.MM.yyyy HH:mm')}
                            </span>
                         </S.LastLoginInfo>
                    </Box>

                    <Box justifyContent="center">
                        <S.ChangePasswordBtn onClick={toggleChangePasswordModal}>
                            {translation('account_editProfileForm_changePassword')}
                        </S.ChangePasswordBtn>
                    </Box>
                </Box>
            </Box>

        </S.AccountEditProfileForm>
    );
};
const mapStateToProps = ({AccountSessionHistory: { data,isPending}}, props) => {
    return {
        data,
        isPending
    }
};
const mapDispatchToProps = {
    fetchSessionsByFilter,
};
const ConnectedAccountEditProfileForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountEditProfileForm)

export default reduxForm({
    form: 'accountEditProfile',
    validate,
    enableReinitialize: true
   // asyncValidate,
   // asyncBlurFields: ['mobileNumber', 'bankAccount', 'documentNumber']
})(ConnectedAccountEditProfileForm);

