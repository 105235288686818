export const START_INTERVAL = 'START_INTERVAL';
export const STOP_INTERVAL = 'STOP_INTERVAL';

const startInterval = ({key, actionCreator, timeout}={}) => {
    return {
        type: START_INTERVAL,
        payload: {
            key,
            actionCreator,
            timeout
        }
    }
};

const stopInterval = (key) => {
    return {
        type: STOP_INTERVAL,
        payload: {
            key
        }
    }
};


export {startInterval, stopInterval}