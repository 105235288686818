import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import S from 'StyledAccountCashback.js';
import Loader from 'Loader.js';
import Datepicker from 'Datepicker.js';
import Pagination from 'Pagination.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import classNames from 'classnames';
import {translation, formatMoney} from 'helpers/utilsHelper.js';
import {formatDate} from 'helpers/datesHelper.js';
import {changeDateByType, changeActiveTab, fetchCashbackByFilter, setCurrentPage} from 'accountCashbackActions.js';
import ReactTooltip from 'react-tooltip';

class AccountCashback extends Component {

    componentDidMount = async() => {
        const {fetchCashbackByFilter} = this.props;
        try {
            const data = await fetchCashbackByFilter();
        } catch (error) {
            console.log('fetchCashbackByFilter:' + error);
        }
    };

    handleChangeStart = (date) => {
        const {changeDateByType, fetchCashbackByFilter, endDate, status, currentPage, limit} = this.props;
        const startDate = new Date(date);
        const params = {
            status,
            endDate,
            startDate,
            currentPage,
            limit
        };

        changeDateByType('startDate', startDate);
        fetchCashbackByFilter(params);
    };

    handleChangeEnd = (date) => {
        const {changeDateByType, fetchCashbackByFilter, startDate, status, currentPage, limit} = this.props;
        const endDate = new Date(date);
        const params = {
            status,
            endDate,
            startDate,
            currentPage,
            limit
        };

        changeDateByType('endDate', endDate);
        fetchCashbackByFilter(params);
    };

    onChangeFilter = (status) => {
        const {changeActiveTab, fetchCashbackByFilter, startDate, endDate, currentPage, limit} = this.props;
        const params = {
            status,
            endDate,
            startDate,
            currentPage,
            limit
        };

        changeActiveTab(status);
        fetchCashbackByFilter(params);
    };

    goPage = async(currentPage) => {
        const {fetchCashbackByFilter, startDate, endDate, limit, status, setCurrentPage} = this.props;
        const params = {
            status,
            endDate,
            startDate,
            currentPage,
            limit
        };
        try {
            await fetchCashbackByFilter(params);
            setCurrentPage(currentPage);
        } catch (error) {
            console.log('goPage:'+error);
        }
    };

    render() {
        const {isPending, error, startDate, endDate, status, data, currentPage, limit, currencyCode} = this.props;

        return (
            <S.AccountCashback>
                <S.CashbackFilters className="wallet-filters">

                    <S.CashbackFiltersDates className="wallet-filters-dates">

                        <S.DateWrapper className="date-wrapper">
                            <S.DateLabel>{translation('common_dateFrom')}</S.DateLabel>
                            <Datepicker
                                selected={startDate}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="yyyy-MM-dd"
                                cb={this.handleChangeStart}
                            />
                            <S.DateIcon className="ion ion-ios-calendar-outline"></S.DateIcon>
                        </S.DateWrapper>

                        <S.DateWrapper className="date-wrapper">
                            <S.DateLabel>{translation('common_dateTo')}</S.DateLabel>
                            <Datepicker
                                selected={endDate}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="yyyy-MM-dd"
                                cb={this.handleChangeEnd}
                            />
                            <S.DateIcon className="ion ion-ios-calendar-outline"></S.DateIcon>
                        </S.DateWrapper>

                    </S.CashbackFiltersDates>

                    <S.CashbackFiltersBtns className="cashback-btns-wrapper">
                        <S.FilterBtn
                            onClick={this.onChangeFilter.bind(this, 'paid')}
                            className={classNames({active: (status == 'paid')})}>{translation('account_cashback_paid')}</S.FilterBtn>
                        <S.FilterBtn
                            onClick={this.onChangeFilter.bind(this, 'awaiting')}
                            className={classNames({active: (status == 'awaiting')})}>{translation('account_cashback_awaiting')}</S.FilterBtn>
                    </S.CashbackFiltersBtns>


                </S.CashbackFilters>

                <S.CashbackList className="cashback-list">
                    {isPending ?
                        <Loader/>
                        :
                        (
                            <>
                            <S.Table className="cashback-table">

                                <S.Head>
                                    <S.Row className="row">
                                    <S.Th>{translation('account_cashback_betSlipId')}</S.Th>
                                    <S.Th>{translation('account_cashback_createTime')}</S.Th>
                                    <S.Th>{translation('account_cashback_offerName')}</S.Th>
                                    <S.Th>{translation('account_cashback_paidValue')}</S.Th>
                                    <S.Th>
                                        <i className="ion ion-help-circled"></i>
                                    </S.Th>
                                    </S.Row>
                                </S.Head>

                                <S.Body>

                                {_map(data[status], ({betSlipId, createTime, offerId, descriptionKey, paidValue=0, nameKey}) => {
                                    return (
                                        <S.Row className="row" key={offerId}>
                                            <S.Td>{betSlipId}</S.Td>
                                            <S.Td>{formatDate(createTime, 'yyyy-MM-dd HH:mm')}</S.Td>
                                            <S.Td>{translation(nameKey)}</S.Td>
                                            <S.Td>{formatMoney(paidValue)} {currencyCode}</S.Td>
                                            <S.Td>
                                                <a data-tip data-for='cashback_desc'>?</a>
                                                <ReactTooltip id='cashback_desc' type="light">
                                                    <span dangerouslySetInnerHTML={{__html: translation(descriptionKey)}}></span>
                                                </ReactTooltip>
                                            </S.Td>
                                        </S.Row>
                                    )
                                })}

                                {!_size(data[status]) && (<S.Row className="no-result"><S.Td colSpan="5">{translation('account_cashback_notFound')}</S.Td></S.Row>)}

                                </S.Body>

                            </S.Table>

                            {_size(data[status])>0 && (<Pagination currentPage={currentPage} limit={limit} data={data[status]} cb={this.goPage}/>)}

                            </>
                        )
                    }
                </S.CashbackList>

            </S.AccountCashback>
        );
    }
}

const mapStateToProps = ({AccountCashback:{isPending, error, startDate, endDate, status, data, currentPage, limit}, Auth:{user:{currencyCode}}}, props) => {
    return {
        error,
        isPending,
        startDate,
        endDate,
        status,
        data,
        currentPage,
        limit,
        currencyCode
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeActiveTab: bindActionCreators(changeActiveTab, dispatch),
        changeDateByType: bindActionCreators(changeDateByType, dispatch),
        fetchCashbackByFilter: bindActionCreators(fetchCashbackByFilter, dispatch),
        setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
        dispatch
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountCashback);
