import _find from 'lodash/find';
import _size from 'lodash/size';
import _get from 'lodash/get';
import _each from 'lodash/each';
import _compact from 'lodash/compact';
import _reduce from 'lodash/reduce';
import {batch} from 'react-redux';
import {translation} from 'utilsHelper.js';
import {startInterval, stopInterval} from 'intervalActions.js';
import {
    calculateWinning,
    changeSlipTotalStake,
    changeSlipStake,
    placeBetSlipSuccess,
    placeBetSlipAgain,
    clearBetslip,
    refreshOddsByOutcomeId,
    refreshPrematchOddsByOutcomeId,
    unlockBetSlip,
    getPlacedBetDetails,
    startIntervalFetchOddsByOutcomeId
} from 'betSlipActions.js';

export const UPDATE_APPROVAL_DATA = 'UPDATE_APPROVAL_DATA';
export const BIND_APPROVAL_BUTTONS = 'BIND_APPROVAL_BUTTONS';
export const REJECT_APPROVAL_DATA = 'REJECT_APPROVAL_DATA';
export const ACCEPT_APPROVAL_DATA = 'ACCEPT_APPROVAL_DATA';
export const SHOW_APPROVAL_MESSAGE = 'SHOW_APPROVAL_MESSAGE';
export const HIDE_APPROVAL_MESSAGE = 'HIDE_APPROVAL_MESSAGE';
export const RESTORE_INITIAL_ODDS = 'RESTORE_INITIAL_ODDS';

const loadApprovalDataOnInit = () => {
    return (dispatch, getState) => {
        dispatch(startIntervalFetchApprovedSlips());
    }
};

const startIntervalFetchApprovedSlips = () => {
    return (dispatch, getState) => {
        const config = {
            key: 'FETCH_APPROVAL_SLIPS',
            actionCreator: fetchApprovedSlips,
            timeout: 10000
        };
        setTimeout(() => {
            dispatch(startInterval(config));
        }, process.env.FETCH_APPROVED_SLIPS_TIMEOUT);
    }
};

const stopIntervalFetchApprovedSlips = () => {
    return (dispatch, getState) => {
        const key = 'FETCH_APPROVAL_SLIPS';
        const {Intervals:{intervals}} = getState();
        const intervalExist = _find(intervals, {key});
        if (_size(intervalExist)) {
            dispatch(stopInterval(key));
        }
    }
};

const bindApprovalButtons = () => {
    return {type: BIND_APPROVAL_BUTTONS}
};

const acceptApprovalData = (msg = ``) => {
    return (dispatch, getState) => {
        dispatch({type: ACCEPT_APPROVAL_DATA});
        if(CLIENT == 'etoto'){
            dispatch(placeBetSlipAgain());
        }
        const fetchPlacedBetDetails = process.env.PLACED_BET_DETAILS_ON_PLACE_BET_SUCCESS;

        if ( fetchPlacedBetDetails ) {
            const {BetSlip:{approvalData:{slipId}}} = getState();
            dispatch(getPlacedBetDetails(slipId));
         }

        dispatch(placeBetSlipSuccess());
        dispatch(showApprovalMessage(msg, 'success'));

    };
};

const rejectApprovalData = (msg = ``) => {
    return (dispatch) => {
        const keepOutcomes = process.env.KEEP_OUTCOMES_AFTER_APPROVAL_REJECT && JSON.parse(process.env.KEEP_OUTCOMES_AFTER_APPROVAL_REJECT);
        dispatch({type: REJECT_APPROVAL_DATA});
        if(keepOutcomes){
            batch(() => {
                if (CLIENT === "fuksiarz") {
                    dispatch(restoreInitialOdds())
                }
                dispatch(unlockBetSlip())
            })
        } else{
            dispatch(clearBetslip());
        }
        dispatch(showApprovalMessage(msg, 'error'));
    };
};

const showApprovalMessage = (msg = ``, status='success') => {
    return {type: SHOW_APPROVAL_MESSAGE, payload: {msg, status}}
};

const hideApprovalMessage = () => {
    return {type: HIDE_APPROVAL_MESSAGE}
};

const updateApprovalData = (approvalData) => {
    return {type: UPDATE_APPROVAL_DATA, payload: {approvalData}};
};

const acceptTraderChanges = () => {
    return async(dispatch, getState, {BettingApi}) => {
        try {

            dispatch(stopIntervalFetchApprovedSlips());

            const {code, data} = await BettingApi.acceptTraderChanges();
            if (code != 200) {
                throw {message: translation(`m_approvalChangeError`)};
            }

            const msg = translation(`approvalChanged_1`);
            dispatch(acceptApprovalData(msg));

            return data;
        } catch ({message}) {
            throw message;
        }
    }
};

const rejectTraderChanges = () => {
    return async(dispatch, getState, {BettingApi}) => {
        try {

            dispatch(stopIntervalFetchApprovedSlips());

            const {code, data} = await BettingApi.rejectTraderChanges();
            if (code != 200) {
                throw {message: translation(`m_approvalChangeError`)};
            }
            const msg = translation(`approvalChanged_10`);
            dispatch(rejectApprovalData(msg));

            return data;
        } catch ({message}) {
            throw message;
        }
    }
};

const showApprovalStakeAndOddsChange = (msg = ``) => {
    return (dispatch) => {
        dispatch(showApprovalStakeChange());
        dispatch(showApprovalOddsChange());
        dispatch(showApprovalMessage(msg));
    }
};

const showApprovalStakeChange = () => {
    return (dispatch, getState) => {
        const {BetSlip:{approvalData:{newStake}}} = getState();
        dispatch({type: 'SHOW_APPROVAL_STAKE_CHANGE'});
    }
};

const showApprovalOddsChange = () => {
    return (dispatch, getState) => {
        const {BetSlip:{approvalData:{oddsChanges}}} = getState();
        const oddsChangesParts = _compact(oddsChanges.split('|'));
        const preparedOddsChange = _reduce(oddsChangesParts, (initialObject, nextElement) => {
            const [outcomeId, prevOdds, outcomeOdds] = nextElement.split('_');
            return {...initialObject, [outcomeId]: {prevOdds, outcomeOdds}};
        }, {});

        batch(() => {
            _each(preparedOddsChange, ({prevOdds, outcomeOdds}, outcomeId) => {
                const params = {outcomeId, outcomeOdds, prevOdds};
                dispatch(refreshOddsByOutcomeId(params));
            });
        });
    }
};

const processApprovalChanges = () => {
    return (dispatch, getState) => {
        const {BetSlip:{approvalData:{status, newStake}, activeTab, betSlips}} = getState();
        const {slipType} = _get(betSlips, [activeTab]);
        const autoAccept = process.env.AUTO_ACCEPT_APPROVAL_NEW_STAKE && JSON.parse(process.env.AUTO_ACCEPT_APPROVAL_NEW_STAKE);
        const allowedStatuses = [3,4]; // statuses in which customer needs to take decision

        if (status) {
            let msg = translation(`approvalChanged_${status}`);
            switch (status) {
                case 1:
                    dispatch(acceptApprovalData(msg));
                    break;
                case 10:
                    dispatch(rejectApprovalData(msg));
                    break;
                case 2:
                    if(autoAccept){
                      dispatch(stopIntervalFetchApprovedSlips());
                      dispatch(acceptApprovalData(msg));
                    } else{
                      allowedStatuses.push(status);
                      dispatch(showApprovalStakeChange());
                      dispatch(showApprovalMessage(msg));
                    }
                    break;
                case 3:
                    dispatch(showApprovalOddsChange());
                    dispatch(showApprovalMessage(msg));
                    break;
                case 4:
                    dispatch(showApprovalStakeAndOddsChange(msg));
                    break;
            }

            // odds lub odds i stawka
            if (allowedStatuses.indexOf(status) != -1) {
                dispatch(bindApprovalButtons());
            }

            if ([2, 4].indexOf(status) != -1 && newStake) {
                if (slipType == 'SYSTEM') {
                    dispatch(changeSlipTotalStake(newStake));
                } else {
                    dispatch(changeSlipStake(newStake));
                }
            }

            if (allowedStatuses.indexOf(status) != -1) {
                dispatch(calculateWinning());
            }
        }
    }
};

const fetchApprovedSlips = () => {
    return async(dispatch, getState, {BettingApi}) => {
        try {
            const {BetSlip:{approvalData}} = getState();
            const {creationDate, slipId} = approvalData;
            const {code, data} = await BettingApi.fetchApprovedSlips();

            if (_size(data)) {
                if (slipId == data.slipId) {
                    switch (data.status) {
                        case 1:
                        case 10:
                            // 1: zaakceptowany, 10: odrzucony
                            dispatch(stopIntervalFetchApprovedSlips());
                            dispatch(updateApprovalData(data));
                            break;
                        case 2:
                        case 3:
                        case 4:
                            // zmiana stawki, oddsów, obu naraz
                            //dispatch(stopIntervalFetchApprovedSlips());
                            dispatch(updateApprovalData(data));
                            break;
                    }
                }
            } else {
                const now = new Date();
                const expireOffsetMillis = 180000;
                if (creationDate + expireOffsetMillis < now.getTime()) {
                    const data = {status: 10};
                    dispatch(stopIntervalFetchApprovedSlips());
                    dispatch(updateApprovalData(data));
                }
            }

            dispatch(processApprovalChanges());

        } catch (error) {
            console.log({error});
        }
    }
};

const restoreInitialOdds = () => {
    return (dispatch, getState) => {
        try {
            const { BetSlip: { activeTab, betSlips } } = getState();
            const { outcomes } = betSlips[activeTab];
            
            const liveOutcomes = [];
            const prematchOutcomes = [];
            for (const outcome of outcomes) {
                if (outcome.outcomeLive === "true") liveOutcomes.push(outcome)
                else {
                    if (!outcome.prevOdds) continue;
                    const { outcomeOdds, prevOdds } = outcome;
                    prematchOutcomes.push({ 
                        ...outcome, 
                        outcomeOdds: prevOdds, 
                        prevOdds: outcomeOdds 
                    })
                }
            }
            batch(() => {
                if (liveOutcomes.length) dispatch(startIntervalFetchOddsByOutcomeId(liveOutcomes))
                if (prematchOutcomes.length) dispatch(refreshPrematchOddsByOutcomeId(prematchOutcomes))
                dispatch(restoreInitialOddsSuccess())
            })
        } catch (error) {
            dispatch(restoreInitialOddsFailure(error))
        }
    }
}

const restoreInitialOddsSuccess = () => {
    return { type: `${RESTORE_INITIAL_ODDS}_SUCCESS` };
}

const restoreInitialOddsFailure = (error) => {
    return { type: `${RESTORE_INITIAL_ODDS}_FAILURE`, error };
}

export {
    loadApprovalDataOnInit,
    startIntervalFetchApprovedSlips,
    stopIntervalFetchApprovedSlips,
    acceptTraderChanges,
    rejectTraderChanges,
    hideApprovalMessage
}