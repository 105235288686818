import _get from 'lodash/get';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import {calculateWinning} from 'betSlipActions.js';

export const TOGGLE_FREEBET = 'TOGGLE_FREEBET';
export const SET_FREEBETS = 'SET_FREEBETS';
export const SET_VALID_FREEBETS = 'SET_VALID_FREEBETS';

const toggleFreebet = (freebet) => {
    return (dispatch) => {
        dispatch({type: TOGGLE_FREEBET, payload: {freebet}});
        dispatch(calculateWinning());
    }
};
const setFreebets = (freebets, type = 'active') => {
    return {
        type: SET_FREEBETS,
        payload: {
            freebets: _get(freebets, [type])
        }
    }
};

// action creators
const fetchFreebetByStatus = (config) => {
    return async(dispatch, getState, {FreebetApi}) => {
        try {
            const {code, data} = await FreebetApi.getFreebetsWithFilters(config);
            dispatch(setFreebets(data));
            return data;
        } catch (error) {
            throw error;
        }
    }
};


const freebetValidCheckCallback = (freebet, stake, outcomes, totalOddsWithoutSalesTaxFactor, type) => {
    const conditions = freebet.betSlipConditions ? freebet.betSlipConditions : freebet.conditionsMap;

    // freebet.service
    for (var cond in conditions) {

        switch (cond) {
            case 'minimumSlipStake':
            case 'slipMinStake':
                if (parseFloat(conditions[cond]) > parseFloat(stake)) {
                    return false;
                }
                break;

            case 'maximumSlipStake':
            case 'slipMaxStake':
                if (parseFloat(conditions[cond]) < parseFloat(stake)) {
                    return false;
                }
                break;

            case 'minimumNumberOfOutcomes':
            case 'slipMinNumberOfOutcomes':
                if (parseFloat(conditions[cond]) > _size(outcomes)) {
                    return false;
                }
                break;

            case 'maximumNumberOfOutcomes':
            case 'slipMaxNumberOfOutcomes':
                if (parseFloat(conditions[cond]) < _size(outcomes)) {
                    return false;
                }
                break;

            case 'combinationMinimumOdds':
            case 'combinationMinOdds':
                if (parseFloat(conditions[cond]) > parseFloat(totalOddsWithoutSalesTaxFactor)) {
                    return false;
                }
                break;

            case 'combinationMaximumOdds':
            case 'combinationMaxOdds':
                if (parseFloat(conditions[cond]) <= parseFloat(totalOddsWithoutSalesTaxFactor)) {
                    return false;
                }
                break;

            case 'minimumOutcomeOdds':
            case 'outcomeMinOdds':
                var isMinimumOutcomeOdds = Object.keys(outcomes).every(function (itemId) {
                    return parseFloat(conditions[cond]) <= parseFloat(outcomes[itemId].outcomeOdds);
                });

                if (!isMinimumOutcomeOdds) {
                    return false;
                }
                break;

            case 'allOutcomesInSportIds':
            case 'allOutcomesInTournamentIds':
            case 'allOutcomesInCategoryIds':
            case 'allOutcomesInEventTypes':
            case 'outcomeAllInEventTypes':
                var arrayValues = conditions[cond];
                if (arrayValues.length > 0) {
                    var isAllOutcomesIn = Object.keys(outcomes).every(function (itemId) {

                        var item = outcomes[itemId];
                        var outcomeProperty = null;
                        switch (cond) {
                            case 'allOutcomesInSportIds':
                                outcomeProperty = item.sportId;
                                break;
                            case 'allOutcomesInTournamentIds':
                                outcomeProperty = item.parentId;
                                break;
                            case 'allOutcomesInCategoryIds':
                                outcomeProperty = item.categoryId;
                                break;
                            case 'allOutcomesInEventTypes':
                            case 'outcomeAllInEventTypes':
                                outcomeProperty = item.eventType;
                                break;
                        }

                        if (outcomeProperty) {
                            return arrayValues.indexOf(outcomeProperty) !== -1;
                        } else {
                            return false;
                        }

                    });

                    if (!isAllOutcomesIn) {
                        return false;
                    }
                }
                break;

            case 'atLeastOneOutcomeInEventTypes':
            case 'outcomeOneInEventTypes':
                var outcomeEventTypes = conditions[cond];
                if (outcomeEventTypes.length > 0) {
                    var isAtLeastOne = Object.keys(outcomes).some(function (itemId) {
                        return outcomeEventTypes.indexOf(outcomes[itemId].eventType) !== -1;
                    });

                    if (!isAtLeastOne) {
                        return false;
                    }
                }
                break;

            case 'slipBetTypes':
                var typesArray = conditions[cond];
                if (typesArray.indexOf(type) == -1 && typesArray.length !== 0) {
                    return false;
                }
                break;
            case 'allowedBetTypes':
                var typesArray = conditions[cond].split(',').map(Number);
                if (typesArray.indexOf(type) == -1) {
                    return false;
                }
                break;
        }

    }

    return true;
};

const checkFreetbetConditionValidity = () => {
    return (dispatch, getState) => {

        const {BetSlip: {freebets, activeTab, betSlips}, Auth:{isLogged}} = getState();
        const {stake, outcomes, totalOddsWithoutSalesTaxFactor, type, freebet} = _get(betSlips, [activeTab]);
        const extraParams = [stake, outcomes, totalOddsWithoutSalesTaxFactor];
        const betslipType = _get(type, ['type']);
        extraParams.push(betslipType);

        if (!isLogged || [0,100].indexOf(betslipType) == -1) {
            return;
        }

        const validFreebets = _filter(freebets, (freebet) => {
            return freebetValidCheckCallback(freebet, ...extraParams);
        });

        dispatch(setValidFreebets(validFreebets));

        if (freebet) {
            const offerFreebetId = _get(freebet, ['offerFreebetId']);
            const freebetExistInValidFreebets = _find(validFreebets, {offerFreebetId});
            if (!freebetExistInValidFreebets) {
                dispatch(toggleFreebet(freebet));
            }
        }
    }
};


const setValidFreebets = (validFreebets) => {
    return {
        type: SET_VALID_FREEBETS,
        payload: {
            validFreebets
        }
    }
};

export {fetchFreebetByStatus, toggleFreebet, setFreebets, checkFreetbetConditionValidity};