import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import S from 'StyledAccountFreebet.js';
import Loader from 'Loader.js';
import Datepicker from 'Datepicker.js';
import Pagination from 'Pagination.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _get from 'lodash/get';
import classNames from 'classnames';
import {translation, formatMoney} from 'helpers/utilsHelper.js';
import {formatDate} from 'helpers/datesHelper.js';
import {changeDateByType, fetchFreebetsByFilter, setCurrentPage, fetchAllFreebets} from 'accountFreebetActions.js';
import ReactTooltip from 'react-tooltip';

class AccountFreebet extends Component {

    componentDidMount = async() => {
        try {
            const [activeFreebet, historyFreebet] =  await this.fetchAllFreebets();
        } catch (error) {
            console.log('fetchFreebetByFilter:' + error);
        }
    };

    handleChangeStart = (date) => {
        const {changeDateByType, endDate, limit} = this.props;
        const startDate = new Date(date);
        const params = {
            endDate,
            startDate,
            limit
        };

        changeDateByType('startDate', startDate);
        this.fetchAllFreebets(params);
    };

    handleChangeEnd = (date) => {
        const {changeDateByType, startDate, limit} = this.props;
        const endDate = new Date(date);
        const params = {
            endDate,
            startDate,
            limit
        };

        changeDateByType('endDate', endDate);
        this.fetchAllFreebets(params);
    };

    fetchAllFreebets = async(params = {}) => {
        const {fetchAllFreebets} = this.props;
        try {
            const result = await fetchAllFreebets(params);
            return result;
        } catch (error) {
            throw error;
        }
    };

    goPage = async(status, currentPage) => {
        const {fetchFreebetsByFilter, startDate, endDate, limit, setCurrentPage} = this.props;
        const params = {
            endDate,
            startDate,
            limit,
            status,
            currentPage
        };

        try {
            await fetchFreebetsByFilter(params);
            setCurrentPage(currentPage, status);
        } catch (error) {
            console.log('goPage:' + error);
        }
    };

    render() {

        const {isPending, error, startDate, endDate, freebets, limit, currencyCode} = this.props;
        const activeFreebets = _get(freebets, 'active');
        const historyFreebets = _get(freebets, 'history');
        const activeFreebetsData = _get(activeFreebets, 'data');
        const historyFreebetsData = _get(historyFreebets, 'data');

        return (
            <S.AccountFreebet>
                <S.FreebetFilters className="freebet-filters">

                    <S.FreebetFiltersDates className="freebet-filters-dates">

                        <S.DateWrapper className="date-wrapper">
                            <S.DateLabel>{translation('common_dateFrom')}</S.DateLabel>
                            <Datepicker
                                selected={startDate}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="yyyy-MM-dd"
                                cb={this.handleChangeStart}
                            />
                            <S.DateIcon className="ion ion-ios-calendar-outline"></S.DateIcon>
                        </S.DateWrapper>

                        <S.DateWrapper className="date-wrapper">
                            <S.DateLabel>{translation('common_dateTo')}</S.DateLabel>
                            <Datepicker
                                selected={endDate}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="yyyy-MM-dd"
                                cb={this.handleChangeEnd}
                            />
                            <S.DateIcon className="ion ion-ios-calendar-outline"></S.DateIcon>
                        </S.DateWrapper>

                    </S.FreebetFiltersDates>

                </S.FreebetFilters>


                <S.FreebetList>

                    {isPending ?
                        <Loader/>
                        : (
                            <>
                            <S.FreebetActiveList className="freebet-active-list">
                                <S.FreebetListHeader>{translation('account_freebets_active_header')}</S.FreebetListHeader>

                                <S.FreebetFakeTable>
                                    <S.Thead>
                                        <S.Tr>
                                            <S.Th className="th freebet_id">
                                                <span>{translation('account_freebets_freebetId')}</span>
                                            </S.Th>
                                            <S.Th className="th freebet_ammount">
                                                <span>{translation('account_freebets_freebetAmount')}</span>
                                            </S.Th>
                                            <S.Th className="th freebet_name">
                                                <span>{translation('account_freebets_freebetName')}</span>
                                                </S.Th>
                                            <S.Th className="th freebet_expirationDate">
                                                <span>{translation('account_freebets_freebetExpirationDate')}</span>
                                            </S.Th>
                                        </S.Tr>
                                    </S.Thead>
                                    <S.Tbody>
                                        {activeFreebets ?
                                            (<>
                                            {_map(activeFreebetsData, ({validTo, nameKey, offerFreebetId, freebetStake}) => {
                                                return (
                                                    <S.Tr className="row-flex" key={offerFreebetId}>
                                                        <S.Td className="td freebet-id">
                                                            <span>{offerFreebetId}</span>
                                                        </S.Td>
                                                        <S.Td className="td freebet-ammount">
                                                            <span className="ammount">{freebetStake.toFixed(2)}</span>&nbsp;
                                                            <span className="currency">{currencyCode}</span></S.Td>
                                                        <S.Td className="td freebet-name">
                                                            <span>{translation(nameKey)}</span>
                                                        </S.Td>
                                                        <S.Td className="td freebet-expirationDate">
                                                            <span>{formatDate(validTo, 'yyyy-MM-dd HH:mm')}</span>
                                                        </S.Td>
                                                    </S.Tr>
                                                )
                                            })}

                                            {_size(activeFreebetsData)>0 && (<Pagination currentPage={_get(activeFreebets,'currentPage')} limit={limit} data={activeFreebetsData} cb={this.goPage.bind(this,'active')}/>)}
                                            </>)
                                            :
                                            <S.NoResult>{translation('account_freebets_noActiveFreebets')}</S.NoResult>
                                        }
                                    </S.Tbody>
                                </S.FreebetFakeTable>

                            </S.FreebetActiveList>
                            <S.FreebetHistoryList className="freebet-history-list">
                                <S.FreebetListHeader>{translation('account_freebets_history_header')}</S.FreebetListHeader>

                                <S.FreebetFakeTable>
                                    <S.Thead>
                                        <S.Tr>
                                            <S.Th className="th freebet_id">
                                                <span>{translation('account_freebets_freebetId')}</span>
                                            </S.Th>
                                            <S.Th className="th freebet_ammount">
                                                <span>{translation('account_freebets_freebetAmount')}</span>
                                            </S.Th>
                                            <S.Th className="th freebet_name">
                                                <span>{translation('account_freebets_freebetName')}</span>
                                            </S.Th>
                                            <S.Th className="th freebet_expirationDate">
                                                <span>{translation('account_freebets_freebetExpirationDate')}</span>
                                            </S.Th>
                                        </S.Tr>
                                    </S.Thead>

                                    <S.Tbody>
                                        {activeFreebets ?
                                            (<>
                                            {_map(historyFreebetsData, ({validTo, nameKey, offerFreebetId, freebetStake}) => {
                                                return (
                                                    <S.Tr className="row-flex" key={offerFreebetId}>
                                                        <S.Td className="td freebet-id">
                                                            <span>{offerFreebetId}</span>
                                                        </S.Td>
                                                        <S.Td className="td freebet-ammount">
                                                            <span className="ammount">{freebetStake.toFixed(2)}</span>&nbsp;
                                                            <span className="currency">{currencyCode}</span></S.Td>
                                                        <S.Td className="td freebet-name">
                                                            <span>{translation(nameKey)}</span>
                                                        </S.Td>
                                                        <S.Td className="td freebet-expirationDate">
                                                            <span>{formatDate(validTo, 'yyyy-MM-dd HH:mm')}</span>
                                                        </S.Td>
                                                    </S.Tr>
                                                )
                                            })}

                                            {_size(historyFreebetsData)>0 && (<Pagination currentPage={_get(historyFreebets,'currentPage')} limit={limit} data={historyFreebetsData} cb={this.goPage.bind(this,'history')}/>)}
                                            </>)

                                            :
                                            <S.NoResult>{translation('account_freebets_noHistoryFreebets')}</S.NoResult>
                                        }

                                    </S.Tbody>

                                </S.FreebetFakeTable>
                            </S.FreebetHistoryList>
                            </>
                        )
                    }

                </S.FreebetList>

            </S.AccountFreebet>
        );
    }
}

const mapStateToProps = ({AccountFreebet:{isPending, error, startDate, endDate, freebets, limit}, Auth:{user:{currencyCode}}}, props) => {
    return {
        error,
        isPending,
        startDate,
        endDate,
        freebets,
        limit,
        currencyCode
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeDateByType: bindActionCreators(changeDateByType, dispatch),
        fetchFreebetsByFilter: bindActionCreators(fetchFreebetsByFilter, dispatch),
        setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
        fetchAllFreebets: bindActionCreators(fetchAllFreebets, dispatch),
        dispatch
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountFreebet);
