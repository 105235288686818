import styled from 'styled-components';
import StyledDatepicker from 'StyledDatepicker.js';
import StyledModal from 'StyledModal.js';
import {List, Button} from 'global/styles/styles.js';
const S = {};

S.AccountCurrentBonus = styled.div`
    width: 100%;
    padding: .8333rem 0 0;
`;
S.BonusFilters = styled.div`
    display: flex;
    justify-content: space-between;
`;
S.BonusFiltersDates = styled.div`
    display: flex;
    & > div{
         margin-right: 10px;
         &:last-of-type{
             margin-right: 0;
         }
    }
`;
S.DateWrapper = styled.div`
    width: 237px;
    height: 46px;
    display: flex;
    position: relative;
    border-top: 2px solid #eee;
    border-bottom: 2px solid #eee;
    border-right: 2px solid #eee;
    & .react-datepicker-wrapper,
    & .react-datepicker__input-container{
       display: flex;
    }
    & .react-datepicker-wrapper{
       width: calc(100% - 4.5rem);
    }
    & .react-datepicker__input-container{
       width: 100%;
    }
    
    ${StyledDatepicker.Datepicker}{
        flex: 1;
        padding-left: 10px;
        border: none;
        font-size: 17px;
        font-weight: 700;
        width: 100%;
    }
`;
S.DateLabel = styled.div`
    display: flex;
    background-color: #eee;
    font-weight: 700;
    color: #676767;
    width: 4.5rem;
    justify-content: center;
    align-items: center;
`;
S.DateIcon = styled.i`
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 33px;
    font-size: 1.67rem;
    justify-content: center;
    align-items: center;
    display: flex !important;
    pointer-events: none;
`;

S.BonusFiltersBtns = styled.div`
    display: flex;
    margin-left: 10px;
    & > a{
        margin-right: 15px;
        &:last-of-type{
            margin-right: 0;
        }
    }
`;
S.FilterBtn = styled(Button)`
    margin-top: 0;
    background-color: #eee;
    color: #bcbcbc;
    font-weight: 400;
    padding-left: 1rem;
    padding-right: 1rem;
    width: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 4px;
    display: block;
    justify-content: center;
    align-items: center;
    position: relative;
    white-space: nowrap;
    padding-top: 15px;
    text-decoration: none;
   
    &.active{
            color: #676767;
            font-weight: 700;
            text-overflow: ellipsis;
            overflow: hidden;
           
           &:after{
            content: '';
            position: absolute;
            background-color: #676767;
            height: .25rem;
            bottom: 6px;
            left: 1rem;
            right: 1rem;
           } 
    }
`;

S.BonusListHeader= styled.div`
    background-color: #a3a3a3;
    color: #fff;
    padding: 1rem;
    font-weight: 500;
    border-bottom: .1rem solid #fff;
    font-size: 1.5rem;
    font-size: 1.5rem;
`;

S.Table = styled.table`
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    margin-top: 0;
`;

S.Thead = styled.thead`
`;

S.Tbody = styled.tbody``;

S.Tr = styled.tr`
    border-top: 0;
    
    &:before,
    &:after{
     display: none !important;
    }
    
    &.no-result{
        height: 40px;
        padding: 0 1.25rem;
        line-height: 4rem!important;
        font-size: 1rem;
        background-color: #f3f3f3;
        color: #000;
        border-right: .08rem solid #fff;
        border-top: .08rem solid #fff;
      
        & > td{
            text-align: center;
        }
    }
    
`;

S.Td = styled.td`
    padding: 0 1.25rem;
    line-height: 2rem!important;
    font-size: 1rem;
    background-color: #f3f3f3;
    color: #000;
    border-right: .08rem solid #fff;
    border-top: .08rem solid #fff;
    vertical-align: middle;
    text-align: center;
     
    &.bonus-actions{
        text-align: center;
    }
    &.pagination{
        background-color: transparent;
    }
        &.wageredPercent{
        width: 15%;
    }
`;

S.Th = styled.th`
    background-color: #eaeaea;
    color: #a3a3a3;
    padding: .4rem 1.25rem;
    font-size: 1rem;
    font-weight: 400;
    border-right: .08rem solid #fff;
    line-height: 1.42857143;
    vertical-align: bottom;
    border-top: 0;
`;

S.BonusList = styled.div``;


S.NoResult = styled.tr`
    height: 40px;
    text-align: center;
`;

S.Button = styled(Button)`
    display: block;
    line-height: 2rem;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    height: 100%;
    border: 0;
    box-sizing: border-box;
    width: 100%;
    margin: 5px 0;
    &.reject{
        background-color: #bebebe;
    }
    &.accept{
        background-color: #00a651;
    }
`;

S.ActivateModal = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: -30px;
`;

S.BtnsWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

S.ButtonCancel = styled(Button)`
    color: #fff;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    padding: 6px 12px;
    background: #00a651;
    margin-right: 1rem;
    margin-top: 1rem;
`;
S.ButtonAccept = styled(S.ButtonCancel)``;
S.Title = styled.div`
    font-size: 1.2307692308rem;
    letter-spacing: .5px;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
`;
S.Description = styled.div`
    text-align: center;
`;

S.ProgressCircularWrapper = styled.div`
    width: 50%;
    margin: 0 auto;
    padding: 5px;
`;


S.ModalInfo = styled.div`
    width: 100%;
    margin-top: -44px;
`;

S.InfoTitle = styled.h4`
    color: #2d9ef5;
    text-transform: uppercase;
    font-size: 1.83rem;
    padding: 0;
    font-weight: 800;
    font-style: italic;
    float: none!important;
    text-align: center;
    margin-bottom: 2rem;
`;

S.InfoDescription = styled.div`
    text-align: center;
    color: #000;
`;

export default S;