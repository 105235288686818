import styled from 'styled-components';
import StyledLoader from 'StyledLoader.js';
import {Form, Input, Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const SubmitBtnBgColor = ({theme:{$registerFormSubmitBtnBgColor}}) => `${$registerFormSubmitBtnBgColor}`;
const SubmitBtnColor = ({theme:{$registerFormSubmitBtnColor}}) => `${$registerFormSubmitBtnColor}`;

const baseFont = `12px`;

const S = {};

S.ResponsibleGamingForm = styled(Form)`
    width: ${rem('584px', baseFont)};
    font-family: "Roboto Condensed", sans-serif;
    padding: 0 0 ${rem('20px', baseFont)};
`;

S.CloseModalButton = styled(Button)`
    position: absolute;
    right: -40px;
    top: -40px;
    padding: 0;
    width: 2.8rem;
    height: 2.8rem;
    transform: none;
    line-height: 1;
    margin-right: -3rem;
    margin-top: -3rem;
    border-radius: 50%;
    bottom: 0;
    margin: .83rem 0;
    background: #fff url(/images/svg/header_close_black.svg) no-repeat 50% 50%;
    background-size: 1.47rem 1.47rem;
    cursor: pointer;
`;

S.Warning = styled.div`
    color: #CE0006;
    font-size: ${rem('12px', baseFont)};
    text-align: center;
`;

S.LoaderWrapper = styled.div`
    width: ${rem('584px', baseFont)};
`;

S.Row = styled.div`
    width: 100%;
`;

S.Cover = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 5;
    
    & ${StyledLoader.LoaderWrapper}{
    }
`;


S.Label = styled.label`
    color: #787d83 !important;
    font-size: ${rem('14px', baseFont)};
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
`;

S.FakeChbox = styled.div`
    width: ${rem('16px', baseFont)};
    height: ${rem('16px', baseFont)};
    border: 1px solid #E8E8E8;
    border-radius: 3px;
    margin-right: .83rem;
    cursor: pointer;
    margin-top: 2px;
    flex-shrink: 0;
    pointer-events: none;
    z-index: 3;
    background-color: #FCFCFC;
    position: relative;
`;

S.CheckMark = styled.span`
    position: absolute;
    left: 3px;
    top: 0;
    font-weight: bold;
    color: #787d83;
`;

S.Input = styled(Input)`
    box-shadow: none;
    color: #262626;
    font-size:  ${rem('14px', baseFont)};
    padding: 2.1rem ${rem('35px', baseFont)} 2.1rem 1rem;
    background-color: #FCFCFC;
    border: 1px solid #E8E8E8;
    border-radius: 3px;
    display: block;
    text-align: left;
    width: 100%;
    height: ${rem('54px', baseFont)};
    &:-webkit-autofill {
                background-color: #FCFCFC;
                -webkit-box-shadow: 0 0 0rem 83.33rem #FCFCFC inset;
                &:hover,
                &:focus {
                  -webkit-box-shadow: 0 0 0rem 83.33rem #FCFCFC inset;
                }
     }
     
    &.has-error{
        border: .08rem solid red;
    }
    &.is-valid{
        border: .08rem solid #63dd7d;
    }
    
    &[type="number"]{
      -moz-appearance: textfield;
    }
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
   
`;

S.SubmitBtn = styled(Button)`
    background-color: ${SubmitBtnBgColor};
    height: ${rem('44px', baseFont)};
    font-size: 1rem;
    font-weight: 600;
    padding: 0;
    width: ${rem('125px', baseFont)};
    margin-bottom: ${rem('5px', baseFont)};
    margin-top:  ${rem('44px', baseFont)};
    color: ${SubmitBtnColor};
    border: 2px solid #CE0006;
    border-radius: 3px;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    display: block;
    font-size: ${rem('14px', baseFont)};
    cursor: pointer;
    &:hover{
        color: #fff;
        background-color: #CE0006;
    }
`;

S.Error = styled.div`
    color: red;
    float: left;
    width: 100%;
    margin-top: 0;
    border-radius: 0 0 2px 2px;
    padding: 2px .85rem 2px .85rem;
    margin-left: ${rem('15px', baseFont)};
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
    margin-bottom: ${rem('20px', baseFont)};
`;

S.Select = styled.div`
    cursor: pointer;
    align-items: stretch;
    display: block;

    & .react-select__menu{
        margin-top: 0;
        z-index: 5;
        
        & .react-select__menu-list{
             padding: 0;
        
            & > div{
                cursor: pointer;
            }
        }
    }
    
    & .react-select__control{
        box-shadow: none;
        min-height: 0;
        height: 4.5rem;
        border: 1px solid #E8E8E8;
        align-items: flex-start;
        background-color: #FCFCFC;
        
        & .react-select__dropdown-indicator{
            padding: 3px;
        }
        
        & .react-select__indicator-separator{
            display: none;
        }
    
        & .react-select__value-container{
             margin-top: ${rem('16px', baseFont)};
             margin-left: ${rem('11px', baseFont)};
             margin-right: ${rem('15px', baseFont)};
            & .react-select__single-value{
                color: #262626;
                font-size: 1.1666666666666667rem;
            }
        }
    
    }

`;

S.Currency = styled.div`
    position: absolute;
    right: 8px;
    top: 20px;
`;

S.Hour = styled(S.Currency)`
    text-transform: lowercase;
`;

S.InputWrapper = styled.div`
    position: relative;
    margin-bottom: 2.25rem;
   
    &.with-checkbox{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: ${rem('10px', baseFont)};
        
        ${S.Error}{
           width: 100%;
        }  
      ${S.Label}{
            flex: 1;
            font-size: 1.17rem;
            font-weight: 400;
            padding-top: 0;
            color: #787d83;
            display: flex;
            position: relative;
            left: 0;
            cursor: pointer;
            top: 0;
            
            & a{
                 color: #CE0006;
            }
        }
         ${S.Input}{
            position: absolute!important;
            padding: 0!important;
            border: 0!important;
            overflow: hidden;
            z-index: 0;
            left: 3px;
            top: 1px;
            height: 14px;
            width: auto;
         }
    }
`;

S.Header = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: ${rem('35px', baseFont)};
  height:  ${rem('80px', baseFont)};
  border-bottom: 1px solid #E8E8E8;
  padding: 0 0 ${rem('9px', baseFont)} ${rem('87px', baseFont)};
  position: relative;
`;

S.Title = styled.h2`
   margin: 0;
   color: #262626;
    font-size: ${rem('24px', baseFont)}
    padding: 0 0 0 10px;
    font-weight: 800;
    float: none!important;
    text-align: center;
    text-tranaform: uppercase;
`;

S.Body = styled.div`
    padding: 0 ${rem('66px', baseFont)};
`;

S.BodyInner = styled.div`
    width: ${rem('325px', baseFont)};
    display: flex;
    flex-direction: column;
`;

S.Footer = styled.div`
    display: flex;
    flex-direction: column;
`;

S.HelpBtn = styled.div`
    color: #777D85;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: ${rem('20px', baseFont)};
`;

S.HelpIcon = styled.div`
    cursor: pointer;
    width: ${rem('19px', baseFont)};
    height: ${rem('19px', baseFont)};
    background-color: #CBD0D6;
    border-radius: 50%;
    display: flex;
    color: #fff;
    margin-right: ${rem('10px', baseFont)};
    justify-content: center;
    align-items: center;
    
    &:before{
     background-color: transparent;
    }
`;

S.Info = styled.div`
 font-size: ${rem('14px', baseFont)};
 color: #777D85;
 margin-left:  ${rem('15px', baseFont)};
 margin-bottom:  ${rem('20px', baseFont)};
 & a {
    color: #CE0006;
 }
`;

export default S;