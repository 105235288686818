import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';
import _get from 'lodash/get';
import _findIndex from 'lodash/findIndex';
import {UPDATE_FAVOURITE_LEAGUES, SET_FAVOURITE_LEAGUES, UPDATE_FAVOURITE_BY_TYPE, SET_FAVOURITE_BY_TYPE, REMOVE_FAVOURITE_BY_TYPE} from 'favouritesActions.js';

let initialState = {
    favouritesByType: {},
    favouriteLeagues: [],
    isPending: true,
    error: null
};

export default typeToReducer({
    [UPDATE_FAVOURITE_LEAGUES]: (state, {payload:{leagueId, status}}) => {
        let favouriteLeagues = state.favouriteLeagues;
        if (status) {
            favouriteLeagues = update(favouriteLeagues, {$push: [leagueId]});
        } else {
            const indexOfLeague = favouriteLeagues.indexOf(leagueId);
            if (indexOfLeague != -1) {
                favouriteLeagues = update(favouriteLeagues, {$splice: [[indexOfLeague, 1]]})
            }
        }
        return {...state, favouriteLeagues}
    },
    [SET_FAVOURITE_LEAGUES]: (state, {payload:{favouritesLeaguesList}}) => {
        const favouriteLeagues = update(state.favouriteLeagues, {$set: favouritesLeaguesList})
        return {...state, favouriteLeagues}
    },
    [SET_FAVOURITE_BY_TYPE]: (state, {payload:{data, type}}) =>{
        const favouritesByType = update(state.favouritesByType, {[`${type}`]: (prevData) => {
                const favouritesByType = data||[];
                return update(type, {$set: favouritesByType});
            }});
        return {...state, favouritesByType};
    },
    [UPDATE_FAVOURITE_BY_TYPE]: (state, {payload: {id, name, status, type}}) => {
        let favouritesByType = state.favouritesByType;
        if (status) {
            favouritesByType = update(favouritesByType, {[type]: {$push: [{id, name}]}});
        } else {
            const favourites = _get(favouritesByType, [type]);
            const indexOfItem = _findIndex(favourites, {id});
            if (indexOfItem != -1) {
                favouritesByType = update(favouritesByType, {[type]: {$splice: [[indexOfItem, 1]]}});
            }
        }
        return {...state, favouritesByType}
    },
    [REMOVE_FAVOURITE_BY_TYPE]: (state, {payload:{type}}) => {
        const favouritesByType = update(state.favouritesByType, {$unset: [`${type}`]});
        return {...state, favouritesByType}
    },

}, initialState);
