import styled from 'styled-components';
import StyledDatepicker from 'StyledDatepicker.js';
import {Button} from 'global/styles/styles.js';

const S = {};

S.AccountWallet = styled.div`
    width: 100%;
    padding: 1rem 1.75rem;
    background-color:#DEDEDE;
`;

S.WalletTransactionsList = styled.div`
    background-color: #f3f3f3;
`;

S.NoResult = styled.div`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

S.WalletTransactions = styled.div`
`;

S.FakeTableHead = styled.div`
    display: flex;
    gap: 0.08rem;
    background-color: #A5A5A5;

    & > div{
        padding: 0.75rem;
        border: none;
        background-color: #282828;
        font-size: 1.2rem;
        color: #A5A5A5;
        font-weight: 700;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.wallet-status{
            width: 25%;
        }
        &.wallet-type{
           width: 25%;
         }
        &.wallet-amount{
            width: 25%;
        }
        &.wallet-date{
            width: 25%;
        }
    }
`;

S.WalletFilters = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
`;
S.WalletFiltersDates = styled.div`
    display: flex;
    justify-content: space-around;
    margin:0 auto;
    & > div{
         margin-right: 3.16rem;
         &:last-of-type{
          margin-right: 0;
         }
    }
`;
S.DateWrapper = styled.div`
    position: relative;
    width: 12.08rem;
    height: 3.83rem;
    display: flex;
    flex-direction:column;
    align-items:center;
    & .react-datepicker-wrapper,
    & .react-datepicker__input-container{
       display: flex;
    }
    & .react-datepicker-wrapper{
       width: calc(100% - 4rem);
    }
    & .react-datepicker__input-container{
       width: 100%;
    }
    
    ${StyledDatepicker.Datepicker}{
        flex: 1;
        width: 100%;
        font-size: 0.9rem;
        font-weight: 700;
        color: #898989;
    }
`;
S.DateLabel = styled.div`
    display: flex;
    font-size: 1.2rem;
    font-weight: 700;
    color: #282828;
    width: 4.5rem;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.4167rem;
`;

S.DateIcon = styled.img`
    height: 1.38rem;
    width: 1.38rem;

    path {
        fill: #898989;
    }
`;

S.FilterDayBtn=styled.button`
border: 0.17rem solid #fff;
padding:0.75rem 1.92rem;
background-color:${props => props.isActive ? "#FAE100" : "#898989"};
font-size:1.2rem;
font-weight:bold;
color:${props => props.isActive ? "#000" : "#fff"};

& + & {
    border-left: 0;
}
&.--first{
    border-radius:1.75rem 0 0 1.75rem ;
}
&.--last{
    border-radius: 0 1.75rem 1.75rem 0 ;
}
`

S.FilterDayBtnsWrapper=styled.div`
    display: flex;
    justify-content:center;
    align-items:center;
    width:100%;
    margin-top:2.5rem;
`
S.FilterBtnsLabel=styled.div`
    display: flex;
    align-items: center;
    gap: 0.83rem;
    overflow:hidden;
    margin: 0 0 0.83rem 0;
    width:100%;
    font-size: 1.2rem;
    font-weight:bold;
`

S.FilterBtnsLabelAfter=styled.div`
    flex: 1;
    height: 0.08rem;
    background-color: #898989;
`

S.WalletFiltersBtns = styled.div`
    display: flex;
    width:100%;
    flex-wrap:wrap;
    font-size: 0.9rem;
    padding: 1.08rem 0;
    & > button{
        margin-right: 0.83rem;
        &:last-of-type{
            margin-right: 0;
        }
    }
`;
S.FakeTableBody = styled.div`
    overflow: auto;
    height: 16.66rem;
`
S.DateInputWrapper = styled.div`
display:flex;
justify-content: space-between;
align-items:center;
border-radius:1.75rem;
padding: 0.75rem 1.25rem 0.75rem 1.75rem;
background-color:#fff;
color: #898989;
`;

S.FilterBtn = styled(Button)`
    padding: 0.8333rem 1.6667rem;
    border-radius: 1.75rem;
    background-color: #fff;
    font-weight: bold;
    font-size:1.2rem;

    &.active{
        color: #fff;
        background-color: #282828;
    }
`;


export default S;