import styled from 'styled-components';
import {Button} from 'global/styles/styles.js';
const S = {};

S.UnloggedPanel = styled.div`
    display: flex;
`;
S.RegisterButton = styled(Button)`
    display:flex;
    justify-content:center;
    align-items:center ;
    background-color:${({theme:{$topMenuRegisterBgcColor}})=> $topMenuRegisterBgcColor};
    transition: .2s all linear;
    color: ${({theme:{$topMenuRegisterTextColor}})=> $topMenuRegisterTextColor};
    font-weight: 700;
    font-size: 1.25rem;
    font-family: Roboto,sans-serif;
    border-radius: .42rem;
    padding: 0.375rem 1.25rem;
    border: none;
    border-radius: 1.3125rem;
`;
S.LoginButton = styled(Button)`
    display:flex;
    justify-content:center;
    align-items:center ;
    margin:0 1rem 0 0.4375rem ;
    transition: .2s all linear;
    color: ${({theme:{$topMenuUnloggedBtnColor}})=> $topMenuUnloggedBtnColor};
    border:${({theme:{$topMenuUnloggedBtnColor}})=>`2px solid ${$topMenuUnloggedBtnColor}`};
    font-weight: 700;
    font-size: 1.25rem;
    font-family: Roboto,sans-serif;
    border-radius: .42rem;
    padding: 0.375rem 1.25rem;
    background: none;
    border-radius: 1.3125rem;
`;

S.ButtonsWrapper = styled.div`
    display: flex;
`;


export default S;