import React, {Component} from 'react';
import _size from 'lodash/size';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import DropdownMenu from 'DropdownMenu.js';
import {selectLeagueById, deselectLeagueById, changeSportId,fetchPopularCategories} from 'categoriesActions.js';
import {translation} from 'utilsHelper.js';
import {dropdownMenuSelector} from 'categoriesSelector.js';
import S from 'StyledLeftMenu.js';

class LeftMenu extends Component {

    static propTypes = {};

    static getDerivedStateFromError = (error) => {
        return {hasError: true};
    };

    state = {hasError: false};

    componentDidMount = () => {
        try {
           const { fetchPopularCategories, popularCategories } = this.props;
           if(!_size(popularCategories)){
            fetchPopularCategories();
           }
        } catch (error) {
            console.log('fetchCategories error:', error);
        }
    }

    render() {
        const {isPending, popularCategories, categories,...rest} = this.props;
        const {hasError} = this.state;
        if (hasError) {
            return <div>Error fallback component!</div>
        }
        if (isPending) {
            return <div>Loading ...</div>
        }
        const popularList = []
        const foundLvl1 = []
        popularCategories.forEach(item=>{
            if(item.level == 2 ){
                const foundCategoryLvl1 = categories.find(element => element.categoryId == item.sportId)
                const foundCategoryLvl2 = foundCategoryLvl1.children.find(element => element.categoryId == item.categoryId)
                const obj = {...foundCategoryLvl1}
                popularList.push(foundCategoryLvl2)
                if(!foundLvl1.some(item=>item.sportId == obj.sportId)){
                    foundLvl1.push(obj)
                }
            }
        })
        foundLvl1.forEach(item=>{
            let items = popularList.filter(categoryLvl2 => categoryLvl2.sportId === item.categoryId)
            item.children = items
        })
        return (
            popularCategories.length ? (
                <>
                  <span className='categoryGroupLabel'>
                    <span className='categoryGroupLabel__text'>{translation('popular_sports')}</span>
                  </span>
                  <S.LeftMenu className="left-menu">
                    <DropdownMenu config={foundLvl1} allCategories={categories} isPopular={true} {...rest} />
                  </S.LeftMenu>
                </>
              ) : null
        );
    }
}

const mapStateToProps = (state, props) => {
    const {Categories:{isPending, error, selectedLeagues, prevSortId, categories:orgCategories},PopularCategories:{popularCategories}} = state;
    const categories = dropdownMenuSelector(state, props);

    return {
        popularCategories,
        categories,
        selectedLeagues,
        isPending,
        error,
        prevSortId,
        orgCategories
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        selectLeagueById: bindActionCreators(selectLeagueById, dispatch),
        deselectLeagueById: bindActionCreators(deselectLeagueById, dispatch),
        changeSportId: bindActionCreators(changeSportId, dispatch),
        fetchPopularCategories: bindActionCreators(fetchPopularCategories, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu);
