import React from 'react';
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ProgressCircularBar = ({percentage}) => {
    return (
        <CircularProgressbar
            percentage={percentage}
            text={`${percentage}%`}
        />
    );
};

export default ProgressCircularBar;
