import axios from 'axios';

export default () => {
    return {
      getCFLocationData: () => {
        return axios.get('https://www.cloudflare.com/cdn-cgi/trace');
      },
      getStaticConf: () => {
        return axios.get(`${process.env.STATIC_URL}/conf/conf.json`);
      }
    }
};
