import typeToReducer from 'type-to-reducer';

import {
    NEW_CUSTOMER_DATA_BY_MY_ID
} from 'customerDataByMyIdActions.js';


let initialState = {
    customerDataByMyId: {},
    isPending: true,
    error: null
};

export default typeToReducer({

    [NEW_CUSTOMER_DATA_BY_MY_ID]: {
        PENDING: (state, action) => {
            return {...state}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {customerDataByMyId}}) => {
            return {...state, isPending: false, customerDataByMyId};
        }
    },

}, initialState);