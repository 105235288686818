import styled,{css} from 'styled-components';
import {Form, Input, Button} from 'global/styles/styles.js';
import StyledLoader from 'StyledLoader.js';
const S = {};

S.CashoutForm = styled(Form)`
    width: 100%;
    margin-top: -24px;
    position: relative;
`;

S.Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

S.Label = styled.label`
    color: #000!important;
    font-size: 1.1rem;
    font-weight: 400;
    margin-bottom: 0;
`;

S.InputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    margin-left: 10px;
`;

S.Input = styled(Input)`
    box-shadow: none;
    border-radius: .42rem;
    border: .08rem solid #e1e1e1;
    color: #000;
    font-size: 1.5rem;
    background-color: #f6f6f6;
    display: block;
    width: 100px;
    text-align: right;
    height: 34px;
    padding: 0px;
    padding-right: 5px;
    padding-bottom: 1px;
    &:disabled {
        border: none;
        background-color: #fff;
    }
`;

S.CurrencyCode = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
`;

S.SubmitBtn = styled(Button)`
    border-radius: 21px;
    background-color: #00a651;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    font-size: 1.1666rem;
    cursor: pointer;
    width: 9.083rem;
    height: 36px;
    text-transform: uppercase;
    ${({disabled})=> {
        return (disabled == true) && `
                background: #eee;
                opacity: 1;
                color: #676767
        `;
    }}
`;

S.Error = styled.div`
    color: red;
    margin: 2px 0 0;
    position: absolute;
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
`;

S.Stake = styled.div`
    font-weight: 600;
    color: #000;
    font-size: 1.1666rem;
    text-align: right;
`;

S.PossibleReturn = styled(S.Stake)``;

S.Button = styled(Button)`
    background: #00a651;
    border-radius: .33rem;
    border: 0;
    color: #fff;
    font-size: 1.1666rem;
    cursor: pointer;
    padding: .66rem 2.67rem;
    width: 9.083rem;
    font-weight: 600;
`;

S.ConclusionMsg = styled.div`
    width: 100%;
    text-align: center;
    border-radius: 4px;
    position: relative;
    border: none;
    font-weight: 600;
    color: #000;
    font-size: 1.1666rem;
`;

S.Overlay = styled.div`
    position: absolute;
    left: 0;
    top: -1px;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
    display: flex;
     z-index: 1;
     & ${StyledLoader.LoaderWrapper}{
        position: absolute;
    }
`;

S.VerticalLine = styled.div`
    height: 1px;
    width: 100%;
    background-color: #FAE100;
    margin-top: 20px;
    margin-bottom: 20px; 
`

S.SuccessIcon = styled.i`
    width: 50px;
    height: 50px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(/images/svg/cashout-success-icon2.svg);
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

export default S;