import React, {useRef, useState} from 'react';
import S from 'StyledForgotPasswordVerificationForm.js';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {translation} from 'helpers/utilsHelper.js';
import ModalOpacity from 'ModalOpacity.js';
import useToggle from 'UseToggle.js'


// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    const errors = validation(values, validators.RESET_PASSWORD);
    return errors;
};

const CODE_BLOCK_COUNT = 4;

const renderField = ({
    input,
    label,
    type,
    errors,
    meta: {touched, error, warning}
}) => {
    return (
        <S.Row>
            <S.InputWrapper>
                <S.Input {...input} type={type} error={errors} required="required"/>
                <S.Label>{label}</S.Label>
                {touched && ((error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};



const ForgotPasswordVerificationForm = ({resetPasswordOnSubmit, toggleForgetPasswordVerificationModal,forgotPasswordNumber,  handleSubmit, pristine, submitting, error}) => {

    const [showSuccessModal, toggleSuccessModal] = useToggle(false);
    const inputsRef = useRef(new Array(CODE_BLOCK_COUNT));
    const [verificationCode, setVerificationCode] = useState(new Array(CODE_BLOCK_COUNT))

    const onFormSubmit = async(values) => {

        const postValues = {
            mobile: forgotPasswordNumber,
            password: values.newPassword,
            verificationCode: verificationCode.join(''),
        }
        try {
            await resetPasswordOnSubmit(postValues);
            toggleForgetPasswordVerificationModal();
        } catch (err) {
            throw new SubmissionError({_error: err});
        }
    };
    
    const codeBlockHandler = (e , index) => {
        const code = verificationCode;
        const value = e.target.value
        if (value) {
            code[index] = value
            setVerificationCode(code);
            if(index < CODE_BLOCK_COUNT-1 ){
            inputsRef.current[index + 1].focus();
            }
        }
    }

    return (
        <S.ForgotPasswordVerificationForm method="post" onSubmit={handleSubmit(onFormSubmit)}>

            <ModalOpacity isOpen={showSuccessModal} toggleOpen={toggleSuccessModal} showHeaderIcon={false}
                          showHeaderTitle={false}>
                <S.SuccessModal>
                    <S.SuccessModalTitle>{translation('forgotPasswordForm_successTitle')}</S.SuccessModalTitle>
                    <S.SuccessModalMessage>{translation('forgotPasswordForm_resetPasswordSuccess')}</S.SuccessModalMessage>
                </S.SuccessModal>
            </ModalOpacity>

            <S.Info>{translation('forgotPasswordVerificationForm_newPassword')}</S.Info>

            <S.PinCodeWrapper>
            {Array(CODE_BLOCK_COUNT)
              .fill(undefined)
              .map((_, i) => {
                return (
                    <S.PinInput
                    tabIndex={i + 1}
                    key={`block-code-${i}`}
                    type="text"
                    maxLength={1}
                    autoComplete="off"
                    ref={(e) => {
                        if (e) inputsRef.current[i] = e
                        }
                      }
                    onKeyDown={(e) =>
                      !/^(\d|Backspace|Delete|Tab)$/.test(e.key) &&
                      e.preventDefault()
                    }
                    onChange={(e) => codeBlockHandler(e, i)}
                  />
                )
              })}
          </S.PinCodeWrapper>

            <S.Label>{translation('forgotPasswordVerificationForm_newPassword')}</S.Label>

            <Field
                name="newPassword"
                type="password"
                errors={error}
                component={renderField}
            />

            <S.Label>{translation('forgotPasswordVerificationForm_confirmPassword')}</S.Label>
            <Field
                name="confirmPassword"
                type="password"
                errors={error}
                component={renderField}
            />

            {error && <S.SubmissionError className="submission-error">{error}</S.SubmissionError>}

            <S.SubmitBtn type="submit" disabled={pristine || submitting}>{translation('forgotPasswordForm_recoverPassword')}</S.SubmitBtn>

        </S.ForgotPasswordVerificationForm>
    );
};

export default reduxForm({
    form: 'forgotPasswordVerification',
    validate
})(ForgotPasswordVerificationForm)