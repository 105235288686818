import styled from 'styled-components';
import {List, Button} from 'global/styles/styles.js';
const S = {};

S.BetHistoryItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

S.BetHistoryItem = styled.div`
    display: flex;
    border-bottom:1px solid #898989;
    font-weight:700;
    &.placed{
        color:#2B48B9;
    }
    &.won{
        color:#43A008;
    }
    &.lost{
        color: #CA2717;
    }
    & > div{
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 1rem;
            text-align: center;
            padding: .67rem;
            min-height: 3.33rem;
            color: inherit;
            align-items: center;
           
           &.transaction-amount-cashout{
                flex: 1 1 0;
           }
            
        &.transaction-id{
            flex: 1 1 0;
        }
        &.transaction-amount{
            flex:1 1 0;
        }
        
        &.transaction-date-cashout,
        &.transaction-date{
            flex: 1.5 1 0;
        }
        
        &.transaction-stake-cashout,
        &.transaction-stake{
            flex: 1 1 0;
        }
        &.transaction-win{
            flex: 1 1 0;
        }
        &.transaction-confirmed-win{
            flex: 1 1 0;
        }
        &.transaction-bonus{
            flex: 1 1 0;
        }
        &.transaction-type{
            flex: 1 1 0;
        }
        &.relative {
            position: relative;
        }
        &.transaction-cashout{
            flex: 1 1 0;
            display: flex;
            align-items: center;
            justify-content: center;
            
            & > button{
                background: #166931;
                text-transform: uppercase;
                border-radius: .33rem;
                border: 0;
                color: #fff;
                cursor: pointer;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-top: 0;
                background-color: #00a651;
                font-weight: 700;
                font-size: .85rem;
                padding: .15rem 1rem;
                height: 1.8rem;
            }
        }
        &.transaction-rebet{
            flex: 1 1 0;
            display: flex;
            align-items: center;
            justify-content: center;
        
            & button{
                vertical-align: middle;
                color: #fff;
                background-image: url(/images/svg/rebet.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 2.25rem;
                padding: .15rem 1.75rem;
                margin-top: 0;
                background-color: #00a651;
                font-weight: 700;
                font-size: .85rem;
                height: 1.8rem;
                border: none;
                border-radius: 4px;
            }
        
        }
        &.transaction-status{
            position:relative;
            flex: 1 1 0;
            border-right: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: inherit;
        
              &.default {
              }
    
              &.pending_acceptation {
              }
    
              &.won {
                font-weight: bold;
              }
    
              &.cashout {
                background-color: $lightGreenMain;
              }
    
              &.cashback {
              }
    
    
              &.safebet {
              }
              
              & button{
                    border: none;
                    font-weight: 700;
                    vertical-align: middle;
                    display: block;
                    width: 25%;
                    background: 0 0;
                    padding: 0;
                    height: auto;
                    margin-top: 0;
                    color:inherit;
                    font-size: 1.25rem;
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    &> i{
                        position:absolute;
                        right:15px;
                        font-size:20px;
                    }
                    & > span{
                        font-size: 12px;
                        font-weight: 700;
                    }
                    
              }
        
        }
        
    }
    span.custom-label {
        position: absolute;
        left: 0;
        height: 1.3rem;
        line-height: 1.3rem;
        padding-left: .25rem;
        padding-right: .25rem;
        font-weight: bold;
        color: #ffffff;
        width:3.5rem;
        z-index: 2;
        font-size: 11px;
        font-style: italic;

        &.freebet {
          background-color: #FAE100;
          bttom: 0rem;
        }

        &.cashout {
          background-color: #019A44;
          bottom: 0rem;
        }
      }

    span.custom-angle {
        border-style: solid;
        border-width: 1.3rem 0 0 1.3rem;
        position: absolute;
        left: 3.5rem;
        z-index: 1;

        &.freebet {
          bttom: 0;
          border-color: transparent transparent transparent #FAE100;
        }

        &.cashout {
          bottom: 0;
          border-color: transparent transparent transparent #019A44;
        }
    }
`;

S.ErrorModal = styled.div`
     width: 100%;
`;

S.ErrorModalTitle = styled.h4`
    color: red;
    text-transform: uppercase;
    font-size: 1.83rem;
    padding: 0;
    font-weight: 800;
    font-style: italic;
    float: none!important;
    text-align: center;
    margin-bottom: 3.42rem;
`;

S.ErrorModalMessage = styled.p`
    text-align: center;
    color: #000;
    margin: 0;
    font-size: 1.2rem;
    letter-spacing: .2px;
    font-weight: 400;
`;

export default S;