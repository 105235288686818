import styled from 'styled-components';
import {Form, Input, Button} from 'global/styles/styles.js';
const S = {};

S.CloseIconContainer = styled.div`
background: transparent url(/images/svg/header_close_white.svg) no-repeat 50% 50%;
`;

S.LoginForm = styled(Form)`
width: 100%;
padding:1.5625rem;
`;

S.Row = styled.div`
    margin-bottom: 1rem;
`;

S.Label = styled.label`
    margin:0 0 1.0833rem 0.8333rem;
    pointer-events: none;
    transition: .2s;
    color: #111111!important;
    font-size: 1.1rem;
    font-weight: 700;
`;

S.InputWrapper = styled.div`
    position: relative;
`;

S.Input = styled(Input)`
    box-shadow: none;
    border: .08rem solid #e1e1e1;
    color: #000;
    border-radius:1.75rem;
    font-size: 1.33rem;
    padding: 1.6rem 1.75rem;
    background-color: #f6f6f6;
    display: block;
    width: 100%;
    height: 34px;
    &:valid{
        & + ${S.Label}{
        margin-top: -1.42rem;
        font-size: 1.08rem;
        }
    }
`;
S.ForgottenPassword=styled.div`
display:flex;
align-items:center;
margin: 0 0 1.3125rem 0.8333rem;
span{
    display:flex;
    font-size:1rem;
    font-weight:600;
}
`
S.CreateNewAccount=styled.div`
font-weight: 500;
font-size: 1rem;
line-height: 1.33rem;
color: #0047B9;
border: none;
padding: 0;
width: 100%;
margin: 0 0 0 0.3333rem;
text-decoration:underline #0047B9;
cursor: pointer;
`;
S.CreateAccountContainer = styled.div`
text-align: center;
span{
    font-size:1rem;
    font-weight:600;
}
`
S.SubmitBtn = styled(Button)`
    border-radius: 3rem;
    background-color: ${({theme:{$loginFormSubmitBtnBgColor}}) => $loginFormSubmitBtnBgColor};
    font-size: 1rem;
    font-weight: 600;
    height: 42px;
    width: 100%;
    margin-bottom: 2.18rem;
    text-transform: uppercase;
    margin-top: .69rem;
    color: ${({theme:{$loginFormSubmitBtnColor}}) => $loginFormSubmitBtnColor};
    min-width: 20%;
    cursor: pointer;
    box-shadow: 3px 3px 6px #0000004D;
`;

S.Error = styled.div`
    color: red;
    margin: 0.4rem 0 1.0833rem 0.8333rem;
`;

S.LoginLink = styled.span`
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.33rem;
    color: #0047B9;
    border: none;
    padding: 0;
    width: 100%;
    margin: 0 0 0 0.2222rem;
    text-decoration:underline #0047B9;
    cursor: pointer;
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
`;

export default S;