import React from 'react';
import {NavLink} from 'react-router-dom';
import S from 'StyledAccountMenu.js';
import _map from 'lodash/map';
import accountMenuItems from 'accountMenuItems.enum.js';
import {translation} from 'helpers/utilsHelper';
import LogoutIcon from 'logout_icon.svg'


const AccountMenu = ({logOutUser,freebetCount,handleActiveTabChange}) => {
    return (
        <S.AccountMenu className="account-menu">
            {_map(accountMenuItems, ({translationKey, href, id,file, ...rest}, itemName) => {
                return (
                    <S.AccountMenuItem key={itemName} className="account-menu-item">
                        <S.AccountMenuLink
                        id={id ? id : ''} {...rest} 
                        as={NavLink} to={href} 
                        exact
                        activeClassName="active"
                        onClick={()=>handleActiveTabChange(translation(translationKey))}>
                        <S.Image as="div" dangerouslySetInnerHTML={{__html: file}}/>    
                            {translation(translationKey)}
                            {(itemName=='FREEBETS') && <S.FreebetCount>{freebetCount}</S.FreebetCount>}
                            </S.AccountMenuLink>
                    </S.AccountMenuItem>
                )
            })}
            <S.AccountMenuItem>
            <S.AccountMenuLink
                        activeClassName="active"
                        onClick={()=>logOutUser(true)}>
                            <S.Image as="div" dangerouslySetInnerHTML={{__html:LogoutIcon }}/>    
                                {translation('accountMenu_sidebar_logout')}
                            </S.AccountMenuLink>
            </S.AccountMenuItem>
        </S.AccountMenu>
    );
};

export default AccountMenu;
