export const CHANGE_DATE_BY_TYPE = 'CHANGE_CASHBACK_DATE_BY_TYPE';
export const CHANGE_ACTIVE_TAB = 'CHANGE_CASHBACK_ACTIVE_TAB';
export const FETCH_CASHBACK_LIST = 'FETCH_CASHBACK_LIST';
export const SET_CURRENT_PAGE = 'SET_CASHBACK_CURRENT_PAGE';
export const CHANGE_ACTIVE_FILTER_BTN = 'CHANGE_CASHBACK_ACTIVE_FILTER_BTN';

const setCurrentPage = (currentPage)=>{
    return {
        type: SET_CURRENT_PAGE,
        payload: {currentPage}
    }
};

const changeDateByType = (dateType, date) => {
    return {
        type: CHANGE_DATE_BY_TYPE,
        payload: {
            dateType,
            date
        }
    }
};

const changeActiveTab = (status) => {
    return {
        type: CHANGE_ACTIVE_TAB,
        payload: {
            status
        }
    }
};

const changeActiveFilterBtn = (filterBtnSelected) => {
    return {
        type: CHANGE_ACTIVE_FILTER_BTN,
        payload: {
            filterBtnSelected 
        }
    }
};

const fetchCashbackByFilter = (config) => {
    return async(dispatch, getState, {AccountCashbackApi}) => {
        dispatch(fetchCashbackListPending());

        try{
            const {code, data} = await AccountCashbackApi.getCashbackByFilter(config);
            dispatch(fetchCashbackListSuccess(data));
            return data;
        }catch(error){
            dispatch(fetchCashbackListFailure(error));
            throw error;
        }
    }
};

const fetchCashbackListSuccess = (data) => {
    return {
        type: `${FETCH_CASHBACK_LIST}_SUCCESS`,
        payload: {data}
    }
};

const fetchCashbackListPending = () => {
    return {
        type: `${FETCH_CASHBACK_LIST}_PENDING`
    }
};

const fetchCashbackListFailure = (error) => {
    return {
        type: `${FETCH_CASHBACK_LIST}_FAILURE`,
        payload: {error}
    }
};

export {changeDateByType, changeActiveTab, fetchCashbackByFilter, setCurrentPage, changeActiveFilterBtn}