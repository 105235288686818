export const FETCH_LIVES = 'FETCH_LIVES';

const fetchLivesByGames = ({action = 'major', eventId = null}={}) => {
    return async(dispatch, getState, {LiveApi}) => {
        try {
            let requestAction = null;
            switch (action) {
                case 'major':
                    requestAction = LiveApi.liveEventsMajorUrl;
                    break;
                case 'main':
                    requestAction = LiveApi.liveEventsMainUrl;
                    break;
                case 'multi':
                    requestAction = LiveApi.liveEventsMultiUrl;
                    requestAction = requestAction.bind(eventId);
                    break;
            }
            const {code, data} = await requestAction();
            dispatch(fetchLivesSuccess(data, action));

            return data;
        } catch (error) {
            throw error;
        }
    }
};

const fetchLivesSuccess = (data, type) => {
    return {
        type: `${FETCH_LIVES}_SUCCESS`,
        payload: {data, type}
    }
};

const fetchLivesFailure = (error) => {
    return {
        type: `${FETCH_LIVES}_FAILURE`,
        payload: {error}
    }
};


export {fetchLivesByGames}