import React, {useState, useRef, useEffect} from 'react';
import {connect} from 'react-redux';
import S from 'StyledCashoutForm.js';
import {Field, reduxForm, SubmissionError}  from 'redux-form';
import {translation, formatMoney} from 'helpers/utilsHelper.js';
import Box from 'react-styled-box';
import useToggle from 'UseToggle.js';
import ModalOpacity from 'ModalOpacity.js';
import Loader from 'Loader.js';
import _get from 'lodash/get';
import _capitalize from 'lodash/capitalize';

// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    if (values.maximumAmount && values.maximumAmount.amount) {
        values.maximumAmount = values.maximumAmount.amount;
    }
    const errors = validation(values, validators.CASHOUT);
    return errors;
};

const renderField = ({
    input,
    extraData,
    label,
    type,
    disabled,
    customError,
    currencyCode,
    meta: {touched, error, warning}
}) => {
 
    console.log('renderfield');
    console.log(extraData);


    if (input.name == 'maximumAmount') {
        input.value = extraData.amount ? formatMoney(extraData.amount) : formatMoney(extraData);
    }

    console.log(input.value);
    return (
        <S.Row>

            <S.Label>{label}</S.Label>
            <S.InputWrapper>
                <S.Input {...input} type={type} disabled={disabled} required="required"/>
                <S.CurrencyCode>{currencyCode}</S.CurrencyCode>
                {touched ?
                    ((error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))
                    :
                    (customError && <S.Error>{customError}</S.Error>)
                }
            </S.InputWrapper>

        </S.Row>
    )
};

let CashoutForm = ({
                       toggleLiveCurtainForTxId,
                       curtainActive,
                       refreshBetHistoryList,
                       dispatch,
                       maxAmount,
                       prepareCashout,
                       cachoutOnSubmit,
                       toggleCashoutModal,
                       stake,
                       possibleReturn,
                       currencyCode,
                       isFullCashoutDisabled,
                       transactionId,
                       handleSubmit,
                       pristine,
                       submitting,
                       valid,
                       error
                   }) => {
    const [showInfoModal, toggleInfoModal] = useToggle(false);
    const [showAmountChangeChbox, toggleAmountChangeChbox] =  useState(false);
    const [infoModalSuccessMsg, setInfoModalSuccessMsg] = useState('');
    const [infoModalSuccessAmount, setInfoModalSuccessAmount] = useState('');
    const abortController = useRef(new AbortController());

    useEffect(() => {
        // poprawic, tylko dla curtainActive = true
        return () => {
                abortController.current.abort();
                toggleLiveCurtainForTxId(transactionId, false);
            }
    }, []);


    const onFormSubmit = async(type, values) => {

        const config = {
            slipId: transactionId,
            requestedAmount: ((type == 'full') ? maxAmount.amount ? maxAmount.amount : maxAmount : _get(values, 'partialAmount')),
            type: `cashout${_capitalize(type)}Amount`
        };

        const customerAcceptedAmountChange = _get(values, 'customerAcceptedAmountChange');
        if (customerAcceptedAmountChange) {
            config['customerAcceptedAmountChange'] = customerAcceptedAmountChange;
        }

        try {
            await cachoutOnSubmit(config, abortController.current.signal);
            const successMsg = `${translation(`account_cashout_cashoutSuccess_${type}`)}`;
            const successAmountMsg = `${formatMoney(config.requestedAmount)} ${currencyCode}`;
            setInfoModalSuccessMsg(successMsg);
            setInfoModalSuccessAmount(successAmountMsg);
            toggleInfoModal();

        } catch ({message, oddsHasChanged = null}) {
            if (oddsHasChanged) {
                toggleAmountChangeChbox(true);
            } else {
                toggleAmountChangeChbox(false)
            }

            toggleLiveCurtainForTxId(transactionId, false);
            throw new SubmissionError({_error: message});
        }

    };

    const closeAllModal = async() => {
        toggleCashoutModal();
        try{
            await dispatch(refreshBetHistoryList());
        }catch(error){}
    };

    return (
        <S.CashoutForm method="post">
   
            {curtainActive && (
                <S.Overlay>
                    <Loader size="5"/>
                </S.Overlay>
            )}

            <ModalOpacity isOpen={showInfoModal} showHeaderIcon={false} showHeaderTitle={false} headerPadding="0px"
                          toggleOpen={closeAllModal} showCloseIcon={false} padding="2rem" width="30rem">

                <Box flexDirection="column" justifyContent="center" alignItems="center" width="100%">
                    
                    <Box flexDirection="column" justifyContent="center" margin="">
                        <S.SuccessIcon></S.SuccessIcon>
                    </Box>
                   
                    <Box flexDirection="column" justifyContent="center" margin="0 0 20px">
                        <S.ConclusionMsg>{infoModalSuccessMsg}</S.ConclusionMsg>
                        <S.ConclusionMsg>{infoModalSuccessAmount}</S.ConclusionMsg>
                    </Box>
                    <Box justifyContent="center">
                        <S.Button onClick={closeAllModal}>{translation('common_ok')}</S.Button>
                    </Box>
                </Box>

            </ModalOpacity>

            <Box flexDirection="column" padding="20px">

                {error && <S.SubmissionError className="submission-error">{error}</S.SubmissionError>}

                <Box flexDirection="row" justifyContent="space-between" alignItems="center" height={50}>
                    <S.Label>{translation('account_cashout_amountTitle')}</S.Label>

                    <S.Stake>
                        <span>{formatMoney(stake)}</span>&nbsp;
                        <span>{currencyCode}</span>
                    </S.Stake>
                </Box>

                <Box flexDirection="row" margin='0 0 20px 0' className="full-cashout">

                    <Box margin='0 20px 0 0' flexGrow={1}>
                        <Field
                            name="maximumAmount"
                            type="text"
                            component={renderField}
                            extraData={maxAmount}
                            currencyCode={currencyCode}
                            label={translation('account_cashout_fullTitle')}
                            disabled={true}
                            customError={isFullCashoutDisabled && translation('account_cashout_cashoutBelowStake')}
                        />
                    </Box>

                    <Box justifyContent="space-between" alignItems="center">
                        <S.SubmitBtn type="button" onClick={handleSubmit(onFormSubmit.bind(null, 'full'))}
                                     disabled={submitting || isFullCashoutDisabled}>{translation('account_cashout_full')}</S.SubmitBtn>
                    </Box>

                </Box>

                <Box flexDirection="row" className="partial-cashout hidden">

                    <Box margin='0 20px 0 0' flexGrow={1}>
                        <Field
                            name="partialAmount"
                            type="text"
                            component={renderField}
                            label={translation('cashout_partial_title')}
                        />
                    </Box>

                    <Box>
                        <S.SubmitBtn type="button" onClick={handleSubmit(onFormSubmit.bind(null, 'partial'))}
                                     disabled={pristine || submitting || !valid}>{translation('account_cashout_partial')}</S.SubmitBtn>
                    </Box>

                </Box>

                <Box flexDirection="row" justifyContent="space-between" alignItems="center" height={60} className="hidden">
                    <S.Label>{translation('account_cashout_possibleWinTitle')}</S.Label>

                    <S.PossibleReturn>
                        <span>{formatMoney(possibleReturn)}</span>&nbsp;
                        <span>{currencyCode}</span>
                    </S.PossibleReturn>
                </Box>

                <S.VerticalLine></S.VerticalLine>

                <Box flexDirection="row" justifyContent="flex-end">
                    <S.Button onClick={toggleCashoutModal} disabled={submitting}>{translation('common_cancel')}</S.Button>&nbsp;
                    <S.Button
                        onClick={prepareCashout.bind(null, transactionId)} disabled={submitting}>{translation('common_refresh')}</S.Button>
                </Box>

                {showAmountChangeChbox && (
                    <Box flexDirection="row" justifyContent="flex-end" alignItems="center" height={60}>
                        <label
                            htmlFor="customerAcceptedAmountChange">{translation('account_cashout_acceptAmountChange')}</label>&nbsp;
                        <input type="checkbox" name="customerAcceptedAmountChange" id="customerAcceptedAmountChange"/>
                    </Box>)
                }

            </Box>

        </S.CashoutForm>
    )
};

CashoutForm = reduxForm({
    form: 'cashoutForm',
    validate
})(CashoutForm);

const mapStateToProps = (state, {stake, maxAmount}) => {
    return {
        isFullCashoutDisabled: (stake > maxAmount)
    }
};

export default connect(mapStateToProps)(CashoutForm);

