import { translation } from "utilsHelper.js";
export const GET_BETSLIP_CHECK_RESULTS = "GET_BETSLIP_CHECK_RESULTS";
export const CLEAR_BETSLIP_CHECK_RESULTS = "CLEAR_BETSLIP_CHECK_RESULTS";
export const TOGGLE_BETSLIPDETAILS_MODAL = "TOGGLE_BETSLIPDETAILS_MODAL";

const getBetSlipCheckResultsPending = () => {
    return {
        type: `${GET_BETSLIP_CHECK_RESULTS}_PENDING`
    };
};

const getBetSlipCheckResultsSuccess = (data) => {
    return {
        type: `${GET_BETSLIP_CHECK_RESULTS}_SUCCESS`,
        payload: {
            data
        }
    };
};

const getBetSlipCheckResultsFailure = error => {
    return {
        type: `${GET_BETSLIP_CHECK_RESULTS}_FAILURE`,
        payload: {
            error
        }
    };
};

const toggleBetSlipDetailsModal = (isBetSlipDetailsOpen) => {
    return {
        type: TOGGLE_BETSLIPDETAILS_MODAL,
        payload: { isBetSlipDetailsOpen }
    }
};

const clearBetSlipCheckResults = () => {
    return {
        type: CLEAR_BETSLIP_CHECK_RESULTS
    }
}

const getBetSlipCheckResults = betSlipId => {
    return async (dispatch, getState, { TransactionApi }) => {
        dispatch(getBetSlipCheckResultsPending());
        try {
            const { data, code } = await TransactionApi.getBetSlipTransactions(betSlipId);
            if (code !== 200) {
                throw new Error(translation(`betSlipCheck_error${code}`));
            } else {
                dispatch(getBetSlipCheckResultsSuccess(data));
            }
        } catch ({ message }) {
            dispatch(getBetSlipCheckResultsFailure(message));
        }
    };
};

export { getBetSlipCheckResults, clearBetSlipCheckResults, toggleBetSlipDetailsModal};
