import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';
import {FETCH_LIVES} from 'liveActions.js';

let initialState = {
    error: null,
    lives: {
        major: {
            data: []
        },
        main: {
            data: []
        },
        multi:{
            data: []
        }
    }
};

export default typeToReducer({
    [FETCH_LIVES]: {
        SUCCESS: (state, {payload: {type, data}}) => {
            return {...state, lives: {...state.lives, [type]: {data} }};
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, error};
        }
    }
}, initialState);


