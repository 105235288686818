const encode = encodeURIComponent;

export default (requests, axios) => {
    return {
        getById: (eventId) => {
            const sessionCookie = Cookies.get('X-ODDS-SESSION');
            const config = {};
            if (sessionCookie) {
                config['headers'] = {'X-ODDS-SESSION': sessionCookie};
            }
            return requests.get(`/market/events//${eventId}`, config);
        },
        getAllEventsForLeaguesIds: (ids) => {
            const langCookie = Cookies.get('Language');
            const leaguesIds = [].concat(ids).join(',');
            const config = {};
            config['headers'] = {'Request-Language': langCookie};
            return requests.get(`/market/categories/multi/${leaguesIds}/events`, config);
        },
        getByRemoteId: (remoteIds) => {
            const sessionCookie = Cookies.get('X-ODDS-SESSION');
            const config = {};
            if (sessionCookie) {
                config['headers'] = {'X-ODDS-SESSION': sessionCookie};
            }
            return requests.get(`/market/events/multi/remote/${remoteIds}`, config)
        }
    }
};
