import styled from 'styled-components';
import {List, Button} from 'global/styles/styles.js';
const S = {};

S.Pagination = styled(List)`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    
    & > li{
         margin-right: 10px;
        &:last-of-type{
           margin-right: 0;
        }
    }
`;
S.PaginationItem = styled.li`
`;
S.PaginationBtn = styled(Button)`
    font-size: 20px;
    cursor: pointer;
    &[disabled]{
        cursor: not-allowed;
    }
`;

export default S;