import styled from 'styled-components';
import {Form, Input, Button} from 'global/styles/styles.js';
const S = {};

S.ForgotPasswordVerificationForm = styled(Form)`
width: 100%;
text-align: center;
font-weight: 800;

    .displayNone{
        display: none;
    }
`;

S.Row = styled.div``;

S.Label = styled.label`
    text-align: left;
    pointer-events: none;
    transition: .2s;
    top: 1.4rem;
    left: 1.8rem;
    color: #787d83!important;
    font-size: 1.33rem;
    line-height: 10px
`;

S.InputWrapper = styled.div`
    position: relative;
    margin: 0 25px;
`;

S.Input = styled(Input)`
    box-shadow: none;
    border-radius: 1.3125rem;
    border: .08rem solid #e1e1e1;
    color: ${({error})=> (error!=null) ? '#CA2717' : '#000' };
    font-size: 1.33rem;
    padding: 0 19px;
    background-color: #f6f6f6;
    display: block;
    width: 100%;
    height: 43px;
    &:valid{
        & + ${S.Label}{
        margin-top: -1.42rem;
        font-size: 1.08rem;
        }
    }
`;

S.SubmitBtn = styled(Button)`
    width: 388px;
    height: 2.6875rem;
    color: #fff;
    background-color: #019A44;
    font-size: 16px;
    text-align: center;
    line-height: 10px;
    padding: 0;
    margin: 23px 25px;
    border-radius: 21px;
    display: block;
    cursor: pointer;
`;

S.Error = styled.div`
    color: red;
    margin: 5px 0;
`;

S.SubmissionError = styled.div`
    margin: 10px 44px 10px;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
`;

S.Label = styled.p`
    margin: 17px 44px 13px;
    color: #000;
    font-size: 13px;
`;

S.SuccessModal = styled.div`
`;

S.SuccessModalTitle = styled.h4`
    color: #2d9ef5;
    text-transform: uppercase;
    font-size: 1.83rem;
    padding: 0;
    font-style: italic;
    float: none!important;
    text-align: center;
    margin-bottom: 3.42rem;
`;

S.SuccessModalMessage = styled.p`
    text-align: center;
    color: #000;
    margin: 0;
    font-size: 1.1538461538rem;
    letter-spacing: .2px;
`;

S.LoginLink = styled.span`
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.33rem;
    color: #0047B9;
    border: none;
    padding: 0;
    width: 100%;
    margin: 0 0 0 0.2222rem;
    text-decoration:underline #0047B9;
    cursor: pointer;
`;

S.CreateAccountContainer = styled.div`
    margin: 0 44px 41px;

span{
    font-size:1rem;
}
`
S.PinCodeWrapper = styled.div`
display: flex;
justify-content: center;
`

S.PinInput = styled.input`
width: 15%;
height: 60px;
margin: 30px 0;
text-align: center;
margin: 5px;
border: none;
border-radius: 15px;
font-size: 25px;
outline: none;
`

S.Info = styled.div`
padding: 20px;
`

S.ErrorModal = styled.div``;


export default S;
