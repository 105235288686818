const setSessionCookie = (sessionId) => {
    const host = window.location.host;
    const port = window.location.port;
    let domainName = host.substring(host.lastIndexOf(".", host.lastIndexOf(".") - 1) + 1);

    if (host.split('.').length - 1 > 1) {
        domainName = host.substring(host.indexOf('.'));
    }

    removeSessionCookie();

    if (port != '3111') {
        Cookies.set('X-ODDS-SESSION', sessionId, {domain: domainName, expires: 14});
    } else {
        Cookies.set('X-ODDS-SESSION', sessionId, {expires: 14});
    }
};

const removeSessionCookie = () => {
    const host = window.location.host;
    const port = window.location.port;
    let domainName = host.substring(host.lastIndexOf(".", host.lastIndexOf(".") - 1) + 1);

    if (host.split('.').length - 1 > 1) {
        domainName = host.substring(host.indexOf('.'));
    }

    if (port != '3111') {
        Cookies.remove('X-ODDS-SESSION');
        clearCookie('X-ODDS-SESSION', host);
        clearCookie('X-ODDS-SESSION', domainName);
    } else {
        Cookies.remove('X-ODDS-SESSION');
        clearCookie('X-ODDS-SESSION', host);
        clearCookie('X-ODDS-SESSION', domainName);
    }
};

const extendConfigWithSecureAndSameSiteParams = (obj, extend) => {
    if(PROJECT === 'EUROPEBET')
     { 
      obj.sameSite = 'none';
      obj.secure = true;
      delete obj.domain;
    }
    return obj;
  }

const getSessionCookie = () => {
    return Cookies.get('X-ODDS-SESSION');
};

const clearCookie = (name, domain, path) => {
    var domain = domain || document.domain;
    var path = path || "/";
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=" + domain + "; path=" + path;
};

const setGoogleDataLayerCookie = (customerId) => { 
    Cookies.set('dl_uid', customerId, {expires: 365});
}

const setBtagCookie = (btag) => {
    Cookies.set('btag', btag, {expires: 7});
};

const getBtagCookie = () => {
    return Cookies.get('btag');
};

const removeBtagCookie = () => {
    Cookies.remove('btag');
};

const setPromoCookie = (promo) => {
    Cookies.set('promo', promo, {expires: 7});
};

const getPromoCookie = () => {
    return Cookies.get('promo');
};

const setSuccessedDepositsCounterCookie = (counterValue) => {
    Cookies.set('dl_dc', counterValue, {expires: 90});
};

const getSuccessedDepositsCounterCookie = () => {
    return Cookies.get('dl_dc');
};


const removePromoCookie = () => {
    Cookies.remove('promo');
};

const getLanguageCookie = () => {
    return Cookies.get('Language');
}; 

const setLanguageCookie = (languageCode) => {
    Cookies.set('Language', languageCode, {expires: 365});
};

const setSegmentationCookie = (segments) => {
Cookies.set('UserSegmentation', segments, extendConfigWithSecureAndSameSiteParams({expires: 1}));
};

const getSegmentationCookie = () => {
    return Cookies.get('UserSegmentation');
};

const setAutologinCookies = ({uniqueHash, passwordHash, accountHash}, expiresTime = 30) => {
    Cookies.set('uniqueHash', uniqueHash, {expires: expiresTime});
    Cookies.set('passwordHash', passwordHash, {expires: expiresTime});
    Cookies.set('accountHash', accountHash, {expires: expiresTime});
};

const removeAutologinCookies = ()=>{
    Cookies.remove('uniqueHash');
    Cookies.remove('passwordHash');
    Cookies.remove('accountHash');
};

export {setSessionCookie, removeSessionCookie, setBtagCookie, getBtagCookie, removeBtagCookie, setPromoCookie, setSuccessedDepositsCounterCookie, getSuccessedDepositsCounterCookie, getPromoCookie, removePromoCookie, getLanguageCookie, setLanguageCookie, setAutologinCookies, removeAutologinCookies, getSessionCookie, setSegmentationCookie, getSegmentationCookie, setGoogleDataLayerCookie};