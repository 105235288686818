const encode = encodeURIComponent;

export default (requests, axios) => {
    return {
        getAllCategories: () => {
            const config = {};
            const lang = Cookies.get('Language');
            config['headers'] = {
                'Request-Language': lang || process.env.DEFAULT_LANGUAGE
            };
            return requests.get('/market/categories', config);
        },
        getCategoriesByTime: (time) => {
            const config = {};
            const lang = Cookies.get('Language');
            config['headers'] = {
                'Request-Language': lang || process.env.DEFAULT_LANGUAGE
            };
            return requests.get(`/search/categories/search-by-date/${time}/true`, config);
        },
        getPopularCategories: () => {
            const config = {};
            // przenieść do interceptora
            const lang = Cookies.get('Language');
            config['headers'] = {
                'Request-Language': lang || process.env.DEFAULT_LANGUAGE
            };
            return requests.get('/market/categories/popular/main', config);
        },

        getCategoriesForMarketGroupTypeId: (marketGroupTypeId) => {
            const config = {};
            const lang = Cookies.get('Language');
            config['headers'] = {
                'Request-Language': lang || process.env.DEFAULT_LANGUAGE
            };
            return requests.get('/market/categories/multi/market-types/'+marketGroupTypeId, config);
        },
    }
};
