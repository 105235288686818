import {formatDate} from 'helpers/datesHelper.js';
const encode = encodeURIComponent;
const today = formatDate(new Date(), 'yyyy-MM-dd');

export default (requests, axios) => {
    return {
        getFreebetsWithFilters: ({status, to = today, from = today, limit = 20, offset = 0}) => {
            const sessionCookie = Cookies.get('X-ODDS-SESSION');
            const config = {};
            if (sessionCookie) {
                config['headers'] = {'X-ODDS-SESSION': sessionCookie};
            }
            return requests.get(`/public/offers/freebet/${from}/${to}/${limit}/${offset}/?status=${encode(status)}`, config);
        }
    }
};
