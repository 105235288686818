import React, {useEffect, useMemo, useRef} from 'react';
import {connect} from 'react-redux';
import {compose, bindActionCreators} from 'redux';
import S from 'StyledResponsibleGamingForm.js';
import {Field, Fields, reduxForm, SubmissionError, formValueSelector, destroy} from 'redux-form';
import {translation} from 'helpers/utilsHelper.js';
import {isValueUnique} from 'authActions.js';
import {loadCustomerLimits, updateCustomerLimits} from 'customerActions.js';
import classNames from 'classnames';
import Box from 'react-styled-box';
import ReactTooltip from 'react-tooltip';
import Loader from 'Loader.js';
import Select from 'react-select';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _reduce from 'lodash/reduce';
import _set from 'lodash/set';
import _each from 'lodash/each';
import _compact from 'lodash/compact';
import {getLimitIdByKey} from 'limitTypes.enum.js';

const renderField = ({input, meta, disabled}, fieldName) => {
    const {touched, error, warning, valid, asyncValidating, submitFailed} = meta;
    const inputClass = classNames({
        'async-validating': asyncValidating,
        'has-error': ((touched || submitFailed) && error),
        'is-valid': ((touched || submitFailed) && valid)
    });
    return (
        <Box key={fieldName}>
            <Box width="180px" flexGrow={1} padding="0 7px 0 15px" margin="20px 0 0" alignSelf="flex-start">
                <S.Label>{translation(`account_responsibleGame_${fieldName}`)}</S.Label>
            </Box>
            <Box width="110px" padding="0 0 0 7px">
                <S.Row>
                    <S.InputWrapper>
                        <S.Input {...input} type="number" required="required" className={inputClass}
                                 disabled={disabled}/>
                        {(touched || submitFailed) && ((error && <S.Error>{error}</S.Error>) || (warning &&
                        <S.Error>{warning}</S.Error>))}

                        {(['limitType13', 'limitType15'].indexOf(fieldName) != -1)
                            ?
                            <S.Hour>H</S.Hour>
                            :
                            <S.Currency>{app.config.mainCurrencyCode}</S.Currency>
                        }

                    </S.InputWrapper>
                </S.Row>
            </Box>
        </Box>
    )
};

const renderCheckboxWithLabel = ({
    input,
    label,
    required,
    reset
}) => {
    return (
        <S.Row>
            <S.InputWrapper className="with-checkbox">
                <S.Input {...input} value={input.value} checked={input.value} type="checkbox" required={required}
                         onChange={event => {
                             input.onChange(event.target.checked);
                             if (event.target.checked) {
                                 reset();
                             }
                         }}/>
                <S.FakeChbox>
                    {input.value && <S.CheckMark>&#10004;</S.CheckMark>}
                </S.FakeChbox>
                <S.Label dangerouslySetInnerHTML={{__html: label}}/>
            </S.InputWrapper>
        </S.Row>
    );
};

const renderSelect = ({
    input,
    options,
    disabled,
    resetFieldsToInitial,
    meta: {touched, error, warning, valid, submitFailed}
}) => {
    const inputClass = classNames({
        'has-error': ((touched || submitFailed) && error),
        'is-valid': ((touched || submitFailed) && valid),
        'react-select-container': true
    });
    return (
        <S.Row>
            <S.InputWrapper className="with-select">
                <S.Select as={Select}
                          isDisabled={disabled}
                          options={options}
                          value={_find(options, {value: input.value})}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          className={inputClass}
                          onChange={({value}) => {
                              input.onChange(value);
                              if (value == 'standard') {
                                  resetFieldsToInitial();
                              }
                          }}
                />
                {(touched || submitFailed) && ((error && <S.Error>{error[0]}</S.Error>) || (warning &&
                <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

// validation rules
const required = value => (value || typeof value === 'number' ? undefined : 'Required');
const minLimitByTypeId = (id) => {
    return (value, allValues, {customerLimits}) => {
        id = Number(id);
        const limitById = _find(customerLimits, {limitType: id});
        let defaultLimitValue = _get(limitById, ['defaultLimitValue']);

        const limits = _map(['DAILY_SESSION_TIME_LIMIT', 'MONTHLY_SESSION_TIME_LIMIT'], (key) => getLimitIdByKey(key));
        if (limits.indexOf(id) != -1) {
            defaultLimitValue = Math.floor(defaultLimitValue / 60);
            value = Number(value);
            value = Math.floor(value / 60);
        }

        return (value < defaultLimitValue) ? `${translation('limitUpdateInfo_lowerThanDefault')} ${defaultLimitValue}` : undefined;
    }
};
const maxLimitByTypeId = (id) => {
    return (value, allValues, {customerLimits}) => {
        id = Number(id);
        const limitById = _find(customerLimits, {limitType: id});
        let defaultMaxLimitValue = _get(limitById, ['defaultMaxLimitValue']);

        const limits = _map(['DAILY_SESSION_TIME_LIMIT', 'MONTHLY_SESSION_TIME_LIMIT'], (key) => getLimitIdByKey(key));
        if (limits.indexOf(id) != -1) {
            defaultMaxLimitValue = Math.floor(defaultMaxLimitValue / 60);
            value = Number(value);
            value = Math.floor(value / 60);
        }

        return (value > defaultMaxLimitValue) ? `${translation('limitUpdateInfo_error502')} ${defaultMaxLimitValue}` : undefined;
    }
};

const renderFields = (fields) => {
    const fieldNames = _get(fields, ['names']);
    const disabled = _get(fields, ['disabled']);
    let pickedFields = _pick(fields, fieldNames);
    pickedFields = _reduce(pickedFields, (initialObject, nextObj) => {
        const {input:{name}} = nextObj;
        _set(initialObject, [name], {...nextObj, disabled});
        return initialObject;
    }, {});
    return _map(pickedFields, renderField);
};

let ResponsibleGamingForm = (props) => {

    const {
        toggleConfirmationModal,
        isStandardLimitsSelected,
        isAcceptStandardLimitChecked,
        destroyLimitFormOnUnload,
        updateCustomerLimits,
        loadCustomerLimits,
        isPending,
        toggleOpen,
        handleSubmit,
        submitting,
        error,
        reset,
        change,
        initialValues,
        customerLimits
    } = props;

    useEffect(() => {
        loadCustomerLimits();
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('unload', handleUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('unload', handleUnload);
        };
    }, []);

    const handleBeforeUnload = (e) => {
        const confirmationMessage = 'Uwaga: Zostaniesz wylogowany jeśli zamkniesz ten widok przed zakończeniem procesu potwierdzania limitów.';
        e.returnValue = confirmationMessage;
        return confirmationMessage;
    };

    const handleUnload = () => {
       // destroyLimitFormOnUnload();
    };

    const resetFieldsToInitial = () => {
        _each(fieldsMap, (fieldName) => {
            change(fieldName, _get(initialValues, [fieldName]));
        });
    };

    const onFormSubmit = async(values) => {
        try {
            let limitData = _pick(values, fieldsMap);
            limitData = _map(customerLimits, (limit) => {
                const {limitType} = limit;
                const limitAmount = _get(limitData, [`limitType${limitType}`]);
                if (limitAmount) {
                    return {...limit, limitAmount}
                }
                return null;
            });

            limitData = _compact(limitData);
            await updateCustomerLimits(limitData);
            toggleOpen();
            toggleConfirmationModal();


        } catch (errors) {
            throw new SubmissionError(errors);
        }
    };

    const options = useMemo(() => {
        const options = [
            {label: translation('account_responsibleGame_standardLimits'), value: 'standard'},
            {label: translation('account_responsibleGame_customLimits'), value: 'custom'}
        ];
        return options;
    }, []);

    const limitKeysToIdMap = useMemo(() => {
        const limitKeys = [
            'DAILY_SESSION_TIME_LIMIT',
            'MONTHLY_SESSION_TIME_LIMIT',
            'DAILY_STAKE_LIMIT',
            'MONTHLY_STAKE_LIMIT'
        ];
        return _map(limitKeys, (key) => {
            return getLimitIdByKey(key);
        });
    }, []);

    const fieldsMap = useMemo(() => {
        return _reduce(limitKeysToIdMap, (initialArray, id) => {
            return initialArray.concat(`limitType${id}`);
        }, []);
    }, []);

    const fieldsValidation = useMemo(() => {
        let validationRules = _reduce(limitKeysToIdMap, (initObject, id) => {
            const fieldName = `limitType${id}`;
            const maxLimit = maxLimitByTypeId(id);
            const minLimit = minLimitByTypeId(id);
            return _set(initObject, [fieldName], [required, minLimit, maxLimit]);
        }, {});
        return validationRules;
    }, []);

    const fieldsParse = (value, name) => {
        const limitTypeKeys = ['DAILY_SESSION_TIME_LIMIT', 'MONTHLY_SESSION_TIME_LIMIT'];
        const fieldsToParse = _map(limitTypeKeys, (key) => `limitType${getLimitIdByKey(key)}`);
        if (fieldsToParse.indexOf(name) != -1) {
            if (value) {
                value = Number(value);
                value = Math.floor(value * 60);
            } else {
                value = '';
            }
        }
        return value;
    };

    const fieldsFormat = (value, name) => {
        const limitTypeKeys = ['DAILY_SESSION_TIME_LIMIT', 'MONTHLY_SESSION_TIME_LIMIT'];
        const fieldsToFormat = _map(limitTypeKeys, (key) => `limitType${getLimitIdByKey(key)}`);
        if (fieldsToFormat.indexOf(name) != -1) {
            if (value) {
                value = Number(value);
                value = Math.floor(value / 60);
            } else {
                value = '';
            }
        }
        return value;
    };

    const onCloseHandler = () => {
        var result = confirm('Uwaga: Zostaniesz wylogowany jeśli zamkniesz ten widok przed zakończeniem procesu potwierdzania limitów.');
        if (result) {
            toggleOpen();
        }
    };

    if (isPending) {
        return <S.LoaderWrapper as={Loader} color="#F05A22"/>;
    }

    return (
        <S.ResponsibleGamingForm className="responsible-gaming" onSubmit={handleSubmit(onFormSubmit)} autocomplete="off"
                                 noValidate>

            {submitting && (<S.Cover><Loader color="#F05A22"/></S.Cover>)}

            <S.Header>
                <S.CloseModalButton type="button" onClick={onCloseHandler}></S.CloseModalButton>
                <S.Title>{translation('account_responsibleGame_title')}</S.Title>
            </S.Header>

            <S.Body>

                <S.Info dangerouslySetInnerHTML={{__html: translation('account_responsibleGame_info')}}/>

                {error && <S.SubmissionError className="submission-error">{error}</S.SubmissionError>}

                <Box flexDirection="column">

                    <S.BodyInner>
                        <Box>
                            <Field
                                name="acceptStandardLimits"
                                component={renderCheckboxWithLabel}
                                label={translation('account_responsibleGame_acceptStandardLimits')}
                                required={false}
                                reset={reset}
                            />
                        </Box>

                        <Box>
                            <Field
                                name="limitSetting"
                                component={renderSelect}
                                disabled={isAcceptStandardLimitChecked}
                                options={options}
                                resetFieldsToInitial={resetFieldsToInitial}
                            />
                        </Box>

                        <Fields names={fieldsMap} validate={fieldsValidation} parse={fieldsParse} format={fieldsFormat}
                                component={renderFields} disabled={isStandardLimitsSelected}/>

                    </S.BodyInner>

                </Box>

            </S.Body>


            <S.Footer>

                <S.Warning>{translation('account_responsibleGame_warning')}</S.Warning>

                <S.SubmitBtn type="submit"
                             disabled={submitting}>{translation('account_responsibleGame_setLimits')}</S.SubmitBtn>

                <S.HelpBtn className="online-help">

                    <S.HelpIcon data-tip data-for={`help-tooltip`}>&#63;</S.HelpIcon>

                    <ReactTooltip id={`help-tooltip`} type="light">
                        <span>lorem ipsum...</span>
                    </ReactTooltip>

                    {translation('online_help')}

                </S.HelpBtn>
            </S.Footer>

        </S.ResponsibleGamingForm>
    );
};

const selector = formValueSelector('responsibleGamingForm');
const prepareInitialValues = (values) => {
    if (values) {
        values = _reduce(values, (initialObject, {limitType, defaultLimitValue}) => {
            const key = `limitType${limitType}`;
            _set(initialObject, [key], defaultLimitValue);
            return initialObject;
        }, {});
    }
    return values;
};

const mapStateToProps = (state, props) => {
    const {Customer:{customerLimits, isCustomerLimitsPending:isPending}} = state;
    const initialValues = prepareInitialValues(customerLimits);

    _set(initialValues, ['acceptStandardLimits'], true);
    _set(initialValues, ['limitSetting'], 'standard');

    const isAcceptStandardLimitChecked = selector(state, 'acceptStandardLimits');
    const isStandardLimitsSelected = (selector(state, 'limitSetting') == 'standard');

    return {
        customerLimits,
        isPending,
        initialValues,
        isStandardLimitsSelected,
        isAcceptStandardLimitChecked
    }
};

const mapDispatchToProps = (dispatch) => {
    const actionsToBind = {updateCustomerLimits, loadCustomerLimits};
    return {
        ...bindActionCreators(actionsToBind, dispatch),
        destroyLimitFormOnUnload: () => {
            dispatch(destroy('responsibleGamingForm'));
        }
    }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'responsibleGamingForm'
    })
)(ResponsibleGamingForm);
