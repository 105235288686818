import styled from 'styled-components';
const S = {};

S.CmsPage = styled.div``;

S.CmsWrapper = styled.div`
    height: 100%;
`;

S.Error = styled.div``;

export default S;