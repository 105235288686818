import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';
import _each from 'lodash/each';
import {
    FETCH_CATEGORIES,
    ADD_LEAGUE_BY_ID,
    REMOVE_LEAGUE_BY_ID,
    CHANGE_SPORT_ID,
    SELECT_THIRD_LEVEL_CATEGORY
} from 'categoriesActions.js';

let initialState = {
    isPending: true,
    error: null,
    categories: [],
    selectedLeagues: [],
    prevSortId: null,
    thirdLevelSelectedLeague: null
};

export default typeToReducer({
    [SELECT_THIRD_LEVEL_CATEGORY]:(state, {payload: {thirdLevelSelectedLeague}}) => {
        return {...state, thirdLevelSelectedLeague, selectedLeagues: []};
    },
    [FETCH_CATEGORIES]: {
        PENDING: (state, action) => {
            return {...state}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {categories}}) => {
            return {...state, isPending: false, categories};
        }
    },
    [ADD_LEAGUE_BY_ID]: (state, {payload: {leagueId}}) => {
        return {...state, thirdLevelSelectedLeague: null, selectedLeagues: [...state.selectedLeagues, leagueId]};
    },
    [REMOVE_LEAGUE_BY_ID]: (state, {payload: {leagueId}}) => {
        const leaguesToRemove = [].concat(leagueId);
        let selectedLeagues = state.selectedLeagues;
        _each(leaguesToRemove, (leagueId)=>{
            const indexOfLeagueId = selectedLeagues.indexOf(leagueId);
            if(indexOfLeagueId!=-1){
                selectedLeagues = update(selectedLeagues, {$splice: [[indexOfLeagueId,1]]});
            }
        });
        return {...state, thirdLevelSelectedLeague: null, selectedLeagues};
    },
    [CHANGE_SPORT_ID]: (state, {payload: {prevSortId}}) => {
        return {...state, selectedLeagues: [], prevSortId};
    }
}, initialState);
