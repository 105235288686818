import React, {useState} from 'react';
import S from 'StyledWalletTransactionsItem.js';
import {translation, formatMoney} from 'helpers/utilsHelper.js';
import {formatDate} from 'helpers/datesHelper.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import Deposit from 'transaction_deposit.svg';
import Withdrawal from 'transaction_withdrawal.svg';
import ChevronIcon from 'chevron_down_dark.svg';

const WalletTransactionsItem = ({transactionId, amount, regDate, statusCode, currencyCode, transactionSwitch, typeDescription}) => {
    const [isOpen, setIsOpen] = useState(false);

    const isSameYear = new Date().getFullYear() === new Date(regDate).getFullYear();

    const transactionType = transactionSwitch === 1 ? 'deposit' : 'withdrawal';

    const getTransactionStatusClass = (statusCode) => {
        const status = statusCode.toUpperCase();
        switch (status) {
            case 'DEC' :
                return 'red';
            case 'FLD' :
                return 'red';
            case 'SCS' :
                return 'green';
            case 'IPR' :
                return 'blue';
            case 'INI' :
                return 'blue';
            default:
                return '';
        }
    };

    const parseStatus = (status) => {
        return 'transactionHistory_status_' + status;
    };

    return (
        <div>
            <S.WalletTransactionsItem 
                className={`transaction-list-item ${isOpen ? 'open' : ''}`}
                onClick={() => setIsOpen(!isOpen)}
                >
                <div className="wallet-date">
                    {formatDate(regDate, `dd.MM${isSameYear ? '' : '.yyyy'} HH:mm`)}
                </div>     
                <div className="wallet-type">
                    <S.TypeIcon 
                        as="div" 
                        dangerouslySetInnerHTML={{__html: transactionType === 'deposit' ? Deposit : Withdrawal}}/>
                    {translation(`transactionHistory_type_${transactionType}`)}
                </div>
                <div className={`wallet-amount`}>
                    {formatMoney(amount)}
                </div>
                <div className={`wallet-status ${getTransactionStatusClass(statusCode)}`}>
                    {translation(parseStatus(statusCode))}
                    <S.OpenDetailsIcon 
                        as="div"
                        dangerouslySetInnerHTML={{__html: ChevronIcon}} 
                        isOpen={isOpen}/>
                </div>
            </S.WalletTransactionsItem>
            <S.WalletTransactionsItemDetails isOpen={isOpen}>
                <div>
                    <p>
                        {translation('transactionHistory_details_transactionId')} {transactionId}
                    </p>
                    <p>
                        {translation('transactionHistory_details_transactionDate')} {formatDate(regDate, 'dd.MM.yyyy HH:mm')} {regDate.toTimeString().split(' ')[1]}
                    </p>
                    <p>
                        {translation('transactionHistory_details_transactionDescription')} {typeDescription}
                    </p>
                    <p>
                        {translation('transactionHistory_details_transactionCurrency')} {currencyCode}
                    </p>
                </div>
            </S.WalletTransactionsItemDetails>
        </div>
    );
};

export default WalletTransactionsItem;
