import React, {Component} from 'react';
import S from 'StyledAccountResponsibleGame.js';
import ResponsibleGamingForm from 'ResponsibleGamingForm.js';
import AccountResponsibleGameSelfExclusion from 'AccountResponsibleGameSelfExclusion.js';

class AccountResponsibleGame extends Component {
    render() {
        return (
            <S.AccountResponsibleGame>
                <ResponsibleGamingForm accountView/>
                <AccountResponsibleGameSelfExclusion/>
            </S.AccountResponsibleGame>
        );
    }
}

export default AccountResponsibleGame;