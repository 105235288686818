import styled from 'styled-components';
import StyledDatepicker from 'StyledDatepicker.js';
import { Button} from 'global/styles/styles.js';
const S = {};

S.AccountBetHistory = styled.div`
    width: 100%;
    padding: 1rem  1.75rem;
    background-color:#DEDEDE;
`;
S.BetHistoryFilters = styled.div`
    display: flex;
    flex-wrap:wrap;
`;
S.BetHistoryFiltersDates = styled.div`
    display: flex;
    justify-content: space-around;
    margin:0 auto;
    & > div{
         margin-right: 3.16rem;
         &:last-of-type{
          margin-right: 0;
         }
    }
`;
S.DateWrapper = styled.div`
    position: relative;
    width: 12.08rem;
    height: 3.83rem;
    display: flex;
    flex-direction:column;
    align-items:center;
    & .react-datepicker-wrapper,
    & .react-datepicker__input-container{
       display: flex;
    }
    & .react-datepicker-wrapper{
       width: calc(100% - 4rem);
    }
    & .react-datepicker__input-container{
       width: 100%;
    }
    
    ${StyledDatepicker.Datepicker}{
        flex: 1;
        width: 100%;
        font-size: 0.9rem;
        font-weight: 700;
        color: #898989;
    }
`;
S.DateInputWrapper = styled.div`
display:flex;
justify-content: space-between;
align-items:center;
border-radius:1.75rem;
padding: 0.75rem 1.25rem 0.75rem 1.75rem;
background-color:#fff;
color: #898989;
`;
S.DateLabel = styled.div`
    display: flex;
    font-weight: 700;
    color: #282828;
    font-size:1.2rem;
    width: 4.5rem;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.4167rem;
`;
S.DateIcon = styled.i`
font-size:1.4167rem;
`;
S.FilterBtnsLabel=styled.div`
    display: flex;
    align-items: center;
    gap: 0.83rem;
    overflow:hidden;
    margin: 0 0 0.83rem 0;
    width:100%;
    font-size: 1.2rem;
    font-weight:bold;
`
S.FilterBtnsLabelAfter=styled.div`
    flex: 1;
    height: 0.08rem;
    background-color: #898989;
`
S.BetHistoryFiltersBtns = styled.div`
    display: flex;
    width:100%;
    margin:0 0 0.83rem 0;
    padding:1.08rem 0;
    flex-wrap:wrap;
    & > button{
        margin-right: 15px;
        &:last-of-type{
            margin-right: 0;
        }
    }
`;
S.FilterDayBtn=styled.button`
padding:0.75rem 1.92rem;
background-color:${props => props.isActive ? "#FAE100" : "#898989"};
border: 0.17rem solid #fff;
font-weight:bold;
font-size:1.2rem;
color:${props => props.isActive ? "#000" : "#fff"};
&.--first{
    border-radius:1.75rem 0 0 1.75rem ;
}
&.--first + button{
    border-left:none;
    border-right:none;
}
&.--last{
    border-radius: 0 1.75rem 1.75rem 0 ;
}
`
S.FilterDayBtnsWrapper=styled.div`
    display: flex;
    justify-content:center;
    align-items:center;
    width:100%;
    margin-top:2.5rem;
`
S.FilterBtn = styled(Button)`
    padding: 0.8333rem 1.6667rem;
    border-radius: 1.75rem;
    background-color: #fff;
    font-weight:bold;
    font-size:1.2rem;
    &.--won{
        color:#37A000;
    }
    &.--lost{
        color:#CA2717;
    }
    &.--undecided{
        color:#0047B9;
    }
    &.active{
            color: #fff;
            background-color: #282828;
    }
`;

S.BonusListHeader= styled.div`
    background-color: #a3a3a3;
    color: #fff;
    padding: 1rem;
    font-weight: 500;
    border-bottom: .1rem solid #fff;
    font-size: 1.5rem;
    font-size: 1.5rem;
`;


S.BonusList = styled.div``;


S.BetHistoryTransactionsList = styled.div`
    margin-top: 10px;
    background-color: #f3f3f3;
`;

S.NoResult = styled.div`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

S.BetHistoryTransactionsListItem = styled.div`
display: flex;
&>div {
	padding: 1.2rem .67rem;
	border: none;
	font-size: 1rem;
	color: #fff;
	font-weight: 700;
	text-align: left;
	text-transform: uppercase;
	border-bottom: .08rem solid #fff;
	border-right: .08rem solid #fff;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 500;
	color: #000;
	font-size: 1rem;
	padding: 1.2rem .67rem;
	border-bottom: .08rem solid #fff;
	border-right: .08rem solid #fff;
	&.wallet-status {
		width: 13.2995706264%;
		padding-left: 2.7523945833%;
		display: flex;
		align-items: center;
		&.red{
		    & .status-icon{
		        background-image: url(/images/svg/status_red.svg);
		    }
		}
		&.green{
		    & .status-icon{
		      background-image: url(/images/svg/status_green.svg);
		    }
		}
		
		& .status-icon{
		    margin-left: 1.17rem;
            width: 1.3819275738rem;
            height: 1.3819275738rem;
            background-color: transparent;
            background-position: 0 0;
            background-size: cover;
		}
	}
	&.wallet-type {
		width: 20.1805570847%;
	}
	&.wallet-info {
		width: 20.1805570847%;
	}
	&.wallet-amount {
		width: 15.6005724981%;
		&.green{}
		&.red{}
	}
	&.wallet-date {
		width: 16.9767697897%;
		display: flex;
		justify-content: space-between;
	}
	&.wallet-id {
		flex: 1;
	}
}
`;

S.BetHistoryTransactions = styled.div`
`;

S.FakeTableHead = styled.div`
    display: flex;
    gap: 0.08rem;
    background-color: #A5A5A5;

    & > div{
        padding: 0.75rem;
        border: none;
        background-color: #282828;
        font-size: 1.2rem;
        color: #A5A5A5;
        font-weight: 700;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        
        &.transaction-id{
                flex: 1 1 0;
        }
         &.transaction-date-cashout,
        &.transaction-date{
              flex: 1.5 1 0;
         }
         &.transaction-stake-cashout,
         &.transaction-stake{
               flex: 1 1 0;
           }
         &.transaction-win{
                  flex: 1 1 0;
           }
         &.transaction-confirmed-win{
                  flex: 1 1 0;
           }
           &.transaction-bonus{
               flex: 1 1 0;
            }
           &.transaction-typ{
              flex: 1 1 0;
           }
           &.transaction-cashout{
               flex: 1 1 0;
                display: flex;
                align-items: center;
                justify-content: left;
           }
           &.transaction-status{
               flex: 1 1 0;
               border-right: none;
           }
           &.transaction-rebet{
               flex: 1 1 0;
                display: flex;
                align-items: center;
                justify-content: center;
           }
    }
`;

S.Button = styled(Button)`
    display: block;
    line-height: 2rem;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    height: 100%;
    border: 0;
    box-sizing: border-box;
    width: 100%;
    margin: 5px 0;
    &.reject{
        background-color: #bebebe;
    }
    &.accept{
        background-color: #00a651;
    }
`;

S.BtnReset = styled(Button)`
    margin-top: .465rem;
    height: 2.4rem;
    background-color: #2d9ef5;
    font-size: 1rem;
    font-weight: 700;
    vertical-align: middle;
    color: #fff;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: auto;
    border-radius: 4px;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
        
    & > span{
        font-size: 1rem;
        font-weight: 700;
        color: #fff;
    }
`;

S.BtnRefresh = styled(S.BtnReset)`
    margin-left: 10px;
`;

export default S;