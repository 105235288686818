export default {

  FRONTEND_MISSING_TRANSLATION: {
    id: 2001,
    name: 'MissingTranslation'
  },

  FRONTEND_REFERENCE_ERROR: {
    id: 2002,
    name: 'ReferenceError'
  },

  FRONTEND_TYPE_ERROR: {
    id: 2003,
    name: 'TypeError'
  },

  FRONTEND_SYNTAX_ERROR: {
    id: 2004,
    name: 'SyntaxError'
  },

  FRONTEND_OTHER_ERROR: {
    id: 2100,
    name: 'OtherError'
  },

  getErrorByName: function(key) {
    const type = _.find(this, (val) => {
      return val.name == key;
    });
    return type || this.FRONTEND_OTHER_ERROR;
  }
  
};