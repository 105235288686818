import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Route, Switch, NavLink} from 'react-router-dom';
import AccountBonusRoute from 'AccountBonusRoute.js';
import S from 'StyledAccountBonus.js';
import Datepicker from 'Datepicker.js';
import {translation, formatMoney} from 'helpers/utilsHelper.js';
import {changeDateByType, changeActiveTab, fetchBonusListByFilter} from 'accountBonusActions.js';

class AccountBonus extends Component {

    handleChangeStart = (date) => {
        const {changeDateByType, endDate, limit, fetchBonusListByFilter, activeTab} = this.props;
        const startDate = new Date(date);
        const params = {
            endDate,
            startDate,
            limit
        };

        changeDateByType('startDate', startDate);

        if(activeTab == 'historical'){
            params['status'] = 'HISTORY'
        }else{
            params['status'] = ['ACTIVE', 'QUEUE'];
        }

        fetchBonusListByFilter(params);
    };

    handleChangeEnd = (date) => {
        const {changeDateByType, startDate, limit, fetchBonusListByFilter, activeTab} = this.props;
        const endDate = new Date(date);
        const params = {
            endDate,
            startDate,
            limit
        };

        changeDateByType('endDate', endDate);

        if(activeTab == 'historical'){
            params['status'] = 'HISTORY'
        }else{
            params['status'] = ['ACTIVE', 'QUEUE'];
        }

        fetchBonusListByFilter(params);
    };

    render() {

        const {match, startDate, endDate, changeActiveTab} = this.props;
        return (
            <S.AccountBonus>
                <S.BonusFilters className="bonus-filters">

                    <S.BonusFiltersDates className="bonus-filters-dates">

                        <S.DateWrapper className="date-wrapper">
                            <S.DateLabel>{translation('common_dateFrom')}</S.DateLabel>
                            <Datepicker
                                selected={startDate}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="yyyy-MM-dd"
                                cb={this.handleChangeStart}
                            />
                            <S.DateIcon className="ion ion-ios-calendar-outline"></S.DateIcon>
                        </S.DateWrapper>

                        <S.DateWrapper className="date-wrapper">
                            <S.DateLabel>{translation('common_dateTo')}</S.DateLabel>
                            <Datepicker
                                selected={endDate}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="yyyy-MM-dd"
                                cb={this.handleChangeEnd}
                            />
                            <S.DateIcon className="ion ion-ios-calendar-outline"></S.DateIcon>
                        </S.DateWrapper>

                    </S.BonusFiltersDates>

                    <S.BonusFiltersBtns className="bonus-btns-wrapper">
                        <S.FilterBtn as={NavLink} to={`${match.path}/current-bonus`} exact activeClassName="active"
                                     onClick={changeActiveTab.bind(null, 'current')}>Current
                            bonuses</S.FilterBtn>
                        <S.FilterBtn as={NavLink} to={`${match.path}/historical-bonus`} exact activeClassName="active"
                                     onClick={changeActiveTab.bind(null, 'historical')}>Historical
                            bonuses</S.FilterBtn>
                    </S.BonusFiltersBtns>


                </S.BonusFilters>

                <AccountBonusRoute {...this.props}/>

            </S.AccountBonus>
        );
    }
}

const mapStateToProps = ({AccountBonus:{startDate, endDate, activeTab, limit}}, props) => {
    return {
        startDate,
        endDate,
        activeTab,
        limit
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeDateByType: bindActionCreators(changeDateByType, dispatch),
        changeActiveTab: bindActionCreators(changeActiveTab, dispatch),
        fetchBonusListByFilter: bindActionCreators(fetchBonusListByFilter, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountBonus);

