import React,{useState} from 'react';
import S from 'StyledAccountPayments.js';
import {translation} from 'helpers/utilsHelper';
import { useSelector } from 'react-redux';
import AccountDeposit from 'AccountDeposit.js';
import {AccountWithdraw} from 'AccountWithdraw.js';

const AccountPayments = () => {
    const {balance,currencyCode} = useSelector(state => state?.Auth.user);
    const [currentPaymentMethod,setCurrentPaymentMethod] = useState('deposit')
    const paymentMethods = [
        { key: 'deposit', label: 'Deposit' },
        { key: 'withdraw', label: 'Withdraw' },
      ];
    return (
        <S.AccountPaymentsContainer>
            <S.Balance>{translation('paymentsBalance')} {balance} {currencyCode} <S.InfoIcon>i</S.InfoIcon></S.Balance>
            <S.PaymentsContainer>
                <S.PaymentSwitcher>
                {paymentMethods.map((method) => (
                    <S.PaymentSwitch
                      key={method.key}
                      onClick={() => setCurrentPaymentMethod(method.key)}
                      className={currentPaymentMethod === method.key ? 'active' : ''}
                    >
                        {method.label}
                    </S.PaymentSwitch>
                ))}
                </S.PaymentSwitcher>
                <S.Payment>
                <S.PaymentTitle>{translation(`account_${currentPaymentMethod}_title`)}</S.PaymentTitle>
                    {currentPaymentMethod === "deposit" ? 
                    (
                        <AccountDeposit/>
                    ):
                    (
                        <AccountWithdraw/>
                    )}
                </S.Payment>
            </S.PaymentsContainer>
        </S.AccountPaymentsContainer>
    );
};

export default AccountPayments;
