import styled from 'styled-components';
import StyledLoader from 'StyledLoader.js';
import {Form, Input, Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const baseFont = `12px`;

const S = {};

S.RegisterForm = styled(Form)`
    width: 100%;
    padding:1.5625rem 4.3125rem;
`;

S.Cover = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 5;
    
    & ${StyledLoader.LoaderWrapper}{
    }
`;


S.InfoLabel=styled.div`
    margin:0 0 0.3333rem 0.8333rem;
    color: #111111!important;
    font-size: .9rem;
    font-weight: 700;
`
S.Label = styled.label`
    margin:0 0 0.3333rem  0.8333rem;
    pointer-events: none;
    transition: .2s;
    color: #111111!important;
    font-size: 1.1rem;
    font-weight: 700;
    span{
        color: #E30613;
    }
`;
S.Row = styled.div`
    margin-top: 0.6rem;
`;

S.Input = styled(Input)`
    box-shadow: none;
    border: .08rem solid #e1e1e1;
    color: #000;
    border-radius:1.75rem;
    font-size: ${rem('14px', baseFont)};
    padding: 1.45rem 1.75rem;
    background-color: #f6f6f6;
    display: block;
    width: 100%;
    height: 30px;
    &:valid{
        & + ${S.Label}{
            margin-top: -1.42rem;
            font-size: 1.08rem;
        }
    }
    &.has-error{
        border: .08rem solid red;
        border-radius: 1.75rem;
    }
    &.is-valid{
        border: .08rem solid #63dd7d;
    }
`;

S.SubmitBtn = styled(Button)`
    border-radius: 1.75rem;
    background-color: ${({theme:{$registerFormSubmitBtnBgColor}}) => $registerFormSubmitBtnBgColor};
    font-size: 1rem;
    font-weight: 600;
    padding: 0.8333rem;
    width: 100%;
    margin-bottom: 1.7rem;
    margin-top: 1.8rem;
    color: ${({theme:{$registerFormSubmitBtnColor}}) => $registerFormSubmitBtnColor};
    min-width: 100%;
    cursor: pointer;
    text-transform:uppercase;
    box-shadow: 3px 3px 6px #0000004D;
`;

S.Error = styled.div`
    color: red;
    width: 100%;
    margin-top: 0;
    border-radius: 0 0 2px 2px;
    padding: 2px .85rem 0px .85rem;
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
    margin-bottom: ${rem('20px', baseFont)};
`;

S.SubmissionSuccess = styled(S.SubmissionError)`
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
`;


S.InputWrapper = styled.div`
    position: relative;
    height: 60px;
    
    &.with-checkbox{
        margin:0 0 0.3333rem 0.8333rem;
        display: flex;
        flex-wrap: wrap;
        height: auto;
        ${S.Error}{
           width: 100%;
        }  
        ${S.Label}{
            flex: 1;
            align-items:center;
            font-size: 11px;
            font-weight: 600;
            padding-top: 0;
            color: #787d83;
            display: flex;
            position: relative;
            left: 0;
            cursor: pointer;
            top: 0;
        }
         ${S.Input}{
            position: absolute!important;
            padding: 0!important;
            border: 0!important;
            overflow: hidden;
            z-index: 0;
            left: 3px;
            top: -5px;
            width: auto;
         }
    }
`;

export default S;
