import styled from 'styled-components';
import {Form, Input, Button} from 'global/styles/styles.js';
import {rem} from 'polished';
import StyledDatepicker from 'StyledDatepicker.js';
const S = {};
const baseFont = `12px`;
S.RegisterForm = styled(Form)`
 width: 100%;
 padding:1.5625rem 4.3125rem;
`;

S.InfoLabel=styled.div`
    margin:0 0 0.3333rem 0.8333rem;
    color: #111111!important;
    font-size: .9rem;
    font-weight: 700;
`
S.Label = styled.label`
    margin:0 0 0.3333rem  0.8333rem;
    pointer-events: none;
    transition: .2s;
    color: #111111!important;
    font-size: 1.1rem;
    font-weight: 700;
    span{
        color: #E30613;
    }
`;
S.Row = styled.div`
    margin-top: 0.6rem;
`;

S.RowDatePicker = styled.div`
    margin-top: 0.6rem;
    height: ${rem('60px', baseFont)};
`;

S.DatePickerWrapper = styled.div`
    display: flex;
    flex-wrap:wrap ;
    position: relative;
    
    & .react-datepicker-wrapper {
        width: 100%;
    }

    ${StyledDatepicker.Datepicker}{
        box-shadow: none;
        display: inline-block;
        width: 100%;
        font-size: ${rem('14px', baseFont)};
        font-weight: 500;
        border: none;
        height: ${rem('37px', baseFont)};
        padding-left: 1.75rem;
        border-radius: 21px;
        opacity: 1;

        &[disabled]{
            cursor: not-allowed;
            opacity: 0.7;
        }

        &.has-error{
            border: .08rem solid red!important;
            border-radius: 1.75rem!important;
        }

        &.is-valid { 

        }
}
`;

S.FakeChbox = styled.div`
    width: 2.3rem;
    height: 2.3rem;
    border-radius: 0.5rem;
    margin-right: .83rem;
    margin-top: .25rem;
    flex-shrink: 0;
    pointer-events: none;
    cursor: pointer;
    z-index: 3;
    background-color: #fff;
    position: relative;
    font-size: 22px;
`;

S.CheckMark = styled.span`
    position: absolute;
    left: 5px;
    top: 0;
    font-weight: bold;
    color: #787d83;
`;

S.Input = styled(Input)`
    box-shadow: none;
    border: .08rem solid #e1e1e1;
    color: #000;
    border-radius:1.75rem;
    font-size: ${rem('14px', baseFont)};
    padding: 1.45rem 1.75rem;
    background-color: #f6f6f6;
    display: block;
    width: 100%;
    height: 30px;
    &:valid{
        & + ${S.Label}{
            margin-top: -1.42rem;
            font-size: 1.08rem;
        }
    }
    &.has-error{
        border: .08rem solid red;
        border-radius: 1.75rem;
    }
    &.is-valid{
        border: .08rem solid #63dd7d;
    }
    &.input-checkbox {
        width: 2.2rem!important;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: transparent;
    }
`;

S.SubmitBtn = styled(Button)`
    border-radius: 1.75rem;
    background-color: ${({theme:{$registerFormSubmitBtnBgColor}}) => $registerFormSubmitBtnBgColor};
    font-size: 1rem;
    font-weight: 600;
    padding: 0.8333rem;
    width: 100%;
    margin-bottom: 1.7rem;
    margin-top: 1.8rem;
    color: ${({theme:{$registerFormSubmitBtnColor}}) => $registerFormSubmitBtnColor};
    min-width: 100%;
    cursor: pointer;
    text-transform:uppercase ;
    box-shadow: 3px 3px 6px #0000004D;
`;

S.Error = styled.div`
    color: red;
    width: 100%;
    margin-top: 0;
    border-radius: 0 0 2px 2px;
    padding: 2px .85rem 0px .85rem;
    &.with-checkbox {
        padding-left: 0px;
    }
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
`;

S.DateOfBirth=styled.div`
display:flex;
justify-content:space-around;
div{
    display:flex;
    flex-direction:column;
    select{
        font-size:1.2rem;
        border:none;
        border-radius:21px;
        padding:10px;
    }
}
`
S.InputWrapper = styled.div`
    position: relative;
    height: 60px;
    
    &.with-checkbox{
        margin:0 0 0.3333rem 0.8333rem;
        display: flex;
        flex-wrap: wrap;
        height: auto;
        ${S.Error}{
           width: 100%;
        }  
        ${S.Label} {
            flex: 1;
            align-items:center;
            font-size: 11px;
            font-weight: 600;
            padding-top: 0;
            color: #282828;
            display: flex;
            position: relative;
            left: 0;
            cursor: pointer;
            top: 0;
        }
        span {
            color: #282828;
        }
         ${S.Input}{
            position: absolute!important;
            padding: 0!important;
            border: 0!important;
            overflow: hidden;
            z-index: 0;
            left: 3px;
            top: -5px;
            width: auto;
         }
    }
`;

S.InputWrapperRow = styled.div`
    display: flex;
    flex-direction: row;
`;
S.InputWrapperCol = styled.div`
    display: flex;
`;

export default S;