import { translation } from "utilsHelper.js";
export const GET_QUICK_SEARCH_RESULTS = "GET_QUICK_SEARCH_RESULTS";
export const CLEAR_QUICK_SEARCH_RESULTS = "CLEAR_QUICK_SEARCH_RESULTS";

const getQuickSearchResultsPending = () => {
    return {
        type: `${GET_QUICK_SEARCH_RESULTS}_PENDING`
    };
};

const getQuickSearchResultsSuccess = (data) => {
    return {
        type: `${GET_QUICK_SEARCH_RESULTS}_SUCCESS`,
        payload: {
            data
        }
    };
};

const getQuickSearchResultsFailure = error => {
    return {
        type: `${GET_QUICK_SEARCH_RESULTS}_FAILURE`,
        payload: {
            error
        }
    };
};

const clearQuickSearchResults = () => {
    return {
        type: CLEAR_QUICK_SEARCH_RESULTS
    }
}

const getQuickSearchResults = (phrase, participantId = null) => {
    return async (dispatch, getState, { SearchApi }) => {
        dispatch(getQuickSearchResultsPending());
        try {
            const pattern = participantId??phrase;
            const { data, code } = await SearchApi.getResultsForPhrase(pattern);

            if (code !== 200) {
                throw new Error(translation(`quickSearch_error${code}`));
            } else {
                dispatch(getQuickSearchResultsSuccess(data));
            }
        } catch ({ message }) {
            dispatch(getQuickSearchResultsFailure(message));
        }
    };
};

const saveChoiceHint = (area, itemId) => {
    return async (dispatch, getState, { SearchApi }) => {
        try {
            await SearchApi.saveChoiceHint(area, itemId);
        } catch (err) {
            console.log(err);
        }
    };
}

export { getQuickSearchResults, clearQuickSearchResults, saveChoiceHint };
