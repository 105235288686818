import React from 'react';
import S from 'StyledBetHistoryItemDetails.js';
import {CSSTransition} from 'react-transition-group';
import {translation, decimal} from 'helpers/utilsHelper.js'
import {formatDate} from 'helpers/datesHelper.js';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

const BetHistoryItemDetails = ({isVisible, transaction, toggleCashoutModal, toggleCashoutErrorModal, prepareCashout, setCashoutErrorCode, checkCashout, checkFreebet}) => {

    const getTransactionDetailsStatus = (statusCode) => {
        let resultText = 'details_placed';

        switch (statusCode) {
            case 0 :
                resultText = 'details_placed';
                break;
            case 1 :
                resultText = 'details_placed';
                break;
            case 2 :
                resultText = 'details_won';
                break;
            case 3 :
                resultText = 'details_lost';
                break;
            case 4 :
                resultText = 'details_cancel';
                break;
        }

        return resultText;
    };

    const toggleCashout = async(transactionId) => {
        try {
            await prepareCashout(transactionId);
            toggleCashoutModal();
        } catch (errorCode) {
            setCashoutErrorCode(errorCode);
            toggleCashoutErrorModal();
        }
    };

    return (
        <CSSTransition in={isVisible}
                       className="slide-transition"
                       classNames="slide-transition"
                       unmountOnExit
                       timeout={300}>

            <S.BetHistoryDetailsWrapper className="bet-history-details-wrapper">
            {_map(_get(transaction, 'transactionDetails'), (transactionDetails) => {
                return (!_isEmpty(transactionDetails) && (
                    <S.BetHistoryItemDetails id={`bet-history-item-details-${transaction.transactionId}`} className="bet-history-item-details" key={`bet_history_details_${transaction.transactionId}`}>
                                <S.BetHistoryDetailsBody key={`bet_history_details_body_${transactionDetails.eventId}`}>
                                    <div className='leftSide'>
                                        <span className="event-name">Slip ID{transaction.transactionId}</span>
                                        {(process.env.CASHOUT && checkCashout(transaction.transactionId) && !checkFreebet(transaction.transactionId)) ? 
                                            (
                                                <button
                                                    className="button-cashout"
                                                    onClick={toggleCashout.bind(null, transaction.transactionId)}>
                                                        {translation('account_betHistoryTransactionItem_cashout')}
                                                </button>
                                            ):<></>
                                        }
                                        <span className="event-name">{transactionDetails.eventName} {decimal(transactionDetails.odds)}</span>
                                    </div>
                                    <div className='rightSide'>
                                        <span className="event-name">{transactionDetails.eventName}</span>
                                        <span className="event-name">{transactionDetails.categoryName3}</span>
                                        <span>{formatDate(transactionDetails.eventStart, 'dd-MM-yyyy')}&nbsp;{formatDate(transactionDetails.eventStart, 'HH:mm:ss')}</span>
                                    </div>
                                </S.BetHistoryDetailsBody>
                    </S.BetHistoryItemDetails>
                ));
            })}
            </S.BetHistoryDetailsWrapper>
        </CSSTransition>
    );
};

export default BetHistoryItemDetails;
