import styled from 'styled-components';
import {List} from 'global/styles/styles.js';
const S = {};

const duration = 500;

const hostname = window.location.hostname

let imageDirectory = (hostname) => {

    switch (hostname) {
        case 'localhost':
            imageDirectory = '/';
            return imageDirectory;
            break;
        case 'test.premierbetzone.co.tz':
        case 'premierbetzone.co.tz':
        case 'test.premierbetzone.co.ao':
        case 'premierbetzone.co.ao':
        case 'test.sbazone.com':
        case 'sbazone.com':
        case 'test.guineegameszone.com':
        case 'guineegameszone.com':
        case 'test.mercurybetzone.com':
        case 'mercurybetzone.com':
        case 'test.nairabetzone.com':
        case 'nairabetzone.com':
            imageDirectory = `${window.location.origin}/`
            return imageDirectory;
            break;
        default:
            imageDirectory = `${window.location.origin}/${process.env.MAIN_COUNTRY_CODE.toLowerCase()}/`
            return imageDirectory;
    }
};

imageDirectory(hostname)
S.DropdownPopularTitle = styled('div')`
    padding:0.17rem 0;
    text-transform:uppercase;
    margin-left: 3.17rem;
    font-weight:900;
    color:#555555;
`
S.DropdownMenuList = styled(List)`
    width: 100%;

    &.slide-transition-enter {
        max-height: 0px;
        overflow: hidden;
        transition: max-height ${duration}ms;
    }
        
    &.slide-transition-enter-active {
        max-height: 1000px;
    }

    &.slide-transition-exit {
        max-height: 1000px;
        overflow: hidden;
        transition: max-height ${duration}ms;
    }
        
    &.slide-transition-exit-active {
        max-height: 0px;
    }   
`;

S.DropdownSubmenuList = styled(S.DropdownMenuList)`
    float:left;
    width: 100%;
    &.slide-transition-enter {
        max-height: 0px;
        overflow: hidden;
        transition: max-height ${duration}ms;
    }
        
    &.slide-transition-enter-active {
        max-height: 1000px;
    }

    &.slide-transition-exit {
        max-height: 1000px;
        overflow: hidden;
        transition: max-height ${duration}ms;
    }
        
    &.slide-transition-exit-active {
        max-height: 0px;
    }        
`;

S.Title = styled.span`
    overflow: hidden;
    flex: 1;
    padding: 0 10px 0 1rem;
    &.is-active{
        color: ${({theme:{$categoriesLvl3CheckboxActiveBorderColor}}) => $categoriesLvl3CheckboxActiveBorderColor};
    }
`;

S.Marker = styled.div`
    width: 3px;
    height: 100%;
    
    position: relative;
    margin-right: 1.5rem;
`;

S.Icon = styled.div`
    flex-shrink: 0;
    margin-right: 1rem;
    color: ${({theme:{$categoriesSportIconColor}}) => $categoriesSportIconColor};
`;

S.Arrow = styled.div`
    height: 100%;
    margin: 0 5px 0 0;
    display: flex;
    align-items: center;
    transition: transform 0.5s;
    margin-right: 5px;
    &:before {
        content: '';
        width:  1.2rem;
        height: .87rem;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        background-color: ${({ theme: { $categoriesDropdownItemLvl2ArrowColor } }) => $categoriesDropdownItemLvl2ArrowColor};
        mask-size:  1.2rem .87rem;
        -webkit-mask-size:  1.2rem .87rem;
        mask: url(${imageDirectory}images/svg/chevron_right_grey.svg) no-repeat 50% 50%;
        -webkit-mask: url(${imageDirectory}images/svg/chevron_right_grey.svg) no-repeat 50% 50%;
    }
`;

S.EventsCount = styled.span`
    display: inline-block;
    flex-shrink: 0;
    overflow: hidden;
    width: 3.8333rem;
    min-height: 1.8333rem;
    line-height: 1.3rem;
    border-radius: 1.25rem;
    padding: 0.25rem 0;
    background-color: #fff;
    color: #000;
    text-align: center;
    white-space: nowrap;
    font-weight: bold;
    font-size: 1.1rem;
    text-overflow: ellipsis;
`;

S.DropdownMenuItemInner = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 3.5rem;
    border-bottom: .08rem solid ${({theme:{$categoriesDropdownItemBorderBottom}}) => $categoriesDropdownItemBorderBottom};;
    overflow: hidden;
    box-shadow: none;
    border-radius: 0;
    font-size: 1.1rem!important;
    margin-top: 0;
    background: 0 0;
    background-color: ${({theme:{$categoriesDropdownItemBgColor}}) => $categoriesDropdownItemBgColor};
    padding: 0 1rem 0 0;
    color: ${({theme:{$categoriesDropdownItemColor}}) => $categoriesDropdownItemColor};
   
    &:hover {
        background-color: ${({theme:{$categoriesDropdownItemBgColorHover}}) => $categoriesDropdownItemBgColorHover};
    }
    &.sport-icon{
        text-align: inherit;
        font-family: inherit;
        &:before{
            padding-left: 1rem;
            font-family: 'icomoon', sans-serif;
        }
    }
`;

S.DropdownMenuItem = styled.li`
    position: relative;
    overflow: hidden;

    &[data-level="1"].is-active {
        &>${S.DropdownMenuItemInner}{

            & ${S.Title}
            {
                color: ${({theme:{$categoriesDropdownItemActiveColor}}) => $categoriesDropdownItemActiveColor};
            }
            & ${S.EventsCount}
            {
                background-color: ${({theme:{$categoriesDropdownItemActiveColor}}) => $categoriesDropdownItemActiveColor};
            }
            & ${S.Marker}
            {
                background-color: ${({ theme: { $categoriesMarkerColor } }) => $categoriesMarkerColor};
                &:after {
                    content: '';
                    position: absolute;
                    height: 0;
                    width: 0;
                    left: 3px;
                    border-top: 1.75rem solid transparent;
                    border-bottom: 1.75rem solid transparent;
                    border-left: 1.2rem solid ${({ theme: { $categoriesMarkerColor } }) => $categoriesMarkerColor};
                    display: block;
                }
            }
        }
    }

    &[data-level="2"]{
        &>${S.DropdownMenuItemInner}{
            background-color: ${({theme:{$categoriesDropdownItemLvl2BgColor}}) => $categoriesDropdownItemLvl2BgColor};
        }

        & ${S.Title}
        {
            padding-left: 3.17rem;
        }
        & ${S.Marker} {
            background-color: ${({ theme: { $categoriesMarkerColor } }) => $categoriesMarkerColor};
        }
    }

    &[data-level="2"].is-active {
        &>${S.DropdownMenuItemInner}
        {
            & ${S.Arrow}
            {
                transform: rotate(90deg);
                /* &:before {
                   background-color: ${({theme:{$categoriesDropdownItemLvl2ActiveArrowColor}}) => $categoriesDropdownItemLvl2ActiveArrowColor};
                } */
            }
        }
    }
    
    &[data-level="3"]{
        &>${S.DropdownMenuItemInner}
        {
            background-color: ${({theme:{$categoriesDropdownItemLvl3BgColor}}) => $categoriesDropdownItemLvl3BgColor};
        }
        & ${S.Title}
        {
            padding-left: 0;
        }
        & ${S.Marker}
        {
            background-color: transparent;
            margin-right: 3rem;
        }
    }
`;

S.FakeChbox = styled.div`
    width: 1.67rem;
    height: 1.67rem;
    border: .17rem solid ${({theme:{$categoriesLvl3CheckboxBorderColor}}) => $categoriesLvl3CheckboxBorderColor};
    background-color: ${({theme:{$categoriesLvl3CheckboxBgColor}}) => $categoriesLvl3CheckboxBgColor};
    border-radius: 0.1875rem;
    margin-right: 0.625rem;
    &.is-active {
        border-color: ${({theme:{$categoriesLvl3CheckboxActiveBorderColor}}) => $categoriesLvl3CheckboxActiveBorderColor};
        background-color:  ${({theme:{$categoriesLvl3CheckboxActiveBgColor}}) => $categoriesLvl3CheckboxActiveBgColor};
        &:after {
            content: "\f3fd";
            display: block;
            margin-top: -.8rem;
            margin-left: .25rem;
            color: #000;
            font-family: Ionicons;
            font-size: 2.2rem;
            font-weight: bold;
        }
}
`;

export default S;