import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {translation, formatMoney} from 'helpers/utilsHelper.js';
import {formatDate,compareAsc,substractDays,getTimeFilterRange} from 'helpers/datesHelper.js';
import Datepicker from 'Datepicker.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _some from 'lodash/some';
import _omit from 'lodash/omit';
import _find from 'lodash/find';
import _get from 'lodash/get';
import Loader from 'Loader.js';
import Pagination from 'Pagination.js';
import classNames from 'classnames';
import S from 'StyledAccountBetHistory.js';
import {
    changeDateByType,
    changeActiveTab,
    setCurrentPage,
    fetchTransactionsByFilter,
    fetchCashoutsMaximumAmount,
    resetBetHistoryList,
    refreshBetHistoryList,
    processCashoutAmount,
    processRebet,
    processLiveCurtainForTxId,
    toggleLiveCurtainForTxId
} from 'accountBetHistoryActions.js';

import BetHistoryTransactionItem from 'BetHistoryTransactionItem.js';
import { toggleAccountModal} from 'accountActions.js';

class AccountBetHistory extends Component {

    state = {
        isCountDownRequired: false,
    }

    componentDidMount = async() => {
        const {fetchTransactionsByFilter, startDate, endDate, limit, activeTab} = this.props;
        const params = {
            endDate,
            startDate,
            limit,
            activeTab
        };
        try {
            const data = await fetchTransactionsByFilter(params);
        } catch (error) {
            console.log('fetchTransactionsByFilter:' + error);
        }
    };

    handleChangeStart = (date) => {
        const {changeDateByType, endDate, limit, activeTab, fetchTransactionsByFilter} = this.props;
        const startDate = new Date(date);
        const params = {
            endDate,
            startDate,
            limit,
            activeTab
        };

        changeDateByType('startDate', startDate);
        fetchTransactionsByFilter(params);
    };

    handleChangeEnd = (date) => {
        const {changeDateByType, startDate, limit, activeTab, fetchTransactionsByFilter} = this.props;
        const endDate = new Date(date);
        const params = {
            endDate,
            startDate,
            limit,
            activeTab
        };

        changeDateByType('endDate', endDate);
        fetchTransactionsByFilter(params);
    };

    onChangeFilter = (activeTab) => {
        const {changeActiveTab, fetchTransactionsByFilter, startDate, endDate, limit} = this.props;
        const params = {
            activeTab,
            endDate,
            startDate,
            limit
        };

        changeActiveTab(activeTab);
        fetchTransactionsByFilter(params);
    };

    prepareCashout = async(slipId) => {
        const {fetchCashoutsMaximumAmount} = this.props;
        try {
            const maxAmount = await fetchCashoutsMaximumAmount(slipId);
            if (maxAmount.countDownRequired != undefined) {
                this.setState({isCountDownRequired: maxAmount.countDownRequired});
            }
            return maxAmount;
        } catch (error) {
            throw error;
        }
    };

    processCashout = async(config, abortControllerSignal) => {
        const curtainDelay = async(delayMS) => {
            const p = new Promise((resolve, reject) => {
                delayMS = Number(delayMS);
                const timerId = setTimeout(resolve, delayMS);
                abortControllerSignal.addEventListener('abort', () => {
                    clearTimeout(timerId);
                    reject({message: translation('account_cashoutForm_aborted')});
                });
            });
            return p;
        };

        const {processCashoutAmount, processLiveCurtainForTxId, transactionsList} = this.props;
        try {
            let slipId = _get(config, ['slipId']);
            slipId = Number(slipId);

            const hasCashoutLiveCurtain = process.env.CASHOUT_LIVE_CURTAIN && JSON.parse(process.env.CASHOUT_LIVE_CURTAIN);
            if (hasCashoutLiveCurtain && this.state.isCountDownRequired) {
                const curtainData = _omit({...config}, ['slipId', 'type']);
                const delayMS = await processLiveCurtainForTxId(slipId, curtainData);
                await curtainDelay(delayMS);
                const result = await processCashoutAmount(config);
                return result;
            }else{
                const result = await processCashoutAmount(config);
                return result;
            }
        } catch (error) {
            throw error;
        }
    };

    processRebet = async(eventId) => {
        const {processRebet} = this.props;
        try {
            const result = await processRebet(eventId);
            return result;
        } catch (error) {
            throw error;
        }
    };
    setStartDate = (days) => {
        const {changeDateByType, fetchTransactionsByFilter, activeTab, currentPage, limit} = this.props;
        const today = new Date();
        const startDate = substractDays(today, days);
        const endDate = today;
        const params = {
            startDate,
            activeTab,
            endDate,
            currentPage,
            limit
        };

        fetchTransactionsByFilter(params);
        changeDateByType('startDate', startDate);
        changeDateByType('endDate', endDate);
    };
    compareDates = (dateLeft, dateRight) => {
        dateLeft = new Date(formatDate(dateLeft, 'yyyy-MM-dd'));
        dateRight = new Date(formatDate(dateRight, 'yyyy-MM-dd'));
        const result = compareAsc(dateLeft, dateRight);
        if (!result) {
            return true;
        }
        return false;
    };
    goPage = async(currentPage) => {
        const {fetchTransactionsByFilter, startDate, endDate, limit, activeTab, setCurrentPage} = this.props;
        const offset = currentPage * limit;
        const params = {
            activeTab,
            endDate,
            startDate,
            limit,
            currentPage: offset
        };
        try {
            await fetchTransactionsByFilter(params);
            setCurrentPage(currentPage);
        } catch (error) {
            console.log('goPage:' + error);
        }
    };

    render() {
        
        const today = new Date();

        const { isPending, activeTab, startDate, endDate, limit, transactionsList, currentPage, cashouts, activeFreebets, currencyCode, refreshBetHistoryList, toggleAccountModal, toggleLiveCurtainForTxId } = this.props;

        return (
            <S.AccountBetHistory className="account-bet-history">
                <S.BetHistoryFilters className="bet-history-filters">

                    <S.BetHistoryFiltersDates className="bet-history-filters-dates">

                        <S.DateWrapper className="date-wrapper">
                            <S.DateLabel>{translation('common_dateFrom')}</S.DateLabel>
                            <S.DateInputWrapper>
                                <Datepicker
                                    selected={substractDays(today, 1)}
                                    selectsStart
                                    startDate={substractDays(today, 1)}
                                    endDate={endDate}
                                    dateFormat="yyyy-MM-dd"
                                    cb={this.handleChangeStart}
                                />
                                <S.DateIcon className="ion ion-ios-calendar-outline"></S.DateIcon>
                            </S.DateInputWrapper>
                        </S.DateWrapper>

                        <S.DateWrapper className="date-wrapper">
                            <S.DateLabel>{translation('common_dateTo')}</S.DateLabel>
                            <S.DateInputWrapper>
                                <Datepicker
                                    selected={endDate}
                                    selectsEnd
                                    startDate={substractDays(today, 1)}
                                    endDate={endDate}
                                    dateFormat="yyyy-MM-dd"
                                    cb={this.handleChangeEnd}
                                />
                                <S.DateIcon className="ion ion-ios-calendar-outline"></S.DateIcon>
                            </S.DateInputWrapper>
                        </S.DateWrapper>

                    </S.BetHistoryFiltersDates>
                    <S.FilterDayBtnsWrapper>

                        <S.FilterDayBtn className='--first' isActive={((this.compareDates(startDate, substractDays(today, 0)) || this.compareDates(startDate,substractDays(today,1))) && this.compareDates(endDate, today))}
                                     onClick={this.setStartDate.bind(this, 1)}>
                            {translation('account_betHistory_range_yesterday')}
                        </S.FilterDayBtn>

                        <S.FilterDayBtn isActive={(this.compareDates(startDate, substractDays(today, 7)) && this.compareDates(endDate, today))}
                                     onClick={this.setStartDate.bind(this, 7)}>
                            {translation('account_betHistory_range_week')}
                        </S.FilterDayBtn>

                        <S.FilterDayBtn className="--last" isActive={(this.compareDates(startDate, substractDays(today, 30)) && this.compareDates(endDate, today))}
                                     onClick={this.setStartDate.bind(this, 30)}>
                            {translation('account_betHistory_range_month')}
                        </S.FilterDayBtn>

                    </S.FilterDayBtnsWrapper>

                    <S.BetHistoryFiltersBtns className="bet-history-btns-wrapper">
                        <S.FilterBtnsLabel>
                        {translation('transactionHistory_filter')}
                        <S.FilterBtnsLabelAfter/>
                        </S.FilterBtnsLabel>
                        <S.FilterBtn
                            onClick={this.onChangeFilter.bind(this, 'all')}
                            className={`${classNames({active: (activeTab == 'all')})}`}>{translation('account_betHistory_types_all')}</S.FilterBtn>
                        <S.FilterBtn
                            onClick={this.onChangeFilter.bind(this, 'undecided')}
                            className={`--undecided ${classNames({active: (activeTab == 'undecided')})}`}>{translation('account_betHistory_types_undecided')}</S.FilterBtn>
                        <S.FilterBtn
                            onClick={this.onChangeFilter.bind(this, 'won')}
                            className={`--won ${classNames({active: (activeTab == 'won')})}`}>{translation('account_betHistory_types_won')}</S.FilterBtn>
                        <S.FilterBtn
                            onClick={this.onChangeFilter.bind(this, 'lost')}
                            className={`--lost ${classNames({active: (activeTab == 'lost')})}`}>{translation('account_betHistory_types_lost')}</S.FilterBtn>
                    </S.BetHistoryFiltersBtns>

                    {/* <S.BtnReset onClick={resetBetHistoryList}>{translation('common_reset')}</S.BtnReset>
                    <S.BtnRefresh onClick={refreshBetHistoryList}>{translation('common_refresh')}</S.BtnRefresh> */}

                </S.BetHistoryFilters>

                <S.BetHistoryTransactions className="bet-history-transaction-list">
                    {isPending ?
                        <Loader/>
                        :
                        (
                            <>
                            <S.BetHistoryTransactionsList className="transaction-list">

                            <S.FakeTableHead>
                                    {/* <div className="transaction-id">{translation('common_id')}</div> */}
                                    <div
                                        className={`transaction-date${(process.env.CASHOUT) ? '-cashout' : ''}`}>{translation('account_betHistory_date')}</div>
                                    <div
                                        className={`transaction-stake${(process.env.CASHOUT) ? '-cashout' : ''}`}>{translation('account_betHistory_stake')}</div>
                                    <div className="transaction-win">{translation('account_betHistory_possibleWin')}</div>
                                    {/* <div className="transaction-confirmed-win">{translation('account_betHistory_confirmedWin')}</div> */}

                                    {/* {process.env.BONUS_BET &&
                                    <div className="transaction-bonus">{translation('account_betHistory_bonusBet')}</div>} */}
                                    <div className="transaction-typ">{translation('account_betHistory_type')}</div>
                                     {/* {process.env.CASHOUT &&
                                    <div className="transaction-cashout">{translation('account_betHistory_cashout')}</div>} */}
                                    {/* {process.env.REBET_ALLOWED &&
                                    <div className="transaction-rebet">{translation('account_betHistory_rebet')}</div>} */}
                                    <div className="transaction-status">{translation('account_betHistory_status')}</div>
                                </S.FakeTableHead>

                                {_map(transactionsList, (transaction, index) => {
                                    const transactionItemProps = {
                                        index,
                                        currencyCode,
                                        transaction,
                                        transactionsList,
                                        cashouts,
                                        activeFreebets,
                                        refreshBetHistoryList,
                                        toggleLiveCurtainForTxId
                                    };
                                    return (
                                        <BetHistoryTransactionItem key={transaction.transactionId}
                                                                   className="transaction-list-item" {...transactionItemProps}
                                                                   prepareCashout={this.prepareCashout}
                                                                   processCashout={this.processCashout}
                                                                   processRebet={this.processRebet}
                                                                   toggleAccountModal={toggleAccountModal}
                                                >
                                        </BetHistoryTransactionItem>
                                    )
                                })}

                                {!_size(transactionsList) && (
                                    <S.NoResult className="no-result">{translation('account_betHistory_transfersNotFound')}</S.NoResult>)}

                            </S.BetHistoryTransactionsList>

                            {_size(transactionsList) > 0 && (
                                <Pagination currentPage={currentPage} limit={limit} data={transactionsList}
                                            cb={this.goPage}/>)}

                            </>
                        )
                    }
                </S.BetHistoryTransactions>

            </S.AccountBetHistory>
        );
    }
}

const mapStateToProps = ({AccountBetHistory:{isPending, error, startDate, endDate, limit, transactionsList, currentPage, activeTab, cashouts}, AccountFreebet:{freebets:{active:{data:activeFreebets}}}, Auth:{user:{currencyCode}}}, props) => {
    return {
        error,
        isPending,
        startDate,
        endDate,
        limit,
        currencyCode,
        activeTab,
        transactionsList,
        cashouts,
        activeFreebets,
        currentPage,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        changeActiveTab: bindActionCreators(changeActiveTab, dispatch),
        setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
        changeDateByType: bindActionCreators(changeDateByType, dispatch),
        fetchTransactionsByFilter: bindActionCreators(fetchTransactionsByFilter, dispatch),
        fetchCashoutsMaximumAmount: bindActionCreators(fetchCashoutsMaximumAmount, dispatch),
        resetBetHistoryList: bindActionCreators(resetBetHistoryList, dispatch),
        refreshBetHistoryList: bindActionCreators(refreshBetHistoryList, dispatch),
        processCashoutAmount: bindActionCreators(processCashoutAmount, dispatch),
        processRebet: bindActionCreators(processRebet, dispatch),
        toggleAccountModal: bindActionCreators(toggleAccountModal, dispatch),
        processLiveCurtainForTxId: bindActionCreators(processLiveCurtainForTxId, dispatch),
        toggleLiveCurtainForTxId: bindActionCreators(toggleLiveCurtainForTxId, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountBetHistory);
