import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom'
import {topMenuUserIconSVG} from 'global/svgIcons/svgIcons.js';
import Select from 'react-select';
import AccountMenu from 'AccountMenu.js';
import AccountRoute from 'AccountRoute.js';
import S from 'StyledAccount.js';
import {translation, moneyToFixedFloor} from 'helpers/utilsHelper.js';
import _find from 'lodash/find';
import _each from 'lodash/each';
import _size from 'lodash/size';
import {fetchFreebetByStatus} from 'freebetActions.js';
import {logOutUser} from 'authActions.js';
import accountMenuItems from 'accountMenuItems.enum.js';

/**
 * balance to separate component 
 * remove setState
 * remove freebetCount from state
 * connect params via redux
 */
class Account extends Component {

    static getDerivedStateFromError = (error) => {
        return {hasError: true};
    };

    state = {hasError: false, freebetCount: 0,activeTab:null};

    componentDidMount = async() => {
        const {fetchFreebetByStatus} = this.props;
        try {
            const config = {
                status: 'ACTIVE'
            };
            const {active} = await fetchFreebetByStatus(config);
            this.setState((prevState) => {
                return {...prevState, freebetCount: _size(active)};
            })

        } catch (error) {
            console.log('fetchFreebetByStatus:' + error);
        }
    };

    getCurrentTab = () => {
        const {location: {pathname}} = this.props;
        return Object.values(accountMenuItems).find(({href}) => href === pathname)
    }

    getBalanceList = () => {
        const {user:{bonusBalanceList, currencyCode, balance}} = this.props;
        const options = [];
        const mainBalance = {
            value: 0,
            label: `${translation('account_balance')}: ${moneyToFixedFloor(balance, 2)} ${currencyCode}`
        };

        options.push(mainBalance);

        _each(bonusBalanceList, ({nameKey, currency, wagerBonusId, amount}) => {
            const bonusBalance = {
                value: wagerBonusId,
                label: `${translation(nameKey)}: ${moneyToFixedFloor(amount, 2)} ${currency}`
            };
            options.push(bonusBalance);
        });

        return options;
    };

    selectedOption = () => {
        const currentBalanceId = localStorage.getItem('_bonus_balance_id') || this.props.balanceId;
        const selectedValue = _find(this.getBalanceList(), {value: Number(currentBalanceId)});
        return selectedValue;
    };
    handleActiveTabChange = (newValue) =>{
        this.setState((prevState) => {
            return {...prevState, activeTab: newValue};
        })
    }
    onBalanceChange = ({value}) => {
        const balanceId = value;
        const setBonusBalance = Boolean(balanceId);

        this.props.toggleBalance(setBonusBalance, balanceId);
        localStorage.setItem('_bonus_balance_id', balanceId);
    };


    render() {
        const {user:{login, userId, balance, currencyCode},logOutUser} = this.props;
        const {file, translationKey} = this.getCurrentTab()
        // if (hasError) {
        //     return <div>Error fallback component!</div>
        // }

        return (
            <S.AccountWrapper className="account-wrapper">

                    <S.AccountTop className="account-top">

                        <S.AccountTopLeftSide className="account-left-side">
                            <S.UserIcon dangerouslySetInnerHTML={topMenuUserIconSVG()}></S.UserIcon>
                            <S.UserInfo>
                                <S.UserName className="user-name">{login}</S.UserName>
                                <S.UserId className="user-id">{userId}</S.UserId>
                            </S.UserInfo>
                        </S.AccountTopLeftSide>

                        <S.AccountTopRightSide className="account-right-side">
                            <S.Image as="div" dangerouslySetInnerHTML={{__html: file}}/>
                            <S.ActiveTabTitle>{translation(translationKey)}</S.ActiveTabTitle>
                        </S.AccountTopRightSide>

                    </S.AccountTop>

                    <S.AccountBottom className="account-bottom">
                        <S.AccountSidebar className="account-sidebar">
                            <AccountMenu logOutUser={logOutUser} handleActiveTabChange={this.handleActiveTabChange} freebetCount={this.state.freebetCount}/>
                        </S.AccountSidebar>
                        <S.AccountBody className="account-body">
                            <AccountRoute/>
                        </S.AccountBody>
                    </S.AccountBottom>

            </S.AccountWrapper>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchFreebetByStatus: bindActionCreators(fetchFreebetByStatus, dispatch),
        logOutUser: bindActionCreators(logOutUser, dispatch),
        dispatch
    }
};
export default connect(null, mapDispatchToProps)(withRouter(Account));
