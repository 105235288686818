import React from 'react';
import S from 'StyledForgotPasswordForm.js';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {translation} from 'helpers/utilsHelper.js';
import ModalOpacity from 'ModalOpacity.js';
import { connect } from 'react-redux';
import useToggle from 'UseToggle.js'


// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    const errors = validation(values, validators.FORGOT_PASSWORD);
    return errors;
};

const renderField = ({
    input,
    label,
    type,
    errors,
    meta: {touched, error, warning}
}) => {
    return (
        <S.Row>
            <S.InputWrapper>
                <S.Input {...input} type={type} error={errors} required="required"/>
                <S.Label>{label}</S.Label>
                {touched && ((error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

const ForgotPasswordForm = ({forgotPasswordOnSubmit, toggleLoginModal, toggleForgetPasswordModal, toggleRegisterModal, toggleForgetPasswordVerificationModal, setForgotPasswordNumber,  handleSubmit, pristine, submitting, error}) => {

    const toggleRegisterAndForgotPasswordModal = ()=>{
        toggleForgetPasswordModal()
        toggleRegisterModal()
        toggleLoginModal()
    }

    const [showSuccessModal, toggleSuccessModal] = useToggle(false);

    const onFormSubmit = async(values) => {
        const mobileWithPrefix = values.mobilePrefix + values.mobile
        try {
            const formData = {
                mobile: mobileWithPrefix
            }
            await forgotPasswordOnSubmit(formData);
            setForgotPasswordNumber(mobileWithPrefix);
            toggleForgetPasswordVerificationModal();
            toggleForgetPasswordModal();
            toggleLoginModal();
        } catch (err) {
            throw new SubmissionError({_error: err});
        }
    };

    return (
        <S.ForgotPasswordForm method="post" onSubmit={handleSubmit(onFormSubmit)}>

            <ModalOpacity isOpen={showSuccessModal} toggleOpen={toggleSuccessModal} showHeaderIcon={false}
                          showHeaderTitle={false}>
                <S.SuccessModal>
                    <S.SuccessModalTitle>{translation('forgotPasswordForm_successTitle')}</S.SuccessModalTitle>
                    <S.SuccessModalMessage>{translation('forgotPasswordForm_resetPasswordSuccess')}</S.SuccessModalMessage>
                </S.SuccessModal>
            </ModalOpacity>

            <S.Info>{translation('forgotPasswordForm_text1')}</S.Info>

            <Field
                name="mobile"
                type="text"
                errors={error}
                component={renderField}
            />

            {error && <S.SubmissionError className="submission-error">{error}</S.SubmissionError>}

            <S.SubmitBtn type="submit" disabled={pristine || submitting}>{translation('forgotPasswordForm_recoverPassword')}</S.SubmitBtn>

            <S.CreateAccountContainer>
            <span>{translation('loginModal_createAccount')} <S.LoginLink onClick={toggleRegisterAndForgotPasswordModal}>{translation('loginModal_clickHere')}
            </S.LoginLink></span>
            </S.CreateAccountContainer>

        </S.ForgotPasswordForm>
    );
};

const mapStateToProps = (state) => {
    const {Auth: {brandData}} = state;
    return {
        initialValues: {
            mobilePrefix: brandData?.mobilePrefix ?? ''
        }
    }
}

let ForgotPasswordReduxForm = reduxForm({
    form: 'forgotPassword',
    validate
})(ForgotPasswordForm)

ForgotPasswordReduxForm = connect( mapStateToProps)(ForgotPasswordReduxForm);

export default ForgotPasswordReduxForm