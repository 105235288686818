import styled from 'styled-components';
import StyledDatepicker from 'StyledDatepicker.js';
import {List, Button} from 'global/styles/styles.js';
const S = {};

S.AccountCashback = styled.div`
    width: 100%;
    padding: .8333rem 2.5rem 0;
    padding-left: 30.7692px;
    padding-right: 30.7692px;
`;
S.CashbackFilters = styled.div`
    display: flex;
    justify-content: space-between;
`;
S.CashbackFiltersDates = styled.div`
    display: flex;
    & > div{
         margin-right: 10px;
         &:last-of-type{
             margin-right: 0;
         }
    }
`;
S.DateWrapper = styled.div`
    width: 237px;
    height: 46px;
    display: flex;
    position: relative;
    border-top: 2px solid #eee;
    border-bottom: 2px solid #eee;
    border-right: 2px solid #eee;
    & .react-datepicker-wrapper,
    & .react-datepicker__input-container{
       display: flex;
    }
    & .react-datepicker-wrapper{
       width: calc(100% - 4.5rem);
    }
    & .react-datepicker__input-container{
       width: 100%;
    }
    
    ${StyledDatepicker.Datepicker}{
        flex: 1;
        padding-left: 10px;
        border: none;
        font-size: 17px;
        font-weight: 700;
        width: 100%;
    }
`;
S.DateLabel = styled.div`
    display: flex;
    background-color: #eee;
    font-weight: 700;
    color: #676767;
    width: 4.5rem;
    justify-content: center;
    align-items: center;
`;
S.DateIcon = styled.i`
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 33px;
    font-size: 1.67rem;
    justify-content: center;
    align-items: center;
    display: flex !important;
    pointer-events: none;
`;

S.CashbackFiltersBtns = styled.div`
    display: flex;
    margin-left: 10px;
    & > button{
        margin-right: 15px;
        &:last-of-type{
            margin-right: 0;
        }
    }
`;
S.FilterBtn = styled(Button)`
    margin-top: 0;
    background-color: #eee;
    color: #bcbcbc;
    font-weight: 400;
    padding-left: 1rem;
    padding-right: 1rem;
    width: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 4px;
    display: block;
    justify-content: center;
    align-items: center;
    position: relative
    white-space: nowrap;
   
    &.active{
            color: #676767;
            font-weight: 700;
            text-overflow: ellipsis;
            overflow: hidden;
           
           &:after{
            content: '';
            position: absolute;
            background-color: #676767;
            height: .25rem;
            bottom: 6px;
            left: 1rem;
            right: 1rem;
           } 
    }
`;

S.Table = styled.table`
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
`;

S.Head = styled.thead`
`;

S.Body = styled.tbody``;

S.Row = styled.tr`
    border-top: 0;
    
    &:before,
    &:after{
     display: none !important;
    }
    
    &.no-result{
        height: 40px;
        padding: 0 1.25rem;
        line-height: 4rem!important;
        font-size: 1rem;
        background-color: #f3f3f3;
        color: #000;
        border-right: .08rem solid #fff;
        border-top: .08rem solid #fff;
      
        & > td{
            text-align: center;
        }
    }
`;

S.Td = styled.td`
    padding: 0 1.25rem;
    line-height: 4rem!important;
    font-size: 1rem;
    background-color: #f3f3f3;
    color: #000;
    border-right: .08rem solid #fff;
    border-top: .08rem solid #fff;
    vertical-align: top;
`;

S.Th = styled.th`
    background-color: #eaeaea;
    color: #a3a3a3;
    padding: .4rem 1.25rem;
    font-size: 1rem;
    font-weight: 400;
    border-right: .08rem solid #fff;
    line-height: 1.42857143;
    vertical-align: bottom;
    border-top: 0;
`;

S.CashbackList = styled.div``;
export default S;