import {formatDate} from 'helpers/datesHelper.js';
const encode = encodeURIComponent;
const today = new Date();

export default (requests, axios) => {
    return {
        getTransactionsByFilter: ({activeTab='all', endDate = today, startDate = today, limit = 10, currentPage = 0, forceCache = false}={}) => {
            const dateFrom = formatDate(startDate, 'yyyy-MM-dd');
            const dateTo = formatDate(endDate, 'yyyy-MM-dd');
            const sessionCookie = Cookies.get('X-ODDS-SESSION');
            const config = {};
            if (sessionCookie) {
                config['headers'] = {'X-ODDS-SESSION': sessionCookie};
            }
            if (CLIENT == 'fuksiarz'){
                return requests.get(`/transaction/transfers/${dateFrom}/${dateTo}/${limit}/${currentPage}/${activeTab}`, config);
            } else {
                return requests.get(`/transaction/transfers/${dateFrom}/${dateTo}/${limit}/${currentPage}/${activeTab}/${forceCache}`, config);
            }
        },
        cancellPayout: (data) => {
            return requests.post('/payment/payment/finalize', data);
        }
    }
};
