const encode = encodeURIComponent;

export default (requests) => {
    return {
        calculatePossibleWin: (data) => {
            return requests.post('/betting/bet/calculate-winning/web', data);
        },
        calculateTaxFactor: (data) => {
            return requests.post('/betting/bet/calculate-tax-factor/web', data);
        },
        placeBet: (data) => {
            return requests.post('/betting/bet/place-bet/web', data);
        },
        betInShop: (data) => {
            return requests.post('/betting/bet/place-bet-in-shop', data);
        },
        placeBetForApproval: (data) => {
            return requests.post('/betting/bet/approve-bet/web', data);
        },
        fetchApprovedSlips: () => {
            return requests.get('/customer/session/approvedSlips');
        },
        rejectTraderChanges: () => {
            return requests.get(`/betting/bet/approve-trader-change/false`);
        },
        acceptTraderChanges: () => {
            return requests.get(`/betting/bet/approve-trader-change/true`);
        },
        getLucky: (fromOdds, toOdds, stake, amount, today, tomorrow, dayAfter) => {
            return requests.get(`/betting/betslip/get-lucky/${fromOdds}/${toOdds}/${stake}/${amount}/${today}/${tomorrow}/${dayAfter}`);
        },
        getBestsellersOutcomes: () => {
            return requests.get('betting/outcomes/bestsellers');
        },
        getTotalBonusData: (data) => {
            return requests.post('/betting/bet/calculate-totalbonus/web', data);
        },
        calculatePropositionOffers: (data) => {
            return requests.post('/betting/betslip/cashback/web/false', data);
        },
        calculateBetSlipData: (data) => {
            return requests.post('/betting/bet/calculate/web', data);
        },
        updateOddsForCustomLiveBets: (outcomeIds)=>{
            return requests.get(`/custom-bet/update-odds-bulk/${outcomeIds}`)
        },
    }
}
