import _get from 'lodash/get';

const limits = {
    DAILY_STAKE_LIMIT: 1,
    WEEKLY_STAKE_LIMIT: 2,
    MONTHLY_STAKE_LIMIT: 3,

    DAILY_MAX_LOSS_LIMIT: 4,
    WEEKLY_MAX_LOSS_LIMIT: 5,
    MONTHLY_MAX_LOSS_LIMIT: 6,

    DAILY_MAX_DEPOSIT: 7,
    WEEKLY_MAX_DEPOSIT: 8,
    MONTHLY_MAX_DEPOSIT: 9,

    SESSION_TIME_LIMIT: 10,
    BETTING_EXCLUSION_TIME_LIMIT: 11,

    MAX_STAKE_LIMIT: 12,
    DAILY_SESSION_TIME_LIMIT: 13,
    WEEKLY_SESSION_TIME_LIMIT: 14,
    MONTHLY_SESSION_TIME_LIMIT: 15,

    UNITS: {
        time: [10, 11, 13, 14, 15],
        currency: [1, 2, 3, 4, 5, 6, 7, 8, 9, 12]
    }
};

const getLimitIdByKey = (key) => {
    return _get(limits, [key]);
};

export {limits as default, getLimitIdByKey};