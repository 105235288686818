import React, {Component} from 'react';
import S from 'StyledCmsPage.js';
import _find from 'lodash/find';
import _size from 'lodash/size';
import _each from 'lodash/each';
import {cmsPage, createMarkup} from 'cmsActions.js';
import {connect} from 'react-redux';
import Loader from 'Loader.js';
// import MetaTags from 'MetaTags.js';

class CmsPage extends Component {

  constructor(props){
    super(props);
    this.cmsContainerRef = React.createRef();
    this.state = {
      noIndex: false
    }
  }

  componentDidMount = async () => {
      var urlsToRedirectError = [
          '/category-top',
          '/category-bottom'
        ]

        for(let url of urlsToRedirectError) {
          if(location.pathname.startsWith(url)) {
            this.setState({noIndex: true})
          }
        }

        const {cmsPage, name, isPlaces, alternativeContent, countryCode, languageCode, brand} = this.props;
        try {
            await cmsPage(name, null, isPlaces, alternativeContent, countryCode, languageCode, brand);
            this.processScripts();
        } catch (error) {
            console.log('cmsPageError:', error);
        }
  };

  componentWillUnmount = () => {
    document.querySelector('head meta[name="robots"][content="noindex, nofollow"]')?.remove();
  };

    processScripts = () => {
        const cmsContainerSelector = this.cmsContainerRef.current;
        if (!_size(cmsContainerSelector)) return;

        const scripts = [...cmsContainerSelector.querySelectorAll('script')];
        _each(scripts, (dom) => {
            const scriptContent = dom.innerText;
            const fn = new Function(scriptContent);
            fn();
        });
    };

  render() {
    let {name, cmsData, isPending, isCmsPageExist, error, isPlaces = false} = this.props;

    const cmsPage = _find(cmsData, {name})?? {};
    let html = '';
    let metaParams = null;
    if(_size(cmsPage)){
       html = cmsPage.data.html;
       let {title, metaDescriptions, tags} = cmsPage.data;
       metaParams = {title, metaDescriptions, tags, isPlaces};

       if(this.state.noIndex) {
        metaParams['noIndex'] = true;
      }
    }

    return (
      <S.CmsPage className="cms-page">
        
      {error ? (null):((isPending && !isCmsPageExist) || !_size(cmsPage)) ?
          <Loader/>
          :
          (
              <>
              {/* <MetaTags {...metaParams}/> */}
              <S.CmsWrapper ref={this.cmsContainerRef} dangerouslySetInnerHTML={createMarkup(html)}></S.CmsWrapper>
              </>
          )

      }
    </S.CmsPage>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {CmsPages:{cmsData, isPending, error}} = state;
  const isCmsPageExist = _find(cmsData, {name: props.name}) ? true : false;
  return {
    cmsData,
    isPending,
    error,
    isCmsPageExist
  }
};

const mapDispatchToProps = {
    cmsPage
};

export default connect(mapStateToProps, mapDispatchToProps)(CmsPage);