import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {loadPersonalData, updatePersonalData} from 'customerActions.js';
import {changePassword} from 'authActions.js';
import Loader from 'Loader.js';
import AccountEditProfileForm from 'AccountEditProfileForm.js';
import S from 'StyledAccountEditProfile.js';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

class AccountEditProfile extends Component {

    componentDidMount = async() => {
      this.refreshData();
    };

    refreshData = async() => {
      const {loadPersonalData} = this.props;
      try {
          await loadPersonalData();
      } catch (error) {
          console.log('loadPersonalData->', error);
      }
  };


    updateUserDataOnSubmit = async(values) => {
        const {updatePersonalData} = this.props;
        try {
            const result = await updatePersonalData(values);
            return result;
        } catch (error) {
            throw error;
        }
    };

    changePasswordOnSubmit = async(values) => {
        const {changePassword} = this.props;

        try {
            const result = await changePassword(values);
            return result;
        } catch (error) {
            throw error;
        }
    };

    render() {
        const {userData, isPending, userId} = this.props;
        const initialValues = {};

        const countries = app.service.Language.cachedDict.countries;
        const mainCountryCode = process.env.MAIN_COUNTRY_CODE; 

        if (!_isEmpty(userData)) {
            initialValues['mobileNumber'] = _get(userData, 'mobileNumber');
            initialValues['email'] = _get(userData, 'email');
            initialValues['lastName'] = _get(userData, 'lastName');
            initialValues['firstName'] = _get(userData, 'firstName');
            initialValues['dateOfBirth'] = _get(userData, 'dateOfBirthAsString') || new Date('01-01-1950');
            initialValues['loginName'] = _get(userData, 'loginName');
            initialValues['address'] = _get(userData, 'address');
            initialValues['city'] = _get(userData, 'city');
            initialValues['country'] = countries[mainCountryCode];
        }

        return (
            <S.AccountEditProfile className="account-edit-profile">
                {(isPending) ?
                    <Loader/>
                    :
                    (<AccountEditProfileForm initialValues={initialValues}
                                             userData={userData}
                                             userId={userId}
                                             updateUserDataOnSubmit={this.updateUserDataOnSubmit}
                                             refreshData={this.refreshData}
                                             changePasswordOnSubmit={this.changePasswordOnSubmit}/>)
                }
            </S.AccountEditProfile>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {Customer:{userData, error, isPending}, Auth:{user:{userId}}} = state;
    return {
        userData,
        error,
        isPending,
        userId
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadPersonalData: bindActionCreators(loadPersonalData, dispatch),
        updatePersonalData: bindActionCreators(updatePersonalData, dispatch),
        changePassword: bindActionCreators(changePassword, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountEditProfile);
