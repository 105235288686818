import validation from 'sb-validate-js/validate.js';
import {translation} from 'utilsHelper.js';
import {substractYears, formatDate} from 'datesHelper.js'
import {validateDO, isPeselAdult, isPeselValid} from 'validateHelper.js';
import _range from 'lodash/range';
import _repeat from 'lodash/repeat';
import _reduce from 'lodash/reduce';
import _size from 'lodash/size';

validation.validators.isAdult = function (value, {message, latest}) {
    if (!value) return null;
    const adult = value <= latest;
    return !adult ? message() : null;
};

validation.validators.checkMobileNumberPattern = function (value, {message}) {
    if (!value) return null;
    const brandId = process.env.BRAND_ID;
    let pattern;
    switch (brandId) { 
        case 5:
            pattern = /^[0-9]{9,11}$/;
            break;
        case 8:
            pattern = /^[1-9]\d{7,10}$/;
            break;
        case 14:
            pattern = /^[1-9]\d{7,10}$/;
            break;
        case 23:
            pattern = /^[1-9]\d{7,10}$/;
            break;
        default:
            pattern = /^[1-9][0-9]{8,10}$/;
            break;
    }
    
    if (value.match(pattern)) {
        return null;
    } else {
        return message();
    }
}

export default {
    REGISTER_STEP1: {
        firstName: {
            presence: {
                message: () => translation('account_registerForm_firstNameEmpty'),
            },
        },
        lastName: {
            presence: {
                message: () => translation('account_registerForm_lastNameEmpty'),
            },
        },
        password: {
            length: {
                minimum: 8,
                maximum: 25,
                tooShort: () => translation('account_registerForm_passwordTooShort'),
                tooLong: () => translation('account_registerForm_passwordTooLong'),
            },
            format: {
                pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$/,
                message: () => translation('account_registerForm_wrongPasswordPattern'),
            },
            presence: {
                message: () => translation('account_registerForm_passwordEmpty'),
            },
        },
        confirmPassword: {
            presence: {
                message: () => translation('account_registerForm_passwordEmpty'),
            },
            equality: {
                attribute: 'password',
                message: () => translation('account_registerForm_passwordsNotEqual'),
            }
        },
        mobile: {
            presence: {
                message: () => translation('account_registerForm_phoneEmpty'),
            },
           format: {
                pattern: /^[1-9][0-9]{8}$/,
               message: () => translation('account_registerForm_wrongPhoneNumberPattern'),
            },
            length: {
                minimum: 9,
                maximum: 9,
                tooShort: () => translation('account_registerForm_phoneTooShort'),
                    tooLong: () => translation('account_registerForm_phoneTooLong'),
            },
        },
        email: {
            email : {
                message: () => translation('account_registerForm_wrongEmailPattern')
            }
        },
        acceptRules: {
            inclusion: {
                within: [true],
                message: () => translation('account_registerForm_acceptTerms'),
            },
            presence: {
                message: () => translation('account_registerForm_acceptTerms'),
            },
        },
        dateOfBirth: {
            isAdult: {
                latest: substractYears(new Date, 18),
                message: () => translation('account_registerForm_notAdult')
            }
        },
    },
    REGISTER_STEP2: {
        verificationCode: {
            presence: {
                message: () => translation('account_registerForm_verificationCode'),
            }
        }
    },
    LOGIN: {
        login: {
            presence: {
                message: () => translation('loginModal_loginEmpty'),
            },
            checkMobileNumberPattern: {
                message: ()=> translation('loginModal_wrongMobileNumberPattern')
            }
        },
        password: {
            presence: {
                message: () => translation('loginModal_passwordEmpty'),
            }
        }
    },
    FORGOT_PASSWORD: {
        mobile: {
            presence: {
                message: () => translation('loginModal_loginEmpty'),
            },
            checkMobileNumberPattern: {
                message: ()=> translation('loginModal_wrongMobileNumberPattern')
            }
        },
    },
    CASHOUT: {
        partialAmount: {
            // presence: {
            //     message: () => translation('cashout_PartialAmountEmpty'),
            // },
            // format: {
            //     pattern: /^\d{0,2}(\.\d{1,2})?$/,
            //     message: 'cashout_InvalidValue',
            // },
            numericality: {
                attribute: 'maximumAmount',
                lessThan: 'maximumAmount',
                notLessThan: () => translation('account_cashout_partialAmount_maxAmount'),
                notValid: () => translation('account_cashout_notValidNumber'),
            },
        },
    },
    CHANGE_PASSWORD: {
        oldPassword: {
            presence: {
                message: () => translation('account_changePasswordForm_oldPasswordEmpty'),
            },
        },
        newPassword: {
            presence: {
                message: () => translation('account_changePasswordForm_newPasswordEmpty'),
            },
            equality: {
                attribute: 'confirmPassword',
                message: () => translation('account_changePasswordForm_passwordsNotEqual'),
            },
            length: {
                minimum: 8,
                maximum: 25,
                tooShort: () => translation('account_changePasswordForm_passwordTooShort'),
                tooLong: () => translation('account_changePasswordForm_passwordTooLong'),
            },
            format: {
                pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$/,
                message: () => translation('account_changePasswordForm_wrongPasswordPattern'),
            },
        },
        confirmPassword: {
            presence: {
                message: () => translation('account_changePasswordForm_confirmPasswordEmpty'),
            },
            equality: {
                attribute: 'newPassword',
                message: () => translation('account_changePasswordForm_passwordsNotEqual'),
            },
        },
    },
    RESET_PASSWORD: {
        newPassword: {
            presence: {
                message: () => translation('account_changePasswordForm_newPasswordEmpty'),
            },
            equality: {
                attribute: 'confirmPassword',
                message: () => translation('account_changePasswordForm_passwordsNotEqual'),
            },
            length: {
                minimum: 8,
                maximum: 25,
                tooShort: () => translation('account_changePasswordForm_passwordTooShort'),
                tooLong: () => translation('account_changePasswordForm_passwordTooLong'),
            },
            format: {
                pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$/,
                message: () => translation('account_changePasswordForm_wrongPasswordPattern'),
            },
        },
        confirmPassword: {
            presence: {
                message: () => translation('account_changePasswordForm_confirmPasswordEmpty'),
            },
            equality: {
                attribute: 'newPassword',
                message: () => translation('account_changePasswordForm_passwordsNotEqual'),
            },
        },
    },
    EDIT_ACCOUNT: {
        email: {
            email: {
                message: () => translation('account_registerForm_wrongEmailPattern')
            }
        },
        mobileNumber: {
            presence: {
                message: () => translation('account_editProfileForm_phoneEmpty'),
            }
        },
        countryOfBirth: {
            presence: {
                message: () => translation('account_editProfileForm_countryEmpty'),
            }
        },
        city: {
            presence: {
                message: () => translation('account_editProfileForm_cityEmpty'),
            }
        }
    },
    PARAGONEX_AUTHORIZATION: {
        password: {
            length: {
                minimum: 5,
                maximum: 20,
                tooShort: () => translation('paragonex_passwordTooShort'),
                tooLong: () => translation('paragonex_passwordTooLong'),
            },
            presence: {
                message: () => translation('paragonex_passwordEmpty'),
            },
        },
        confirmPassword: {
            presence: {
                message: () => translation('paragonex_passwordEmpty'),
            },
            equality: {
                attribute: 'password',
                message: () => translation('paragonex_passwordsNotEqual'),
            }
        },
    },
    PARAGONEX_TRANSFER: {
        payoutAmount: {
            presence: {
                message: () => translation('paragonex_payoutAmountEmpty'),
            },
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('paragonex_wrongAmountPattern'),
            },
        },
        depositAmount: {
            presence: {
                message: () => translation('paragonex_depositAmountEmpty'),
            },
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('paragonex_wrongAmountPattern'),
            },
        }
    }
};

