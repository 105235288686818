import {createSelector} from 'reselect';

const getNestedData = (arr, parent) => {
    let self = this;
    let out = [];

    for (let i in arr) {
        if (arr[i].parentCategory == parent) {
            let children = getNestedData(arr, arr[i].categoryId);

            if (children.length) {
                arr[i].children = children;
            }
            out.push(arr[i]);
        }
    }
    return out.sort(sortData);
};

const sortData = (a, b) => {
    if (a.sortOrder < b.sortOrder) return -1;
    if (a.sortOrder > b.sortOrder) return 1;

    return 0;
};

const categoriesSelector = state => state.Categories.categories;
const dropdownMenuSelector = createSelector(
    categoriesSelector,
    items => getNestedData(items, 0)
)

export {dropdownMenuSelector}