export const CHANGE_ACTIVE_TAB = 'CHANGE_FREEBET_ACTIVE_TAB'
export const CHANGE_DATE_BY_TYPE = 'CHANGE_FREEBET_DATE_BY_TYPE';
export const FETCH_FREEBET_LIST = 'FETCH_FREEBET_LIST';
export const SET_CURRENT_PAGE = 'SET_FREEBET_CURRENT_PAGE';
export const RESET_TO_INITIAL_STATE = 'RESET_FREEBETS_TO_INITIAL_STATE';

const resetToInitialState = ()=>{
    return {
        type: RESET_TO_INITIAL_STATE
    }
}

const setCurrentPage = (currentPage, status) => {
    status = status.toLowerCase();
    return {
        type: SET_CURRENT_PAGE,
        payload: {currentPage, status}
    }
};

const changeActiveTab = (activeTab) => {
    return {
        type: CHANGE_ACTIVE_TAB,
        payload: {
            activeTab
        }
    }
};

const changeDateByType = (dateType, date) => {
    return {
        type: CHANGE_DATE_BY_TYPE,
        payload: {
            dateType,
            date
        }
    }
};

const fetchFreebetsByFilter = (config = {}) => {
    return async(dispatch, getState, {AccountFreebetApi}) => {

        try {
            const {code, data} = await AccountFreebetApi.getFreebetByFilter(config);
            const {status = 'ACTIVE'} = config;
            dispatch(fetchFreebetListSuccess(data, status));
            return data;
        } catch (error) {
            throw error;
        }
    }
};

const fetchAllFreebets = (config = {}) => {
    return async(dispatch) => {
        dispatch(fetchFreebetListPending());

        try {
            const activeFreebetPromise = dispatch(fetchFreebetsByFilter(config));
            const historyFreebetPromise = dispatch(fetchFreebetsByFilter({...config, status: 'HISTORY'}));
            const result = await Promise.all([activeFreebetPromise, historyFreebetPromise]);

            dispatch(fetchFreebetListFulfilled(result));

            return result;
        } catch (error) {
            dispatch(fetchFreebetListFailure(error));
            throw error;
        }
    }
};

const fetchFreebetListFulfilled = (result = []) => {
    return {
        type: `${FETCH_FREEBET_LIST}_FULFILLED`,
        payload: {result}
    }
};

const fetchFreebetListSuccess = (data, status) => {
    status = status.toLowerCase();
    data = data[status];
    return {
        type: `${FETCH_FREEBET_LIST}_SUCCESS`,
        payload: {data, status}
    }
};

const fetchFreebetListPending = () => {
    return {
        type: `${FETCH_FREEBET_LIST}_PENDING`
    }
};

const fetchFreebetListFailure = (error) => {
    return {
        type: `${FETCH_FREEBET_LIST}_FAILURE`,
        payload: {error}
    }
};

export {changeDateByType, fetchFreebetsByFilter, setCurrentPage, fetchAllFreebets, changeActiveTab, resetToInitialState}