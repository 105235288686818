import React, {useState} from 'react';
import S from 'StyledUnloggedPanel.js';
import {translation} from 'helpers/utilsHelper.js';
import ModalOpacity from 'ModalOpacity.js';
import useToggle from 'UseToggle.js'
import LoginForm from 'LoginForm.js';
import RegisterWizardForm from 'RegisterWizardForm.js';
import ForgotPasswordForm from 'ForgotPasswordForm.js';
import ForgotPasswordVerificationForm from 'ForgotPasswordVerificationForm.js'
import {integrationBetgeniusCreateScript} from 'integrationActions.js';

const UnloggedPanel = ({loginOnSubmit, registerOnSubmit, forgotPasswordOnSubmit, resetPasswordOnSubmit}) => {

    const [showLoginModal, toggleLoginModal] = useToggle(false);
    const [showRegisterModal, toggleRegisterModal] = useToggle(false);
    const [showForgetPasswordModal, toggleForgetPasswordModal] = useToggle(false);
    const [showForgetPasswordVerificationModal, toggleForgetPasswordVerificationModal] = useToggle(false);
    const [forgotPasswordNumber, setForgotPasswordNumber] = useState();

    if(showRegisterModal){
        if(process.env.BETGENIUS_TAGS_INTEGRATION_SCRIPT == 'true'){
            integrationBetgeniusCreateScript('regstart', null);
        }
    }
    return (
        <S.UnloggedPanel className="unlogged-panel">

        
            <S.ButtonsWrapper className="buttons-wrapper">
                <S.RegisterButton className="register-btn"
                          onClick={toggleRegisterModal}>
                    {translation('account_unloggedPanel_header_register')}
                </S.RegisterButton>
                <S.LoginButton className="login-btn"
                          onClick={toggleLoginModal}>
                    {translation('account_unloggedPanel_header_login')}
                </S.LoginButton>
            </S.ButtonsWrapper>
              
            <ModalOpacity isOpen={showLoginModal} titleColor="#fff" toggleOpen={toggleLoginModal} backgroundBody="#DEDEDE" backgroundHeader="#019A44" title={translation('loginModal_title')} padding="0px" width="35rem" closeIconImg="/images/svg/header_close_white.svg">
                <LoginForm toggleLoginModal={toggleLoginModal} loginOnSubmit={loginOnSubmit} toggleForgetPasswordModal={toggleForgetPasswordModal} toggleRegisterModal={toggleRegisterModal}/>
                {/* placeholder */}
            </ModalOpacity>

            <ModalOpacity titleColor="#000" backgroundHeader="#FAE100"  backgroundBody="#DEDEDE" isOpen={showRegisterModal} toggleOpen={toggleRegisterModal} title="Register" padding="0px" width="40rem">
                 <RegisterWizardForm  toggleRegisterModal={toggleRegisterModal} registerOnSubmit={registerOnSubmit} /> 
                
            </ModalOpacity>

            <ModalOpacity isOpen={showForgetPasswordModal} toggleOpen={toggleForgetPasswordModal} title={translation('forgotPasswordModal_title')} showHeaderIcon={false} titleColor="#fff" backgroundBody="#DEDEDE" backgroundHeader="#019A44" padding="0px" width="35rem" closeIconImg="/images/svg/header_close_white.svg">
                <ForgotPasswordForm toggleLoginModal={toggleLoginModal} forgotPasswordOnSubmit={forgotPasswordOnSubmit} toggleRegisterModal={toggleRegisterModal} toggleForgetPasswordModal={toggleForgetPasswordModal} toggleForgetPasswordVerificationModal= {toggleForgetPasswordVerificationModal} setForgotPasswordNumber = {setForgotPasswordNumber}/>
            </ModalOpacity>

            <ModalOpacity isOpen={showForgetPasswordVerificationModal} toggleOpen={toggleForgetPasswordVerificationModal} title={translation('forgotPasswordVerificationModal_title')} showHeaderIcon={false} titleColor="#fff" backgroundBody="#DEDEDE" backgroundHeader="#019A44" padding="0px" width="35rem" closeIconImg="/images/svg/header_close_white.svg">
                <ForgotPasswordVerificationForm toggleForgetPasswordVerificationModal={toggleForgetPasswordVerificationModal} resetPasswordOnSubmit={resetPasswordOnSubmit} forgotPasswordNumber={forgotPasswordNumber}/>
            </ModalOpacity>

        </S.UnloggedPanel>
    );
};

export default UnloggedPanel;
