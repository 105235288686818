import React, {useState}  from 'react';
import _get from 'lodash/get';
import S from 'StyledBetHistoryTransactionItem.js';
import useToggle from 'UseToggle.js'
import ModalOpacity from 'ModalOpacity.js';
import BetHistoryItemDetails from 'BetHistoryItemDetails.js';
import {formatDate} from 'helpers/datesHelper.js';
import {translation} from 'helpers/utilsHelper.js'
import {getTransactionResultStatusText} from 'helpers/transactionsHelper.js'
import betSlipTypes from 'betSlipTypes.enum.js';
import CashoutForm from 'CashoutForm.js';

const BetHistoryTransactionItem = ({
    transaction,
    transactionsList,
    currencyCode,
    index,
    cashouts,
    activeFreebets,
    prepareCashout,
    processCashout,
    refreshBetHistoryList,
    processRebet,
    toggleAccountModal,
    toggleLiveCurtainForTxId
}) => {

    const {transactionId, regDate, stake, possibleReturn, confirmedReturn, bonus, statusCode, slipType, curtainActive = false,tax, maxAmount = ''} = transaction;
    const [showTransactionDetails, toggleTransactionDetails] = useToggle(false);
    const [showCashoutModal, toggleCashoutModal] = useToggle(false);
    const [showCashoutErrorModal, toggleCashoutErrorModal] = useToggle(false);
    const [cashoutErrorCode, setCashoutErrorCode] = useState('');

    const isRebetPossible = (index) => {
        let possibleRebet = false;
        const transactionByIndex = transactionsList?.[index]?.transactionDetails ?? [];
        const now = new Date().getTime();

        for (let i = 0; i < transactionByIndex.length; i++) {
            if (transactionByIndex[i].eventStart >= now) {
                possibleRebet = true;
                break;
            }
        }
        return possibleRebet;
    };

    const addRebet = () => {
        try {

            app.service.BetSlip.clearBetSlip();
            const transactionByIndex = transactionsList?.[index]?.transactionDetails ?? [];
            const now = new Date().getTime();

            for (let i = 0; i < transactionByIndex.length; i++) {
                if (transactionByIndex[i].eventStart >= now) {
                    const outcome = transactionByIndex[i];
                    addOutcomeToSlip(outcome);
                }
            }

            app.service.BetSlip.betSlip.stake = _get(transactionsList[index], 'stake');
            app.service.BetSlip.onStakeChange();
            toggleAccountModal(false);

        } catch (error) {
            console.log({error});
        }
    };

    const addOutcomeToSlip = (singleOutcome) => {
        const {eventId, outcomeId, eventType, gameId, sportId, outcomeName, eventName, gameName, odds} = singleOutcome;

        const outcome = {
            outcomeId: outcomeId,
            outcomeLive: eventType === 2,
            gameId: gameId,
            eventType: eventType,
            eventId: eventId,
            sportId: sportId,
            combinationType: 1,
            outcomeName: outcomeName,
            eventName: eventName,
            gameName: gameName,
            outcomeOdds: odds
        };
        app.service.BetSlip.selectOutcomeWithObject(outcome);
    };


    // poprawić
    const getTransactionStatusClass = (statusCode, stake, confirmedReturn, pretty, cashBackReturn) => {

        if (cashBackReturn) {
            return 'cashback';
        }

        let resultText = '';

        if (statusCode == 2) {
        resultText = getTransactionResultStatusText(app.config.betIsWonIfConfirmedReturnIsGtZero,stake,confirmedReturn)

        } else {

            switch (statusCode) {
                case 0:
                    resultText = 'pending_acceptation';
                    break;
                case 1:
                    resultText = 'placed';
                    break;
                case 2 :
                    resultText = 'placed';
                    break;
                case 4 :
                    resultText = 'cancel';
                    break;
                case 5 :
                    resultText = 'cashout';
                    break;
                default:
                    resultText = 'default';
                    break;
            }
        }

        if (pretty && resultText) {
            resultText = resultText.substring(0, 1).toUpperCase() + resultText.substring(1, resultText.length);
        }

        return resultText;
    };

    const checkCashout = (slipId) => {
        return (cashouts && cashouts.indexOf(slipId) !== -1);
    };

    const checkFreebet = (slipId) => {
        return (activeFreebets && activeFreebets.indexOf(slipId) !== -1);
    };

    const parseSlipType = (slipType) => {
        return betSlipTypes.getByType(slipType).name;
    };

    const printTransaction = (e, slipId) => {
        const printFromHistory = true;
        app.component.BetSlip.printTicket(slipId, printFromHistory);
        e.stopPropagation();
    }
        
    const transactionStatusClass = getTransactionStatusClass(statusCode, stake, confirmedReturn);
    const transactionStatus = getTransactionStatusClass(statusCode, stake, confirmedReturn, true);
    const cashoutFormProps = {
        toggleLiveCurtainForTxId,
        curtainActive,
        refreshBetHistoryList,
        transactionId,
        stake,
        possibleReturn,
        maxAmount,
        toggleCashoutModal,
        toggleCashoutErrorModal,
        prepareCashout,
        currencyCode,
        cachoutOnSubmit: processCashout,
        initialValues: {maximumAmount: maxAmount}
    };

    return (
        <S.BetHistoryItemWrapper className="bet-history-item-wrapper">

            <ModalOpacity isOpen={showCashoutModal} toggleOpen={toggleCashoutModal} showHeaderIcon={false}
                          showHeaderTitle={false} showCloseIcon={false} padding="0px" width="45rem">
                <CashoutForm {...cashoutFormProps}></CashoutForm>
            </ModalOpacity>

            
            <ModalOpacity isOpen={showCashoutErrorModal} toggleOpen={toggleCashoutErrorModal} showHeaderIcon={false}
                          showHeaderTitle={false} padding="8px">
                <S.ErrorModal>
                    <S.ErrorModalTitle>{translation('account_betHistoryTransactionItem_cashout_error_title')}</S.ErrorModalTitle>
                    <S.ErrorModalMessage>{translation('account_betHistoryTransactionItem_cashout_error_' + cashoutErrorCode)}</S.ErrorModalMessage>
                </S.ErrorModal>
            </ModalOpacity>

            <S.BetHistoryItem className={`bet-history-item ${transactionStatusClass}`}>

                {/* <div className="transaction-id">
                    {transactionId}
                </div> */}

                <div className={`relative transaction-date${(process.env.CASHOUT) ? '-cashout' : ''}`}>
                    <span>{formatDate(regDate, 'dd-MM-yyyy')}</span> <span>{formatDate(regDate, 'HH:mm:ss')}</span>
                    {process.env.CASHOUT && (<>
                        {(checkCashout(transactionId) && !checkFreebet(transactionId)) ? <>
                            <span className="custom-label cashout">{translation('common_cashout')}</span>
                            <span className="custom-angle cashout"></span></> : <></>}
                     </>)}
                </div>

                <div className={`transaction-amount${(process.env.CASHOUT) ? '-cashout' : ''}`}>
                    {stake}&nbsp;{currencyCode}
                </div>

                <div className="transaction-win">
                    {(possibleReturn - tax).toFixed(2)}&nbsp;{currencyCode}
                </div>

                {/* <div className="transaction-confirmed-win">
                    {confirmedReturn}&nbsp;{currencyCode}
                </div> */}
{/* 
                {process.env.BONUS_BET && (<div className="transaction-bonus">
                    {bonus}&nbsp;{currencyCode}
                </div>)} */}

                <div className="transaction-type">
                    <span>{translation(parseSlipType(slipType))}</span>

                    {(process.env.ENABLE_FREEBET && checkFreebet(transactionId)) && (
                        <span>{translation('account_betHistoryTransactionItem_transactionWithFreebet')}</span>)}
                    {(typeof bonusId != 'undefined') && (<span>{translation('account_betHistoryTransactionItem_transactionFromBonusBalance')}</span>)}
                </div>
{/* 
                 {process.env.CASHOUT && (<div className="transaction-cashout">
                    {(checkCashout(transactionId) && !checkFreebet(transactionId)) ? (
                            <button
                                onClick={toggleCashout.bind(null, transactionId)}>{translation('account_betHistoryTransactionItem_cashout')}</button>) : 'UNAVAILABLE'}
                 </div>)} 
*/}
                {/* {process.env.REBET_ALLOWED && (
                    <div className="td transaction-rebet">
                        {(() => {
                            const hasRebet = isRebetPossible(index);
                            if (hasRebet) {
                                return ( <button className="rebet-btn" onClick={addRebet.bind(null, index)}></button> )
                            }
                        })()}
                    </div>
                )} */}

                <div className={`transaction-status ${transactionStatusClass}`}>
                    <button id={`transaction-details-${transactionId}`} onClick={toggleTransactionDetails}>
                        <span>{transactionStatus}</span>
                        <i className={`ion arrows ion-ios-arrow-${(showTransactionDetails) ? 'down' : 'right'}`}></i>
                    </button>
                </div>

                {process.env.PRINT_TICKET && JSON.parse(process.env.PRINT_TICKET) && (
                  <div id={`transaction-details-${transactionId}`} className='transaction-print' onClick={(event) => printTransaction(event, transactionId, showTransactionDetails)}>
                      <div className='print-icon'></div>
                  </div>
                )}

            </S.BetHistoryItem>

            <BetHistoryItemDetails 
                isVisible={showTransactionDetails} 
                transaction={transaction} 
                toggleCashoutModal={toggleCashoutModal}
                toggleCashoutErrorModal={toggleCashoutErrorModal}
                prepareCashout={prepareCashout}
                checkCashout={checkCashout}
                checkFreebet={checkFreebet}
                setCashoutErrorCode={setCashoutErrorCode}>
            </BetHistoryItemDetails>

        </S.BetHistoryItemWrapper>
    );
};

export default BetHistoryTransactionItem;
