import typeToReducer from "type-to-reducer";
import update from 'immutability-helper';

import {
    LOAD_OFFERS_CANCELED_ON_WITHDRWAL,
    LOAD_OFFERS_BALANCES,
    WITHDRAW_OFFER_BALANCE

} from "offersActions.js";

let initialState = {
    isPending: false,
    error: null,
    offersBalances: [],
    isOffersBalancesPending: false,
    isOfferWithdrawBalancePending: false,
    isOfferWithdrawSuccess: false
};


export default typeToReducer({
    [LOAD_OFFERS_CANCELED_ON_WITHDRWAL]: {
        PENDING: (state, action) => {
            return {...state, isPending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {callOffersCanceledOnWithdrawal}}) => {
            return {...state, isPending: false, callOffersCanceledOnWithdrawal};
        }
    },
    [LOAD_OFFERS_BALANCES]: {
        PENDING: (state, action) => {
            return {...state, isOffersBalancesPending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, error, isOffersBalancesPending: false};
        },
        SUCCESS: (state, {payload: {offersBalances}}) => {
            return update(state, {
                isOffersBalancesPending: {$set: false},
                offersBalances: {$set: offersBalances}
            })
        }
    },
    [WITHDRAW_OFFER_BALANCE]: {
        PENDING: (state, action) => {
            return {...state, isOfferWithdrawBalancePending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, error, isOfferWithdrawBalancePending: false};
        },
        SUCCESS: (state, {payload: {data}}) => {
            return update(state, {
                isOfferWithdrawBalancePending: {$set: false},
                error: {$set: null},
                isOfferWithdrawSuccess: {$set: true}
            })
        }
    }
}, initialState);

