import React from 'react';
import S from 'StyledLoginForm.js';
import {Field, reduxForm, SubmissionError } from 'redux-form';
import {notifyError, translation} from 'helpers/utilsHelper.js';
import classNames from 'classnames';
import { activateSportRadarEventsTrack } from 'integrationActions.js';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';

// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    const errors = validation(values, validators.LOGIN);
    return errors;
};

const renderField = ({
    input,
    label,
    type,
    hidden,
    meta: {touched, error, warning}
}) => {
    const rowClass = classNames({
        'hidden': hidden
    });
    return (
        <S.Row className={rowClass}>
            <S.InputWrapper>
                <S.Input {...input} type={type} required="required"/>
                {touched && ((error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

const LoginForm = (props) => {

    const {loginOnSubmit,toggleLoginModal, toggleForgetPasswordModal, handleSubmit, pristine, submitting,toggleRegisterModal, error} = props;
    const onFormSubmit = async(values) => {
        try {
            let loginData = _pick(values,['login','password']);
            loginData.login = values.mobilePrefix + loginData.login;
            const user = await loginOnSubmit(loginData);
            activateSportRadarEventsTrack("login", "user", { action: "complete", userId: user?.userId?.toString()});
        } catch (err) {
            throw new SubmissionError({_error: err});
        }
    };
    const toggleRegisterAndLoginModal = ()=>{
        toggleLoginModal()
        toggleRegisterModal()
    }

    return (
        <S.LoginForm method="post" onSubmit={handleSubmit(onFormSubmit)}>
            <div>
            <S.Label>{translation('loginModal_login')}</S.Label>
            <Field
                name="login"
                type="text"
                component={renderField}
            />
            </div>
            <div>
            <S.Label>{translation('loginModal_password')}</S.Label>
            <Field
                name="password"
                type="password"
                component={renderField}
            />
            </div>
            <Field
                hidden={true}
                name="mobilePrefix"
                type="text"
                component={renderField}
                />

            <S.ForgottenPassword>
                <span>{translation('loginModal_forgotPassword')}</span><span><S.LoginLink onClick={toggleForgetPasswordModal}>{translation('loginModal_forgotPasswordLink')}
            </S.LoginLink></span>

            </S.ForgottenPassword>

            {error && <S.SubmissionError className="submission-error">{error}</S.SubmissionError>}

            <S.SubmitBtn type="submit" disabled={pristine || submitting}>{translation('loginModal_loginBtn')}</S.SubmitBtn>

            <S.CreateAccountContainer>
            <span>{translation('loginModal_createAccount')} <S.LoginLink onClick={toggleRegisterAndLoginModal}>{translation('loginModal_clickHere')}
            </S.LoginLink></span>
            </S.CreateAccountContainer>


        </S.LoginForm>
    );
}

const mapStateToProps = (state) => {
    const {Auth: {brandData}} = state;
    return {
        initialValues: {
            mobilePrefix: brandData?.mobilePrefix ?? ''
        }
    }
}

let LoginReduxForm = reduxForm({
    form: 'login',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate
})(LoginForm)

LoginReduxForm = connect( mapStateToProps)(LoginReduxForm);

export default LoginReduxForm 